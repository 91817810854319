import { TitleInformationComponent } from "../../components";
import { useCustomHook } from "../../hooks/useDispatchTimeCurrent";
import { StoreDetailTableHeadContainer } from "../StoreDetailTableHeadContainer/StoreDetailTableHeadContainer";
import { StoreSheduleDetailTableBodyContainer } from "../StoreSheduleDetailTableBodyContainer/StoreSheduleDetailTableBodyContainer";

export const StoreDetailGroupsShedulesContainer = ({
  data,
  handleOnchage,
  handleAdd,
}) => {
  const { handleClick } = useCustomHook();
  const handleShedules = (index, name, selectHour = "") => {
    handleClick(index, "Shedules", name, selectHour);
  };
  return (
    <div className="comboDetailGroupsContainer">
      <TitleInformationComponent title={"Horarios de Recojo por Tienda"} />
      <hr />
      <table className="comboDetailGroupsContainer__table">
        <StoreDetailTableHeadContainer
          openingTime={true}
          closingTime={true}
          isActive={true}
          isDelete={true}
        />
        <tbody>
          {data?.map((item, index) => (
            <StoreSheduleDetailTableBodyContainer
              key={index}
              {...item}
              isActive
              isDelete
              onChange={handleOnchage}
              index={index}
              getTimeCurrent={handleShedules}
            />
          ))}
          <tr className="comboDetailGroupsContainer__table__button">
            <td>
              <button onClick={handleAdd}>Agregar Producto</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
