import { TitleComponent } from "../../../../components";
import { SettingBoxContainer } from "../../../../containers";

const data = [
  {
    id: 1,
    title: "Banner Principal",
    text: "Bienvenido a nuestra página de inicio.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/home-31-03-2023.webp",
    slug: "principal",
  },
  {
    id: 2,
    title: "Banner Lo Más Nuevo",
    text: "Conoce más sobre nosotros y nuestra misión.",
    url: "https://cdn-icons-png.flaticon.com/512/4906/4906519.png",
    slug: "lo-mas-nuevo",
  },
  {
    id: 3,
    title: "Banner Lo Más Pedido",
    text: "Descubre nuestra historia y cómo comenzamos.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/history-31-03-2023.png",
    slug: "lo-mas-pedido",
  },
];

export const SettingCoffeeShopBannersIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Banners"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
