import { useDispatch, useSelector } from "react-redux";
import { setCommentOrderProduct } from "../../store/order/ordersSlice";

export const SelectCommentComponent = ({ indexProduct }) => {
  const { coment } = useSelector(
    (state) => state.createOrder.ordenesProductos[indexProduct]
  );
  const dispatch = useDispatch();

  //   dispatch(setCreateOrder({ reference: e.target.value }));
  return (
    <div className="selectCommentComponent">
      <div className="selectCommentComponent__text">
        COMENTARIOS ADICIONALES
      </div>
      <textarea
        className="selectCommentComponent__input"
        onChange={(e) => {
          dispatch(
            setCommentOrderProduct({
              index: indexProduct,
              coment: e.target.value,
            })
          );
        }}
        value={coment}
      />
    </div>
  );
};
