export const TitleComponent = ({
  title,
  style = {
    fontSize: "32px",
    fontWeight: "bold",
  },
}) => {
  const { fontSize, fontWeight } = style;
  return (
    <div
      className="titleComponent"
      style={{ fontSize: fontSize , fontWeight: fontWeight }}
    >
      <h2>{title}</h2>
    </div>
  );
};
