import { Link } from "react-router-dom";

export const CouponListFieldsValueComponent = ({ coupon }) => {
  const url = `/cupones/${coupon.id}/detalles`;
  return (
    <tr className="clientListFieldsValueComponent">
      <td className="clientListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="clientListFieldsValueComponent__fullName">
        <Link to={url}>{coupon.name}</Link>
      </td>
      <td className="clientListFieldsValueComponent__code">
        <Link to={url}>{coupon.code}</Link>
      </td>
      <td className="clientListFieldsValueComponent__email">
        <Link to={url}>{coupon.couponType}</Link>
      </td>
      <td className="clientListFieldsValueComponent__code">
        <Link to={url}>{coupon.discont}</Link>
      </td>
      <td className="clientListFieldsValueComponent__phone">
        <Link to={url}>{coupon.discontType}</Link>
      </td>
    </tr>
  );
};
