import { useDispatch, useSelector } from "react-redux";
import {
  ComboDetailGroupsContainer,
  ComboDetailInformationContainer,
} from "../";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getRetrieveCombo } from "../../store/combo";
import { getListOptionChannel } from "../../store/channel";
import { getListOptionsCollection } from "../../store/collection";
import { getListOptionTag } from "../../store/tag/thunks";
import { getListOptionComplement } from "../../store/complement";
import { getListOptionsProducts } from "../../store/product";
import { LoadingComponent } from "../../components";

export const CombosDetailMainContainer = () => {
  const { comboData, showLoading } = useSelector(
    (state) => state.retrieveCombo
  );
  const { listOptionChannelData } = useSelector(
    (state) => state.listOptionChannel
  );
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const { listOptionsCollection } = useSelector(
    (state) => state.listOptionsCollection
  );
  const { listOptionTagData } = useSelector((state) => state.listOptionTag);
  const { listOptionComplementData } = useSelector(
    (state) => state.listOptionComplement
  );
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.combosId) {
      dispatch(getRetrieveCombo(params.combosId));
    }
    dispatch(getListOptionChannel());
    dispatch(getListOptionsCollection());
    dispatch(getListOptionTag());
    dispatch(getListOptionComplement());
    dispatch(getListOptionsProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!showLoading && params.combosId ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <ComboDetailInformationContainer
            comboData={comboData}
            listOptionChannelData={listOptionChannelData}
            listOptionsCollection={listOptionsCollection}
            listOptionTagData={listOptionTagData}
            listOptionComplementData={listOptionComplementData}
            params={params}
          />
          <ComboDetailGroupsContainer
            comboData={comboData}
            listOptionsProducts={listOptionsProducts}
          />
        </div>
      )}
    </>
  );
};
