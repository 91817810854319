import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPostAuthorization } from "../../services/requestPost";
import { cleanCreateNote } from "../../store/note";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const CollectionCreateHeaderContainer = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  let body = useSelector((state) => state.createCollection);
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };
  const dispatch = useDispatch();
  const createCollection = () => {
    const token = localStorage.getItem("token");
    const objCopy = { ...body };
    if (objCopy["active"] === undefined) {
      objCopy["active"] = true;
    }
    requestPostAuthorization(`admin/colecciones/`, objCopy, token);
    dispatch(cleanCreateNote());
  };
  return (
    <div className="collectionCreateHeaderContainer ">
      <div className="collectionCreateHeaderContainer__title">
        <TitleComponent title="Crear Colección" />
      </div>
      <div className="collectionCreateHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Crear Colección"
            msg="¿Está seguro que desea crear la Colección?"
            onClose={onOpenConfirmd}
            onConfirm={createCollection}
          />
        </ModalLayout>
      )}
    </div>
  );
};
