import { useSelector } from "react-redux";
import { ModifierListFieldsValueComponent } from "../../components";

export const ModifierListFieldsValueContainer = () => {
  const { modifierData } = useSelector((state) => state.listModifiers);
  return (
    <tbody>
      {modifierData?.results?.length > 0 &&
        modifierData?.results?.map((modifier) => {
          return (
            <ModifierListFieldsValueComponent
              key={modifier.id}
              modifier={modifier}
            />
          );
        })}
    </tbody>
  );
};
