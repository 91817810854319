export const InformationFieldInputContainer = ({
  label,
  value,
  name,
  handleOnchage,
  type = "text",
}) => {
  return (
    <div className="informationFieldInputContainer">
      <div className="informationFieldInputContainer__text">{label}</div>

      {type === "text" && (
        <div className="informationFieldInputContainer__input">
          <input
            value={value || ""}
            onChange={handleOnchage}
            name={name}
            type={type}
          />
        </div>
      )}

      {type === "number" && (
        <div className="informationFieldInputContainer__input-number">
          <input
            value={value || ""}
            type={type}
            name={name || ""}
            onChange={handleOnchage}
          />
        </div>
      )}

      {type === "checkbox" && (
        <div className="informationFieldInputContainer__input-checked">
          <input
            value={value || ""}
            checked={value || ""}
            type="checkbox"
            onChange={(e) => handleOnchage(e)}
            name={name}
          />
        </div>
      )}
    </div>
  );
};
