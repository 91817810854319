import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ModifierRetrieveHeaderContainer,
  ModifierRetrieveInformationContainer,
} from "../../../containers";
import { getRetrieveModifier } from "../../../store/modifier";
import { LoadingComponent } from "../../../components";
export const ProductModifierDetailPage = () => {
  const { modifierData, showLoading } = useSelector(
    (state) => state.retrieveModifier
  );
  const path = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRetrieveModifier(path.modifierId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path.modifierId]);

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <section className="productModifierDetailPage">
          <ModifierRetrieveHeaderContainer modifierId={path.modifierId} />
          <ModifierRetrieveInformationContainer data={modifierData} />
        </section>
      )}
    </>
  );
};
