import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setClaimLoading,
  setListClaim,
  setRetrieveClaim,
  setRetrieveClaimLoading,
} from "./claimSlice";

export const getListClaim = (params) => {
  return async (dispatch) => {
    dispatch(setClaimLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/reclamos/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "configuracion/landing/reclamacion";
        value.text = "Claim";
        value.page = params.page;
        dispatch(setListClaim({ data: value, params: params }));
        dispatch(setClaimLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveClaim = (claimId) => {
  return async (dispatch) => {
    dispatch(setRetrieveClaimLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/reclamos/${claimId}/`, token).then(
      (value) => {
        dispatch(setRetrieveClaim({ data: value }));
        dispatch(setRetrieveClaimLoading({ showLoading: false }));
      }
    );
  };
};
