
import { createSlice } from "@reduxjs/toolkit";
export const listOptionsDocumentSlice = createSlice({
    name: "listOptionsDocument",
    initialState: {
        listOptionsDocument: [],
    },
    reducers: {
      setListOptionsDocument: (state, action) => {
        state.listOptionsDocument = action.payload.data;
      },
    },
  });
  export const { setListOptionsDocument } = listOptionsDocumentSlice.actions;