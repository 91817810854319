import { useSelector } from "react-redux";
import { CollectionListFieldsValueComponent } from "../../components";

export const CollectionListFieldsValueContainer = () => {
  const { collectionData } = useSelector((state) => state.listCollections);
  return (
    <tbody>
      {collectionData?.results?.length > 0 &&
        collectionData?.results?.map((collection) => {
          return (
            <CollectionListFieldsValueComponent
              key={collection.id}
              collection={collection}
            />
          );
        })}
    </tbody>
  );
};
