import { getListOptions } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListModifier,
  setListOptionsModifier,
  setModifierLoading,
  setRetrieveModifier,
  setRetrieveModifierLoading,
  setUpdateModifier,
} from "./modifierSlice";

export const getListModifier = (params) => {
  return async (dispatch) => {
    dispatch(setModifierLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/modificadores/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "productos/modificadores";
        value.text = "Modificadores";
        value.page = params.page;
        dispatch(setListModifier({ data: value, params: params }));
        dispatch(setModifierLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveModifier = (modifierId) => {
  return async (dispatch) => {
    dispatch(setRetrieveModifierLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/modificadores/${modifierId}/`, token).then(
      (value) => {
        dispatch(setRetrieveModifier({ data: value }));
        dispatch(setUpdateModifier(value));
        dispatch(setRetrieveModifierLoading({ showLoading: false }));
      }
    );
  };
};

export const getListOptionsModifier = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/modificadores/lista_opciones/", token).then(
      (value) => {
        const inputParamsData = [value, "modifier", "name", "id"];
        const listOptionModifier = getListOptions(inputParamsData);
        dispatch(setListOptionsModifier({ data: listOptionModifier }));
      }
    );
  };
};
