import {
  ProductDetailInformationContainer,
  ProductDetailInformationSecondContainer,
  ProductDetailGroupPresentationContainer,
  ProductDetailGroupModifiedContainer,
  ProductDetailGroupNoteContainer,
} from "..";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListOptionChannel } from "../../store/channel";
import { getListOptionComplement } from "../../store/complement";
import { getListOptionTag } from "../../store/tag/thunks";
import { getListOptionsModifier } from "../../store/modifier";
import { getListOptionsNote } from "../../store/note";

export const ProductCreateMainContainer = () => {
  const productData = useSelector((state) => state.updateProduct);
  const { listOptionChannelData } = useSelector(
    (state) => state.listOptionChannel
  );
  const { listOptionComplementData } = useSelector(
    (state) => state.listOptionComplement
  );
  const { listOptionTagData } = useSelector((state) => state.listOptionTag);
  const { listOptionsModifier } = useSelector(
    (state) => state.listOptionsModifier
  );
  const { listOptionsNote } = useSelector((state) => state.listOptionsNote);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptionChannel());
    dispatch(getListOptionComplement());
    dispatch(getListOptionTag());
    dispatch(getListOptionsModifier());
    dispatch(getListOptionsNote());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="productDetailMainContainer">
      <ProductDetailInformationContainer
        data={productData}
        channels={listOptionChannelData}
        isCreate={true}
      />
      <ProductDetailInformationSecondContainer
        data={productData}
        optionsTags={listOptionTagData}
        optionsComplement={listOptionComplementData}
      />
      <ProductDetailGroupPresentationContainer
        data={productData?.gruposPresentaciones?.[0]}
      />
      <ProductDetailGroupModifiedContainer
        data={productData?.gruposModificadores?.[0]}
        options={listOptionsModifier}
      />
      <ProductDetailGroupNoteContainer
        data={productData?.gruposNotas?.[0]}
        options={listOptionsNote}
      />
    </div>
  );
};
