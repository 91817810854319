import { createSlice } from "@reduxjs/toolkit";
export const listEnvironmentSlice = createSlice({
  name: "listEnvironment",
  initialState: {
    environmentData: [],
    showLoading: false,
  },
  reducers: {
    setListEnvironment: (state, action) => {
      state.environmentData = action.payload.data;
    },
    setEnvironmentLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListEnvironment, setEnvironmentLoading } =
  listEnvironmentSlice.actions;

export const retrieveEnvironmentSlice = createSlice({
  name: "retrieveEnvironment",
  initialState: {
    environmentData: {
      key: "",
      value: "",
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveEnvironment: (state, action) => {
      state.environmentData.key =
        action.payload?.data?.key ?? state?.environmentData?.key;
      state.environmentData.value =
        action?.payload?.data?.value ?? state.environmentData.value;
    },

    clearUpdateEnvironment: (state) => {
      state.environmentData = {
        key: "",
        value: "",
      };
    },
    setRetrieveEnvironmentLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveEnvironment,
  clearUpdateEnvironment,
  setRetrieveEnvironmentLoading,
} = retrieveEnvironmentSlice.actions;
