import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { clearUpdateCombo } from "../../store/combo/comboSlice";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const SettingReceptionHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { receptionData } = useSelector((state) => state.retrieveReception);

  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const handleCreateCombo = async () => {
    const body = { ...receptionData };
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/correosRecepciones/${path.receptionId}/`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateCombo());
      navigate("/configuracion/correos-de-recepcion");
    });
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle de Correos de Recepción" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Actualizar"} onClick={toggleModalUpdate} />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Correos de Recepción"
            msg="¿Está segur@ que desea actualizar Correos de Recepción?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
