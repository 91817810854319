import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerBreadSlice = createSlice({
  name: "retrieveBannerBread",
  initialState: {
    bannerBreadData: {
      coverDesktop: "",
      coverMobile: "",
      photos: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerBread: (state, action) => {
      state.bannerBreadData.coverDesktop =
        action.payload?.data?.coverDesktop ||
        state?.bannerBreadData?.coverDesktop;
      state.bannerBreadData.coverMobile =
        action.payload?.data?.coverMobile ||
        state?.bannerBreadData?.coverMobile;
      state.bannerBreadData.photos =
        action.payload?.data?.photos || state?.bannerBreadData?.photos;
    },
    setRetrieveBannerBreadLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerBread, setRetrieveBannerBreadLoading } =
  retrieveBannerBreadSlice.actions;
