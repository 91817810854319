import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  clearUpdateProductModifier,
  setUpdateProductModifier,
} from "../../store/product/productSlice";

export const ProductDetailModifierContainer = ({
  options,
  index,
  data,
  modifiers,
  setModifiers,
}) => {
  const dispatch = useDispatch();
  const modifierInitial = !!data
    ? {
        value: data.id,
        label: data.name,
        name: "modifier",
      }
    : false;
  const handleOnchageModifier = (e) => {
    if (!!e) {
      const newModifiers = [...modifiers];
      newModifiers[index] = {
        id: e.value,
        name: e.label,
      };
      setModifiers(newModifiers);
      dispatch(
        setUpdateProductModifier({
          groupIndex: 0,
          index: index,
          modifierId: e.value,
        })
      );
    } else {
      clearModifierCurrent();
    }
  };
  const clearModifierCurrent = (e) => {
    const newModifiers = [...modifiers];
    newModifiers.splice(index, 1);
    setModifiers(newModifiers);
    dispatch(
      clearUpdateProductModifier({
        groupIndex: 0,
        index: index,
      })
    );
  };
  return (
    <div className="productDetailModifierContainer">
      <div className="productDetailModifierContainer__select">
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={modifierInitial}
          isSearchable={true}
          isClearable={true}
          name="modifier"
          options={options}
          placeholder={"Elije un modificador"}
          onChange={handleOnchageModifier}
        />
      </div>
      <div className="productDetailModifierContainer__button">
        <button onClick={clearModifierCurrent}>Eliminar</button>
      </div>
    </div>
  );
};
