import { PaymentListFieldsKeyComponent } from "../../components";
import { PaymentListFieldsValueContainer } from "../PaymentListFieldsValueContainer/PaymentListFieldsValueContainer";

export const PaymentListTableContainer = () => {
  return (
    <table className="productListTableContainer">
      <PaymentListFieldsKeyComponent />
      <PaymentListFieldsValueContainer />
    </table>
  );
};
