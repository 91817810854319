import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  OrderListContainer,
  PaginationContainer,
} from "../../containers";
import { getListOrders } from "../../store/order";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

export const OrderIndexPage = () => {
  const { orderData, showLoading } = useSelector((state) => state.listOrders);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageOrder = serachParams.get("page");
  const stateOrder = serachParams.get("state");
  const shopOrder = serachParams.get("shop");
  const modalityOrder = serachParams.get("modality");
  const searchOrder = serachParams.get("search");

  let ordersParams = {
    page_size: 20,
    page: pageOrder ?? 1,
    state: stateOrder ?? "",
    shop: shopOrder ?? "",
    modality: modalityOrder ?? "",
    search: searchOrder ?? "",
  };

  useEffect(() => {
    dispatch(getListOrders(ordersParams));
    // eslint-disable-next-line
  }, [pageOrder, pageOrder, stateOrder, shopOrder, modalityOrder, searchOrder]);

  const dataHeader = {
    title: "Ordenes",
    nameButton: "Crear Orden",
    url: "/ordenes/crear",
  };
  return (
    <section className="orderIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <OrderListContainer />
          <PaginationContainer data={orderData} />
        </>
      )}
    </section>
  );
};
