import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  StoreDetailHeaderContainer,
  StoreDetailMainContainer,
} from "../../../containers";
import { getStoreDetail } from "../../../store/store/thunks";

export const SettingStoreDetailPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.storeId) {
      dispatch(getStoreDetail(params.storeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="orderDetailPage">
      <StoreDetailHeaderContainer />
      <StoreDetailMainContainer />
    </section>
  );
};
