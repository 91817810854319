import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ProductDetailNoteContainer, ProductDetailTableContainer } from "../";
import {
  clearUpdateProductNoteGroup,
  setUpdateProductNoteGroup,
} from "../../store/product";

export const ProductDetailGroupNoteContainer = ({ data, options }) => {
  const dispatch = useDispatch();
  const [noteGroup, setNoteGroup] = useState({
    name: "",
    isMandatory: false,
    order: 1,
    active: true,
    isMultiple: false,
  });
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    if (!!data) {
      setNoteGroup(data);
      setNotes(data?.notas ? data?.notas : []);
    }
  }, [data]);

  const handleOnchageNoteGroup = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active" || name === "isMandatory" || name === "isMultiple") {
      value = e.target.checked;
    }
    setNoteGroup({ ...noteGroup, [name]: value });
    const updateProductNoteGroup = {
      ...noteGroup,
      [name]: value,
    };
    updateProductNoteGroup.index = 0;
    dispatch(setUpdateProductNoteGroup(updateProductNoteGroup));
  };
  const addNote = () => {
    setNotes([...notes, null]);
  };
  const clearGroupCurrent = (e) => {
    setNoteGroup({
      name: "",
      isMandatory: false,
      order: 1,
      active: true,
      isMultiple: false,
    });
    setNotes([]);
    dispatch(clearUpdateProductNoteGroup());
  };
  return (
    <div className="productDetailGroupNoteContainer">
      <div className="productDetailGroupNoteContainer__container">
        <div className="productDetailGroupNoteContainer__container__title">
          Grupo de Notas
        </div>
        <div className="productDetailGroupNoteContainer__container__table">
          <ProductDetailTableContainer
            isPresentation={false}
            data={noteGroup}
            isOpen={false}
            handleOnchage={handleOnchageNoteGroup}
            clearGroupCurrent={clearGroupCurrent}
          />
        </div>
        {!!noteGroup.name && (
          <div className="productDetailGroupNoteContainer__container__left">
            <div className="productDetailGroupNoteContainer__container__left__title">
              Notas
            </div>
            {notes.map((note, index) => (
              <ProductDetailNoteContainer
                key={index}
                options={options}
                data={note}
                index={index}
                notes={notes}
                setNotes={setNotes}
              />
            ))}
            <div
              className="productDetailGroupModifiedContainer__container__left__button"
              onClick={addNote}
            >
              <div>Agregar Nota</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
