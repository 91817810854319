import { apiPath } from "./apiPath";

export const exportExcel = (url, name, body) => {
  const token = localStorage.getItem("token");
  apiPath
    .post(url, body, {
      headers: {
        Authorization: `Token ${token}`,
      },
      responseType: "arraybuffer",
    })
    .then((value) => {
      const myBlob = new Blob([value.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = URL.createObjectURL(myBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${name}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
