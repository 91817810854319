import { TitleComponent } from "../../components";

export const SettingLandingContactHeaderContainer = () => {
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del Contacto" />
      </div>
    </section>
  );
};
