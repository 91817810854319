import { requestGetAuthorization } from "../../../../services/requestGet";
import { setRetrieveBannerNew, setRetrieveBannerNewLoading } from "./newSlice";

function transformData(data) {
  const transformedData = {};
  for (const key in data) {
    if (Array.isArray(data[key]) && key === "photo") {
      const photosArray = [];
      data[key].forEach((photoObject) => {
        const url = photoObject.photo;
        const name = url.substring(url.lastIndexOf("/") + 1);
        const slug = photoObject.slug;
        photosArray.push({
          url,
          type: "",
          name,
          slug,
        });
      });
      transformedData[key] = photosArray;
    } else if (!Array.isArray(data[key])) {
      transformedData[key] = {
        url: data[key],
        type: "",
        name: data[key].substring(data[key].lastIndexOf("/") + 1),
      };
    } else {
      transformedData[key] = data[key];
    }
  }
  return transformedData;
}

export const getRetrieveBannerNew = () => {
  return async (dispatch) => {
    dispatch(setRetrieveBannerNewLoading({ showLoading: true }));

    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/banners/lo_mas_nuevo/`, token).then(
      (value) => {
        let obj = { photo: value.photo };
        const transformedData = transformData(obj);
        dispatch(
          setRetrieveBannerNew({
            data: transformedData,
            slugItem: value.slugItem,
          })
        );
        dispatch(setRetrieveBannerNewLoading({ showLoading: false }));
      }
    );
  };
};
