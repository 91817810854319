import { useEffect } from "react";
import {
  ListHeaderContainer,
  PaginationContainer,
  ProductListContainer,
} from "../../containers";

import { getListProducts } from "../../store/product";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

export const ProductIndexPage = () => {
  const { productData, showLoading } = useSelector(
    (state) => state.listProducts
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageProduct = serachParams.get("page");
  const collectionProduct = serachParams.get("collection");
  const activeProduct = serachParams.get("active");
  const searchProduct = serachParams.get("search");

  let productParams = {
    page_size: 20,
    page: pageProduct ?? 1,
    active: activeProduct ?? "",
    collection: collectionProduct ?? "",
    search: searchProduct ?? "",
  };
  useEffect(() => {
    dispatch(getListProducts(productParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProduct, collectionProduct, activeProduct, searchProduct]);

  const dataHeader = {
    title: "Productos",
    nameButton: "Crear Producto",
    url: "/productos/crear",
  };

  return (
    <section className="productIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ProductListContainer />
          <PaginationContainer data={productData} />
        </>
      )}
    </section>
  );
};
