import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
export const ProductListFieldsValueComponent = ({ product }) => {
  const url = `/productos/${product.id}/detalles`;
  const stateColor = orderStateColor(product?.active);
  return (
    <tr className="productListFieldsValueComponent">
      <td className="productListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="productListFieldsValueComponent__code">
        <Link to={url}>{product?.codigo} </Link>
      </td>
      <td className="productListFieldsValueComponent__name">
        <Link to={url}>{product?.name || ""}</Link>
      </td>
      <td className="productListFieldsValueComponent__description">
        <Link to={url}>{product?.description}</Link>
      </td>
      <td className="productListFieldsValueComponent__image">
        <Link to={url}>
          <img src={product?.imageMini} alt={product?.name} />
        </Link>
      </td>
      <td className="productListFieldsValueComponent__collection">
        <Link to={url}>{product?.colections?.[0]?.name}</Link>
      </td>
      <td className="productListFieldsValueComponent__sub-category">
        <Link to={url}>
          {product?.subCategory
            ? product?.subCategory?.name
            : "Sin sub categoria"}
        </Link>
      </td>
      <td className={`productListFieldsValueComponent__state ${stateColor}`}>
        <Link to={url}>{product?.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
