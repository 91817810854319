import { useDispatch, useSelector } from "react-redux";
import {
  SectionTableContainer,
  SettingLandingCollaboratorsInformationContainer,
} from "..";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getRetrieveCollaborators } from "../../store/collaborators/thunks";
import {
  cleanCreateCollaborator,
  setAddCollaboratorsFunctions,
  setAddCollaboratorsRequirements,
  setRetrieveCollaborators,
  setUpdateCollaboratorFunctions,
  setUpdateCollaboratorRequirements,
} from "../../store/collaborators/collaboratorsSlice";
import { LoadingComponent } from "../../components";

export const SettingLandingCollaboratorsMainContainer = () => {
  const { collaboratorsData, showLoading } = useSelector(
    (state) => state.retrieveCollaborators
  );
  const path = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (path.collaboratorId) {
      dispatch(getRetrieveCollaborators(path.collaboratorId));
    } else {
      dispatch(cleanCreateCollaborator());
    }
    // eslint-disable-next-line
  }, []);

  const handleOnchangeDispathFunctions = (e, index) => {
    const payload = {
      name: e.target.name,
      value: e.target.name === "active" ? e.target.checked : e.target.value,
      index,
    };
    dispatch(setUpdateCollaboratorFunctions(payload));
  };

  const handleOnchangeDispathRequirement = (e, index) => {
    const payload = {
      name: e.target.name,
      value: e.target.name === "active" ? e.target.checked : e.target.value,
      index,
    };
    dispatch(setUpdateCollaboratorRequirements(payload));
  };

  const handleOnchangeAddFuntion = () => {
    dispatch(setAddCollaboratorsFunctions());
  };
  const handleOnchangeAddRequirements = () => {
    dispatch(setAddCollaboratorsRequirements());
  };

  const handleOnchage = ({ target }) => {
    const { name, checked, value } = target;
    let result = value;
    if (name === "active") result = checked;
    dispatch(setRetrieveCollaborators({ data: { [name]: result } }));
  };

  return (
    <div className="productDetailMainContainer">
      {!showLoading && path.collaboratorId ? (
        <LoadingComponent />
      ) : (
        <>
          <SettingLandingCollaboratorsInformationContainer
            data={collaboratorsData}
            handleOnchage={handleOnchage}
          />
          <SectionTableContainer
            data={collaboratorsData?.functions}
            handleOnchangeDispath={handleOnchangeDispathFunctions}
            handleOnchangeAddFuntion={handleOnchangeAddFuntion}
            title="Funciones"
          />
          <SectionTableContainer
            data={collaboratorsData?.requirements}
            handleOnchangeDispath={handleOnchangeDispathRequirement}
            handleOnchangeAddFuntion={handleOnchangeAddRequirements}
            title="Requisitos"
          />
        </>
      )}
    </div>
  );
};
