import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { getListOptions } from "../../helpers";
import {
  getRetrieveComplement,
  setRetrieveComplement,
  setUpdateComplementProduct,
} from "../../store/complement";
import { getListOptionsProducts } from "../../store/product";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const ProductComplementDetailInformationContainer = () => {
  const complementData = useSelector((state) => state.retrieveComplement);
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptionsProducts());
    if (params.complementId) {
      dispatch(getRetrieveComplement(params.complementId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processListOptionsProducts = getListOptions([
    listOptionsProducts,
    "products",
    "name",
  ]);

  //   const obj = processListOptionsProducts.filter((objeto) =>
  //     complementData?.products.includes(objeto.id)
  //   );

  const handleOnchageMultipleProducts = (e) => {
    dispatch(setUpdateComplementProduct({ products: e }));
  };

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    dispatch(setRetrieveComplement({ data: { [key]: value } }));
  };

  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />

      {!complementData?.showLoading && params.complementId ? (
        <LoadingComponent />
      ) : (
        <>
          <SelectOptionsContainer
            title={"Nombre"}
            name="name"
            handleOnchange={handleOnChange}
            isInput={true}
            value={complementData.name}
          />
          <SelectOptionsContainer
            title={"Productos "}
            handleOnchange={handleOnchageMultipleProducts}
            name="products "
            isInputMulti={true}
            value={complementData?.products}
            dataList={processListOptionsProducts}
            placeholderTitle={"Seleccione 1 o más productos"}
          />
          <SelectOptionsContainer
            title={"Order"}
            name="order"
            handleOnchange={handleOnChange}
            isNumber={true}
            value={complementData?.order ? complementData?.order : "0"}
          />
        </>
      )}
    </div>
  );
};
