import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListDataFilter } from "../../helpers/getListDataFilter";
import { setCollection, setCreateCollection } from "../../store/collection";

const SelectionContainerField = ({
  name,
  listOptionData,
  id,
  title,
  value,
  retrieve,
  index,
}) => {
  const [myValue, setMyValue] = useState("");
  const dispatch = useDispatch();
  const collectionData = useSelector((state) => state.collection);
  const createCollectionData = useSelector((state) => state.createCollection);

  const handleSelect = (e) => {
    if (retrieve) {
      setMyValue(e.target.value);
      if (title === "Combos") {
        let data = collectionData?.combos;
        const combos = getListDataFilter(data, id, e.target.value);
        dispatch(setCollection({ combos: combos }));
        return;
      }
      let data = collectionData?.productos;
      const products = getListDataFilter(data, id, e.target.value);
      dispatch(setCollection({ productos: products }));
    } else {
      setMyValue(e.target.value);
      if (title === "Combos") {
        let data = createCollectionData?.combos;
        const combos = getListDataFilter(data, index, e.target.value);
        dispatch(setCreateCollection({ combos: combos }));
        return;
      }
      let data = createCollectionData?.productos;
      const products = getListDataFilter(data, index, e.target.value);
      dispatch(setCreateCollection({ productos: products }));
    }
  };

  useEffect(() => {
    setMyValue(value);
    console.log("valuee", value);
  }, [value]);

  return (
    <div className="sectionSelectionContainer__container__fields">
      <div className="sectionSelectionContainer__container__fields__input">
        <select name={name} value={myValue} onChange={(e) => handleSelect(e)}>
          <option value="">{name}</option>
          {listOptionData.map((item) => (
            <option key={item?.id} value={item?.id} defaultValue={name}>
              {item?.name}
            </option>
          ))}
        </select>
      </div>
      <div className="sectionSelectionContainer__container__fields__checked">
        <input type="checkbox" />
      </div>
    </div>
  );
};

export const SectionSelectionContainer = ({
  title,
  subTitle,
  data,
  listOptionData,
  handleOnchage,
  retrieve = true,
}) => {
  const [addFields, setAddFields] = useState([]);

  const addFieldsValueContainer = () => {
    let add = "a";
    setAddFields(addFields.concat(add));
  };
  console.log("gaa1->1 ", data);
  console.log("gaa1->2 ", listOptionData);
  return (
    <div className="sectionSelectionContainer">
      <div className="sectionSelectionContainer__title">{title}</div>
      <div className="sectionSelectionContainer__container">
        <div className="sectionSelectionContainer__container__header">
          <div className="sectionSelectionContainer__container__header__left">
            {subTitle}
          </div>

          <div className="sectionSelectionContainer__container__header__right">
            ¿Eliminar?
          </div>
        </div>
        {data?.map((item, index) => (
          <SelectionContainerField
            key={item?.id}
            listOptionData={listOptionData}
            name={item?.name}
            value={item?.id}
            id={index}
            handleOnchage={handleOnchage}
            retrieve={retrieve}
          />
        ))}
        {addFields.map((item, index) => (
          <SelectionContainerField
            title={title}
            key={index}
            listOptionData={listOptionData}
            name={item?.name}
            value={item?.id}
            id={data?.length === undefined ? index : index + data?.length}
            index={index}
            handleOnchage={handleOnchage}
            retrieve={retrieve}
          />
        ))}
        <div
          className="sectionSelectionContainer__container__button"
          onClick={addFieldsValueContainer}
        >
          <div>Agregar Producto adicional</div>
        </div>
      </div>
    </div>
  );
};
