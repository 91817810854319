import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyValueInputComponent } from "../../components";
import { getTypeClientOrders, setCreateClient } from "../../store/client";

export const ClientCreateInformationContainer = () => {
  const { typeClient: typesClient } = useSelector(
    (state) => state.listTypeClient
  );
  const data = useSelector((state) => state.createClient);
  const [clientData, setClientData] = useState(data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTypeClientOrders());
    // eslint-disable-next-line
  }, []);

  const handleOnchage = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setClientData({ ...clientData, [name]: value });
    dispatch(setCreateClient({ [name]: value }));
  };
  return (
    <div className="clientCreateInformationContainer">
      <div className="clientCreateInformationContainer__title">Información</div>
      <div className="clientCreateInformationContainer__container">
        <KeyValueInputComponent
          text={"Nombres"}
          name="names"
          value={clientData.names || ""}
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Apellidos"}
          name="surnames"
          value={clientData.surnames || ""}
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Email"}
          name="email"
          value={clientData.email || ""}
          type="email"
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Celular"}
          name="phone"
          value={clientData.phone || ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <div className="clientCreateInformationContainer__container__type">
          <p>Tipo de Cliente</p>
          <select name="type" onChange={handleOnchage}>
            <option value={""}>---------</option>
            {typesClient.map((type, index) => (
                <option value={type} key={index} >
                  {type}
                </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
