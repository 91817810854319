import {
  SettingLandingClaimHeaderContainer,
  SettingLandingClaimMainContainer,
} from "../../../../containers";

export const SettingLandingClaimDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingClaimHeaderContainer />
      <SettingLandingClaimMainContainer />
    </section>
  );
};
