import { ClientCreateHeaderContainer, ClientCreateInformationContainer } from "../../containers"

export const ClientCreatePage = () => {
  return (
    <div className="ClientCreatePage">
      <ClientCreateHeaderContainer />
      <ClientCreateInformationContainer />
    </div>
  )
}
