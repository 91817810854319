import state from "../../data/statePayment.json";
import {
  ButtonSearchComponent,
  FilterComponent,
  InputSearchComponent,
} from "../../components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestGetAuthorization } from "../../services/requestGet";

export const PaymentSearchContainer = () => {
  const [paymentMethod, setPaymentMethod] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/metodos_pagos/lista_opciones/", token).then(
      (value) => {
        setPaymentMethod(value);
      }
    );
  }, []);
  const filter = () => {
    const paymentMethod = document.getElementById("paymentMethod").value;
    const stateProduct = document.getElementById("statePayment").value;
    const searchProduct = document.getElementById("searchPayment").value;

    let url = `/pagos?`;
    if (stateProduct !== "null") {
      url = url.concat(`state=${stateProduct}`);
    }
    if (searchProduct !== "null") {
      url = url.concat(`&search=${searchProduct}`);
    }
    if (paymentMethod !== "null") {
      url = url.concat(`&paymentMethod=${paymentMethod}`);
    }
    navigate(url);
  };

  const dataInput = { id: "searchPayment", placeholder: "Buscar pago" };

  const handleEnter = (e) => {
    if (e.key !== "Enter") return;
    filter();
  };

  return (
    <div className="productSearchContainer">
      <div className="productSearchContainer__filters">
        <div className="productSearchContainer__filters__item">
          <FilterComponent data={state} name={"Estado"} id={"statePayment"} />
        </div>
        <div className="productSearchContainer__filters__item">
          <FilterComponent
            data={paymentMethod}
            name={"Métodos de Pago"}
            id={"paymentMethod"}
          />
        </div>
      </div>
      <div className="productSearchContainer__search">
        <div className="productSearchContainer__search__input">
          <InputSearchComponent data={dataInput} onKeyDown={handleEnter} />
        </div>
        <div className="productSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
