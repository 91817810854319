import { createSlice } from "@reduxjs/toolkit";
export const listProductsSlice = createSlice({
  name: "listProducts",
  initialState: {
    productData: [],
    showLoading: false,
  },
  reducers: {
    setListProducts: (state, action) => {
      state.productData = action.payload.data;
    },
    setProductLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListProducts, setProductLoading } = listProductsSlice.actions;

export const retrieveProductSlice = createSlice({
  name: "retrieveProduct",
  initialState: {
    productData: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveProduct: (state, action) => {
      state.productData = action.payload.data;
    },
    setRetrieveProductLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveProduct, setRetrieveProductLoading } =
  retrieveProductSlice.actions;

export const updateProductSlice = createSlice({
  name: "updateProduct",
  initialState: {
    name: "",
    slug: "",
    description: "",
    channels: [],
    igv: false,
    price: 0.0,
    pricePromotion: 0.0,
    order: 1,
    image: "",
    active: true,
    subCategory: null,
    colections: [],
    tags: [],
    isCustom: false,
    complements: [],
    gruposPresentaciones: [],
    gruposModificadores: [],
    gruposNotas: [],
    imageIsLoaded: false,
  },
  reducers: {
    setUpdateProduct: (state, action) => {
      state.name = action.payload.name || state.name;
      state.slug = action.payload.slug || state.slug;
      state.description = action.payload.description || state.description;
      state.channels = action.payload.channels || state.channels;
      state.igv =
        action.payload.igv !== undefined ? action.payload.igv : state.igv;
      state.price = action.payload.price || state.price;
      state.pricePromotion =
        action.payload.pricePromotion || state.pricePromotion;
      state.order = action.payload.order || state.order;
      state.image = action.payload.image || state.image;
      state.active =
        action.payload.active !== undefined
          ? action.payload.active
          : state.active;
      state.subCategory = action.payload.subCategory || state.subCategory;
      state.colections = action.payload.colections || state.colections;
      state.tags = action.payload.tags || state.tags;
      state.isCustom =
        action.payload.isCustom !== undefined
          ? action.payload.isCustom
          : state.isCustom;
      state.complements = action.payload.complements || state.complements;
      state.imageIsLoaded = action.payload.imageIsLoaded || state.imageIsLoaded;
    },
    setUpdateProductPresentationGroup: (state, action) => {
      const presentations =
        state.gruposPresentaciones[action.payload.index]?.presentaciones;
      const presentationGroup = {
        name: action.payload.name,
        isMandatory: action.payload.isMandatory,
        order: action.payload.order,
        active: action.payload.active,
        presentaciones: presentations || [],
      };
      state.gruposPresentaciones[action.payload.index] = presentationGroup;
    },
    setUpdateProductModifierGroup: (state, action) => {
      const modifiers =
        state.gruposModificadores[action.payload.index]?.modificadores;
      const modifierGroup = {
        name: action.payload.name,
        isMandatory: action.payload.isMandatory,
        order: action.payload.order,
        active: action.payload.active,
        isMultiple: action.payload.isMultiple,
        modificadores: modifiers || [],
      };
      state.gruposModificadores[action.payload.index] = modifierGroup;
    },
    setUpdateProductNoteGroup: (state, action) => {
      const notes = state.gruposNotas[action.payload.index]?.notas;
      const noteGroup = {
        name: action.payload.name,
        isMandatory: action.payload.isMandatory,
        order: action.payload.order,
        active: action.payload.active,
        isMultiple: action.payload.isMultiple,
        notas: notes || [],
      };
      state.gruposNotas[action.payload.index] = noteGroup;
    },
    setUpdateProductPresentation: (state, action) => {
      const presentation = {
        name: action.payload.name,
        amount: action.payload.amount,
        order: action.payload.order,
        active: action.payload.active,
      };
      state.gruposPresentaciones[action.payload.groupIndex].presentaciones[
        action.payload.index
      ] = presentation;
    },
    setUpdateProductModifier: (state, action) => {
      const modifierId = action.payload.modifierId;
      state.gruposModificadores[action.payload.groupIndex].modificadores[
        action.payload.index
      ] = modifierId;
    },
    setUpdateProductNote: (state, action) => {
      const noteId = action.payload.noteId;
      state.gruposNotas[action.payload.groupIndex].notas[action.payload.index] =
        noteId;
    },
    clearUpdateProductPresentationGroup: (state) => {
      state.gruposPresentaciones = [];
    },
    clearUpdateProductModifierGroup: (state) => {
      state.gruposModificadores = [];
    },
    clearUpdateProductNoteGroup: (state) => {
      state.gruposNotas = [];
    },
    clearUpdateProductPresentation: (state, action) => {
      const index = action.payload.index;
      const groupIndex = action.payload.groupIndex;
      state.gruposPresentaciones[groupIndex].presentaciones.splice(index, 1);
    },
    clearUpdateProductModifier: (state, action) => {
      const index = action.payload.index;
      const groupIndex = action.payload.groupIndex;
      state.gruposModificadores[groupIndex].modificadores.splice(index, 1);
    },
    clearUpdateProductNote: (state, action) => {
      const index = action.payload.index;
      const groupIndex = action.payload.groupIndex;
      state.gruposNotas[groupIndex].notas.splice(index, 1);
    },
    clearUpdateProduct: (state) => {
      state.name = "";
      state.slug = "";
      state.description = "";
      state.channels = [];
      state.igv = false;
      state.price = 0.0;
      state.pricePromotion = 0.0;
      state.order = 1;
      state.image = "";
      state.active = true;
      state.subCategory = null;
      state.colections = [];
      state.tags = [];
      state.isCustom = false;
      state.complements = [];
      state.gruposPresentaciones = [];
      state.gruposModificadores = [];
      state.gruposNotas = [];
    },
  },
});
export const {
  clearUpdateProduct,
  clearUpdateProductModifier,
  clearUpdateProductModifierGroup,
  clearUpdateProductNote,
  clearUpdateProductNoteGroup,
  clearUpdateProductPresentation,
  clearUpdateProductPresentationGroup,
  setUpdateProduct,
  setUpdateProductModifier,
  setUpdateProductModifierGroup,
  setUpdateProductNote,
  setUpdateProductNoteGroup,
  setUpdateProductPresentation,
  setUpdateProductPresentationGroup,
} = updateProductSlice.actions;

export const listOptionsProductsSlice = createSlice({
  name: "listOptionsProducts",
  initialState: {
    listOptionsProducts: [],
  },
  reducers: {
    setListOptionsProducts: (state, action) => {
      state.listOptionsProducts = action.payload.data;
    },
  },
});
export const { setListOptionsProducts } = listOptionsProductsSlice.actions;
