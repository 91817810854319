import { createSlice } from "@reduxjs/toolkit";
export const listCollectionsSlice = createSlice({
  name: "collection",
  initialState: {
    collectionData: [],
    showLoading: false,
  },
  reducers: {
    setListCollections: (state, action) => {
      state.collectionData = action.payload.data;
    },
    setCollectionLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListCollections, setCollectionLoading } =
  listCollectionsSlice.actions;

export const retrieveCollectionSlice = createSlice({
  name: "retrieveCollection",
  initialState: {
    collectionData: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveCollection: (state, action) => {
      state.collectionData = action.payload.data;
    },
    setRetrieveCollectionLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveCollection, setRetrieveCollectionLoading } =
  retrieveCollectionSlice.actions;

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    name: "",
    slug: "",
    description: "",
    startTime: "",
    endTime: "",
    order: 1,
    active: true,
    productos: [],
    combos: [],
  },
  reducers: {
    setCollection: (state, action) => {
      state.name = action.payload.name || state.name;
      state.slug = action.payload.slug || state.slug;
      state.description = action.payload.description || state.description;
      state.startTime = action.payload.startTime || state.startTime;
      state.endTime = action.payload.endTime || state.endTime;
      state.order = action.payload.order || state.order;
      state.active = action.payload.active;
      state.productos = action.payload.productos || state.productos;
      state.combos = action.payload.combos || state.combos;
    },
  },
});
export const { setCollection } = collectionSlice.actions;

export const listOptionsCollectionSlice = createSlice({
  name: "listOptionsCollection",
  initialState: {
    listOptionsCollection: [],
  },
  reducers: {
    setListOptionsCollection: (state, action) => {
      state.listOptionsCollection = action.payload.data;
    },
  },
});
export const { setListOptionsCollection } = listOptionsCollectionSlice.actions;

export const createCollectionSlice = createSlice({
  name: "createCollection",
  initialState: {
    name: "",
    slug: "",
    description: "",
    startTime: "",
    endTime: "",
    order: 1,
    active: true,
    productos: [],
    combos: [],
  },
  reducers: {
    setCreateCollection: (state, action) => {
      state.name = action.payload.name || state.name;
      state.slug = action.payload.slug || state.slug;
      state.description = action.payload.description || state.description;
      state.startTime = action.payload.startTime || state.startTime;
      state.endTime = action.payload.endTime || state.endTime;
      state.order = action.payload.order || state.order;
      state.active = action.payload.active;
      state.productos = action.payload.productos || state.productos;
      state.combos = action.payload.combos || state.combos;
    },
    cleanCreateCollection: (state) => {
      state.name = "";
      state.slug = "";
      state.description = "";
      state.startTime = "";
      state.endTime = "";
      state.order = 1;
      state.active = true;
      state.productos = [];
      state.combos = [];
    },
  },
});
export const { setCreateCollection, cleanCreateCollection } =
  createCollectionSlice.actions;
