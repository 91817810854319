import { ClientListFieldsKeyComponent } from "../../components";
import { ClientListFieldsValueContainer } from "../../containers";

export const ClientListTableContainer = () => {
  return (
    <table className="clientListTableContainer">
      <ClientListFieldsKeyComponent />
      <ClientListFieldsValueContainer />
    </table>
  );
};
