export const PaymentListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="paymentListFieldsKeyComponent">
        <th className="paymentListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="paymentListFieldsKeyComponent__code">Código</th>
        <th className="paymentListFieldsKeyComponent__order">Orden</th>
        <th className="paymentListFieldsKeyComponent__method">Metodo de pago</th>
        <th className="paymentListFieldsKeyComponent__state">Estado</th>
        <th className="paymentListFieldsKeyComponent__date">Fecha de creación</th>
      </tr>
    </thead>
  );
};
