import { getListOptions } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setCollection,
  setCollectionLoading,
  setListCollections,
  setListOptionsCollection,
  setRetrieveCollection,
  setRetrieveCollectionLoading,
} from "./collectionsSlice";

export const getListColletions = (params) => {
  return async (dispatch) => {
    dispatch(setCollectionLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/colecciones/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "productos/colecciones";
        value.text = "Colecciones";
        value.page = params.page;
        dispatch(setCollectionLoading({ showLoading: false }));
        dispatch(setListCollections({ data: value, params: params }));
      }
    );
  };
};

export const getRetrieveCollection = (id) => {
  return async (dispatch, getState) => {
    dispatch(setRetrieveCollectionLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/colecciones/${id}/`, token).then((value) => {
      dispatch(setRetrieveCollection({ data: value }));
      const productsIds = value?.productos?.map((item) => item.id);
      const combosIds = value?.combos?.map((item) => item.id);
      const updateData = { ...value };
      updateData.productos = productsIds;
      updateData.combos = combosIds;
      dispatch(setCollection(updateData));
      dispatch(setRetrieveCollectionLoading({ showLoading: false }));
    });
  };
};

export const getListOptionsCollection = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/colecciones/lista_opciones/", token).then(
      (value) => {
        const inputParamsData = [value, "collections", "name", "id"];
        const listOptionCollection = getListOptions(inputParamsData);
        dispatch(setListOptionsCollection({ data: listOptionCollection }));
      }
    );
  };
};
