import { useState } from "react";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../../containers";
import { requestUpdateAuthorization } from "../../services/requestUpdate";
import { useSelector } from "react-redux";
import { requestDelete } from "../../services/requestDelete";
import { useNavigate } from "react-router-dom";

export const NoteRetrieveHeaderContainer = ({ noteId }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const body = useSelector((state) => state.updateNote);
  const navigate = useNavigate();
  const onCloseCanceled = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };

  const updateNote = () => {
    const token = localStorage.getItem("token");
    requestUpdateAuthorization(`admin/notas/${noteId}/`, body, token);
  };
  const deleteModifier = () => {
    const token = localStorage.getItem("token");
    requestDelete(`admin/notas/${noteId}/`, token).then(() =>
      navigate("/productos/notas")
    );
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };
  return (
    <div className="noteRetrieveHeaderContainer ">
      <div className="noteRetrieveHeaderContainer__title">
        <TitleComponent title="Detalle de la nota" />
      </div>
      <div className="noteRetrieveHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Eliminar"}
          color={"red"}
          onClick={onCloseCanceled}
        />
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>

      {isOpenDelete && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Nota"
            msg="¿Está seguro que desea eliminar la nota?"
            style={canceledStateStyle}
            onClose={onCloseCanceled}
            onConfirm={deleteModifier}
          />
        </ModalLayout>
      )}

      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Editar Nota"
            msg="¿Está seguro que desea editar la nota?"
            style={canceledStateStyle}
            onClose={onOpenConfirmd}
            onConfirm={updateNote}
          />
        </ModalLayout>
      )}
    </div>
  );
};
