import { useState } from "react";
import SelectTimeMUIComponent from "../../components/SelectTimeMUIComponent/SelectTimeMUIComponent";
import { ModalLayout } from "../../layouts";

export const StoreSheduleDetailTableBodyContainer = ({
  onChange,
  index,
  day = false,
  startTime = false,
  endTime = false,
  order = false,
  isActive = false,
  isDelete = false,
  modality = false,
  price = false,
  timeOpening = false,
  timeClosing = false,
  active,
  getTimeCurrent = () => {},
}) => {
  const [isOpenStartTime, setIsOpenStartTime] = useState(false);
  const [isOpenEndTime, setIsOpenEndTime] = useState(false);
  const [isOpenTimeClosing, setIsOpenTimeClosing] = useState(false);
  const [isOpenTimeOpening, setIsOpenTimeOpening] = useState(false);

  const handleChange = () => {
    setIsOpenStartTime(false);
    setIsOpenEndTime(false);
    setIsOpenTimeClosing(false);
    setIsOpenTimeOpening(false);
  };

  return (
    <tr className="storeSheduleDetailTableBodyContainer">
      {(day || day === "") && (
        <td className="storeSheduleDetailTableBodyContainer__day">
          <input value={day} onChange={(e) => onChange(e, index)} name="day" />
        </td>
      )}
      {(modality || modality === "") && (
        <td className="storeSheduleDetailTableBodyContainer__modality">
          <input
            value={modality}
            onChange={(e) => onChange(e, index)}
            name="modality"
          />
        </td>
      )}
      {(startTime || startTime === "") && (
        <>
          <td className="storeSheduleDetailTableBodyContainer__startTime">
            <input
              value={startTime}
              onChange={(e) => onChange(e, index)}
              name="startTime"
            />
          </td>

          <td className="storeSheduleDetailTableBodyContainer__time">
            <span onClick={() => getTimeCurrent(index, "startTime")}>
              Ahora
            </span>{" "}
            <span>|</span>{" "}
            <span
              onClick={() => {
                setIsOpenStartTime(!isOpenStartTime);
              }}
            >
              ⌚{" "}
            </span>
          </td>
          <td>
            {isOpenStartTime && (
              <ModalLayout>
                <SelectTimeMUIComponent
                  setIsOpen={handleChange}
                  onChange={getTimeCurrent}
                  index={index}
                  name="startTime"
                />
              </ModalLayout>
            )}
          </td>
        </>
      )}
      {(timeOpening || timeOpening === "") && (
        <>
          <td className="storeSheduleDetailTableBodyContainer__startTime">
            <input
              value={timeOpening}
              onChange={(e) => onChange(e, index)}
              name="timeOpening"
            />
          </td>
          <td className="storeSheduleDetailTableBodyContainer__time">
            <span onClick={() => getTimeCurrent(index, "timeOpening")}>
              Ahora
            </span>
            <span>|</span>
            <span onClick={() => setIsOpenTimeOpening(!timeOpening)}>⌚</span>
          </td>
          <td>
            {isOpenTimeOpening && (
              <ModalLayout>
                <SelectTimeMUIComponent
                  setIsOpen={handleChange}
                  onChange={getTimeCurrent}
                  index={index}
                  name="timeOpening"
                />
              </ModalLayout>
            )}
          </td>
        </>
      )}
      {(endTime || endTime === "") && (
        <>
          <td className="storeSheduleDetailTableBodyContainer__endTime">
            <input
              value={endTime}
              onChange={(e) => onChange(e, index)}
              name="endTime"
            />
          </td>
          <td className="storeSheduleDetailTableBodyContainer__time">
            <span onClick={() => getTimeCurrent(index, "endTime")}> Ahora</span>{" "}
            <span>|</span>{" "}
            <span onClick={() => setIsOpenEndTime(!isOpenEndTime)}>⌚ </span>
          </td>
          <td>
            {isOpenEndTime && (
              <ModalLayout>
                <SelectTimeMUIComponent
                  setIsOpen={handleChange}
                  onChange={getTimeCurrent}
                  index={index}
                  name="endTime"
                />
              </ModalLayout>
            )}
          </td>
        </>
      )}
      {(timeClosing || timeClosing === "") && (
        <>
          <td className="storeSheduleDetailTableBodyContainer__endTime">
            <input
              value={timeClosing}
              onChange={(e) => onChange(e, index)}
              name="closingTime"
            />
          </td>
          <td className="storeSheduleDetailTableBodyContainer__time">
            <span onClick={() => getTimeCurrent(index, "timeClosing")}>
              Ahora
            </span>
            <span>|</span>
            <span onClick={() => setIsOpenTimeClosing(!timeClosing)}>⌚</span>
          </td>
          <td>
            {isOpenTimeClosing && (
              <ModalLayout>
                <SelectTimeMUIComponent
                  setIsOpen={handleChange}
                  onChange={getTimeCurrent}
                  index={index}
                  name="timeClosing"
                />
              </ModalLayout>
            )}
          </td>
        </>
      )}
      {(order || order === "") && (
        <td className="storeSheduleDetailTableBodyContainer__order">
          <input value={order} onChange={() => {}} type="number" name="order" />
        </td>
      )}
      {(price || price === "") && (
        <td className="storeSheduleDetailTableBodyContainer__">
          <input
            value={price}
            onChange={(e) => onChange(e, index)}
            type="number"
            name="price"
          />
        </td>
      )}

      {isActive && (
        <td className="storeSheduleDetailTableBodyContainer__active">
          <input
            type="checkbox"
            name="active"
            checked={active}
            onChange={(e) => onChange(e, index)}
          />
        </td>
      )}
      {isDelete && (
        <td className="storeSheduleDetailTableBodyContainer__delete">
          <input type="checkbox" name="delete" />
        </td>
      )}
    </tr>
  );
};
