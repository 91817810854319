import {
  ProductCollectionDetailHeaderContainer,
  ProductCollectionDetailMainContainer,
} from "../../../containers";

export const ProductCollectionDetailPage = () => {
  return (
    <section>
      <ProductCollectionDetailHeaderContainer />
      <ProductCollectionDetailMainContainer />
    </section>
  );
};
