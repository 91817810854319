import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { useState } from "react";
import { processPhotoFormData } from "../../helpers/convertToFormData";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { useNavigate } from "react-router-dom";

const photoFields = [{ fieldName: "photo", dataField: "photo" }];

export const SettingCoffeeShopBannersOrderHeaderContainer = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };
  const navigate = useNavigate();
  const { photo, slugItem } = useSelector((state) => state.retrieveBannerOrder);

  const handleUpdate = async () => {
    let body = { ...photo };
    const formData = new FormData();
    await processPhotoFormData(body, formData, photoFields);
    formData.append("slug_item", slugItem);

    await requestPatchFileAuthorization(
      "admin/banners/lo_mas_pedidos/",
      formData
    ).then(() => {
      navigate("/configuracion/cafeteria/banners");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Banner Lo Más Pedido" />
      </div>
      <div className="collectionCreateHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Guardar Banner"
            msg="¿Está segur@ que desea guardar el Banner?"
            onClose={onOpenConfirmd}
            onConfirm={handleUpdate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
