import { TitleInformationComponent } from "../../components";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const SettingLandingCollaboratorsInformationContainer = ({
  data,
  handleOnchage,
}) => {
  return (
    <>
      <div className="productDetailInformationContainer">
        <TitleInformationComponent title={"Información General"} />
        <SelectOptionsContainer
          title={"Nombre"}
          name="name"
          handleOnchange={handleOnchage}
          isInput={true}
          value={data?.name}
        />
        <SelectOptionsContainer
          title={"Order"}
          name="order"
          handleOnchange={handleOnchage}
          isNumber={true}
          value={data?.order}
        />
        <SelectOptionsContainer
          title={"Activo"}
          name="active"
          handleOnchange={handleOnchage}
          isCheckbox={true}
          value={data?.active}
        />
      </div>

      {/* <div className="productDetailInformationContainer">
        <TitleInformationComponent title={"Requisitos"} />
        <table width={"90%"} style={{ textAlign: "center" }}>
          <thead width={"90%"}>
            <tr>
              <th>Descripción</th>
              <th>Orden</th>
              <th>¿Activo?</th>
              <th>¿Eliminar?</th>
            </tr>
          </thead>
          <tbody width={"90%"}>
            {collaboratorsData?.requirements?.map((item, index) => (
              <tr key={index}>
                <td>
                  <textarea
                    name="description"
                    value={item.description}
                    onChange={(e) =>
                      dispatch(
                        setUpdateCollaboratorRequirements({
                          name: e.target.name,
                          value: e.target.value,
                          index,
                        })
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="order"
                    value={item.order}
                    onChange={(e) =>
                      dispatch(
                        setUpdateCollaboratorRequirements({
                          name: e.target.name,
                          value: e.target.value,
                          index,
                        })
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={item.active}
                    name="active"
                    onChange={(e) =>
                      dispatch(
                        setUpdateCollaboratorRequirements({
                          name: e.target.name,
                          value: e.target.checked,
                          index,
                        })
                      )
                    }
                  />
                </td>
                <td>
                  <button onClick={() => {}}>Eliminar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="productDetailGroupModifiedContainer__container__left__button"
          onClick={() => dispatch(setAddCollaboratorsRequirements())}
        >
          <div>Agregar Requisito</div>
        </div>
      </div> */}
    </>
  );
};
