import { createSlice } from "@reduxjs/toolkit";
export const listOptionChannelSlice = createSlice({
  name: "listOptionChannel",
  initialState: {
    listOptionChannelData: [],
  },
  reducers: {
    setListOptionChannel: (state, action) => {
      state.listOptionChannelData = action.payload.data;
    },
  },
});
export const { setListOptionChannel } = listOptionChannelSlice.actions;
