import { useContext } from "react";
import { Navigate } from "react-router-dom";

import LoginContext from "../../store/loginContext";
import { LoginPage } from "./../LoginPage/LoginPage";

export const LoginPageValidate = () => {
  const LoginCtx = useContext(LoginContext);
  return (
    <section>
      {!LoginCtx?.isLogin ? <LoginPage /> : <Navigate to="/" />}
    </section>
  );
};
