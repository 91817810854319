import {
  ModifierCreateHeaderContainer,
  ModifierCreateInformationContainer,
} from "../../../containers";

export const ProductModifierCreatePage = () => {
  return (
    <div className="productModifierCreatePage">
      <ModifierCreateHeaderContainer />
      <ModifierCreateInformationContainer />
    </div>
  );
};
