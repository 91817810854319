import { TitleComponent } from "../../components";
import { SettingBoxContainer } from "../../containers";

const data = [
  {
    id: 1,
    title: "Ventas por periodo",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2015/05/13/22/56/radishes-766105_960_720.jpg",
    slug: "venta-ordenes-totales",
  },
  {
    id: 2,
    title: "Ventas por cliente",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2014/07/16/05/18/beach-394503_960_720.jpg",
    slug: "ventas-por-cliente",
  },
  {
    id: 3,
    title: "Ventas por Productos",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2014/07/16/05/18/beach-394503_960_720.jpg",
    slug: "ventas-por-productos",
  },
];

export const ReportsIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Informes"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
