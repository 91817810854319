import {
  PaymentRetrieveContainer,
  PaymentRetrieveHeaderContainer,
} from "../../containers";

export const PaymentDetailPage = () => {
  return (
    <section className="paymentDetailPage">
      <PaymentRetrieveHeaderContainer />
      <PaymentRetrieveContainer />
    </section>
  );
};
