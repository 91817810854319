import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { createFormData } from "../../helpers/convertToFormData";
import { ModalLayout } from "../../layouts";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { requestPostFileAuthorization } from "../../services/requestPost";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const StoreDetailHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { storeData } = useSelector((state) => state.retrieveStore);
  const navigate = useNavigate();
  const params = useParams();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };

  const handleUpdateStore = async () => {
    const formData = await createFormData(storeData);
    const url = `admin/tiendas/${storeData.id}/`;
    requestPatchFileAuthorization(url, formData).then(() => {
      console.log("Se actualizó correctamente");
    });
  };
  const handleCreateStore = async () => {
    const formData = await createFormData(storeData);
    const url = `admin/tiendas/`;
    requestPostFileAuthorization(url, formData).then(() => {
      console.log("Se creo correctamente");
      navigate(`/configuracion/tiendas/`);
    });
  };

  const handleCoverture = () => {
    navigate(`/configuracion/tiendas/${params.storeId}/coberturas`);
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent
          title={!!storeData?.id ? "Detalle de la tienda" : "Crear tienda"}
        />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Ver Cobertura"}
          color={"green"}
          onClick={handleCoverture}
        />
        <ButtonPushComponent
          name={storeData?.id ? "Guardar" : "Crear Tienda"}
          onClick={toggleModalUpdate}
        />
      </div>

      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title={storeData?.id ? "Actualizar Tienda" : "Crear Tienda"}
            msg={
              storeData?.id
                ? "¿Estás segur@ que desea actualizar la Tienda?"
                : "¿Estás segur@ que desea crear la Tienda?"
            }
            onClose={toggleModalUpdate}
            onConfirm={storeData?.id ? handleUpdateStore : handleCreateStore}
          />
        </ModalLayout>
      )}
    </section>
  );
};
