import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { getRetrieveContact } from "../../store/contact/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { getFormatDate } from "../../helpers";

export const SettingLandingContactInformationContainer = () => {
  const { contactData, showLoading } = useSelector(
    (state) => state.retrieveContact
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.contactId) {
      dispatch(getRetrieveContact(path.contactId));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          <SelectOptionsContainer
            title={"Nombre"}
            name="name"
            handleOnchange={() => {}}
            isInput={true}
            value={contactData?.name}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Correo"}
            name="email"
            handleOnchange={() => {}}
            isInput={true}
            value={contactData?.email}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Descripción"}
            name="description"
            handleOnchange={() => {}}
            isTextArea={true}
            value={contactData?.description}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Fecha de Envio"}
            name="description"
            handleOnchange={() => {}}
            isInput={true}
            value={getFormatDate(contactData?.created)}
            readOnly={true}
          />
        </div>
      )}
    </>
  );
};
