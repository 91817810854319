import {
  ModifierListTableContainer,
  ModifierSearchContainer,
} from "../../containers";

export const ModifierListContainer = () => {
  return (
    <div className="modifierListContainer">
      <div className="modifierListContainer__search">
        <ModifierSearchContainer />
      </div>
      <div className="modifierListContainer__table">
        <ModifierListTableContainer />
      </div>
    </div>
  );
};
