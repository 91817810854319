import { createSlice } from "@reduxjs/toolkit";
export const listCouponSlice = createSlice({
  name: "listCoupon",
  initialState: {
    couponData: [],
    showLoading: false,
  },
  reducers: {
    setListCoupon: (state, action) => {
      state.couponData = action.payload.data;
    },
    setCouponLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListCoupon, setCouponLoading } = listCouponSlice.actions;

export const retrieveCouponSlice = createSlice({
  name: "retrieveCoupon",
  initialState: {
    couponData: {
      code: "",
      name: "",
      description: "",
      couponType: "",
      discontType: "",
      discont: "",
      numberCouponsLimit: "",
      startDate: "",
      startDateTime: "",
      expirationDate: "",
      expirationDateTime: "",
      userType: "",
      users: [],
      maximumCouponsUse: 1,
      products: [],
      collections: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveCoupon: (state, action) => {
      state.couponData.code =
        action.payload?.data?.code ?? state?.couponData?.code;
      state.couponData.name =
        action?.payload?.data?.name ?? state.couponData.name;
      state.couponData.description =
        action.payload.data?.description ?? state.couponData.description;
      state.couponData.couponType =
        action.payload.data?.couponType ?? state.couponData.couponType;
      state.couponData.discontType =
        action.payload.data?.discontType ?? state.couponData.discontType;
      // state.couponData.discont =
      //   action.payload.data?.discont ?? state.couponData.discont;
      state.couponData.discont = action.payload.data?.discont;
      state.couponData.numberCouponsLimit =
        action.payload.data?.numberCouponsLimit ??
        state.couponData.numberCouponsLimit;
      state.couponData.startDate =
        action.payload.data?.startDate ?? state.couponData.startDate;
      state.couponData.startDateTime =
        action.payload.data?.startDateTime ?? state.couponData.startDateTime;
      state.couponData.expirationDate =
        action.payload.data?.expirationDate ?? state.couponData.expirationDate;
      state.couponData.expirationDateTime =
        action.payload.data?.expirationDateTime ??
        state.couponData.expirationDateTime;
      state.couponData.userType =
        action.payload.data?.userType ?? state.couponData.userType;
      state.couponData.users =
        action.payload.data?.users ?? state.couponData.users;
      state.couponData.maximumCouponsUse =
        action.payload.data?.maximumCouponsUse ??
        state.couponData.maximumCouponsUse;
      state.couponData.products =
        action.payload.data?.products ?? state.couponData.products;
      state.couponData.collections =
        action.payload.data?.collections ?? state.couponData.collections;
    },
    setUpdateCouponCollections: (state, action) => {
      state.couponData.collections =
        action.payload.collections || state.collections;
    },
    setUpdateCouponUsers: (state, action) => {
      state.couponData.users = action.payload.users || state.users;
    },
    setUpdateCouponProducts: (state, action) => {
      state.couponData.products = action.payload.products || state.products;
    },
    clearUpdateCoupon: (state) => {
      state.couponData = {
        code: "",
        name: "",
        description: "",
        couponType: "",
        discontType: "",
        discont: "",
        numberCouponsLimit: "",
        startDate: "",
        expirationDate: "",
        userType: "",
        users: [],
        maximumCouponsUse: 1,
        products: [],
        collections: [],
      };
    },
    setRetrieveCouponLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveCoupon,
  setUpdateCouponCollections,
  setUpdateCouponUsers,
  setUpdateCouponProducts,
  clearUpdateCoupon,
  setRetrieveCouponLoading,
} = retrieveCouponSlice.actions;
