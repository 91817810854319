import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import {
  ComboDetailTableBodyContainer,
  ComboDetailTableHeadContainer,
} from "../";
import { getListOptions } from "../../helpers";
import {
  setUpdateComboGroupsAddProductsItems,
  setUpdateComboGroupsInputsProductsItems,
  setUpdateComboGroupsProductsItems,
} from "../../store/combo/comboSlice";

export const ComboDetailGroupsContainer = ({
  comboData,
  listOptionsProducts,
}) => {
  const dispatch = useDispatch();

  const handleOnchageAddProducts = () => {
    let index = comboData?.gruposProductos?.length;
    dispatch(setUpdateComboGroupsAddProductsItems({ index }));
  };

  const handleOnchageProducts = (e, index) => {
    dispatch(
      setUpdateComboGroupsProductsItems({
        gruposProductos: e,
        index,
      })
    );
  };

  const handleOnchageInput = (e, index) => {
    dispatch(
      setUpdateComboGroupsInputsProductsItems({
        index,
        value: e.target.value,
        name: e.target.name,
      })
    );
  };

  const processListOptionsProducts = getListOptions([
    listOptionsProducts,
    "products",
    "name",
  ]);

  return (
    <div className="comboDetailGroupsContainer">
      <TitleInformationComponent title={"Grupos de Productos"} />
      <hr />
      <table className="comboDetailGroupsContainer__table">
        <ComboDetailTableHeadContainer />
        <tbody>
          {comboData?.gruposProductos?.map((item, index) => (
            <ComboDetailTableBodyContainer
              key={item.id}
              {...item}
              listDataProducts={processListOptionsProducts}
              onChange={handleOnchageProducts}
              onChangeInput={handleOnchageInput}
              index={index}
            />
          ))}
          <tr className="comboDetailGroupsContainer__table__button">
            <td>
              <button onClick={handleOnchageAddProducts}>
                Agregar Producto
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
