import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  NoteListContainer,
  PaginationContainer,
} from "../../../containers";
import { getLisNotes } from "../../../store/note";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../../components";

export const ProductNoteIndex = () => {
  const { noteData, showLoading } = useSelector((state) => state.listNotes);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageNote = serachParams.get("page");
  const activeNote = serachParams.get("active");
  const searchNote = serachParams.get("search");
  let noteParams = {
    page_size: 20,
    page: pageNote ?? 1,
    active: activeNote ?? "",
    search: searchNote ?? "",
  };
  useEffect(() => {
    dispatch(getLisNotes(noteParams));
    // eslint-disable-next-line
  }, [pageNote, activeNote, searchNote]);
  const dataHeader = {
    title: "Notas",
    nameButton: "Crear Notar",
    url: "/productos/notas/crear",
  };
  return (
    <section className="productIndexPage">
      <ListHeaderContainer data={dataHeader} />

      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <NoteListContainer />
          <PaginationContainer data={noteData} />
        </>
      )}
    </section>
  );
};
