import { TitleComponent } from "../../../components";
import { SettingBoxContainer } from "../../../containers";

const data = [
  {
    id: 1,
    title: "Coberturas",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://d302y7dk73tjzt.cloudfront.net/media/landing/shop/cover_mobile/pueblo_libreMob.jpg",
  },
  {
    id: 2,
    title: "Banners",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2016/05/05/22/00/teddy-bear-1374874_960_720.jpg",
  },
  {
    id: 3,
    title: "GifCards",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2015/05/13/22/56/radishes-766105_960_720.jpg",
  },
];

export const SettingGroceryStoreIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Bodega"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
