import {
  SettingEnvironmentHeaderContainer,
  SettingEnvironmentMainContainer,
} from "../../../../containers";

export const SettingEnvironmentDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingEnvironmentHeaderContainer />
      <SettingEnvironmentMainContainer />
    </section>
  );
};
