import { useSelector } from "react-redux";
import { PaymentListFieldsValueComponent } from "../../components";

export const PaymentListFieldsValueContainer = () => {
  const { paymentData } = useSelector((state) => state.listPayments);
  return (
    <tbody>
      {paymentData?.results?.length > 0 &&
        paymentData?.results?.map((payment) => {
          return (
            <PaymentListFieldsValueComponent
              key={payment.id}
              payment={payment}
            />
          );
        })}
    </tbody>
  );
};
