import React from "react";
export const TableListFieldsKeyComponent = ({ titles }) => {
  return (
    <thead>
      <tr className="tableListFieldsKeyComponent">
        <th className="tableListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>

        {titles?.map((title, index) => (
          <React.Fragment key={index}>
            {title === "codigo" && (
              <th className="tableListFieldsKeyComponent__code">Código</th>
            )}
            {title === "name" && (
              <th className="tableListFieldsKeyComponent__name">Nombre</th>
            )}
            {title === "email" && (
              <th className="tableListFieldsKeyComponent__email">Email</th>
            )}
            {title === "document" && (
              <th className="tableListFieldsKeyComponent__document">
                Documento
              </th>
            )}
            {title === "created" && (
              <th className="tableListFieldsKeyComponent__created">
                Fecha de creación
              </th>
            )}
            {title === "phone" && (
              <th className="tableListFieldsKeyComponent__phone">Telefono</th>
            )}
            {title === "date" && (
              <th className="tableListFieldsKeyComponent__date">Dia</th>
            )}
            {title === "time" && (
              <th className="tableListFieldsKeyComponent__time">Hora</th>
            )}
            {title === "numberPerson" && (
              <th className="tableListFieldsKeyComponent__numberPerson">
                N° Persona
              </th>
            )}
            {title === "reservationReason" && (
              <th className="tableListFieldsKeyComponent__reservationReason">
                Razón
              </th>
            )}
            {title === "shop" && (
              <th className="tableListFieldsKeyComponent__shop">Tienda</th>
            )}

            {title === "lastNameFather" && (
              <th className="tableListFieldsKeyComponent__fullName">
                Apellidos
              </th>
            )}
            {title === "district" && (
              <th className="tableListFieldsKeyComponent__district">
                Distrito
              </th>
            )}
            {title === "typeContracted" && (
              <th className="tableListFieldsKeyComponent__typeContracted">
                Tipo de Contrato
              </th>
            )}
            {title === "typeClaim" && (
              <th className="tableListFieldsKeyComponent__typeClaim">
                Tipo de Reclamo
              </th>
            )}
            {title === "typeDocument" && (
              <th className="tableListFieldsKeyComponent__typeDocument">
                Tipo de DNI
              </th>
            )}
            {title === "numberDocument" && (
              <th className="tableListFieldsKeyComponent__numberDocument">
                N° de Documento
              </th>
            )}

            {title === "key" && (
              <th className="tableListFieldsKeyComponent__key">Key</th>
            )}
            {title === "value" && (
              <th className="tableListFieldsKeyComponent__value">Valor</th>
            )}
            {/* {title === "email" && (
              <th className="tableListFieldsKeyComponent__email">Correo</th>
            )} */}
            {title === "tipoFormulario" && (
              <th className="tableListFieldsKeyComponent__type-form">
                Tipo de Formulario
              </th>
            )}
            {title === "image" && (
              <th className="tableListFieldsKeyComponent__phone">Imagen</th>
            )}

            {title === "description" && (
              <th className="tableListFieldsKeyComponent__description">
                Descripción
              </th>
            )}

            {title === "order" && (
              <th className="tableListFieldsKeyComponent__order">Orden</th>
            )}

            {title === "price" && (
              <th className="tableListFieldsKeyComponent__active">Price</th>
            )}
            {title === "active" && (
              <th className="tableListFieldsKeyComponent__active">Active</th>
            )}
            {title === "state" && (
              <th className="tableListFieldsKeyComponent__state">Estado</th>
            )}
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};
