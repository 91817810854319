import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
import { getFormatDate } from "../../helpers/getFormatDate";

export const OrderListFieldsValueComponent = ({ order }) => {
  const date = new Date(order.created);
  const url = `/ordenes/${order.id}/detalles`;
  const stateColor = orderStateColor(order.state);

  return (
    <tr className="orderListFieldsValueComponent">
      <td className="orderListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="orderListFieldsValueComponent__code">
        <Link to={url}>{order.codigo}</Link>
      </td>
      <td className="orderListFieldsValueComponent__client">
        <Link to={url}>{order.client?.fullName}</Link>
      </td>
      <td className="orderListFieldsValueComponent__modality">
        <Link to={url}>{order.modality}</Link>
      </td>
      <td className="orderListFieldsValueComponent__shop">
        <Link to={url}>{order.shop?.name}</Link>
      </td>
      <td className="orderListFieldsValueComponent__total">
        <Link to={url}>{order.totalNetAmount.toFixed(2)}</Link>
      </td>
      <td className={`orderListFieldsValueComponent__state ${stateColor}`}>
        {order.state}
      </td>
      <td className="orderListFieldsValueComponent__date">
        <Link to={url}>{getFormatDate(date)}</Link>
      </td>
    </tr>
  );
};
