import { getListOptions } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListNotes,
  setListOptionsNote,
  setNoteLoading,
  setRetrieveNote,
  setRetrieveNoteLoading,
  setUpdateNote,
} from "./noteSlice";

export const getLisNotes = (params) => {
  return async (dispatch) => {
    dispatch(setNoteLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/notas/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "productos/notas";
        value.text = "Notas";
        value.page = params.page;
        dispatch(setListNotes({ data: value, params: params }));
        dispatch(setNoteLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveNote = (noteId) => {
  return async (dispatch) => {
    dispatch(setRetrieveNoteLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/notas/${noteId}/`, token).then((value) => {
      dispatch(setRetrieveNote({ data: value }));
      dispatch(setUpdateNote(value));
      dispatch(setRetrieveNoteLoading({ showLoading: false }));
    });
  };
};

export const getListOptionsNote = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/notas/lista_opciones/", token).then(
      (value) => {
        const inputParamsData = [value, "note", "name", "id"];
        const listOptionNote = getListOptions(inputParamsData);
        dispatch(setListOptionsNote({ data: listOptionNote }));
      }
    );
  };
};
