export const StoreDetailTableBodyContainer = ({
  id,
  name,
  photo,
  order,
  active,
  onChange = () => {},
  index,
}) => {
  return (
    <tr className="storeDetailTableBodyContainer">
      <td className="storeDetailTableBodyContainer__name">
        <input value={name} onChange={(e) => onChange(e, index)} name="name" />
      </td>
      <td className="storeDetailTableBodyContainer__image">
        <div className="storeDetailTableBodyContainer__image__link">
          Actualmente:{" "}
          <a
            href={photo.url ?? photo}
            alt="image"
            target="_blank"
            rel="noreferrer"
          >
            {photo.url ?? photo}
          </a>
        </div>
        <div className="storeDetailTableBodyContainer__image__input">
          <label htmlFor="myfile">Modificar:</label>
          <input
            type="file"
            id="myfile"
            name="photo"
            onChange={(e) => onChange(e, index)}
          />
        </div>
      </td>
      <td className="storeDetailTableBodyContainer__order">
        <input value={order} onChange={() => {}} type="number" name="order" />
      </td>
      <td className="storeDetailTableBodyContainer__active">
        <input
          type="checkbox"
          onChange={(e) => onChange(e, index)}
          name="active"
          checked={active}
        />
      </td>
    </tr>
  );
};
