import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ListHeaderContainer,
  PaginationContainer,
  TableContainer,
} from "../../../../containers";
import { filterObjectKeys } from "../../../../helpers";
import { getListClaim } from "../../../../store/claim/thunks";
import { LoadingComponent } from "../../../../components";

const URL = "configuracion/landing/reclamacion";
const dataInput = { id: "searchClaim", placeholder: "Buscar variable" };
const allowedKeys = [
  "id",
  "name",
  "lastNameFather",
  "lastNameMother",
  "created",
  "district",
  "typeContracted",
  "typeClaim",
  "typeDocument",
  "numberDocument",
  "shop",
];
const dataHeader = {
  title: "Reclamación",
  nameButton: "Crear reclamo",
  url: "",
};

export const SettingLandingClaimIndexPage = () => {
  const { claimData, showLoading } = useSelector((state) => state.listClaim);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageClaim = serachParams.get("page");
  const searchClaim = serachParams.get("search");

  let claimParams = {
    page_size: 20,
    page: pageClaim ?? 1,
    search: searchClaim ?? "",
  };

  useEffect(() => {
    dispatch(getListClaim(claimParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageClaim, searchClaim]);

  const filter = () => {
    const searchValue = document.getElementById("searchClaim").value;
    if (searchValue === "") {
      dispatch(getListClaim());
      navigate("/" + URL);
      return;
    }
    const searchUrl = `/configuracion/landing/reclamacion?search=${searchValue}`;

    navigate(searchUrl);
  };

  const handleKeyDown = (e) => {
    const searchValue = document.getElementById("searchClaim").value;
    if (e.key === "Backspace" && searchValue.length === 1) {
      dispatch(getListClaim());
      navigate("/" + URL);
    }
    if (e.key !== "Enter") return;
    filter();
  };

  const getUrl = (id) => {
    return `/configuracion/landing/reclamacion/${id}/detalles`;
  };

  const newData = claimData?.results?.map((obj) =>
    filterObjectKeys(obj, allowedKeys)
  );
  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} addButton={false} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TableContainer
            filter={filter}
            handleKeyDown={handleKeyDown}
            dataInput={dataInput}
            data={newData}
            url={getUrl}
            titles={allowedKeys}
            isFilter={false}
          />
          <PaginationContainer data={claimData} />
        </>
      )}
    </section>
  );
};
