import { useState } from "react";
import Select from "react-select";
import { SelectCountComponent } from "../../components";
import MultipleSelectChip from "../SelectChipContainer/SelectChipContainer";

export const SelectOptionsContainer = ({
  title,
  indexProduct,
  handleOnchange = () => {},
  data,
  placeholder = "Seleccionar",
  value = "",
  name,
  dataList,
  valueDefault,
  count = false,
  isSelect = false,
  isTextArea = false,
  isInput = false,
  isImage = false,
  isCheckbox = false,
  isInputMulti = false,
  isNumber = false,
  isInputDate = false,
  isInputHour = false,
  placeholderTitle = "",
  readOnly = false,
  isImageEdit = false,
  isSelectData = false,
  moved = "",
}) => {
  const [selectValue, setSelectValue] = useState("");

  const onHandleChange = (e) => {
    handleOnchange(e);
    if (!count) return;
    setSelectValue(e.label);
  };
  return (
    <>
      <div className="selectOptionsContainer">
        <p>{title}</p>
        <div className="selectOptionsContainer__select">
          {isSelect && (
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "blue" : "#ebeef2",
                  borderRadius: "14px",
                  padding: "3px 7px ",
                }),
              }}
              placeholder={<div>{placeholder}</div>}
              options={data}
              onChange={onHandleChange}
              value={{ label: valueDefault }}
            />
          )}
          {isSelectData && (
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "blue" : "#ebeef2",
                  borderRadius: "14px",
                  padding: "3px 7px ",
                }),
              }}
              placeholder={<div>{placeholder}</div>}
              options={data}
              onChange={onHandleChange}
              defaultValue={data?.find((item) => item.label === valueDefault)}
            />
          )}

          {isInput && (
            <input
              className="selectOptionsContainer__select__input"
              value={value || ""}
              placeholder={placeholder}
              name={name}
              onChange={handleOnchange}
              readOnly={readOnly}
            />
          )}

          {isInputDate && (
            <>
              <span>Fecha:</span>
              <input
                className="selectOptionsContainer__select__inputDate"
                value={value || ""}
                placeholder={placeholder}
                name={name}
                onChange={handleOnchange}
                type="date"
              />
            </>
          )}

          {isInputHour && (
            <>
              <span>Hora:</span>
              <input
                className="selectOptionsContainer__select__inputHour"
                value={value || ""}
                placeholder={placeholder}
                name={name}
                onChange={handleOnchange}
                type="time"
              />
            </>
          )}

          {isTextArea && (
            <textarea
              className="selectOptionsContainer__select__textarea"
              value={value}
              name={name}
              onChange={handleOnchange}
            />
          )}

          {isImage && (
            <div className="selectOptionsContainer__select__image">
              <div className="selectOptionsContainer__select__image__link">
                Actualmente:{" "}
                <a
                  aria-label={value}
                  href={!!value.url ? value.url : value}
                  alt="image"
                  target="_blank"
                  rel="noreferrer"
                >
                  {!!value.url ? value.url : value}
                </a>
              </div>
              <div className="selectOptionsContainer__select__image__input">
                <label htmlFor="myfile">Modificar:</label>
                <input
                  type="file"
                  id="myfile"
                  name={name}
                  onChange={handleOnchange}
                />
              </div>
            </div>
          )}

          {isImageEdit && (
            <div className="selectOptionsContainer__select__image-edit">
              <div className="selectOptionsContainer__select__image-edit__link">
                <a
                  aria-label={value}
                  href={!!value.url ? value.url : value}
                  alt="image-edit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Actualmente:
                  {/* <span>{!!value.url ? value.url : value.substring(43)}</span> */}
                  <span>{!!value.url ? value.url : value}</span>
                </a>
              </div>
              <div className="selectOptionsContainer__select__image-edit__input">
                <div className="selectOptionsContainer__select__image-edit__input__container">
                  <label htmlFor="myfile">Modificar:</label>
                  <input
                    type="file"
                    id="myfile"
                    name={name}
                    onChange={handleOnchange}
                  />
                </div>
                <div className="selectOptionsContainer__select__image-edit__input__img">
                  <img src={value} alt="img-home"></img>
                </div>
              </div>
            </div>
          )}

          {isCheckbox && (
            <input
              className="selectOptionsContainer__select__input"
              value={value}
              checked={value}
              placeholder={placeholder}
              name={name}
              onChange={handleOnchange}
              type="checkbox"
            />
          )}

          {isNumber && (
            <input
              className="selectOptionsContainer__select__input"
              value={value || ""}
              placeholder={placeholder}
              name={name}
              onChange={handleOnchange}
              type="number"
              min="0"
            />
          )}

          {isInputMulti && (
            <div
              className={`selectOptionsContainer__select__multiple ${moved}`}
            >
              <MultipleSelectChip
                listOptions={dataList}
                data={value}
                name={name}
                handleChange={handleOnchange}
                placeholderTitle={placeholderTitle || "Select.."}
                newStyle={true}
              />
            </div>
          )}
        </div>
      </div>
      {count && isSelect && selectValue && (
        <SelectCountComponent
          indexProduct={indexProduct}
          selectValue={selectValue}
        />
      )}
    </>
  );
};
