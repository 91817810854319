import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { useNavigate } from "react-router-dom";
import {
  processPhotoFormData,
  processPhotosArray,
} from "../../helpers/convertToFormData";

const photoFields = [
  { fieldName: "coverDesktop", dataField: "coverDesktop" },
  { fieldName: "coverMobile", dataField: "coverMobile" },
];

export const SettingLandingBannersUsHeaderContainer = () => {
  const { bannerUsData } = useSelector((state) => state.retrieveBannerUs);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const navigate = useNavigate();
  const handleUpdate = async () => {
    let body = { ...bannerUsData };
    const formData = new FormData();

    await processPhotoFormData(body, formData, photoFields);
    await processPhotosArray(body?.photos, formData, "photos", "photo", "slug");

    await requestPatchFileAuthorization(
      "admin/banners/nosotros/",
      formData
    ).then(() => {
      navigate("/configuracion/landing/banners");
    });
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Acerca de Nosotros" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Actualizar Acerca de Nosotros"}
          onClick={toggleModalUpdate}
        />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Acerca de Nosotros"
            msg="¿Está seguro que desea actualizar acerca de Nosotros?"
            onClose={toggleModalUpdate}
            onConfirm={handleUpdate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
