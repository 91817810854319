import { ProductListFieldsKeyComponent } from "../../components";
import { ProductListFieldsValueContainer } from "../../containers";

export const ProductListTableContainer = () => {
  return (
    <table className="productListTableContainer">
      <ProductListFieldsKeyComponent />
      <ProductListFieldsValueContainer />
    </table>
  );
};
