import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import {
  requestPatchAuthorization,
  requestPatchFileAuthorization,
} from "../../services/requestPatch";
import { clearUpdateProduct } from "../../store/product";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const ProductDetailHeaderContainer = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const openOrCloseModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const productData = useSelector((state) => state.updateProduct);
  const path = useParams();
  const updateProduct = async () => {
    const body = { ...productData };
    delete body.image;
    const token = localStorage.getItem("token");
    if (productData.imageIsLoaded) {
      const image = productData.image;
      let blob = await fetch(image.url).then((r) => r.blob());
      const file = new File([blob], image.name, { type: image.type });
      const formData = new FormData();
      formData.append("image", file);
      await requestPatchFileAuthorization(
        `admin/productos/${path.productId}/`,
        formData,
        token
      );
    }
    requestPatchAuthorization(
      `admin/productos/${path.productId}/`,
      body,
      token
    ).then((value) => {
      dispatch(clearUpdateProduct());
      window.location.reload();
    });
  };

  const deleteProduct = () => {
    dispatch(clearUpdateProduct());
  };

  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del producto" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Eliminar"}
          color={"red"}
          onClick={openOrCloseModal}
        />
        <ButtonPushComponent name={"Guardar"} onClick={updateProduct} />
      </div>

      {isOpenDelete && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Producto"
            msg="¿Está seguro que desea eliminar el producto?"
            style={canceledStateStyle}
            onClose={openOrCloseModal}
            onConfirm={deleteProduct}
          />
        </ModalLayout>
      )}
    </section>
  );
};
