import { useSelector } from "react-redux";
import { NoteListFieldsValueComponent } from "../../components";

export const NoteListFieldsValueContainer = () => {
  const { noteData } = useSelector((state) => state.listNotes);
  return (
    <tbody>
      {noteData?.results?.length > 0 &&
        noteData?.results?.map((note) => {
          return <NoteListFieldsValueComponent key={note.id} note={note} />;
        })}
    </tbody>
  );
};
