import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListReservation,
  setReservationLoading,
  setRetrieveReservation,
  setRetrieveReservationLoading,
} from "./reservationSlice";

export const getListReservation = (params) => {
  return async (dispatch) => {
    dispatch(setReservationLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/reservaciones/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "configuracion/landing/reservaciones";
        value.text = "Reservation";
        value.page = params.page;
        dispatch(setListReservation({ data: value }));
        dispatch(setReservationLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveReservation = (contactId) => {
  return async (dispatch) => {
    dispatch(setRetrieveReservationLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/reservaciones/${contactId}/`, token).then(
      (value) => {
        dispatch(setRetrieveReservation({ data: value }));
        dispatch(setRetrieveReservationLoading({ showLoading: false }));
      }
    );
  };
};
