import {
  OrderCreateTitleContainer,
  OrderCreateMainContainer,
} from "../../containers";

export const OrderCreatePage = () => {
  return (
    <section>
      <OrderCreateTitleContainer />
      <OrderCreateMainContainer />
    </section>
  );
};
