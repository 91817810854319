import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { setRetrieveBannerHome } from "../../store/banner/home/homeSlice";
import { getRetrieveBannerHome } from "../../store/banner/home/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const SettingLandingBannersHomeInformationContainer = () => {
  const { bannerHomeData, showLoading } = useSelector(
    (state) => state.retrieveBannerHome
  );
  const dispatch = useDispatch();

  const newData = [
    {
      title: "Portada Desktop",
      name: "mainCover",
      value: bannerHomeData?.mainCover?.url,
    },
    {
      title: "Portada Mobile",
      name: "coverMobile",
      value: bannerHomeData?.coverMobile?.url,
    },
    {
      title: "Imagen de Cafeteria Artesanal",
      name: "coffeeImage",
      value: bannerHomeData?.coffeeImage?.url,
    },
    {
      title: "Imagen de Bodega Atelier",
      name: "store",
      value: bannerHomeData?.store?.url,
    },
    {
      title: "Giftcard Desktop",
      name: "giftcard",
      value: bannerHomeData?.giftcard?.url,
    },
    {
      title: "Giftcard Mobile",
      name: "giftcardMobile",
      value: bannerHomeData?.giftcardMobile?.url,
    },
    {
      title: "Video de Nuestra Historia",
      name: "videoHistory",
      value: bannerHomeData?.videoHistory?.url,
    },
  ];

  useEffect(() => {
    dispatch(getRetrieveBannerHome());
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    dispatch(setRetrieveBannerHome({ data: { [key]: value } }));
  };

  const handleOnChangeArray = (e, index, data, name) => {
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    const updatedData = {
      ...data[index],
      url: value.url,
      type: value.type,
      name: value.name,
    };
    const updateds = [
      ...data.slice(0, index),
      updatedData,
      ...data.slice(index + 1),
    ];
    dispatch(setRetrieveBannerHome({ data: { [name]: updateds } }));
  };

  const handleOnChangeText = (e, index) => {
    const updatedData = {
      ...bannerHomeData?.phrases[index],
      description: e.target.value,
    };
    const updateds = [
      ...bannerHomeData?.phrases.slice(0, index),
      updatedData,
      ...bannerHomeData?.phrases.slice(index + 1),
    ];
    dispatch(setRetrieveBannerHome({ data: { phrases: updateds } }));
  };

  return (
    <div className="productDetailInformationContainer">
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TitleInformationComponent title={"Información General"} />
          {newData?.map(({ title, name, value }) => (
            <SelectOptionsContainer
              key={name}
              title={title}
              handleOnchange={handleOnChange}
              name={name}
              isImageEdit={true}
              value={value}
            />
          ))}
          {[...Array(6)].map((_, index) => (
            <SelectOptionsContainer
              key={`collage-${index}`}
              title={`Collage ${index + 1}`}
              handleOnchange={(e) =>
                handleOnChangeArray(e, index, bannerHomeData?.photos, "photos")
              }
              name={`imagen_${index + 1}`}
              isImageEdit={true}
              value={bannerHomeData?.photos?.[index]?.url}
            />
          ))}
          {[...Array(3)].map((_, index) => (
            <SelectOptionsContainer
              key={`phrase-${index}`}
              title={`Frase ${index + 1}`}
              handleOnchange={(e) => handleOnChangeText(e, index)}
              name={`frase_${index + 1}`}
              isTextArea={true}
              value={bannerHomeData?.phrases?.[index]?.description}
            />
          ))}
        </>
      )}
    </div>
  );
};
