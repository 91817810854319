import { CollectionListFieldsKeyComponent } from "../../components";
import { CollectionListFieldsValueContainer } from "../../containers";

export const CollectionListTableContainer = ({ data }) => {
  return (
    <table className="collectionListTableContainer">
      <CollectionListFieldsKeyComponent />
      <CollectionListFieldsValueContainer />
    </table>
  );
};
