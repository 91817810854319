import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import {
  getRetrieveReception,
  setRetrieveReception,
} from "../../store/reception";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const SettingReceptionInformationContainer = () => {
  const { receptionData, showLoading } = useSelector(
    (state) => state.retrieveReception
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.receptionId) {
      dispatch(getRetrieveReception(path.receptionId));
    }
    // eslint-disable-next-line
  }, []);

  const handleOnchage = (e) => {
    dispatch(
      setRetrieveReception({ data: { [e.target.name]: e.target.value } })
    );
  };
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          <SelectOptionsContainer
            title={"Tipo Email"}
            handleOnchange={() => {}}
            name="tipoFormulario"
            isInput={true}
            value={receptionData?.tipoFormulario}
          />
          <SelectOptionsContainer
            title={"Dirreción de Correo "}
            handleOnchange={handleOnchage}
            name="email"
            isInput={true}
            value={receptionData?.email}
          />
        </div>
      )}
    </>
  );
};
