import { getFormatDate } from "../../helpers/getFormatDate";
import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
export const PaymentListFieldsValueComponent = ({ payment }) => {
  const date = new Date(payment.created);
  const url = `/pagos/${payment.id}/detalles`;
  const stateColor = orderStateColor(payment?.state);
  return (
    <tr className="paymentListFieldsValueComponent">
      <td className="paymentListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="paymentListFieldsValueComponent__code">
        <Link to={url}>{payment.code}</Link>
      </td>
      <td className="paymentListFieldsValueComponent__order">
        <Link to={url}>{payment.order.codigo}</Link>
      </td>
      <td className="paymentListFieldsValueComponent__method">
        <Link to={url}>{payment.paymentMethod.name}</Link>
      </td>
      <td className={`paymentListFieldsValueComponent__state ${stateColor}`}>
        <Link to={url}>{payment.state}</Link>
      </td>
      <td className="paymentListFieldsValueComponent__date">
        <Link to={url}>{getFormatDate(date)}</Link>
      </td>
    </tr>
  );
};
