import {
  SettingReceptionHeaderContainer,
  SettingReceptionMainContainer,
} from "../../../../containers";

export const SettingReceptionEmailDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingReceptionHeaderContainer />
      <SettingReceptionMainContainer />
    </section>
  );
};
