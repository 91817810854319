import React from "react";

export const ClientListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="clientListFieldsKeyComponent">
        <th className="clientListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="clientListFieldsKeyComponent__code">Código</th>
        <th className="clientListFieldsKeyComponent__fullName">
          Nombres y Apellidos
        </th>
        <th className="clientListFieldsKeyComponent__email">Email</th>
        <th className="clientListFieldsKeyComponent__phone">Celular</th>
        <th className="clientListFieldsKeyComponent__active">Estado</th>
      </tr>
    </thead>
  );
};
