import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TitleInformationComponent,
  SearchLocationComponent,
  SelectCommentComponent,
} from "../../components";
import { MapSearchComponent } from "../../components/MapSearchComponent/MapSearchComponent";
import { apiPath } from "../../helpers";
import { getListDataFilter } from "../../helpers/getListDataFilter";
import { getListOptions } from "../../helpers/getListOptions";
import { useField } from "../../hooks/useField";
import { getListOptionsClients } from "../../store/client";
import { getListOptionsCombo } from "../../store/combo/thunks";
import { getListOptionsDocument } from "../../store/document/thunks";
import {
  setCreatedOrderProduct,
  setCreateOrder,
} from "../../store/order/ordersSlice";
import { getListOptionsProducts } from "../../store/product";
import {
  getListOptionsStore,
  getListOptionsStorePickup,
} from "../../store/store/thunks";
import { OrderListGroupPresentationContainer } from "../OrderListGroupPresentationContainer/OrderListGroupPresentationContainer";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

const paymentMethod = [
  { value: 1, label: "Efectivo", name: "paymentMethod" },
  { value: 2, label: "Tarjeta", name: "paymentMethod" },
];

export const OrderCreateMainContainer = () => {
  const [idStore, setIdStore] = useState("");
  const [addFields, setAddFields] = useState([]);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [reference, setReference] = useState("");
  const [address, setAddress] = useState("");
  const [selectDeliveryType, setSelectDeliveryType] = useState("En Tienda");
  const [selectTicket, setSelectTicket] = useState("Boleta");

  const { listOptionsCombo } = useSelector((state) => state.listOptionsCombo);
  const createOrder = useSelector((state) => state.createOrder);
  const { listOptionsClients } = useSelector(
    (state) => state.listOptionsClients
  );
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const { listOptionsStore } = useSelector((state) => state.listOptionsStore);
  const { listOptionsDocument } = useSelector(
    (state) => state.listOptionsDocument
  );
  const { listOptionsStorePickup } = useSelector(
    (state) => state.listOptionsStorePickup
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptionsClients());
    dispatch(getListOptionsStore());
    dispatch(getListOptionsDocument());
    dispatch(getListOptionsProducts());
    dispatch(getListOptionsCombo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { store, businessName, ruc, identidad, onChange } = useField({
    client: "",
    hours: "",
    typeStore: "En Tienda",
    store: 1,
    state: "",
    ticket: "boleta",
    typeDocument: "",
    identidad: "",
    ruc: "",
    businessName: "",
    products: [],
    combo: [],
  });

  useEffect(() => {
    dispatch(getListOptionsStorePickup(store));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);
  const InputRadioContainer = ({
    text,
    text2,
    onChange,
    isSelectData,
    name,
  }) => {
    return (
      <div
        className="orderCreateMainContainer__input-radio"
        onChange={onChange}
      >
        <div className="orderCreateMainContainer__input-radio__item">
          <input
            defaultChecked={isSelectData === text}
            name={name}
            type="radio"
            value={text}
          />
          {text}
        </div>
        <div className="orderCreateMainContainer__input-radio__item">
          <input
            defaultChecked={isSelectData === text2}
            name={name}
            type="radio"
            value={text2}
          />
          {text2}
        </div>
      </div>
    );
  };

  //Primer parametro la data, 2do el name que estara en la nueva data y tercero el key a cambiar
  const processListOptionsClients = getListOptions([
    listOptionsClients,
    "client",
    "user",
  ]);
  const processListOptionsTienda = getListOptions([
    listOptionsStore,
    "store",
    "name",
  ]);
  const processListOptionsHours = getListOptions([
    listOptionsStorePickup,
    "hours",
    "schedulePickup",
  ]);
  const processListOptionsDocumento = getListOptions([
    listOptionsDocument,
    "typeDocument",
    "name",
  ]);

  const processListOptionsProducts = getListOptions([
    listOptionsProducts,
    "products",
    "name",
  ]);

  const processListOptionsCombo = getListOptions([
    listOptionsCombo,
    "combo",
    "name",
  ]);
  const validateCoverage = useCallback(
    async (lat, lng) => {
      try {
        const resp = await apiPath.post(
          "/coberturas/verificar_direccion/",
          {
            latitude: lat,
            longitude: lng,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (resp.data.isPoint) {
          setIdStore(resp.data.shopId);
        } else {
          setIdStore(0);
        }
      } catch (error) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangePlace = (place) => {
    const {
      geometry: { location },
      formatted_address,
    } = place;
    const lat = location?.lat();
    const lng = location?.lng();
    if (lat && lng) {
      validateCoverage(lat, lng);
      setCoordinates({ lat, lng });
      setAddress(formatted_address);
    }
  };

  const getAddressByLatLng = useCallback(
    async (location) => {
      validateCoverage(location.lat, location.lng);
      setCoordinates(location);
      setReference("");
      const geo = new window.google.maps.Geocoder();
      const response = await geo.geocode({ location });
      setAddress(response?.results[0]?.formatted_address);
    },
    [validateCoverage]
  );

  const onChangeValue = (e) => {
    setSelectDeliveryType(e.target.value);
    onChange(e);
  };
  const onChangeTicket = (e) => {
    setSelectTicket(e.target.value);
    onChange(e);
  };

  const onChangeReference = (e) => {
    setReference(e.target.value);
    dispatch(setCreateOrder({ reference: e.target.value }));
    onChange(e);
  };

  const handleOnchage = (e, index) => {
    let id = parseInt(e.value);
    let data = createOrder?.products;

    const products = getListDataFilter(data, index, id);
    dispatch(setCreatedOrderProduct({ product: id, index }));
    dispatch(setCreateOrder({ products: products }));
  };

  const addFieldsValueContainer = () => {
    let data = createOrder?.products;
    let count = addFields;
    if (data.length === count.length - 1) {
      return;
    }
    setAddFields([...addFields, addFields.length]);
  };
  return (
    <div className="orderCreateMainContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Cliente"}
        handleOnchange={onChange}
        data={processListOptionsClients}
        placeholder={"Buscar Cliente"}
        isSelectData={true}
      />

      <InputRadioContainer
        text={"En Tienda"}
        text2={"Delivery"}
        onChange={onChangeValue}
        isSelectData={selectDeliveryType}
        name={"typeStore"}
      />
      {selectDeliveryType === "En Tienda" ? (
        <>
          <SelectOptionsContainer
            title={"Tienda"}
            handleOnchange={onChange}
            data={processListOptionsTienda}
            placeholder={"Seleccionar Tienda"}
            isSelectData={true}
          />
          <SelectOptionsContainer
            title={"Horario de recojo"}
            handleOnchange={onChange}
            data={processListOptionsHours}
            placeholder={"Seleccionar Hora"}
            isSelectData={true}
          />
        </>
      ) : (
        <div className="orderCreateMainContainer__direction">
          <SearchLocationComponent
            onChangePlace={onChangePlace}
            idStore={idStore}
            setIdStore={setIdStore}
            currentAddress={address}
            log={coordinates.lng}
            lat={coordinates.lat}
            reference={reference}
          />
          {!!idStore && (
            <>
              <div className="orderCreateMainContainer__direction__container__text">
                "Esta dentro de cobertura"
              </div>

              <SelectOptionsContainer
                title={"Referencia"}
                handleOnchange={onChangeReference}
                name={"reference"}
                isInput={true}
                value={reference}
                placeholder={"Referencia"}
              />
            </>
          )}

          {idStore === 0 && (
            <div className="orderCreateMainContainer__direction__container__text">
              "Esta fuera de cobertura"
            </div>
          )}

          <div className="orderCreateMainContainer__map">
            <MapSearchComponent
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              onGetAddressByLatLng={getAddressByLatLng}
            />
          </div>
        </div>
      )}

      <InputRadioContainer
        text={"Boleta"}
        text2={"Factura"}
        onChange={onChangeTicket}
        isSelectData={selectTicket}
        name={"ticket"}
      />
      {selectTicket === "Boleta" ? (
        <>
          <SelectOptionsContainer
            title={"Tipo de Documento"}
            handleOnchange={onChange}
            data={processListOptionsDocumento}
            placeholder={"Seleccionar tipo"}
            isSelectData={true}
          />
          <SelectOptionsContainer
            title={"Nro. de Identidad"}
            handleOnchange={onChange}
            name="identidad"
            isInput={true}
            value={identidad}
          />
        </>
      ) : (
        <>
          <SelectOptionsContainer
            title={"Número de RUC"}
            handleOnchange={onChange}
            name={"ruc"}
            isInput={true}
            value={ruc}
            placeholder={"Número de RUC"}
          />
          <SelectOptionsContainer
            title={"Nombre o Razón Social"}
            handleOnchange={onChange}
            name={"businessName"}
            isInput={true}
            value={businessName}
            placeholder={"Nombre o Razón Social"}
          />
        </>
      )}
      <SelectOptionsContainer
        title={"Método de Pago"}
        handleOnchange={onChange}
        placeholder={"Elige un método"}
        data={paymentMethod}
        isSelectData={true}
      />
      {addFields.map((item, indexProduct) => (
        <div key={indexProduct}>
          <SelectOptionsContainer
            key={indexProduct}
            indexProduct={indexProduct}
            title={"Eligir Producto"}
            count={true}
            handleOnchange={(e) => handleOnchage(e, indexProduct)}
            data={processListOptionsProducts.filter(
              (item) => !createOrder?.products?.includes(item.value)
            )}
            isSelectData={true}
          />

          {createOrder?.products[indexProduct] && (
            <>
              <OrderListGroupPresentationContainer
                id={createOrder?.products[indexProduct]}
                indexProduct={indexProduct}
              />
              <SelectCommentComponent indexProduct={indexProduct} />
            </>
          )}
        </div>
      ))}
      <div className="orderCreateMainContainer__container-add">
        <div
          className="orderCreateMainContainer__container-add__button"
          onClick={addFieldsValueContainer}
        >
          + Producto
        </div>
      </div>

      <SelectOptionsContainer
        title={"Eligir Combo"}
        handleOnchange={onChange}
        data={processListOptionsCombo}
        placeholder={"Seleccionar Combo"}
        isSelectData={true}
      />
    </div>
  );
};
