import { extractDetailsFromList } from "../../helpers";
import { requestPostAuthorization } from "../../services/requestPost";
import {
  setListOrderClientsDetail,
  setListOrderProductsDetail,
  setListOrderSalesDetail,
  setListStatisticsClients,
  setListStatisticsDelivery,
  setListStatisticsLocalMir,
  setListStatisticsLocalPue,
  setListStatisticsOrders,
  setListStatisticsOrdersStates,
  setListStatisticsStore,
  setListStatisticsTopClients,
  setListStatisticsTopPayment,
  setListStatisticsTopProducts,
  setOrderClientsLoading,
  setOrderProductsLoading,
  setOrderSalesLoading,
  setStatisticsClientsLoading,
  setStatisticsLoading,
  setStatisticsOrdersLoading,
  setStatisticsOrdersStatesLoading,
  setStatisticsTopClientsLoading,
  setStatisticsTopPaymentLoading,
  setStatisticsTopProductsLoading,
} from "./statisticsSlice";

export const getListOrdersDelivery = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    body.modality = "Delivery";

    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsDelivery({
          dataStatisticsDelivery: value.listNumbersOrdersByDate,
        })
      );
      dispatch(setStatisticsLoading({ showLoading: false }));
    });
  };
};

export const getListOrdersStore = (body) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    body.modality = "En Tienda";
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsStore({
          dataStatisticsStore: value.listNumbersOrdersByDate,
        })
      );
    });
  };
};

export const getListOrdersLocalMir = (body) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    body.modality = "En Tienda";
    body.shop = 1;
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsLocalMir({
          dataStatisticsLocalMir: value.listNumbersOrdersByDate,
        })
      );
    });
  };
};

export const getListOrdersLocalPue = (body) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    body.modality = "En Tienda";
    body.shop = 2;
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsLocalPue({
          dataStatisticsLocalPue: value.listNumbersOrdersByDate,
        })
      );
    });
  };
};

export const getListOrdersByDate = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsOrdersLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsOrders({
          dataStatisticsOrders: value.listNumbersOrdersByDate,
        })
      );
      dispatch(setStatisticsOrdersLoading({ showLoading: false }));
    });
  };
};

export const getListOrdersClients = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsClientsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_clientes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsClients({
          dataStatisticsClients: value.listNumbersClientsByDate,
        })
      );
      dispatch(setStatisticsClientsLoading({ showLoading: false }));
    });
  };
};

export const getListOrdersStates = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsOrdersStatesLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/calcular_cantidad_ordenes_estados/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsOrdersStates({
          dataStatisticsOrdersStates: value.listNumbersOrdersByState,
        })
      );
      dispatch(setStatisticsOrdersStatesLoading({ showLoading: false }));
    });
  };
};

export const getListTopClients = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsTopClientsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/ranking_clientes/",
      body,
      token
    ).then((value) => {
      const newValue = value.listClients.slice(0, 10);
      const extractedDetailsList = extractDetailsFromList(newValue);
      dispatch(
        setListStatisticsTopClients({
          dataTopClients: extractedDetailsList,
        })
      );
      dispatch(setStatisticsTopClientsLoading({ showLoading: false }));
    });
  };
};

export const getListTopProducts = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsTopProductsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/ranking_productos/",
      body,
      token
    ).then((value) => {
      const newValue = value.listProducts.slice(0, 10);
      dispatch(
        setListStatisticsTopProducts({
          dataTopProducts: newValue,
        })
      );
      dispatch(setStatisticsTopProductsLoading({ showLoading: false }));
    });
  };
};

export const getListTopPayment = (body) => {
  return async (dispatch) => {
    dispatch(setStatisticsTopPaymentLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/ranking_metodos_pagos/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListStatisticsTopPayment({
          dataTopPayment: value.listPaymentMethods,
        })
      );
      dispatch(setStatisticsTopPaymentLoading({ showLoading: false }));
    });
  };
};

export const getListOrderSalesDetail = (body) => {
  return async (dispatch) => {
    dispatch(setOrderSalesLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/detalle_ordenes_ventas/",
      body,
      token
    ).then((value) => {
      dispatch(setOrderSalesLoading({ showLoading: false }));
      dispatch(
        setListOrderSalesDetail({
          dataOrderSalesDetail: value?.shops,
        })
      );
    });
  };
};

export const getListOrderClientsDetail = (body) => {
  return async (dispatch) => {
    dispatch(setOrderClientsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/detalle_clientes/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListOrderClientsDetail({
          dataOrderClientsDetail: value?.clients,
        })
      );
      dispatch(setOrderClientsLoading({ showLoading: false }));
    });
  };
};

export const getListOrderProductsDetail = (body) => {
  return async (dispatch) => {
    dispatch(setOrderProductsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestPostAuthorization(
      "/admin/estadisticas/detalle_productos/",
      body,
      token
    ).then((value) => {
      dispatch(
        setListOrderProductsDetail({
          dataOrderProductsDetail: value?.products,
        })
      );
      dispatch(setOrderProductsLoading({ showLoading: false }));
    });
  };
};
