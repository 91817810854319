import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
import { getFormatDate } from "../../helpers";

export const TableListFieldsValueComponent = ({
  data,
  url,
  height = "40px",
}) => {
  const activeColor = orderStateColor(data?.active);
  const stateColor = orderStateColor(data?.state);
  return (
    <tr className="tableListFieldsValueComponent" style={{ height }}>
      <td className="tableListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      {data.codigo !== undefined && (
        <td className="tableListFieldsValueComponent__code">
          <Link to={url(data.id)}>{data.codigo}</Link>
        </td>
      )}
      {data.name !== undefined && (
        <td className="tableListFieldsValueComponent__name">
          <Link to={url(data.id)}>{data.name}</Link>
        </td>
      )}
      {data.lastNameFather !== undefined &&
        data.lastNameMother !== undefined && (
          <td className="tableListFieldsValueComponent__fullName">
            <Link to={url(data.id)}>
              {data.lastNameFather} {data.lastNameMother}
            </Link>
          </td>
        )}
      {data.email !== undefined && (
        <td className="tableListFieldsValueComponent__email">
          <Link to={url(data.id)}>{data.email}</Link>
        </td>
      )}
      {data.document !== undefined && (
        <td className="tableListFieldsValueComponent__document">
          <Link to={url(data.id)}>{data.document}</Link>
        </td>
      )}
      {data.phone !== undefined && (
        <td className="tableListFieldsValueComponent__phone">
          <Link to={url(data.id)}>{data.phone}</Link>
        </td>
      )}
      {data.state !== undefined && (
        <td className={`tableListFieldsValueComponent__state ${stateColor}`}>
          <Link to={url(data.id)}>{data.state}</Link>
        </td>
      )}
      {data.date !== undefined && (
        <td className="tableListFieldsValueComponent__date">
          <Link to={url(data.id)}>{data.date}</Link>
        </td>
      )}
      {data.created !== undefined && (
        <td className="tableListFieldsValueComponent__created">
          <Link to={url}>{getFormatDate(data.created)}</Link>
        </td>
      )}
      {data.time !== undefined && (
        <td className="tableListFieldsValueComponent__time">
          <Link to={url(data.id)}>{data.time}</Link>
        </td>
      )}
      {data.numberPerson !== undefined && (
        <td className="tableListFieldsValueComponent__numberPerson">
          <Link to={url(data.id)}>{data.numberPerson}</Link>
        </td>
      )}
      {data.reservationReason !== undefined && (
        <td className="tableListFieldsValueComponent__reservationReason">
          <Link to={url(data.id)}>{data.reservationReason}</Link>
        </td>
      )}

      {data.district !== undefined && (
        <td className="tableListFieldsValueComponent__district">
          <Link to={url(data.id)}>{data.district}</Link>
        </td>
      )}
      {data.order !== undefined && (
        <td className="tableListFieldsValueComponent__order">
          <Link to={url(data.id)}>{data.order}</Link>
        </td>
      )}
      {data.typeContracted !== undefined && (
        <td className="tableListFieldsValueComponent__typeContracted">
          <Link to={url(data.id)}>{data.typeContracted}</Link>
        </td>
      )}
      {data.typeClaim !== undefined && (
        <td className="tableListFieldsValueComponent__typeClaim">
          <Link to={url(data.id)}>{data.typeClaim}</Link>
        </td>
      )}
      {data.typeDocument !== undefined && (
        <td className="tableListFieldsValueComponent__typeDocument">
          <Link to={url(data.id)}>{data.typeDocument}</Link>
        </td>
      )}
      {data.numberDocument !== undefined && (
        <td className="tableListFieldsValueComponent__numberDocument">
          <Link to={url(data.id)}>{data.numberDocument}</Link>
        </td>
      )}
      {data.shop !== undefined && (
        <td className="tableListFieldsValueComponent__shop">
          <Link to={url(data.id)}>{data.shop}</Link>
        </td>
      )}

      {data.slug !== undefined && (
        <td className="tableListFieldsValueComponent__email">
          <Link to={url(data.id)}>{data.slug}</Link>
        </td>
      )}
      {data.key !== undefined && (
        <td className="tableListFieldsValueComponent__key">
          <Link to={url(data.id)}>{data.key}</Link>
        </td>
      )}
      {data.value !== undefined && (
        <td className="tableListFieldsValueComponent__value">
          <Link to={url(data.id)}>{data.value}</Link>
        </td>
      )}
      {/* {data.email !== undefined && (
        <td className="tableListFieldsValueComponent__email">
          <Link to={url(data.id)}>{data.email}</Link>
        </td>
      )} */}
      {data.tipoFormulario !== undefined && (
        <td className="tableListFieldsValueComponent__type-form">
          <Link to={url(data.id)}>{data.tipoFormulario}</Link>
        </td>
      )}
      {data.imageMini !== undefined && data.name !== undefined && (
        <td className="productListFieldsValueComponent__image">
          <Link to={url(data.id)}>
            <img src={data?.imageMini} alt={data?.name} />
          </Link>
        </td>
      )}
      {data.description !== undefined && (
        <td className="tableListFieldsValueComponent__description">
          <Link to={url(data.id)}>{data.description}</Link>
        </td>
      )}
      {data.price !== undefined && (
        <td className="tableListFieldsValueComponent__phone">
          <Link to={url(data.id)}>{data.price}</Link>
        </td>
      )}
      {data.active !== undefined && (
        <td className={`tableListFieldsValueComponent__active ${activeColor}`}>
          <Link to={url(data.id)}>{data.active ? "Activo" : "Inactivo"}</Link>
        </td>
      )}
    </tr>
  );
};
