import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ProductDetailInformationItemContainer } from "../";
import { setUpdateProduct } from "../../store/product";
import MultipleSelectChip from "../SelectChipContainer/SelectChipContainer";
import { getListOptions } from "../../helpers";

export const ProductDetailInformationContainer = ({
  data,
  channels,
  isCreate = false,
}) => {
  const [productData, setProductData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!data) {
      setProductData(data);
    }
  }, [data]);
  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "price" || name === "pricePromotion" || name === "channels") {
      value = parseFloat(value);
    }
    if (name === "active") {
      value = e.target.checked;
    }
    if (name === "image") {
      const file = e.target.files[0];
      value = {
        url: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      };
      dispatch(setUpdateProduct({ imageIsLoaded: true }));
    }
    setProductData({ ...productData, [name]: value });
    dispatch(setUpdateProduct({ [name]: value }));
  };
  const handleOnchageMultiple = (e, name) => {
    dispatch(setUpdateProduct({ [name]: e.map((item) => item.value) }));
  };

  const processListChannels = getListOptions([
    data.channels,
    "channels",
    "name",
  ]);
  return (
    <div className="productDetailInformationContainer">
      <div className="productDetailInformationContainer__title">Productos</div>
      <div className="productDetailInformationContainer__container">
        {!isCreate && (
          <ProductDetailInformationItemContainer
            title="Código"
            name="codigo"
            value={productData?.codigo || ""}
            disabled="disabled"
            handleOnchage={handleOnchage}
          />
        )}
        <ProductDetailInformationItemContainer
          title="Nombre del producto"
          name="name"
          value={productData?.name || ""}
          handleOnchage={handleOnchage}
        />
        <ProductDetailInformationItemContainer
          title="Precio"
          name="price"
          value={productData?.price || ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <ProductDetailInformationItemContainer
          title="Precio Promoción"
          name="pricePromotion"
          value={productData?.pricePromotion || ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <ProductDetailInformationItemContainer
          title="Slug"
          name="slug"
          value={productData?.slug || ""}
          handleOnchage={handleOnchage}
        />

        <div className="productDetailInformationItemContainer">
          <div className="productDetailInformationItemContainer__title">
            Descripción
          </div>
          <div className="productDetailInformationItemContainer__description">
            <textarea
              value={productData?.description || ""}
              onChange={handleOnchage}
              name="description"
            />
          </div>
        </div>

        <div className="productDetailInformationItemContainer">
          <div className="productDetailInformationItemContainer__title">
            Images
          </div>
          {!isCreate && (
            <div className="productDetailInformationItemContainer__text">
              Actualmente:{" "}
              {data?.image &&
                data?.image.slice(data?.image?.indexOf("producto"))}
            </div>
          )}
          <div className="productDetailInformationItemContainer__description__file">
            <input type="file" name="image" onChange={handleOnchage} />
          </div>
        </div>
        <div className="productDetailInformationItemContainer">
          <div className="productDetailInformationItemContainer__title">
            Canales
          </div>
          <div className="productDetailInformationContainer__multiple">
            <MultipleSelectChip
              listOptions={channels}
              data={processListChannels}
              handleChange={handleOnchageMultiple}
              name="channels"
              placeholderTitle="Elije uno o varios canales"
            />
          </div>
        </div>
        <ProductDetailInformationItemContainer
          title="¿Activo?"
          name="active"
          checked={productData?.active}
          type="checkbox"
          handleOnchage={handleOnchage}
        />
      </div>
    </div>
  );
};
