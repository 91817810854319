import { OrderDetailContainer, OrderDetailUserContainer } from "../";

export const OrderDetailMainContainer = ({ data, dataItems }) => {
  return (
    <section className="orderDetailMainContainer">
      <OrderDetailContainer data={data} dataItems={dataItems} />
      <OrderDetailUserContainer data={data} />
    </section>
  );
};
