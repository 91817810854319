import {
  SettingCoffeeShopSuggestiveHeaderContainer,
  SettingCoffeeShopSuggestiveMainContainer,
} from "../../../../containers";

export const SettingCoffeeShopSuggestiveDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingCoffeeShopSuggestiveHeaderContainer />
      <SettingCoffeeShopSuggestiveMainContainer />
    </section>
  );
};
