import { useDispatch, useSelector } from "react-redux";
import { SettingLandingCollaboratorsInformationContainer } from "..";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { cleanCreateCollaborator } from "../../store/collaborators/collaboratorsSlice";
import { getRetrieveQuestions } from "../../store/questions/thunks";
import {
  LoadingComponent,
  ReactQuillComponent,
  TitleInformationComponent,
} from "../../components";
import React from "react";
import { FaTrash } from "react-icons/fa";

import {
  setAddAnswers,
  setAddQuestion,
  setRetrieveAnswers,
  setRetrieveAnswersActive,
  setRetrieveAnswersOrder,
  setRetrieveQuestions,
  setRetrieveQuestionsItem,
} from "../../store/questions/questionsSlice";

export const SettingLandingQuestionsMainContainer = () => {
  const { questionsData, showLoading } = useSelector(
    (state) => state.retrieveQuestions
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.questionId) {
      dispatch(getRetrieveQuestions(path.questionId));
    } else {
      dispatch(cleanCreateCollaborator());
    }
    // eslint-disable-next-line
  }, []);
  const handleOnchangeAdd = () => {
    dispatch(setAddQuestion());
  };

  const handleOnchage = ({ target }) => {
    const { name, checked, value } = target;
    let result = value;
    if (name === "active") result = checked;
    dispatch(setRetrieveQuestions({ data: { [name]: result } }));
  };
  const handleOnchageQuestion = (e, index) => {
    const { name, checked, value } = e.target;
    let result = value;
    if (name === "active") result = checked;
    dispatch(setRetrieveQuestionsItem({ [name]: result, index }));
  };

  const handleOnchageQuestionAnswers = (data, index, indexAnswer) => {
    dispatch(setRetrieveAnswers({ data, index, indexAnswer }));
  };

  const handleOnchageAnswers = (data, index, indexAnswer) => {
    const { name, checked, value } = data.target;
    if (name === "active") {
      return dispatch(
        setRetrieveAnswersActive({ data: checked, index, indexAnswer })
      );
    }
    dispatch(setRetrieveAnswersOrder({ data: value, index, indexAnswer }));
  };

  const handleOnchangeAddAnswers = (index) => {
    dispatch(setAddAnswers({ index }));
  };

  return (
    <>
      {!showLoading && path.questionId ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <SettingLandingCollaboratorsInformationContainer
            data={questionsData}
            handleOnchage={handleOnchage}
          />

          <TitleInformationComponent title={"Preguntas y Respuestas"} />
          <div className="productDetailInformationContainer">
            {questionsData?.questions?.map((question, index) => (
              <React.Fragment key={question.id}>
                <div>
                  <table className="productDetailInformationContainer__table">
                    <thead>
                      <tr>
                        <th className="productDetailInformationContainer__table__question">
                          Pregunta
                        </th>
                        <th className="productDetailInformationContainer__table__order">
                          Order
                        </th>
                        <th className="productDetailInformationContainer__table__active">
                          Activo
                        </th>
                        <th className="productDetailInformationContainer__table__delete">
                          Eliminar
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="productDetailInformationContainer__table__question">
                          <textarea
                            type="text"
                            name="name"
                            value={question.name}
                            onChange={(e) => handleOnchageQuestion(e, index)}
                          />
                        </td>
                        <td className="productDetailInformationContainer__table__order">
                          <input
                            type="number"
                            name="order"
                            value={question.order}
                            onChange={(e) => handleOnchageQuestion(e, index)}
                          />
                        </td>
                        <td className="productDetailInformationContainer__table__active">
                          <input
                            type="checkbox"
                            name="active"
                            checked={question.active}
                            onChange={(e) => handleOnchageQuestion(e, index)}
                          />
                        </td>
                        <td className="productDetailInformationContainer__table__delete">
                          <FaTrash />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {question?.answers?.map((answer, i) => (
                    <div
                      key={answer.id}
                      className="productDetailInformationContainer__answers"
                    >
                      <div className="productDetailInformationContainer__answers__title">
                        <div className="productDetailInformationContainer__answers__title__answers">
                          Respuesta
                        </div>
                        <div className="productDetailInformationContainer__answers__title__order">
                          Order
                        </div>
                        <div className="productDetailInformationContainer__answers__title__active">
                          Activo
                        </div>
                      </div>
                      <div className="productDetailInformationContainer__answers__quill">
                        <div className="productDetailInformationContainer__answers__quill__component">
                          <ReactQuillComponent
                            data={answer.description}
                            index={index}
                            indexOf={i}
                            fnc={handleOnchageQuestionAnswers}
                          />
                        </div>
                        <div className="productDetailInformationContainer__answers__quill__order">
                          <input
                            value={answer.order}
                            name="order"
                            onChange={(e) => handleOnchageAnswers(e, index, i)}
                          />
                        </div>
                        <div className="productDetailInformationContainer__answers__quill__active">
                          <input
                            type="checkbox"
                            checked={answer.active}
                            name="active"
                            onChange={(e) => handleOnchageAnswers(e, index, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="productDetailGroupModifiedContainer__container__left__button"
                  onClick={() => handleOnchangeAddAnswers(index)}
                >
                  <div>Agregar Respuesta</div>
                </div>
              </React.Fragment>
            ))}
            <div
              className="productDetailGroupModifiedContainer__container__left__button"
              onClick={handleOnchangeAdd}
            >
              <div>Agregar Pregunta</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
