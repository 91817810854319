import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ProductDetailFieldsValueContainer,
  ProductDetailTableContainer,
} from "../";
import { ProductDetailFieldsKeyComponent } from "../../components";
import {
  clearUpdateProductPresentationGroup,
  setUpdateProductPresentationGroup,
} from "../../store/product";

export const ProductDetailGroupPresentationContainer = ({ data }) => {
  const dispatch = useDispatch();
  const [presentations, setPresentations] = useState([]);
  const [presentationGroup, setPresentationGroup] = useState({
    name: "",
    isMandatory: false,
    order: 1,
    active: true,
  });
  useEffect(() => {
    if (!!data) {
      setPresentationGroup(data);
      setPresentations(data?.presentaciones ? data?.presentaciones : []);
    }
  }, [data]);
  const handleOnchagePresentationGroup = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active" || name === "isMandatory") {
      value = e.target.checked;
    }
    setPresentationGroup({ ...presentationGroup, [name]: value });
    const updateProductPresentationGroup = {
      ...presentationGroup,
      [name]: value,
    };
    updateProductPresentationGroup.index = 0;
    dispatch(setUpdateProductPresentationGroup(updateProductPresentationGroup));
  };
  const addPresentation = () => {
    setPresentations([...presentations, null]);
  };
  const clearGroupCurrent = (e) => {
    setPresentationGroup({
      name: "",
      isMandatory: false,
      order: 1,
      active: true,
    });
    setPresentations([]);
    dispatch(clearUpdateProductPresentationGroup());
  };
  return (
    <div className="productDetailGroupPresentationContainer">
      <div className="productDetailGroupPresentationContainer__container">
        <div className="productDetailGroupPresentationContainer__container__title">
          Grupo de Presentaciones
        </div>
        <div className="productDetailGroupPresentationContainer__container__table">
          <ProductDetailTableContainer
            isPresentationGroup={true}
            data={presentationGroup}
            isOpen={false}
            handleOnchage={handleOnchagePresentationGroup}
            clearGroupCurrent={clearGroupCurrent}
          />
        </div>
        {!!presentationGroup.name && (
          <div className="productDetailGroupPresentationContainer__container__left">
            <div className="productDetailGroupPresentationContainer__container__left__title">
              Presentaciones
            </div>

            <div className="productDetailGroupPresentationContainer__container__left__table">
              <table className="productDetailTableContainer">
                <ProductDetailFieldsKeyComponent
                  isPresentationGroup={true}
                  isPresentation={true}
                />
                <tbody>
                  {presentations.map((item, index) => (
                    <ProductDetailFieldsValueContainer
                      isPresentationGroup={true}
                      isPresentation={true}
                      data={item}
                      key={index}
                      index={index}
                      customs={presentations}
                      setCustoms={setPresentations}
                    />
                  ))}
                  <tr
                    className="productDetailGroupPresentationContainer__container__left__table__button"
                    onClick={addPresentation}
                  >
                    <td>Agregar Producto adicional</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
