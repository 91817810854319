function extractDetails(jsonObject) {
  const { count, names, surnames } = jsonObject;
  const name = names.split(" ")[0] + " " + surnames.split(" ")[0];

  return { count, name };
}

export const extractDetailsFromList = (array) => {
  return array.map((jsonObject) => extractDetails(jsonObject));
};
