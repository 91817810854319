import { TitleComponent } from "../../../components";
import { SettingBoxContainer } from "../../../containers";

const data = [
  {
    id: 3,
    title: "Banners",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2015/05/13/22/56/radishes-766105_960_720.jpg",
    slug: "banners",
  },
  {
    id: 4,
    title: "Venta Sugestiva",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2014/07/16/05/18/beach-394503_960_720.jpg",
    slug: "venta-sugestiva",
  },
];

export const SettingCoffeeShopIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Cafeteria"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
