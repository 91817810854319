import { createSlice } from "@reduxjs/toolkit";
export const retrieveBannerOrderSlice = createSlice({
  name: "retrieveBannerOrder",
  initialState: {
    photo: {},
    slugItem: null,
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerOrder: (state, action) => {
      state.photo = action.payload.data;
      state.slugItem = action.payload.slugItem;
    },
    setRetrieveBannerOrderSlug: (state, action) => {
      state.slugItem = action.payload.slugItem;
    },
    setRetrieveBannerOrderLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveBannerOrderLoading,
  setRetrieveBannerOrder,
  setRetrieveBannerOrderSlug,
} = retrieveBannerOrderSlice.actions;
