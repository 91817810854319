import {
  ProductDetailInformationContainer,
  ProductDetailInformationSecondContainer,
  ProductDetailGroupPresentationContainer,
  ProductDetailGroupModifiedContainer,
  ProductDetailGroupNoteContainer,
} from "../";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getRetrieveProduct } from "../../store/product";
import { useDispatch, useSelector } from "react-redux";
import { getListOptionChannel } from "../../store/channel";
import { getListOptionComplement } from "../../store/complement";
import { getListOptionTag } from "../../store/tag/thunks";
import { getListOptionsModifier } from "../../store/modifier";
import { getListOptionsNote } from "../../store/note";
import { LoadingComponent } from "../../components";

export const ProductDetailMainContainer = () => {
  const { productData, showLoading } = useSelector(
    (state) => state.retrieveProduct
  );
  const { listOptionChannelData } = useSelector(
    (state) => state.listOptionChannel
  );
  const { listOptionComplementData } = useSelector(
    (state) => state.listOptionComplement
  );
  const { listOptionTagData } = useSelector((state) => state.listOptionTag);
  const { listOptionsModifier } = useSelector(
    (state) => state.listOptionsModifier
  );
  const { listOptionsNote } = useSelector((state) => state.listOptionsNote);
  const dispatch = useDispatch();
  const path = useParams();

  useEffect(() => {
    dispatch(getRetrieveProduct(path.productId));
    // eslint-disable-next-line
  }, [path.productId]);
  useEffect(() => {
    dispatch(getListOptionChannel());
    dispatch(getListOptionComplement());
    dispatch(getListOptionTag());
    dispatch(getListOptionsModifier());
    dispatch(getListOptionsNote());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <ProductDetailInformationContainer
            data={productData}
            channels={listOptionChannelData}
          />
          <ProductDetailInformationSecondContainer
            data={productData}
            optionsTags={listOptionTagData}
            optionsComplement={listOptionComplementData}
          />
          <ProductDetailGroupPresentationContainer
            data={productData?.gruposPresentaciones?.[0]}
          />
          <ProductDetailGroupModifiedContainer
            data={productData?.gruposModificadores?.[0]}
            options={listOptionsModifier}
          />
          <ProductDetailGroupNoteContainer
            data={productData?.gruposNotas?.[0]}
            options={listOptionsNote}
          />
        </div>
      )}
    </>
  );
};
