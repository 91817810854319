import {
  ImageComponent,
  KeyValueComponent,
  TitleComponent,
} from "../../components";
import background from "../../assets/background.svg";
import { FaEnvelope, FaPhone, FaUser, FaBell } from "react-icons/fa";

export const ClientRetrieveInformationContainer = ({
  clientId,
  clientData,
}) => {
  const titleStyle = { fontSize: "25px" };
  const keyValueStyle = {
    fontSize: "15px",
    color: "#637085",
    fontWeight: "bold",
  };
  return (
    <div className="clientRetrieveInformationContainer">
      <div className="clientRetrieveInformationContainer__background">
        <ImageComponent src={background} />
      </div>
      <div className="clientRetrieveInformationContainer__information">
        <div className="clientRetrieveInformationContainer__information__image">
          <ImageComponent src={"https://i.ibb.co/gm0F52m/5.png"} />
        </div>
        <div className="clientRetrieveInformationContainer__information__title">
          <div className="clientRetrieveInformationContainer__information__title__names">
            <TitleComponent
              title={clientData?.user?.names}
              style={titleStyle}
            />
            <TitleComponent
              title={clientData?.user?.surnames}
              style={titleStyle}
            />
          </div>
          <div className="clientRetrieveInformationContainer__information__title__code">
            <p>{clientData?.codigo}</p>
          </div>
        </div>
        <div className="clientRetrieveInformationContainer__information__keyValue">
          <div className="clientRetrieveInformationContainer__information__keyValue__item">
            <FaEnvelope />
            <KeyValueComponent
              keyName={"EMAIL"}
              valueName={clientData?.user?.email}
              style={keyValueStyle}
            />
          </div>
          <div className="clientRetrieveInformationContainer__information__keyValue__item">
            <FaPhone />
            <KeyValueComponent
              keyName={"CELULAR"}
              valueName={clientData?.user?.phone}
              style={keyValueStyle}
            />
          </div>
          <div className="clientRetrieveInformationContainer__information__keyValue__item">
            <FaUser />
            <KeyValueComponent
              keyName={"TIPO DE CLIENTE"}
              valueName={"Persona"}
              // valueName={clientData?.type}
              style={keyValueStyle}
            />
          </div>
          <div className="clientRetrieveInformationContainer__information__keyValue__item">
            <FaBell />
            <KeyValueComponent
              keyName={"¿SUSCRITO?"}
              valueName={clientData?.user?.isNewsletterSubscriber ? "Si" : "No"}
              style={keyValueStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
