export const KeyValueInputComponent = ({
  name,
  value,
  type = "text",
  checked = true,
  handleOnchage,
  text,
  step,
}) => {
  return (
    <div className="keyValueInputComponent">
      <div className="keyValueInputComponent__title">{text}</div>
      <div className="keyValueInputComponent__description">
        <input
          type={type}
          value={value}
          onChange={handleOnchage}
          checked={checked}
          name={name}
          step={step}
        />
      </div>
    </div>
  );
};
