import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  NoteRetrieveHeaderContainer,
  NoteRetrieveInformationContainer,
} from "../../../containers";
import { getRetrieveNote } from "../../../store/note";
import { LoadingComponent } from "../../../components";

export const ProductNoteDetailPage = () => {
  const { noteData, showLoading } = useSelector((state) => state.retrieveNote);
  const path = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRetrieveNote(path.noteId));
    // eslint-disable-next-line
  }, [path.noteId]);

  return (
    <section className="productNoteDetailPage">
      <NoteRetrieveHeaderContainer noteId={path.noteId} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <NoteRetrieveInformationContainer data={noteData} />
      )}
    </section>
  );
};
