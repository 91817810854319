export const LoginFormComponent = ({
  onChange,
  handleAuth,
  isError = false,
}) => {
  return (
    <>
      <div className={"login-form__container__form__main__label"}>
        <label
          className={"login-form__container__form__main__label__title"}
          htmlFor="userName"
        >
          Email
        </label>
      </div>
      <div className={"login-form__container__form__main__input"}>
        <input
          type="text"
          name="userName"
          placeholder="Introduzca su correo electrónico"
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className={"login-form__container__form__main__label"}>
        <label
          className={"login-form__container__form__main__label__title"}
          htmlFor="password"
        >
          Contraseña
        </label>
      </div>
      <div className={"login-form__container__form__main__input"}>
        <input
          type="password"
          name="password"
          placeholder="Introduzca su contraseña"
          onChange={(e) => onChange(e)}
        />
      </div>
      {isError ? (
        <div className={"login-form__container__form__main__error"}>
          <p>* Credenciales no válidas</p>
        </div>
      ) : (
        <div className={"login-form__container__form__main__error"}>
          <p></p>
        </div>
      )}
      <div className={"login-form__container__form__main__button"}>
        <button type="submit" onClick={() => handleAuth()}>
          Ingresar
        </button>
      </div>
    </>
  );
};
