import {
  SettingLandingCollaboratorsHeaderContainer,
  SettingLandingCollaboratorsMainContainer,
} from "../../../../containers";

export const SettingLandingCollaboratorsDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingCollaboratorsHeaderContainer />
      <SettingLandingCollaboratorsMainContainer />
    </section>
  );
};
