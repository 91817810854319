import { requestGetAuthorization } from "../../services/requestGet";
import {
  setListOptionsStore,
  setListOptionsStorePickup,
  setListStore,
  setRetrieveStore,
  setRetrieveStoreLoading,
  setStoreLoading,
} from "./storeSlice";

export const getListOptionsStore = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/tiendas/lista_opciones/", token).then(
      (value) => {
        dispatch(setListOptionsStore({ data: value }));
      }
    );
  };
};

export const getListOptionsStorePickup = (storeId = 1) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/tiendas/${storeId}/horarios_recojos/`,
      token
    ).then((value) => {
      dispatch(setListOptionsStorePickup({ data: value }));
    });
  };
};

export const getListStore = () => {
  return async (dispatch) => {
    dispatch(setStoreLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/tiendas/`, token).then((value) => {
      dispatch(setListStore({ data: value }));
      dispatch(setStoreLoading({ showLoading: false }));
    });
  };
};

export const getStoreDetail = (storeId = 1) => {
  return async (dispatch) => {
    dispatch(setRetrieveStoreLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/tiendas/${storeId}/`, token).then(
      (value) => {
        dispatch(setRetrieveStore({ data: value }));
        dispatch(setRetrieveStoreLoading({ showLoading: false }));
      }
    );
  };
};
