import {
  SettingCoffeeShopBannersNewHeaderContainer,
  SettingCoffeeShopBannersNewMainContainer,
} from "../../../../../containers";
export const SettingCoffeeShopBannersNewDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingCoffeeShopBannersNewHeaderContainer />
      <SettingCoffeeShopBannersNewMainContainer />
    </section>
  );
};
