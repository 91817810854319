export const ClientRetrieveOrderFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="clientRetrieveOrderFieldsKeyComponent">
        <th className="clientRetrieveOrderFieldsKeyComponent__code">
          Código
        </th>
        <th className="clientRetrieveOrderFieldsKeyComponent__modality">
          Modalidad
        </th>
        <th className="clientRetrieveOrderFieldsKeyComponent__shop">
          Tienda
        </th>
        <th className="clientRetrieveOrderFieldsKeyComponent__total">
          Total
        </th>
        <th className="clientRetrieveOrderFieldsKeyComponent__state">
          Estado
        </th>
        <th className="clientRetrieveOrderFieldsKeyComponent__date">
          Fecha
        </th>
      </tr>
    </thead>
  );
};
