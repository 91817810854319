import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  OrderDetailTitleContainer,
  OrderDetailMainContainer,
} from "../../containers";
import {
  getRetrieveOrder,
  getRetrieveOrderItems,
} from "../../store/order/thunks";
import { LoadingComponent } from "../../components";

export const OrderDetailPage = () => {
  const { orderData, showLoading } = useSelector(
    (state) => state.retrieveOrder
  );
  const { orderRetrieveItemsData, showLoading: showItemsLoading } = useSelector(
    (state) => state.retrieveOrderItems
  );
  const dispatch = useDispatch();
  const path = useParams();

  useEffect(() => {
    dispatch(getRetrieveOrder(path.orderId));
    dispatch(getRetrieveOrderItems(path.orderId));
  }, [dispatch, path.orderId]);

  return (
    <section className="orderDetailPage">
      {!showLoading && !showItemsLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <OrderDetailTitleContainer data={orderData} />
          <OrderDetailMainContainer
            data={orderData}
            dataItems={orderRetrieveItemsData}
          />
        </>
      )}
    </section>
  );
};
