import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonPushComponent,
  TitleComponent,
} from "../../components";
import { ModalLayout } from "../../layouts";
import { requestDelete } from "../../services/requestDelete";
import {
  requestPatchAuthorization,
  requestPatchFileAuthorization,
} from "../../services/requestPatch";
import { clearUpdateCombo } from "../../store/combo/comboSlice";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const CombosDetailHeaderContainer = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { comboData } = useSelector((state) => state.retrieveCombo);

  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openOrCloseModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const convertMap = (array) => {
    return array.map((item) => item.id);
  };

  const handleCreateCombo = async () => {
    const body = { ...comboData };
    body.channels && (body.channels = convertMap(body.channels));
    body.colections && (body.colections = convertMap(body.colections));
    body.tags && (body.tags = convertMap(body.tags));
    body.complements && (body.complements = convertMap(body.complements));
    body.complements && (body.complements = convertMap(body.complements));

    body.gruposProductos = body?.gruposProductos.map((item) => ({
      name: item.name,
      order: item.order,
      number: item.number,
      products: item.products.map((product) => product.id),
    }));
    const token = localStorage.getItem("token");
    delete body.image;
    const image = comboData.image;

    if (!!image) {
      let blob = await fetch(image.url).then((r) => r.blob());
      const file = new File([blob], image.name, { type: image.type });
      const formData = new FormData();
      formData.append("image", file);
      await requestPatchFileAuthorization(
        `admin/combos/${path.combosId}/`,
        formData,
        token
      );
    }
    requestPatchAuthorization(
      `admin/combos/${path.combosId}/`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateCombo());
      navigate("/combos");
    });
  };

  const deleteCombo = () => {
    const token = localStorage.getItem("token");
    requestDelete(`admin/combos/${path.combosId}/`, token).then(() =>
      navigate("/combos")
    );
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del combo" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Eliminar"}
          color={"red"}
          onClick={openOrCloseModal}
        />
        <ButtonPushComponent name={"Guardar"} onClick={toggleModalUpdate} />
      </div>

      {isOpenDelete && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Combo"
            msg="¿Está seguro que desea eliminar el combo?"
            style={canceledStateStyle}
            onClose={openOrCloseModal}
            onConfirm={deleteCombo}
          />
        </ModalLayout>
      )}

      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Combo"
            msg="¿Está seguro que desea actualizar el combo?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
