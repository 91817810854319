import { createSlice } from "@reduxjs/toolkit";
export const listOptionComplementSlice = createSlice({
  name: "listOptionComplement",
  initialState: {
    listOptionComplementData: [],
  },
  reducers: {
    setListOptionComplement: (state, action) => {
      state.listOptionComplementData = action.payload.data;
    },
  },
});
export const { setListOptionComplement } = listOptionComplementSlice.actions;

export const retrieveComplementSlice = createSlice({
  name: "retrieveComplement",
  initialState: {
    name: "",
    products: [],
    order: "",
    showLoading: false,
  },
  reducers: {
    setRetrieveComplement: (state, action) => {
      state.name = action.payload.data.name || state.name;
      state.products = action.payload.data.products || state.products;
      state.order = action.payload.data?.order;
    },
    setUpdateComplementProduct: (state, action) => {
      state.products = action.payload.products || state.products;
    },
    clearUpdateComplement: (state) => {
      state = {
        name: "",
        products: [],
        order: "",
      };
    },
    setRetrieveComplementLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveComplement,
  setUpdateComplementProduct,
  clearUpdateComplement,
  setRetrieveComplementLoading,
} = retrieveComplementSlice.actions;

export const listComplementSlice = createSlice({
  name: "listComplement",
  initialState: {
    complementData: [],
    showLoading: false,
  },
  reducers: {
    setListComplement: (state, action) => {
      state.complementData = action.payload.data;
    },
    setComplementLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListComplement, setComplementLoading } =
  listComplementSlice.actions;
