const styles = {
  head: "reportTableHeadComponent__head",
  row: "reportTableHeadComponent__head__row",
  text: "reportTableHeadComponent__head__row__text",
  bodyRow: "reportTableHeadComponent__body__row",
  bodyText: "reportTableHeadComponent__body__row__text",
};



export const ReportTableHeadComponent = ({ titles, headRows }) => {
  return (
    <thead className={styles.head}>
      <tr className={styles.row}>
        {titles.map((title, index) => (
          <th
            className={styles.text}
            key={index}
            rowSpan={title.rowSpan}
            colSpan={title.colSpan}
          >
            {title.text}
          </th>
        ))}
      </tr>

      <tr className={styles.row}>
        {headRows.map((headRow, index) => (
          <th className={styles.text} key={index}>
            {headRow.text}
          </th>
        ))}
      </tr>
    </thead>
  );
};
