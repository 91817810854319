import { useSelector } from "react-redux";
import { ProductListFieldsValueComponent } from "../../components";

export const ProductListFieldsValueContainer = () => {
  const { productData } = useSelector((state) => state.listProducts);
  return (
    <tbody>
      {productData?.results?.length > 0 &&
        productData?.results?.map((product) => {
          return (
            <ProductListFieldsValueComponent
              key={product.id}
              product={product}
            />
          );
        })}
    </tbody>
  );
};
