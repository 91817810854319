import { createSlice } from "@reduxjs/toolkit";
export const listContactSlice = createSlice({
  name: "listContact",
  initialState: {
    contactData: [],
    showLoading: false,
  },
  reducers: {
    setListContact: (state, action) => {
      state.contactData = action.payload.data;
      state.contactParams = action.payload.params;
    },
    setContactLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListContact, setContactLoading } = listContactSlice.actions;

export const retrieveContactSlice = createSlice({
  name: "retrieveContact",
  initialState: {
    contactData: {
      name: "",
      email: "",
      description: "",
      created: "",
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveContact: (state, action) => {
      state.contactData.name =
        action.payload?.data?.name || state?.contactData?.name;
      state.contactData.email =
        action.payload?.data?.email || state?.contactData?.email;
      state.contactData.description =
        action.payload?.data?.description || state?.contactData?.description;
      state.contactData.created =
        action.payload?.data?.created || state?.contactData?.created;
    },
    setRetrieveContactLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveContact, setRetrieveContactLoading } =
  retrieveContactSlice.actions;
