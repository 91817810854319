import { StoreCoverageListFieldsKeyComponent } from "../../components";
import { ModalContainer, StoreCoverageListFieldsValueContainer } from "../";
import { ModalLayout } from "../../layouts";
import { requestDelete } from "../../services/requestDelete";
// import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  filterResultsById
} from "../../store/coverage/coverageSlice";

export const StoreCoverageListTableContainer = () => {
  const { coverageData } = useSelector((state) => state.listCoverage);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [getId, setGetId] = useState("");
  const [getName, setGetName] = useState("");
  const path = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = (id, name) => {
    setIsOpenConfirm(!isOpenConfirm);
    setGetId(id);
    setGetName(name);
  };

  const deleteCombo = () => {
    let filterData = coverageData?.results?.filter(
      (result) => result.id !== getId
    );

    requestDelete(`admin/coberturas/${getId}/`).then(() =>
      navigate(`/configuracion/tiendas/${path.storeId}/coberturas`)
    );
    dispatch(filterResultsById({ data: filterData }));
  };

  return (
    <>
      <table className="clientListTableContainer">
        <StoreCoverageListFieldsKeyComponent />
        <StoreCoverageListFieldsValueContainer
          coverageData={coverageData}
          handleClick={handleClick}
        />
      </table>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Cobertura"
            msg={`¿Está seguro que desea eliminar la cobertura ${getName}`}
            onClose={() => setIsOpenConfirm(!isOpenConfirm)}
            onConfirm={deleteCombo}
          />
        </ModalLayout>
      )}
    </>
  );
};
