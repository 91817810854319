import {
  SettingLandingBannersJobsHeaderContainer,
  SettingLandingBannersJobsMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersJobsDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersJobsHeaderContainer />
      <SettingLandingBannersJobsMainContainer />
    </section>
  );
};
