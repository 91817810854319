import { useState } from "react";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "..";
import { useSelector } from "react-redux";
import { requestUpdateAuthorization } from "../../services/requestUpdate";
import { requestDelete } from "../../services/requestDelete";
import { useNavigate } from "react-router-dom";

export const ModifierRetrieveHeaderContainer = ({ modifierId }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const body = useSelector((state) => state.updateModifier);

  const navigate = useNavigate();
  const onCloseCanceled = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };

  const updateModifier = () => {
    const token = localStorage.getItem("token");
    requestUpdateAuthorization(
      `admin/modificadores/${modifierId}/`,
      body,
      token
    );
  };

  const deleteModifier = () => {
    const token = localStorage.getItem("token");
    requestDelete(`admin/modificadores/${modifierId}/`, token).then(() =>
      navigate("/productos/modificadores")
    );
  };

  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };
  return (
    <div className="modifierRetrieveHeaderContainer ">
      <div className="modifierRetrieveHeaderContainer__title">
        <TitleComponent title="Detalle del modificador" />
      </div>
      <div className="modifierRetrieveHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Eliminar"}
          color={"red"}
          onClick={onCloseCanceled}
        />
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>

      {isOpenDelete && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Modificador"
            msg="¿Está seguro que desea eliminar el modificador?"
            style={canceledStateStyle}
            onClose={onCloseCanceled}
            onConfirm={deleteModifier}
          />
        </ModalLayout>
      )}

      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Editar Modificador"
            msg="¿Está seguro que desea editar el modificador?"
            style={canceledStateStyle}
            onClose={onOpenConfirmd}
            onConfirm={updateModifier}
          />
        </ModalLayout>
      )}
    </div>
  );
};
