import { createContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

const LoginContext = createContext({
  isLogin: false,
  toggleLogin: () => {},
});

export const LoginContextProvider = (props) => {
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);

  function toggleLogin() {
    setIsLogin((prev) => !prev);
  }

  const loginValue = {
    isLogin: isLogin,
    toggleLogin: toggleLogin,
  };

  return (
    <LoginContext.Provider value={loginValue}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
