import { TitleComponent } from "../../components";
import { ClientRetrieveOrderTableContainer } from "../../containers";

export const ClientRetrieveOrderContainer = ({ clientId }) => {
  const titleStyle = { fontSize: "18px" };
  return (
    <div className="clientRetrieveOrderContainer">
      <div className="clientRetrieveOrderContainer__title">
        <TitleComponent title={"ORDENES"} style={titleStyle} />
      </div>
      <div className="clientRetrieveOrderContainer__line">
        <hr className="clientRetrieveOrderContainer__line__one" />
        <hr className="clientRetrieveOrderContainer__line__two" />
      </div>
      <div className="clientRetrieveOrderContainer__table">
        <ClientRetrieveOrderTableContainer clientId={clientId}/>
      </div>
    </div>
  );
};
