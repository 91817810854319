import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListQuestions,
  setQuestionsLoading,
  setRetrieveQuestions,
  setRetrieveQuestionsLoading,
} from "./questionsSlice";

const obj = {
  page_size: 20,
  page: 1,
  search: "",
};

export const getListQuestions = (params = obj) => {
  return async (dispatch) => {
    dispatch(setQuestionsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization(
      "admin/preguntas_frecuentes/",
      params,
      token
    ).then((value) => {
      value.pageSize = params.page_size;
      value.redirectUrl = "configuracion/landing/preguntas-frecuentes";
      value.text = "Preguntas Frecuentes";
      value.page = params.page;
      dispatch(setListQuestions({ data: value }));
      dispatch(setQuestionsLoading({ showLoading: false }));
    });
  };
};

export const getRetrieveQuestions = (collaboratorsId) => {
  return async (dispatch) => {
    dispatch(setRetrieveQuestionsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/preguntas_frecuentes/${collaboratorsId}/`,
      token
    ).then((value) => {
      dispatch(setRetrieveQuestions({ data: value }));
      dispatch(setRetrieveQuestionsLoading({ showLoading: false }));
    });
  };
};
