import { ButtonPushComponent, TitleComponent } from "../../components";

export const ListHeaderContainer = ({ data, addButton = true }) => {
  const { title, nameButton, url } = data;
  const style = {
    fontSize: "32px",
    fontWeight: "bold",
  };
  return (
    <div className="listHeaderContainer">
      <div className="listHeaderContainer__content">
        <div className="listHeaderContainer__content__title">
          <TitleComponent title={title} style={style} />
        </div>
        {addButton && (
          <div className="listHeaderContainer__content__button">
            {data?.buttonMore && (
              <ButtonPushComponent
                name={"Ver Mapa"}
                color={"green"}
                onClick={data?.handleClick}
              />
            )}
            <ButtonPushComponent name={nameButton} url={url} />
          </div>
        )}
      </div>
    </div>
  );
};
