import { getListOptions, getListProcess } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setCombosLoading,
  setListCombos,
  setListOptionsCombo,
  setRetrieveCombo,
  setRetrieveCombosLoading,
} from "./comboSlice";

export const getListCombos = (params) => {
  return async (dispatch) => {
    dispatch(setCombosLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/combos/", params, token).then(
      (value) => {
        dispatch(setListCombos({ data: { value } }));
        dispatch(setCombosLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveCombo = (comboId) => {
  return async (dispatch) => {
    dispatch(setRetrieveCombosLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/combos/${comboId}/`, token).then((value) => {
      if (value.channels) {
        value.channels = getListProcess([value.channels]);
      }
      if (value.colections) {
        value.colections = getListProcess([value.colections]);
      }
      if (value.tags) {
        value.tags = getListProcess([value.tags]);
      }
      if (value.complements) {
        value.complements = getListProcess([value.complements]);
      }
      if (value.gruposProductos) {
        value.gruposProductos?.map((product) => {
          return (product.products = getListOptions([
            product?.products,
            "products",
            "name",
          ]));
        });
      }
      dispatch(setRetrieveCombo({ data: value }));
      dispatch(setRetrieveCombosLoading({ showLoading: false }));
    });
  };
};

export const getListOptionsCombo = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/combos/lista_opciones/`, token).then(
      (value) => {
        dispatch(setListOptionsCombo({ data: value }));
      }
    );
  };
};
