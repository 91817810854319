import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerHistorySlice = createSlice({
  name: "retrieveBannerHistory",
  initialState: {
    bannerHistoryData: {
      coverDesktop: "",
      coverMobile: "",
      photos: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerHistory: (state, action) => {
      state.bannerHistoryData.coverDesktop =
        action.payload?.data?.coverDesktop ||
        state?.bannerHistoryData?.coverDesktop;
      state.bannerHistoryData.coverMobile =
        action.payload?.data?.coverMobile ||
        state?.bannerHistoryData?.coverMobile;
      state.bannerHistoryData.photos =
        action.payload?.data?.photos || state?.bannerHistoryData?.photos;
    },
    setRetrieveBannerHistoryLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerHistory, setRetrieveBannerHistoryLoading } =
  retrieveBannerHistorySlice.actions;
