import { useParams } from "react-router-dom";
import {
  ClientRetrieveAddressContainer,
  ClientRetrieveInformationContainer,
  ClientRetrieveOrderContainer,
} from "../../containers";
import { useDispatch, useSelector } from "react-redux";
import { getRetrieveClient } from "../../store/client";
import { useEffect } from "react";
import { LoadingComponent } from "../../components";

export const ClientDetailPage = () => {
  const path = useParams();
  const { clientData, showLoading } = useSelector(
    (state) => state.retrieveClient
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRetrieveClient(path.clientId));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <section className="clientDetailPage">
          <div className="clientDetailPage__information">
            <ClientRetrieveInformationContainer
              clientId={path.clientId}
              clientData={clientData}
            />
          </div>
          <div className="clientDetailPage__address">
            <ClientRetrieveAddressContainer clientId={path.clientId} />
          </div>
          <div className="clientDetailPage__order">
            <ClientRetrieveOrderContainer clientId={path.clientId} />
          </div>
        </section>
      )}
    </>
  );
};
