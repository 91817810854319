import {
  StoreCoverageDetailHeaderContainer,
  StoreCoverageDetailMainContainer,
} from "../../../../containers";

export const SettingStoreCoverageDetailPage = () => {
  return (
    <section className="productDetailPage">
      <StoreCoverageDetailHeaderContainer />
      <StoreCoverageDetailMainContainer />
    </section>
  );
};
