import {
  CombosDetailHeaderContainer,
  CombosDetailMainContainer,
} from "../../containers";

export const CombosDetailPage = () => {
  return (
    <section className="productDetailPage">
      <CombosDetailHeaderContainer />
      <CombosDetailMainContainer />
    </section>
  );
};
