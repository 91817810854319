import { useDispatch } from "react-redux";
import {
  setRetrieveStoreMethodsItems,
  setRetrieveStorePickUpItems,
  setRetrieveStoreShedulesPickUpItems,
} from "../store/store/storeSlice";

export const useCustomHook = () => {
  const dispatch = useDispatch();

  function handleClick(index, namFunction, name, selectHour = "") {
    const obj = {
      index,
      value: !!selectHour ? selectHour : new Date().toLocaleTimeString(),
      name,
    };
    switch (namFunction) {
      case "PickUpSchedules":
        dispatch(setRetrieveStorePickUpItems({ ...obj }));
        break;

      case "DeliveryMethods":
        dispatch(setRetrieveStoreMethodsItems({ ...obj }));
        break;

      case "Shedules":
        dispatch(setRetrieveStoreShedulesPickUpItems({ ...obj }));
        break;

      default:
        break;
    }
  }

  return { handleClick };
};
