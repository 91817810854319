import {
  ProductComplementDetailHeaderContainer,
  ProductComplementDetailMainContainer,
} from "../../../containers";

export const ProductComplementDetailPage = () => {
  return (
    <section>
      <ProductComplementDetailHeaderContainer />
      <ProductComplementDetailMainContainer />
    </section>
  );
};
