import { StoreCoverageListFieldsValueComponent } from "../../components";

export const StoreCoverageListFieldsValueContainer = ({
  coverageData,
  handleClick,
}) => {
  return (
    <>
      <tbody>
        {coverageData?.results?.length > 0 &&
          coverageData?.results?.map((item) => {
            return (
              <StoreCoverageListFieldsValueComponent
                key={item.id}
                store={item}
                handleClick={handleClick}
              />
            );
          })}
      </tbody>
    </>
  );
};
