export const StoreListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="storeListFieldsKeyComponent">
        <th className="storeListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="storeListFieldsKeyComponent__name">Nombre</th>
        <th className="storeListFieldsKeyComponent__slug">Slug</th>
        <th className="storeListFieldsKeyComponent__description">
          Descripción
        </th>
        <th className="storeListFieldsKeyComponent__address">Dirección</th>
        <th className="storeListFieldsKeyComponent__image">Imagen</th>
        <th className="storeListFieldsKeyComponent__active">Estado</th>
      </tr>
    </thead>
  );
};
