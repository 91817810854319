import { ModifierListFieldsKeyComponent } from "../../components";
import { ModifierListFieldsValueContainer } from "../../containers";

export const ModifierListTableContainer = () => {
  return (
    <table className="modifierListTableContainer">
      <ModifierListFieldsKeyComponent />
      <ModifierListFieldsValueContainer />
    </table>
  );
};
