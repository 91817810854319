import { PaymentSearchContainer, PaymentListTableContainer } from "../";

export const PaymentListContainer = () => {
  return (
    <div className="productListContainer">
      <div className="productListContainer__search">
        <PaymentSearchContainer />
      </div>
      <div className="productListContainer__table">
        <PaymentListTableContainer />
      </div>
    </div>
  );
};
