import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrl } from "../../helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const theme = createTheme({
  palette: {
    primary: {
      main: "#2d99ff",
      contrastText: "#fff",
    },
  },
});

export const PageContainer = ({ dataPagination }) => {
  const dataUrl = useLocation();
  const navigate = useNavigate();
  const url = getUrl();
  const changePage = (pageParams) => {
    const params = new URLSearchParams(dataUrl.search);
    params.set("page", pageParams);
    navigate(`?${params}`);
  };

  const pageParams = url.searchParams.get("page") ?? 1;
  const count = !!dataPagination.pages ? dataPagination.pages : 1;

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Pagination
          className="pageContainer"
          color="primary"
          count={count}
          page={parseInt(!!pageParams ? pageParams : 1)}
          siblingCount={0}
          onChange={(e, pageParams) => changePage(pageParams)}
          size="large"
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Stack>
    </ThemeProvider>
  );
};
