import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { KeyValueInputComponent } from "../../components";
import { setUpdateNote } from "../../store/note";

export const NoteRetrieveInformationContainer = ({ data }) => {
  const [noteData, setNoteData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!data) {
      setNoteData(data);
    }
  }, [data]);
  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active") {
      value = e.target.checked;
    }
    if (name === "amount") {
      value = parseFloat(value);
    }
    setNoteData({ ...noteData, [name]: value });
    dispatch(setUpdateNote({ [name]: value }));
  };
  return (
    <div className="noteRetrieveInformationContainer">
      <div className="noteRetrieveInformationContainer__title">Información</div>
      <div className="noteRetrieveInformationContainer__container">
        <KeyValueInputComponent
          text={"Nombre"}
          name="name"
          value={noteData.name || ""}
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Monto"}
          name="amount"
          value={noteData.amount >= 0 ? noteData.amount : ""}
          type="number"
          handleOnchage={handleOnchage}
          step={0.01}
        />
        <KeyValueInputComponent
          text={"Orden"}
          name="order"
          value={noteData.order >= 0 ? noteData.order : ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"¿Activo?"}
          name="active"
          value={noteData.active || ""}
          type="checkbox"
          checked={noteData.active}
          handleOnchage={handleOnchage}
        />
      </div>
    </div>
  );
};
