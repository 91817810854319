import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListReception,
  setReceptionLoading,
  setRetrieveReception,
  setRetrieveReceptionLoading,
} from "./receptionSlice";
const obj = {
  page_size: 20,
  page: 1,
  search: "",
};
export const getListReception = (params = obj) => {
  return async (dispatch) => {
    dispatch(setReceptionLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization(
      "admin/correosRecepciones/",
      params,
      token
    ).then((value) => {
      value.pageSize = params.page_size;
      value.redirectUrl = "ordenes";
      value.text = "Ordenes";
      value.page = params.page;
      dispatch(setListReception({ data: value }));
      dispatch(setReceptionLoading({ showLoading: false }));
    });
  };
};

export const getRetrieveReception = (receptionId) => {
  return async (dispatch) => {
    dispatch(setRetrieveReceptionLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/correosRecepciones/${receptionId}/`,
      token
    ).then((value) => {
      dispatch(setRetrieveReception({ data: value }));
      dispatch(setRetrieveReceptionLoading({ showLoading: false }));
    });
  };
};
