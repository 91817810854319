import { SettingBoxComponent } from "../../components";

export const SettingBoxContainer = ({ data }) => {
  return (
    <div className="settingBoxContainer">
      {data?.map((item) => {
        return <SettingBoxComponent key={item.id} {...item} />;
      })}
    </div>
  );
};
