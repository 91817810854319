import { createSlice } from "@reduxjs/toolkit";
export const listOrdersSlice = createSlice({
  name: "listOrders",
  initialState: {
    orderData: [],
    showLoading: false,
  },
  reducers: {
    setListOrders: (state, action) => {
      state.orderData = action.payload.data;
    },
    setOrderLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListOrders, setOrderLoading } = listOrdersSlice.actions;

export const retrieveOrderSlice = createSlice({
  name: "retrieveOrder",
  initialState: {
    orderData: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveOrder: (state, action) => {
      state.orderData = action.payload.data;
    },
    setRetriveOrderLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveOrder, setRetriveOrderLoading } =
  retrieveOrderSlice.actions;

export const retrieveOrderItemsSlice = createSlice({
  name: "retrieveOrderItems",
  initialState: {
    orderRetrieveItemsData: [],
    showLoading: false,
  },
  reducers: {
    setRetrieveOrderItems: (state, action) => {
      state.orderRetrieveItemsData = action.payload.data;
    },
    setRetriveOrderItemsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveOrderItems, setRetriveOrderItemsLoading } =
  retrieveOrderItemsSlice.actions;

export const createOrderSlice = createSlice({
  name: "createOrder",
  initialState: {
    client: null,
    typeStore: "En Tienda",
    store: null,
    hours: null,
    state: null,
    ticket: "Boleta",
    typeDocument: null,
    identidad: null,
    ruc: null,
    businessName: null,
    address: "",
    reference: "",
    latitude: null,
    longitude: null,
    products: [],
    ordenesProductos: [],
    combos: [],
    paymentMethod: "",
  },
  reducers: {
    setCreateOrder: (state, action) => {
      state.client = action.payload.client || state.client;
      state.typeStore = action.payload.typeStore || state.typeStore;
      state.store = action.payload.store || state.store;
      state.hours = action.payload.hours || state.hours;
      state.state = action.payload.state || state.state;
      state.ticket = action.payload.ticket || state.ticket;
      state.typeDocument = action.payload.typeDocument || state.typeDocument;
      state.identidad = action.payload.identidad || state.identidad;
      state.ruc = action.payload.ruc || state.ruc;
      state.businessName = action.payload.businessName || state.businessName;
      state.address = action.payload.address || state.address;
      state.reference = action.payload.reference || state.reference;
      state.products = action.payload.products || state.products;
      state.combos = action.payload.combos || state.combos;
      state.latitude = action.payload.latitude || state.latitude;
      state.longitude = action.payload.longitude || state.longitude;
      state.paymentMethod = action.payload.paymentMethod || state.paymentMethod;
    },

    setCreatedOrderProduct: (state, action) => {
      let obj = {
        product: action.payload.product,
        quantity: 1,
        coment: "",
        ordenes_productos_grupos_customs: [],
      };
      state.ordenesProductos[action.payload.index] = obj;
    },

    setCreatedOrderProductGroup: (state, action) => {
      let obj = {
        presentation_group: action.payload.presentation_group || null,
        modifier_group: action.payload.modifier_group || null,
        note_group: action.payload.note_group || null,
        ordenes_productos_customs: [
          {
            presentation: parseInt(action.payload.presentation) || null,
            modifier: parseInt(action.payload.modifier) || null,
            note: parseInt(action.payload.note) || null,
          },
        ],
      };
      if (
        !action.payload.presentation_group &&
        !action.payload.modifier_group &&
        !action.payload.note_group
      )
        return;

      state.ordenesProductos[
        action.payload.indexProduct
      ].ordenes_productos_grupos_customs[action.payload.index] = obj;
    },

    setQuantityOrderProductIncrement: (state, action) => {
      state.ordenesProductos[action.payload.indexProduct].quantity =
        action.payload.quantity + 1;
    },
    setCommentOrderProduct: (state, action) => {
      state.ordenesProductos[action.payload.index].coment =
        action.payload.coment;
    },
    setQuantityOrderProductDecrement: (state, action) => {
      state.ordenesProductos[action.payload.indexProduct].quantity =
        action.payload.quantity - 1;
    },

    cleanCreateOrder: (state) => {
      state.client = null;
      state.typeStore = "En Tienda";
      state.store = null;
      state.hours = null;
      state.state = null;
      state.ticket = "Boleta";
      state.typeDocument = null;
      state.identidad = null;
      state.ruc = null;
      state.businessName = null;
      state.address = "";
      state.reference = "";
      state.latitude = null;
      state.longitude = null;
      state.products = [];
      state.ordenesProductos = [];
      state.combos = [];
    },
  },
});
export const {
  setCreatedOrderProductGroup,
  setQuantityOrderProductIncrement,
  setQuantityOrderProductDecrement,
  setCommentOrderProduct,
  setCreateOrder,
  cleanCreateOrder,
  setCreatedOrderProduct,
} = createOrderSlice.actions;
