import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import { redirectToUrl } from "../../helpers/redirectToUrl";

export const ModalNotificationContainer = ({
  modalNotificationHidden,
  setModalNotificationHidden,
  dataNotification,
}) => {
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setModalNotificationHidden(false);
      }
    };
    document.addEventListener("mouseup", handler);

    return () => {
      document.removeEventListener("mouseup", handler);
    };
  });

  const curretTime = new Date().getTime();

  const currentTimeDiff = (time) => {
    const newDate = new Date(time).getTime();
    const timeDiff = curretTime - newDate;

    const seconds = parseInt(timeDiff / 1000);
    const minutes = parseInt(seconds / 60);
    const hours = parseInt(minutes / 60);
    const days = parseInt(hours / 24);
    const months = parseInt(days / 30);
    const years = parseInt(months / 12);
    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      if (minutes === 1) return `${minutes} minute ago`;
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      if (hours === 1) return `${hours} hour ago`;
      return `${hours} hours ago`;
    } else if (days < 30) {
      if (days === 1) return `${days} day ago`;
      return `${days} days ago`;
    } else if (months < 12) {
      if (months === 1) return `${months} month ago`;
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  };
  return (
    <div
      ref={menuRef}
      className={
        modalNotificationHidden ? "modal" : "modal hidden-notification"
      }
    >
      <div className="modal__content">
        <div className="modal__content__header">
          <div className="modal__content__header__title">
            <h4>Notifications</h4>
          </div>
          <div className="modal__content__header__count">
            <p>{dataNotification?.count}</p>
          </div>
        </div>
        <div className="modal__content__separate">
          <hr />
        </div>
        {dataNotification?.results?.map((item, index) => (
          <Link to={`/ordenes/${item.notificationTypePk}/detalles`} key={index}>
            <div className="modal__content__body">
              <div className="modal__content__body__item">
                <div className="modal__content__body__item__icon">
                  <img
                    src="https://i.ibb.co/DYLmZN6/2.png"
                    alt="profile"
                    width="48px"
                  />
                </div>
                <div className="modal__content__body__item__description">
                  <h3>{item.title}</h3>
                  <p className="modal__content__body__item__description__text">
                    Hay una nueva orden del cliente
                  </p>
                  <div className="modal__content__body__item__description__container">
                    <p className="modal__content__body__item__description__container__name">
                      {item.description.substring(31)}
                    </p>
                    <p className="modal__content__body__item__description__container__time">
                      {currentTimeDiff(item.created)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal__content__body__aspa">
                <p>x</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
