import { useDispatch, useSelector } from "react-redux";
import {
  StoreDetailGroupsPhotosContainer,
  StoreDetailInformationContainer,
  StoreDetailGroupsShedulesContainer,
  StoreDetailGroupsPickUpSchedulesContainer,
  StoreDetailGroupsDeliveryMethodsContainer,
} from "../";
import { changeStoreData, handleOnChangeStore } from "../../helpers";
import {
  setRetrieveStore,
  setRetrieveStoreItems,
  setRetrieveStoreMethodsItems,
  setRetrieveStorePickUpItems,
  setRetrieveStoreShedulesPickUpItems,
  setUpdateStoreAddMethodsDeliveries,
  setUpdateStoreAddPhoto,
  setUpdateStoreAddShedules,
  setUpdateStoreAddShedulesPickUp,
} from "../../store/store/storeSlice";
import { LoadingComponent } from "../../components";
import { useParams } from "react-router-dom";

export const StoreDetailMainContainer = () => {
  const { storeData, showLoading } = useSelector(
    (state) => state.retrieveStore
  );

  const dispatch = useDispatch();
  const params = useParams();
  const handleOnchageInput = (e) => {
    const obj = handleOnChangeStore(e);
    const { key, value } = obj;
    dispatch(setRetrieveStore({ data: { [key]: value } }));
  };
  const handleOnchagePhotoInput = (e, index) => {
    const obj = changeStoreData(e, index);
    dispatch(setRetrieveStoreItems({ ...obj }));
  };
  const handleOnchagePickUpInput = (e, index) => {
    const obj = changeStoreData(e, index);
    dispatch(setRetrieveStorePickUpItems({ ...obj }));
  };
  const handleOnchageDeliveryMethodsInput = (e, index) => {
    const obj = changeStoreData(e, index);
    dispatch(setRetrieveStoreMethodsItems({ ...obj }));
  };
  const handleOnchageShedulesInput = (e, index) => {
    const obj = changeStoreData(e, index);
    dispatch(setRetrieveStoreShedulesPickUpItems({ ...obj }));
  };

  const addPhoto = () => {
    dispatch(setUpdateStoreAddPhoto());
  };

  const addShedules = () => {
    dispatch(setUpdateStoreAddShedules());
  };

  const addMethodsDeliveries = () => {
    dispatch(setUpdateStoreAddMethodsDeliveries());
  };

  const addShedulesPickUp = () => {
    dispatch(setUpdateStoreAddShedulesPickUp());
  };

  return (
    <>
      {!showLoading && params.storeId ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <StoreDetailInformationContainer
            storeData={storeData}
            handleOnchange={handleOnchageInput}
          />
          <StoreDetailGroupsPhotosContainer
            data={storeData?.photos}
            handleOnchage={handleOnchagePhotoInput}
            handleAdd={addPhoto}
          />
          <StoreDetailGroupsPickUpSchedulesContainer
            data={storeData?.shedules}
            handleOnchage={handleOnchagePickUpInput}
            handleAdd={addShedules}
          />
          <StoreDetailGroupsDeliveryMethodsContainer
            data={storeData?.metodosEntregasTiendas}
            handleOnchage={handleOnchageDeliveryMethodsInput}
            handleAdd={addMethodsDeliveries}
          />
          <StoreDetailGroupsShedulesContainer
            data={storeData?.horariosRecojos}
            handleOnchage={handleOnchageShedulesInput}
            handleAdd={addShedulesPickUp}
          />
        </div>
      )}
    </>
  );
};
