import { useEffect, useState } from "react";
import { apiPath } from "../../helpers";
import { requestGet } from "../../services/requestGet";

export const MapSearchComponent = ({
  coordinates = { lat: null, lng: null },
  setCoordinates,
  onGetAddressByLatLng,
}) => {
  const [dataCoverages, setDataCoverages] = useState([]);
  const [dataCoverageCoordinates, setDataCoverageCoordinates] = useState([]);

  useEffect(() => {
    requestGet("/coberturas/").then((value) => {
      setDataCoverages(value);
    });
  }, []);

  useEffect(() => {
    let arrayCoordinates = [];
    if (dataCoverages) {
      dataCoverages?.map(async (item) => {
        await apiPath
          .get(`coberturas/${item.id}/coordenadas/`)
          .then((response) => {
            arrayCoordinates.push(response.data);
            setTimeout(() => {
              setDataCoverageCoordinates(arrayCoordinates);
            }, 800);
          });
      }
      );      
    }
  }, [dataCoverages]);

  useEffect(() => {
    const defaultCenter = {
      lat: -12.1074093,
      lng: -77.0358781,
    };
    if (coordinates.lat !== null && coordinates.lng !== null) {
      return mainMap(coordinates, 18, coordinates);
    } else {
      return mainMap(defaultCenter, 12, defaultCenter);
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  const mainMap = (center, zoom, coordinates) => {
    const mapProp = {
      center: new window.google.maps.LatLng(center.lat, center.lng),
      zoom: zoom,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    };
    const map = new window.google.maps.Map(
      document.getElementById("googleMaps"),
      mapProp
    );

    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(coordinates.lat, coordinates.lng),
      map,
      draggable: true,
    });
    window.google.maps.event.addListener(marker, "dragend", (ev) => {
      const { lat, lng } = ev.latLng;

      onGetAddressByLatLng({ lat: lat(), lng: lng() });
    });
    const polig_custom = new window.google.maps.Polygon({
      paths: dataCoverageCoordinates,
      strokeColor: "#000",
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: "#000",
      fillOpacity: 0.2,
    });
    polig_custom.setMap(map);
  };

  return <div id="googleMaps" style={{ width: "100%", height: "100%" }} />;
};
