import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyValueInputComponent } from "../../components";
import { setCreateNote } from "../../store/note";

export const NoteCreateInformationContainer = () => {
  const data = useSelector((state) => state.createNote);
  const [noteData, setNoteData] = useState(data);
  const dispatch = useDispatch();

  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active") {
      value = e.target.checked;
    }
    if (name === "amount") {
      value = parseFloat(value);
    }
    setNoteData({ ...noteData, [name]: value });
    dispatch(setCreateNote({ [name]: value }));
  };
  return (
    <div className="noteCreateInformationContainer">
      <div className="noteCreateInformationContainer__title">Información</div>
      <div className="noteCreateInformationContainer__container">
        <KeyValueInputComponent
          text={"Nombre"}
          name="name"
          value={noteData.name || ""}
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Monto"}
          name="amount"
          value={noteData.amount >= 0 ? noteData.amount : ""}
          type="number"
          handleOnchage={handleOnchage}
          step={0.01}
        />
        <KeyValueInputComponent
          text={"Orden"}
          name="order"
          value={noteData.order >= 0 ? noteData.order : ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"¿Activo?"}
          name="active"
          value={noteData.active || ""}
          type="checkbox"
          checked={noteData.active}
          handleOnchage={handleOnchage}
        />
      </div>
    </div>
  );
};
