import { useState } from "react";

export const CodeBlockComponent = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
  };

  return (
    <div className="codeBlockComponent">
      <h3 className="codeBlockComponent__title">Link a pagar :</h3>
      <div className="codeBlockComponent__container">
        <a href={code} target="_blank" rel="noreferrer">
          <pre>
            <code suppressContentEditableWarning={true}>{code}</code>
          </pre>
        </a>
        <button className={copied ? "copied" : ""} onClick={handleCopy}>
          {copied ? "Copiado" : "Copiar"}
        </button>
      </div>
    </div>
  );
};
