import React from "react";

export const FilterComponent = ({
  name,
  data,
  getOptionSelect,
  id,
  onchange = () => {},
}) => {
  return (
    <div className="filterComponent">
      <select id={id} onChange={onchange}>
        <option value={"null"}>{name}</option>
        <option value={"null"}>--------------</option>
        {data?.map((item) => {
          return (
            <option key={item.id} value={item.value} onSelect={getOptionSelect}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
