export const CombosListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="clientListFieldsKeyComponent">
        <th className="clientListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="clientListFieldsKeyComponent__code">Código</th>
        <th className="clientListFieldsKeyComponent__fullName">Nombre</th>
        <th className="clientListFieldsKeyComponent__email">Slug</th>
        <th className="clientListFieldsKeyComponent__phone">Imagen</th>
        <th className="clientListFieldsKeyComponent__active">Price</th>
        <th className="clientListFieldsKeyComponent__active">Active</th>
      </tr>
    </thead>
  );
};
