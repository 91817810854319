import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  StoreDetailHeaderContainer,
  StoreDetailMainContainer,
} from "../../../containers";
import { clearUpdateStore } from "../../../store/store/storeSlice";

export const SettingStoreCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateStore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="orderDetailPage">
      <StoreDetailHeaderContainer />
      <StoreDetailMainContainer />
    </section>
  );
};
