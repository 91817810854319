import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { requestPostAuthorization } from "../../services/requestPost";
import { clearUpdateProduct } from "../../store/product";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const ProductCreateHeaderContainer = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openOrCloseModal = () => {
    setIsOpenCreate(!isOpenCreate);
  };
  const productData = useSelector((state) => state.updateProduct);
  const createProduct = async () => {
    const token = localStorage.getItem("token");
    const body = { ...productData };
    delete body.image;
    const image = productData.image;
    let blob = await fetch(image.url).then((r) => r.blob());
    const file = new File([blob], image.name, { type: image.type });
    const formData = new FormData();
    formData.append("image", file);
    requestPostAuthorization(`admin/productos/`, body, token).then(
      async (value) => {
        await requestPatchFileAuthorization(
          `admin/productos/${value.id}/`,
          formData,
          token
        );
        dispatch(clearUpdateProduct());
        navigate("/productos");
      }
    );
  };

  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Crear Producto" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={openOrCloseModal} />
      </div>

      {isOpenCreate && (
        <ModalLayout>
          <ModalContainer
            title="Crear Producto"
            msg="¿Está seguro que desea crear el producto?"
            style={canceledStateStyle}
            onClose={openOrCloseModal}
            onConfirm={createProduct}
          />
        </ModalLayout>
      )}
    </section>
  );
};
