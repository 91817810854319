import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
export const ModifierListFieldsValueComponent = ({ modifier }) => {
  const url = `/productos/modificadores/${modifier.id}/detalles`;
  const stateColor = orderStateColor(modifier?.active);
  return (
    <tr className="modifierListFieldsValueComponent">
      <td className="modifierListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="modifierListFieldsValueComponent__name">
        <Link to={url}>{modifier.name}</Link>
      </td>
      <td className="modifierListFieldsValueComponent__amount">
        <Link to={url}>{modifier.amount}</Link>
      </td>
      <td className={`modifierListFieldsValueComponent__state ${stateColor}`}>
        <Link to={url}>{modifier.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
