import { apiPath } from "../helpers";

const tokenUser = localStorage.getItem("token");

export const requestGet = async (url) => {
  try {
    const response = await apiPath.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestGetAuthorization = async (url, token = tokenUser) => {
  try {
    const response = await apiPath.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestGetParamsAuthorization = async (
  url,
  params,
  token = tokenUser
) => {
  try {
    const response = await apiPath.get(url, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};
