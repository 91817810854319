export const ProductDetailFieldsKeyComponent = ({
  isPresentation = false,
  isPresentationGroup,
}) => {
  return (
    <thead>
      <tr className="productDetailFieldsKeyComponent">
        <th className="productDetailFieldsKeyComponent__name">Nombre</th>
        <th className="productDetailFieldsKeyComponent__order">Orden</th>
        <th className="productDetailFieldsKeyComponent__active">¿Activo?</th>
        {!isPresentation && (
          <th className="productDetailFieldsKeyComponent__mandatory">
            ¿Es obligatorio?
          </th>
        )}
        {!isPresentationGroup && (
          <th className="productDetailFieldsKeyComponent__multiple">
            ¿Es múltiple?
          </th>
        )}
        {isPresentation && (
          <th className="productDetailFieldsKeyComponent__amount">Monto</th>
        )}
        <th className="productDetailFieldsKeyComponent__button">Eliminar</th>
      </tr>
    </thead>
  );
};
