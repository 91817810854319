import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReactQuillComponent,
  TitleInformationComponent,
} from "../../components";
// import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { getListOptions } from "../../helpers";
import { getListOptionsProducts } from "../../store/product";
import {
  getRetrieveSuggestive,
  setRetrieveSuggestiveDescription,
  setRetrieveSuggestiveProducts,
} from "../../store/coffeeShop/suggestive";
// import { setRetrieveBannerAllies } from "../../store/banner/allies/alliesSlice";

export const SettingCoffeeShopSuggestiveInformationContainer = () => {
  const { suggestiveData } = useSelector((state) => state.retrieveSuggestive);
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRetrieveSuggestive());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getListOptionsProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnchageMultipleProducts = (e) => {
    if (e.length > 6) {
      return;
    }
    dispatch(setRetrieveSuggestiveProducts({ products: e }));
  };

  const handleChange = (name) => {
    dispatch(setRetrieveSuggestiveDescription({ name }));
  };

  const processListOptionsProducts = getListOptions([
    listOptionsProducts,
    "products",
    "name",
  ]);

  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Productos"}
        handleOnchange={handleOnchageMultipleProducts}
        name="products "
        isInputMulti={true}
        value={suggestiveData?.products}
        dataList={processListOptionsProducts}
        placeholderTitle={"Seleccione 1 o más productos"}
        moved="left"
      />
      <div className="settingCoffeeShopSuggestiveInformationContainer">
        <p>Descripción</p>
        <div className="settingCoffeeShopSuggestiveInformationContainer__description">
          <ReactQuillComponent
            data={suggestiveData?.name}
            fnc={handleChange}
            isArray={false}
          />
        </div>
      </div>
    </div>
  );
};
