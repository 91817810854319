import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { requestPostAuthorization } from "../services/requestPost";

const firebaseConfig = {
  apiKey: "AIzaSyD5uU-czzbGihbgTPQSLkiO-oPq9xTdh9U",
  authDomain: "pan-atelier-349417.firebaseapp.com",
  projectId: "pan-atelier-349417",
  storageBucket: "pan-atelier-349417.appspot.com",
  messagingSenderId: "849352367172",
  appId: "1:849352367172:web:af6840ec006361e25c3011",
  measurementId: "G-6CRT5YRX83",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getTokenFirebase = async () => {
  return getToken(messaging, {
    vapidKey:
      "BC8Eh7isDNleQLoFUMaQF9r4bU81htJjBuQ-sgxbALVGDFa2TE6F6eF9lkN5-UVOvZp-Y5tCe-WTZPsDrIzvH_Q",
  })
    .then((currentToken) => {
      const registrationId = localStorage.getItem("registrationId")
      if (currentToken && !registrationId) {
        localStorage.setItem("registrationId",currentToken)
        const url = "admin/dispositivos/";
        const token = localStorage.getItem("token");
        const body = {
          registrationId: currentToken,
          type: "web",
        };
        requestPostAuthorization(url, body, token);
      } else {
      }
    })
    .catch((err) => {});
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
