export const KeyValueComponent = ({ keyName, valueName, style }) => {
  const { fontSize, color, fontWeight, display, margin, justifyContent } =
    style;
  return (
    <div
      className="keyValueComponent"
      style={{
        fontSize: fontSize,
        display: display,
        margin: margin,
        justifyContent: justifyContent,
      }}
    >
      <p
        style={{
          color: color,
          fontWeight: fontWeight,
        }}
      >
        {keyName}
      </p>
      <p>{valueName}</p>
    </div>
  );
};
