import { createSlice } from "@reduxjs/toolkit";
export const listCollaboratorsSlice = createSlice({
  name: "listCollaborators",
  initialState: {
    collaboratorsData: [],
    collaboratorsParams: {
      page_size: 20,
      page: 1,
      search: "",
    },
    showLoading: false,
  },
  reducers: {
    setListCollaborators: (state, action) => {
      state.collaboratorsData = action.payload.data;
      state.collaboratorsParams = action.payload.params;
    },
    setListCollaboratorsParams: (state, action) => {
      state.collaboratorsParams.page_size = 20;
      state.collaboratorsParams.page =
        action.payload.params.page || state.collaboratorsParams.page;
      state.collaboratorsParams.search =
        action.payload.params.search || state.collaboratorsParams.search;
    },
    setCollaboratorsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setListCollaborators,
  setListCollaboratorsParams,
  setCollaboratorsLoading,
} = listCollaboratorsSlice.actions;

export const retrieveCollaboratorsSlice = createSlice({
  name: "retrieveCollaborators",
  initialState: {
    collaboratorsData: {
      name: "",
      order: 0,
      active: "",
      functions: [],
      requirements: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveCollaborators: (state, action) => {
      state.collaboratorsData.name =
        action.payload?.data?.name ?? state?.collaboratorsData?.name;
      state.collaboratorsData.order =
        action.payload?.data?.order ?? state?.collaboratorsData?.order;
      state.collaboratorsData.active =
        action.payload?.data?.active ?? state?.collaboratorsData?.active;
      state.collaboratorsData.functions =
        action.payload?.data?.functions ?? state?.collaboratorsData?.functions;
      state.collaboratorsData.requirements =
        action.payload?.data?.requirements ??
        state?.collaboratorsData?.requirements;
    },
    setAddCollaboratorsFunctions: (state) => {
      const obj = {
        id: null,
        description: "",
        order: state.collaboratorsData.functions.length + 1,
        active: false,
      };
      state.collaboratorsData.functions.push(obj);
    },
    setAddCollaboratorsRequirements: (state) => {
      const obj = {
        id: null,
        description: "",
        order: state.collaboratorsData.requirements.length + 1,
        active: false,
      };
      state.collaboratorsData.requirements.push(obj);
    },
    setUpdateCollaboratorFunctions: (state, action) => {
      const { index, name, value } = action.payload;
      state.collaboratorsData.functions[index][name] = value;
    },
    setUpdateCollaboratorRequirements: (state, action) => {
      const { index, name, value } = action.payload;
      state.collaboratorsData.requirements[index][name] = value;
    },
    cleanCreateCollaborator: (state, action) => {
      state.collaboratorsData.name = "";
      state.collaboratorsData.order = 1;
      state.collaboratorsData.active = "";
      state.collaboratorsData.functions = [];
      state.collaboratorsData.requirements = [];
    },
    setRetrieveCollaboratorsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveCollaborators,
  setAddCollaboratorsFunctions,
  setAddCollaboratorsRequirements,
  setUpdateCollaboratorFunctions,
  setUpdateCollaboratorRequirements,
  cleanCreateCollaborator,
  setRetrieveCollaboratorsLoading,
} = retrieveCollaboratorsSlice.actions;
