const transformIntoTwoDigits = (number) => {
  return number.toString().length < 2 ? `0${number}` : number;
};

export const changeDateFormat = (date) => {
  const day = transformIntoTwoDigits(date.getDate());
  const month = transformIntoTwoDigits(date.getMonth() + 1);
  const year = transformIntoTwoDigits(date.getFullYear());
  return `${year}-${month}-${day}`;
};

export const changeDateFormatPlaceholder = (date) => {
  const day = transformIntoTwoDigits(date.getDate());
  const month = transformIntoTwoDigits(date.getMonth() + 1);
  const year = transformIntoTwoDigits(date.getFullYear());
  return `${day}/${month}/${year}`;
};