import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerHomeSlice = createSlice({
  name: "retrieveBannerHome",
  initialState: {
    bannerHomeData: {
      mainCover: "",
      coverMobile: "",
      coffeeImage: "",
      store: "",
      giftcard: "",
      giftcardMobile: "",
      videoHistory: "",
      photos: [],
      phrases: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerHome: (state, action) => {
      state.bannerHomeData.mainCover =
        action.payload?.data?.mainCover || state?.bannerHomeData?.mainCover;
      state.bannerHomeData.coverMobile =
        action.payload?.data?.coverMobile || state?.bannerHomeData?.coverMobile;
      state.bannerHomeData.coffeeImage =
        action.payload?.data?.coffeeImage || state?.bannerHomeData?.coffeeImage;
      state.bannerHomeData.store =
        action.payload?.data?.store || state?.bannerHomeData?.store;
      state.bannerHomeData.giftcard =
        action.payload?.data?.giftcard || state?.bannerHomeData?.giftcard;
      state.bannerHomeData.giftcardMobile =
        action.payload?.data?.giftcardMobile ||
        state?.bannerHomeData?.giftcardMobile;
      state.bannerHomeData.videoHistory =
        action.payload?.data?.videoHistory ||
        state?.bannerHomeData?.videoHistory;
      state.bannerHomeData.photos =
        action.payload?.data?.photos || state?.bannerHomeData?.photos;
      state.bannerHomeData.phrases =
        action.payload?.data?.phrases || state?.bannerHomeData?.phrases;
    },
    setRetrieveBannerLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerHome, setRetrieveBannerLoading } =
  retrieveBannerHomeSlice.actions;
