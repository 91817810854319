import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  PaginationContainer,
  PaymentListContainer,
} from "../../containers";
import { getListPayments } from "../../store/payment";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

const dataHeader = {
  title: "Pagos",
  nameButton: "Crear Pago",
  url: "/pagos/crear",
};

export const PaymentIndexPage = () => {
  const { paymentData, showLoading } = useSelector(
    (state) => state.listPayments
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pagePayment = serachParams.get("page");
  const paymentMethodPayment = serachParams.get("paymentMethod");
  const statePayment = serachParams.get("state");
  const searchPayment = serachParams.get("search");

  let paymentParams = {
    page_size: 20,
    page: pagePayment ?? 1,
    paymentMethod: paymentMethodPayment ?? "",
    state: statePayment ?? "",
    search: searchPayment ?? "",
  };

  useEffect(() => {
    dispatch(getListPayments(paymentParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePayment, paymentMethodPayment, statePayment, searchPayment]);

  return (
    <section className="PaymentIndexPage">
      <ListHeaderContainer data={dataHeader} addButton={false} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <PaymentListContainer />
          <PaginationContainer data={paymentData} />
        </>
      )}
    </section>
  );
};
