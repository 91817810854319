/* eslint-disable no-undef */
import { useEffect } from "react";

export const MapComponent = ({ zoom, latitude,longitude }) => {
  
  useEffect(() => {
      return mainMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const mainMap = () => {
    const mapProp = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const map = new google.maps.Map(
      document.getElementById("googleMaps"),
      mapProp
    );

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map,
      draggable: true,
    });
    google.maps.event.addListener(marker, "dragend", (ev) => {
      const { lat, lng } = ev.latLng;
      onGetAddressByLatLng({ lat: lat(), lng: lng() });
    });
  };

  return <div id="googleMaps" style={{ width: "100%", height: "100%" }} />;
};
