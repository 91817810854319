import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPostAuthorization } from "../../services/requestPost";
import { cleanCreateClient } from "../../store/client";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const ClientCreateHeaderContainer = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const data = useSelector((state) => state.createClient);
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };
  const body = {
    user: {
      email: data.email,
      names: data.names,
      surnames: data.surnames,
      phone: data.phone,
    },
    type: data.type,
  };
  const dispatch = useDispatch();
  const createClient = () => {
    const token = localStorage.getItem("token");
    requestPostAuthorization(`admin/clientes/`, body, token);
    dispatch(cleanCreateClient());
  };
  const canceledStateStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="clientCreateHeaderContainer ">
      <div className="clientCreateHeaderContainer__title">
        <TitleComponent title="Detalle del Cliente" />
      </div>
      <div className="clientCreateHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Crear Cliente"
            msg="¿Está seguro que desea crear el cliente?"
            style={canceledStateStyle}
            onClose={onOpenConfirmd}
            onConfirm={createClient}
          />
        </ModalLayout>
      )}
    </div>
  );
};
