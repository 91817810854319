import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientRetrieveAddressFieldsValueComponent } from "../../components";
import { getRetrieveClientAddress } from "../../store/client";

export const ClientRetrieveAddressFieldsValueContainer = ({ clientId }) => {
  const { listAddress } = useSelector((state) => state.retrieveClient);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRetrieveClientAddress(clientId));
    // eslint-disable-next-line
  }, []);
  return (
    <tbody>
      {listAddress?.length > 0 &&
        listAddress?.map((address, index) => {
          return (
            <ClientRetrieveAddressFieldsValueComponent
              key={index}
              address={address}
            />
          );
        })}
    </tbody>
  );
};
