import React from "react";
import {
  NoteCreateHeaderContainer,
  NoteCreateInformationContainer,
} from "../../../containers";

export const ProductNoteCreatePage = () => {
  return (
    <div className="productNoteCreatePage">
      <NoteCreateHeaderContainer />
      <NoteCreateInformationContainer />
    </div>
  );
};
