import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { ImageComponent } from "../../components/ImageComponent/ImageComponent";
import { LoginFormComponent } from "../../components/LoginFormComponent/LoginFormComponent";
import { requestPost } from "../../services/requestPost";
import LoginContext from "../../store/loginContext";

export const LoginFormContainer = () => {
  const loginCtx = useContext(LoginContext);
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  const [isError, setIsError] = useState(false);
  const { userName, password } = formData;
  const navigate = useNavigate();

  const handleAuth = async () => {
    const userLogin = {
      email: userName,
      password: password,
    };
    if (userName.length > 0 && password.length > 0) {
      const url = "admin/auth/login/";
      requestPost(url, userLogin)
        .then((value) => {
          loginCtx.toggleLogin();
          localStorage.setItem("token", value.token);
          navigate("/");
          return;
        })
        .catch((e) => {
          setIsError(true);
        });
    }
  };

  const loginHandler = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    setIsError(false)
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* <ImageComponent /> */}
      <form className={"login__container__form__main"} onSubmit={loginHandler}>
        <LoginFormComponent
          onChange={onChange}
          handleAuth={handleAuth}
          isError={isError}
        />
      </form>
    </>
  );
};
