import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ProductCreateHeaderContainer,
  ProductCreateMainContainer,
} from "../../containers";
import { clearUpdateProduct } from "../../store/product";

export const ProductCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateProduct());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="productCreatePage">
      <ProductCreateHeaderContainer />
      <ProductCreateMainContainer />
    </div>
  );
};
