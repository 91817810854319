import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListPayments,
  setPaymentLoading,
  setRetrievePayment,
  setRetrievePaymentLoading,
} from "./paymentSlice";

export const getListPayments = (params) => {
  return async (dispatch) => {
    dispatch(setPaymentLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/pagos/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "pagos";
        value.text = "Pagos";
        value.page = params.page;
        dispatch(setPaymentLoading({ showLoading: false }));
        dispatch(setListPayments({ data: value, params: params }));
      }
    );
  };
};

export const getRetrievePayment = (paymentId) => {
  return async (dispatch) => {
    dispatch(setRetrievePaymentLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/pagos/${paymentId}/`, token).then(
      (value) => {
        dispatch(setRetrievePayment({ data: value }));
        dispatch(setRetrievePaymentLoading({ showLoading: false }));
      }
    );
  };
};
