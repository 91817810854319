import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CombosListContainer,
  ListHeaderContainer,
  PaginationContainer,
} from "../../containers";
import { getListCombos } from "../../store/combo";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

export const CombosIndexPage = () => {
  const { combosData, showLoading } = useSelector((state) => state.listCombos);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageCombo = serachParams.get("page");
  const activeCombo = serachParams.get("active");
  const searchCombo = serachParams.get("search");

  let comboParams = {
    page_size: 20,
    page: pageCombo ?? 1,
    active: activeCombo ?? "",
    search: searchCombo ?? "",
  };

  useEffect(() => {
    dispatch(getListCombos(comboParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCombo, searchCombo, activeCombo]);

  const dataHeader = {
    title: "Combos",
    nameButton: "Crear Combo",
    url: "/combos/crear",
  };
  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} />

      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <CombosListContainer />
          <PaginationContainer data={combosData} />
        </>
      )}
    </section>
  );
};
