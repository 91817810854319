import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import { setUpdateCouponCollections } from "../../store/coupon/couponSlice";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const CouponDetailSelectCollectionContainer = ({
  listOptionsCollection,
  couponData,
}) => {
  const dispatch = useDispatch();

  const handleOnchageMultipleColections = (e) => {
    dispatch(setUpdateCouponCollections({ collections: e }));
  };

  return (
    <div className="productDetailInformationContainer">
      {couponData?.couponType === "Para ciertas colecciones" && (
        <>
          <TitleInformationComponent
            title={"En caso seleccionó para colecciones"}
          />
          <SelectOptionsContainer
            title={"Colecciones"}
            handleOnchange={handleOnchageMultipleColections}
            name="products"
            isInputMulti={true}
            value={couponData?.collections}
            dataList={listOptionsCollection}
          />
        </>
      )}
    </div>
  );
};
