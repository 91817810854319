import { getListOptions } from "../../helpers";
import { requestGetAuthorization } from "../../services/requestGet";
import { setListOptionTag } from "./tagSlice";

export const getListOptionTag = () => {
  return async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/tags/lista_opciones/`, token).then(
      (value) => {
        const inputParamsData = [value, "tags", "name", "id"];
        const listOptionTags= getListOptions(inputParamsData);
        dispatch(setListOptionTag({ data: listOptionTags }));
      }
    );
  };
};
