import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { getListOrderSalesDetail } from "../../store/statistics/thunks";
import { LoadingComponent, ReportTableHeadComponent } from "../../components";
import {
  setDateBodyOrderSalesDetail,
  setDateOrderSalesActiveDetail,
  setDateOrderSalesDetail,
} from "../../store/statistics/statisticsSlice";
import { apiPath } from "../../helpers";

const meses = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

const dias = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

const optionsMonth = [
  { value: "00", label: "-------------" },
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

const optionsYear = [
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
];

const titles = [
  { text: "Locales", rowSpan: 2 },
  { text: "Delivery", colSpan: 2 },
  { text: "Recojo en tienda", colSpan: 2 },
  { text: "Cantidad de Ordenes", colSpan: 4 },
  { text: "Ventas", colSpan: 4 },
  { text: "Total de ordenes", rowSpan: 2 },
  { text: "Total de ventas", rowSpan: 2 },
];

const headRows = [
  { text: "Montos" },
  { text: "Cantidad de Ordenes" },
  { text: "Montos" },
  { text: "Cantidad de Ordenes" },
  { text: "Confirmada" },
  { text: "Lista para retiro" },
  { text: "En camino" },
  { text: "Entregada" },
  { text: "Confirmada" },
  { text: "Lista para retiro" },
  { text: "En camino" },
  { text: "Entregada" },
];

export const ReportsOrderTimeContainer = () => {
  const { bodyDate, date, isActive } = useSelector(
    (state) => state.dateOrderSalesDetail
  );
  const { dataOrderSalesDetail, showLoading } = useSelector(
    (state) => state.listOrderSalesDetail
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOrderSalesDetail(bodyDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyDate.startDate, bodyDate.endDate]);

  const TableGrafic = () => {
    const styles = {
      row: "reportTableHeadComponent__body__row",
      text: "reportTableHeadComponent__body__row__text",
    };
    return (
      <table border="1" className="reportTableHeadComponent">
        <ReportTableHeadComponent titles={titles} headRows={headRows} />
        <tbody>
          {dataOrderSalesDetail?.map((item) => (
            <tr className={styles.row} key={item.id}>
              <td className={styles.text}>{item.local}</td>
              <td className={styles.text}>
                s/ {item.delivery.montos.toFixed(2)}
              </td>
              <td className={styles.text}>{item.delivery.numeroOrdenes}</td>
              <td className={styles.text}>
                s/ {item.recojoTienda.montos.toFixed(2)}
              </td>
              <td className={styles.text}>{item.recojoTienda.numeroOrdenes}</td>
              <td className={styles.text}>{item.ordenes.confirmada}</td>
              <td className={styles.text}>{item.ordenes.listaRetiro}</td>
              <td className={styles.text}>{item.ordenes.enCamino}</td>
              <td className={styles.text}>{item.ordenes.entregada}</td>
              <td className={styles.text}>
                s/ {item.ventas.confirmada.toFixed(2)}
              </td>
              <td className={styles.text}>
                s/ {item.ventas.listaRetiro.toFixed(2)}
              </td>
              <td className={styles.text}>
                s/ {item.ventas.enCamino.toFixed(2)}
              </td>
              <td className={styles.text}>
                s/ {item.ventas.entregada.toFixed(2)}
              </td>
              <td className={styles.text}>{item.totalOrdenes}</td>
              <td className={styles.text}>s/ {item.totalVentas.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const exportExcel = () => {
    const token = localStorage.getItem("token");
    apiPath
      .post("admin/estadisticas/exportar_ordenes_ventas/", bodyDate, {
        headers: {
          Authorization: `Token ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((value) => {
        const myBlob = new Blob([value.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileUrl = URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "ordenes-y-ventas.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  function handleOnchange(year, month) {
    const newDate = {
      year,
      month,
    };
    let bodyDate = "";
    if (month === "00") {
      bodyDate = {
        startDate: `${year}-01-31`,
        endDate: `${year}-12-31`,
      };
      dispatch(setDateBodyOrderSalesDetail(bodyDate));
      dispatch(setDateOrderSalesDetail(newDate));
      return;
    }
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const firstDayOfNextMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(firstDayOfNextMonth - 1);
    dispatch(setDateOrderSalesDetail(newDate));
    const dayStr2 = firstDayOfMonth.getDate().toString().padStart(2, "0");
    const dayStr = lastDayOfMonth.getDate().toString().padStart(2, "0");
    bodyDate = {
      startDate: `${year}-${month}-${dayStr2}`,
      endDate: `${year}-${month}-${dayStr}`,
    };
    dispatch(setDateBodyOrderSalesDetail(bodyDate));
  }

  const handleDataPicker = (e) => {
    if (e.length >= 2) {
      let valueMonthFirst = e[0].monthIndex + 1;
      let valueMonthSecond = e[1].monthIndex + 1;

      let valueDayFirst = e[0].day;
      let valuedaySecond = e[1].day;

      if (valueMonthFirst < 10) {
        valueMonthFirst = `0${valueMonthFirst}`;
      }
      if (valueMonthSecond < 10) {
        valueMonthSecond = `0${valueMonthSecond}`;
      }
      if (e[0].day < 10) {
        valueDayFirst = `0${valueDayFirst}`;
      }
      if (e[1].day < 10) {
        valuedaySecond = `0${valuedaySecond}`;
      }

      const startDate = `${e[0].year}-${valueMonthFirst}-${valueDayFirst}`;
      const endDate = `${e[1].year}-${valueMonthSecond}-${valuedaySecond}`;
      const body = {
        startDate,
        endDate,
      };
      dispatch(setDateBodyOrderSalesDetail(body));
    }
  };

  return (
    <>
      <div className="homeDashboardContainer">
        <div className="homeDashboardContainer__container-title">
          <h6 className="homeDashboardContainer__container-title__title">
            Ventas en tiendas por periodo
          </h6>
          <div className="homeDashboardContainer__container-title__button">
            <button onClick={exportExcel}>Descargar</button>
          </div>
        </div>
        <div className="homeDashboardContainer__container-filter">
          <div className="homeDashboardContainer__container-filter__left">
            <div className="homeDashboardContainer__container-filter__left__filter">
              <Select
                isDisabled={isActive.active}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "#ebeef2",
                    borderRadius: "14px",
                    padding: "3px 7px ",
                    width: 200,
                  }),
                }}
                placeholder={"Eliga el mes"}
                options={optionsMonth}
                onChange={(e) => handleOnchange(date.year, e.value)}
                defaultValue={{
                  label: date.month === "00" ? "Seleccione Mes" : date.month,
                }}
              />
            </div>
            <div className="homeDashboardContainer__container-filter__left__filter">
              <Select
                isDisabled={isActive.active}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "#ebeef2",
                    borderRadius: "14px",
                    padding: "3px 7px ",
                    width: 200,
                  }),
                }}
                options={optionsYear}
                onChange={(e) => handleOnchange(e.value, date.month)}
                defaultValue={{ label: date.year }}
              />
            </div>
            <div className="homeDashboardContainer__container-filter__left__filter dashboard-check">
              <input
                type={"checkbox"}
                onChange={(e) =>
                  dispatch(setDateOrderSalesActiveDetail(e.target.checked))
                }
              />
              <label>Filtrar por día</label>
            </div>
          </div>
          <div className="homeDashboardContainer__container-filter__right">
            <DatePicker
              style={{
                opacity: isActive.active ? 1 : 0,
                borderRadius: "14px",
                padding: "3px 7px",
                width: 200,
                height: 40,
                textAlign: "center",
              }}
              months={meses}
              weekDays={dias}
              onChange={handleDataPicker}
              range
              rangeHover
              format="DD/MM/YYYY"
              placeholder={`Filtrar por dias`}
            />
          </div>
        </div>
        <div className="homeDashboardContainer__container-table">
          {showLoading ? <TableGrafic /> : <LoadingComponent />}
        </div>
      </div>
    </>
  );
};
