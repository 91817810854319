import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import { getListOptions } from "../../helpers";
import { setUpdateCouponProducts } from "../../store/coupon/couponSlice";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const CouponDetailSelectProductContainer = ({
  listOptionsProducts,
  couponData,
}) => {
  const dispatch = useDispatch();

  const processListOptionsProducts = getListOptions([
    listOptionsProducts,
    "products",
    "name",
  ]);

  const handleOnchageMultipleColections = (e) => {
    dispatch(setUpdateCouponProducts({ products: e }));
  };

  return (
    <div className="productDetailInformationContainer">
      {couponData?.couponType === "Para ciertos productos" && (
        <>
          <TitleInformationComponent
            title={"En caso seleccionó para productos"}
          />
          <SelectOptionsContainer
            title={"Productos"}
            handleOnchange={handleOnchageMultipleColections}
            name="products"
            isInputMulti={true}
            value={couponData?.products}
            dataList={processListOptionsProducts}
          />
        </>
      )}
    </div>
  );
};
