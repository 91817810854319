import {
  CouponDetailHeaderContainer,
  CouponDetailMainContainer,
} from "../../containers";

export const CouponDetailPage = () => {
  return (
    <section className="productDetailPage">
      <CouponDetailHeaderContainer />
      <CouponDetailMainContainer />
    </section>
  );
};
