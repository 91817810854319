import { ClientRetrieveAddressFieldsKeyComponent } from "../../components";
import { ClientRetrieveAddressFieldsValueContainer } from "../../containers";

export const ClientRetrieveAddressTableContainer = ({ clientId }) => {
  return (
    <table className="clientRetrieveAddressTableContainer">
      <ClientRetrieveAddressFieldsKeyComponent />
      <ClientRetrieveAddressFieldsValueContainer  clientId={clientId}/>
    </table>
  );
};
