import { TitleInformationComponent } from "../../components";
import {
  StoreDetailTableHeadContainer,
  StoreSheduleDetailTableBodyContainer,
} from "../";
import { useCustomHook } from "../../hooks/useDispatchTimeCurrent";

export const StoreDetailGroupsPickUpSchedulesContainer = ({
  data,
  handleOnchage,
  handleAdd,
}) => {
  const { handleClick } = useCustomHook();
  const handlepPickUpSchedules = (index, name, selectHour = "") => {
    handleClick(index, "PickUpSchedules", name, selectHour);
  };
  return (
    <div className="comboDetailGroupsContainer">
      <TitleInformationComponent title={"Horarios de las Tiendas"} />
      <hr />
      <table className="comboDetailGroupsContainer__table">
        <StoreDetailTableHeadContainer
          day={true}
          openingTime={true}
          closingTime={true}
          order={true}
          isActive={true}
          isDelete={true}
        />
        <tbody>
          {data?.map((item, index) => (
            <StoreSheduleDetailTableBodyContainer
              key={index}
              {...item}
              isActive={true}
              isDelete={true}
              onChange={handleOnchage}
              index={index}
              getTimeCurrent={handlepPickUpSchedules}
            />
          ))}
          <tr className="comboDetailGroupsContainer__table__button">
            <td>
              <button onClick={handleAdd}>Agregar Horarios</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
