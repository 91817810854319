import {
  SettingLandingQuestionsHeaderContainer,
  SettingLandingQuestionsMainContainer,
} from "../../../../containers";

export const SettingLandingQuestionsDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingQuestionsHeaderContainer />
      <SettingLandingQuestionsMainContainer />
    </section>
  );
};
