import { ProSidebarProvider } from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import { TopNavContainer } from "../../containers";
import { SidebarContainer } from "../../containers";

export const MainLayout = () => {
  return (
    <ProSidebarProvider>
      <div className="main-container">
        <div className="main-container__sidebar">
          <SidebarContainer />
        </div>
        <div className="main-container__topnav">
          <TopNavContainer />
        </div>
        <div className="main-container__outlet">
          <Outlet />
        </div>
      </div>
    </ProSidebarProvider>
  );
};
