import { MapComponent } from "../../components/MapComponent/MapComponent";
export const ModalMapContainer = ({ latitude, longitude, address,onClose }) => {
  return (
    <div className="modalMapContainer">
      <div className="modalMapContainer__close">
        <p>MAPA</p>
        <button onClick={onClose}>X</button>
      </div>
      <div className="modalMapContainer__map">
        <MapComponent zoom={17} latitude={latitude} longitude={longitude} />
      </div>
      <div className="modalMapContainer__address">
        <p>{address}</p>
      </div>
    </div>
  );
};
