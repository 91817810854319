export const OrderListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="orderListFieldsKeyComponent">
        <th className="orderListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="orderListFieldsKeyComponent__code">Código</th>
        <th className="orderListFieldsKeyComponent__client">Cliente</th>
        <th className="orderListFieldsKeyComponent__modality">Modalidad</th>
        <th className="orderListFieldsKeyComponent__shop">Tienda</th>
        <th className="orderListFieldsKeyComponent__total">Total</th>
        <th className="orderListFieldsKeyComponent__state">Estado</th>
        <th className="orderListFieldsKeyComponent__date">Fecha</th>
      </tr>
    </thead>
  );
};
