import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../../containers";
import { useState } from "react";
import { changeStatusOfClass } from "../../helpers";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

export const PaymentRetrieveHeaderContainer = () => {
  const [isOpenModalStatusChange, setIsOpenModalStatusChange] = useState(false);
  const [isOpenModalPaymentRejection, setIsOpenModalPaymentRejection] =
    useState(false);
  const path = useParams();
  const openModalConfirmPaymentRejection = () => {
    setIsOpenModalPaymentRejection(!isOpenModalPaymentRejection);
  };
  const { paymentData } = useSelector((state) => state.retrievePayment);
  const openConfirmStatusChangeModal = () => {
    setIsOpenModalStatusChange(!isOpenModalStatusChange);
  };
  const urlPaymentChangeState = `admin/pagos/${path.paymentId}/cambiar_estado/`;
  const urlPaymentPaymentRejection = `admin/pagos/${path.paymentId}/rechazar/`;
  return (
    <div className="paymentRetrieveHeaderContainer">
      <div className="paymentRetrieveHeaderContainer__title">
        <TitleComponent title="Detalle del Pago" />
      </div>
      <div className="paymentRetrieveHeaderContainer__container-button">
        {paymentData?.state === "En Revisión" && (
          <>
            <ButtonPushComponent
              name="Rechazar Pago"
              onClick={openModalConfirmPaymentRejection}
              color={"gray"}
            />
            <ButtonPushComponent
              name="Cambiar a Pagado"
              onClick={openConfirmStatusChangeModal}
            />
          </>
        )}
      </div>

      {isOpenModalPaymentRejection && (
        <ModalLayout>
          <ModalContainer
            title="Rechazar Pago"
            msg="¿Está seguro que desea rechazar el pago?"
            onClose={openModalConfirmPaymentRejection}
            onConfirm={(e) => changeStatusOfClass(urlPaymentPaymentRejection)}
          />
        </ModalLayout>
      )}

      {isOpenModalStatusChange && (
        <ModalLayout>
          <ModalContainer
            title="Cambiar Estado"
            msg="¿Está seguro que desea cambiar el estado del pago?"
            onClose={openConfirmStatusChangeModal}
            onConfirm={(e) => changeStatusOfClass(urlPaymentChangeState)}
          />
        </ModalLayout>
      )}
    </div>
  );
};
