import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ProductComplementCreateHeaderContainer,
  ProductComplementDetailMainContainer,
} from "../../../containers";
import { clearUpdateComplement } from "../../../store/complement";

export const ProductComplementCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateComplement());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="orderCreateMainContainer">
      <ProductComplementCreateHeaderContainer />
      <ProductComplementDetailMainContainer />
    </div>
  );
};
