import { useNavigate } from "react-router-dom";
import { ButtonSearchComponent, InputSearchComponent } from "../../components";

export const ProductComplementSearchContainer = () => {
  const navigate = useNavigate();
  const filter = () => {
    const search = document.getElementById("searchComplement").value;
    let url = `/productos/complementos?`;
    if (search !== "null") {
      url = url.concat(`&search=${search}`);
    }
    navigate(url);
  };
  const handleEnter = (e) => {
    if (e.key !== "Enter") return;
    filter();
  };
  const dataInput = {
    id: "searchComplement",
    placeholder: "Buscar Complemento",
  };
  return (
    <div className="clientSearchContainer">
      <div className="clientSearchContainer__search">
        <div className="clientSearchContainer__search__input">
          <InputSearchComponent data={dataInput} onKeyDown={handleEnter} />
        </div>
        <div className="clientSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
