import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { useNavigate, useParams } from "react-router-dom";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { requestPostAuthorization } from "../../services/requestPost";

export const SettingLandingQuestionsHeaderContainer = () => {
  const { questionsData } = useSelector((state) => state.retrieveQuestions);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const navigate = useNavigate();
  const path = useParams();
  const { questionId } = path;

  const handleUpdate = async () => {
    let body = { ...questionsData };
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/preguntas_frecuentes/${questionId}/`,
      body,
      token
    ).then(() => {
      navigate("/configuracion/landing/preguntas-frecuentes");
    });
  };

  const handleCreate = async () => {
    let body = { ...questionsData };
    requestPostAuthorization(`admin/preguntas_frecuentes/`, body).then(() => {
      navigate("/configuracion/landing/preguntas-frecuentes");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Preguntas Frecuentes" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={`${questionId ? "Actualizar" : "Crear Pregunta"}`}
          onClick={toggleModalUpdate}
        />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Pregunta"
            msg="¿Está seguro que desea actualizar Pregunta?"
            onClose={toggleModalUpdate}
            onConfirm={questionId ? handleUpdate : handleCreate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
