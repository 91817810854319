import { useSelector } from "react-redux";
import { StoreListFieldsValueComponent } from "../../components";

export const StoreListFieldsValueContainer = () => {
  const { listStore } = useSelector((state) => state.listStore);
  return (
    <tbody>
      {listStore?.results?.length > 0 &&
        listStore?.results?.map((item) => {
          return <StoreListFieldsValueComponent key={item.id} store={item} />;
        })}
    </tbody>
  );
};
