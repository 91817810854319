import {
  SettingLandingBannersUsHeaderContainer,
  SettingLandingBannersUsMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersUsDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersUsHeaderContainer />
      <SettingLandingBannersUsMainContainer />
    </section>
  );
};
