import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const OrderDetailItemContainer = ({ dataItems }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(Array(dataItems.length).fill(false));

  const handleClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const getArrowIcon = (index) => {
    if (selectedIndex === index) {
      return isOpen[index] ? <IoIosArrowUp /> : <IoIosArrowDown />;
    }
    return <IoIosArrowDown />;
  };
  return (
    <section className="orderDetailItemContainer">
      <div className="orderDetailItemContainer__title">
        <p>Productos</p>
      </div>
      <div className="orderDetailItemContainer__container">
        {dataItems?.map((item, index) => (
          <div key={index}>
            <div className="orderDetailItemContainer__container__product">
              <div className="orderDetailItemContainer__container__product__description">
                <div className="orderDetailItemContainer__container__product__description__image">
                  <img src={item.product.imageMini} alt="img" width="80px" />
                </div>
                <div className="orderDetailItemContainer__container__product__description__text">
                  <h6>
                    {item.product.name} x{item.quantity}
                  </h6>
                </div>
              </div>
              <div className="orderDetailItemContainer__container__product__price">
                <p>s/ {item.totalAmount.toFixed(2)}</p>
                {item?.ordenesProductosGruposCustoms.length > 0 && (
                  <button onClick={() => handleClick(index)}>
                    {" "}
                    {getArrowIcon(index)}
                  </button>
                )}
              </div>
            </div>

            {selectedIndex === index && (
              <>
                {item?.ordenesProductosGruposCustoms?.map((item, index) => (
                  <div
                    key={index}
                    className="orderDetailItemContainer__container__product__item"
                  >
                    <div className="orderDetailItemContainer__container__product__item__title">
                      {item.name}
                    </div>
                    {item?.ordenesProductosCustoms?.map((item, index) => (
                      <div
                        key={index}
                        className="orderDetailItemContainer__container__product__item__description"
                      >
                        <div className="orderDetailItemContainer__container__product__item__description__text">
                          - {item.name}
                        </div>
                        <div className="orderDetailItemContainer__container__product__item__description__amount">
                          s/{item.amount.toFixed(2)}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                {item?.coment && (
                  <>
                    <div className="orderDetailItemContainer__container__product__item__title">
                      Comentario
                    </div>
                    <div className="orderDetailItemContainer__container__product__item__comment">
                      {item.coment}
                    </div>
                  </>
                )}
              </>
            )}
            <div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
