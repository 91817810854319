import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
export const NoteListFieldsValueComponent = ({ note }) => {
  const url = `/productos/notas/${note.id}/detalles`;
  const stateColor = orderStateColor(note?.active);
  return (
    <tr className="noteListFieldsValueComponent">
      <td className="noteListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="noteListFieldsValueComponent__name">
        <Link to={url}>{note.name}</Link>
      </td>
      <td className="noteListFieldsValueComponent__amount">
        <Link to={url}>{note.amount}</Link>
      </td>
      <td className={`noteListFieldsValueComponent__state ${stateColor}`}>
        <Link to={url}>{note.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
