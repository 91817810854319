import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
import { getAbbreviatedFormatDate } from "../../helpers";

export const OrderDetailHeaderContainer = ({ data }) => {
  const navigate = useNavigate();
  return (
    <section className="orderDetailHeaderContainer">
      <div
        className="orderDetailHeaderContainer__icon"
        onClick={() => navigate("/ordenes")}
      >
        <ArrowLeft />
      </div>
      <div className="orderDetailHeaderContainer__container">
        <div className="orderDetailHeaderContainer__container__title">
          <h5>Orden #{data?.codigo}</h5>
        </div>
        <div className="orderDetailHeaderContainer__container__text">
          <p className="orderDetailHeaderContainer__container__text__status">
            Estado: {data?.state}
          </p>
          <p className="orderDetailHeaderContainer__container__text__date">
            Fecha: {data?.created && getAbbreviatedFormatDate(data?.created)}
          </p>
        </div>
      </div>
    </section>
  );
};
