import { TableListFieldsValueComponent } from "../../components";

export const TableListFieldsValueContainer = ({ data, url }) => {
  return (
    <tbody>
      {data?.length > 0 &&
        data?.map((item) => {
          return (
            <TableListFieldsValueComponent
              key={item.id}
              data={item}
              url={url}
            />
          );
        })}
    </tbody>
  );
};
