import { getListProcess } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setCouponLoading,
  setListCoupon,
  setRetrieveCoupon,
  setRetrieveCouponLoading,
} from "./couponSlice";

const convertDate = (dataTime) => {
  const parts = dataTime.split(/[-T:+]/);
  // Extrae la fecha y la hora de las partes correspondientes
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  const hour = parts[3];
  const minute = parts[4];
  const second = parts[5];

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hour}:${minute}:${second}`;
  return { formattedDate, formattedTime };
};

export const getListCoupon = (params) => {
  return async (dispatch) => {
    dispatch(setCouponLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/cupones/", params, token).then(
      (value) => {
        dispatch(setListCoupon({ data: value, params: params }));
        dispatch(setCouponLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveCoupon = (couponId) => {
  return async (dispatch) => {
    dispatch(setRetrieveCouponLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/cupones/${couponId}/`, token).then(
      (value) => {
        if (value?.users) {
          value.users = getListProcess([value.users, "email", "id"]);
        }
        if (value?.products) {
          value.products = getListProcess([value.products]);
        }
        if (value?.collections) {
          value.collections = getListProcess([value.collections]);
        }
        if (value?.expirationDate) {
          let obj = value?.expirationDate;
          value.expirationDate = convertDate(obj).formattedDate;
          value.expirationDateTime = convertDate(obj).formattedTime;
        }
        if (value?.startDate) {
          let obj = value?.startDate;
          value.startDate = convertDate(obj).formattedDate;
          value.startDateTime = convertDate(obj).formattedTime;
        }

        dispatch(setRetrieveCoupon({ data: value }));
        dispatch(setRetrieveCouponLoading({ showLoading: false }));
      }
    );
  };
};
