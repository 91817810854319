import { useContext, useEffect } from "react";
import LoginContext from "../../store/loginContext";
import { ReactComponent as IconLogout } from "../../assets/icon-logout.svg";
import { useRef } from "react";

export const ModalPerfilContainer = ({
  modalPerfilHidden,
  setModalPerfilHidden,
}) => {
  let loginRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!loginRef.current.contains(e.target)) {
        setModalPerfilHidden(false);
      }
    };
    document.addEventListener("mouseup", handler);

    return () => {
      document.removeEventListener("mouseup", handler);
    };
  });

  const loginCtx = useContext(LoginContext);

  const logoutHandler = () => {
    loginCtx.toggleLogin();
    localStorage.clear();
  };

  return (
    <div
      className={
        modalPerfilHidden ? "modal-perfil" : "modal-perfil hidden-modal-perfil"
      }
      ref={loginRef}
    >
      <div className="modal-perfil__content">
        <div className="modal-perfil__content__icon">
          <img
            src="https://i.ibb.co/gm0F52m/5.png"
            alt="profile"
            width="48px"
          />
        </div>
        <div className="modal-perfil__content__name">
          <p>Pan Atelier</p>
        </div>
      </div>
      <div className="modal-perfil__separate">
        <hr />
      </div>
      <div className="modal-perfil__item" onClick={logoutHandler}>
        <div className="modal-perfil__item__icon">
          <IconLogout />
        </div>
        <div className="modal-perfil__item__text">
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};
