export const getListOptions = (inputParamsData) => {
  let [array, name = "name", label = "label", value = "id"] = inputParamsData;
  return array?.map((item) => {
    const option = { ...item };
    option.value = item[value];
    option.label = item[label];
    option.name = name;
    return option;
  });
};

export const getListOptionsUsers = (inputParamsData) => {
  let [array, value = "id"] = inputParamsData;
  return array?.map((item) => {
    const option = { ...item };
    option.value = item[value];
    option.label = item["email"];
    option.name = "user";
    return option;
  });
};

export const getListProcess = (data) => {
  let [array, name = "name", value = "id"] = data;
  return array?.map((item) => {
    const option = { ...item };
    option.value = item[value];
    option.label = item[name];
    option.name = name;
    return option;
  });
};

export const getListOptionsItem = (inputParamsData) => {
  const [array, name = "name", label = "label", value = "id"] = inputParamsData;
  return array.map((item) => {
    const option = {};
    option.value = item[value];
    option.label = item[label];
    option.name = name;
    return option;
  });
};
