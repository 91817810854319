import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearUpdateProductPresentation,
  setUpdateProductPresentation,
} from "../../store/product";

export const ProductDetailFieldsValueContainer = ({
  data,
  isPresentation = false,
  handleOnchage,
  isPresentationGroup,
  index = 0,
  customs,
  setCustoms,
  clearGroupCurrent,
}) => {
  const dispatch = useDispatch();
  const [presentation, setPresentation] = useState({
    name: "",
    amount: 0,
    order: 1,
    active: true,
  });
  useEffect(() => {
    if (!!data) {
      setPresentation(data);
    }
  }, [data]);
  const onChage = (e) => {
    handleOnchage(e);
  };
  const onChagePresentation = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active") {
      value = e.target.checked;
    }
    const newCustoms = [...customs];
    newCustoms[index] = {
      ...presentation,
      [name]: value,
    };
    setCustoms(newCustoms);
    setPresentation({ ...presentation, [name]: value });
    const updateProductPresentation = {
      ...presentation,
      [name]: value,
    };
    updateProductPresentation.index = index;
    updateProductPresentation.groupIndex = 0;
    dispatch(setUpdateProductPresentation(updateProductPresentation));
  };
  const dataCurrent = isPresentation ? presentation : data;
  const clearPresentationCurrent = (e) => {
    const newCustoms = [...customs];
    newCustoms.splice(index, 1);
    setCustoms(newCustoms);
    dispatch(
      clearUpdateProductPresentation({
        groupIndex: 0,
        index: index,
      })
    );
  };

  return (
    <tr className="productDetailFieldsValueContainer">
      <td className="productDetailFieldsValueContainer__name">
        <input
          name="name"
          type="text"
          value={dataCurrent?.name || ""}
          onChange={isPresentation ? onChagePresentation : onChage}
        />
      </td>
      <td className="productDetailFieldsValueContainer__order">
        <input
          name="order"
          type="number"
          value={dataCurrent?.order || ""}
          onChange={isPresentation ? onChagePresentation : onChage}
        />
      </td>
      <td className="productDetailFieldsValueContainer__active">
        <input
          name="active"
          type="checkbox"
          checked={dataCurrent?.active ? "checked" : ""}
          onChange={isPresentation ? onChagePresentation : onChage}
        />
      </td>
      {!isPresentation && (
        <td className="productDetailFieldsValueContainer__mandatory">
          <input
            name="isMandatory"
            type="checkbox"
            checked={dataCurrent?.isMandatory ? "checked" : ""}
            onChange={isPresentation ? onChagePresentation : onChage}
          />
        </td>
      )}
      {!isPresentationGroup && (
        <td className="productDetailFieldsValueContainer__multiple">
          <input
            name="isMultiple"
            type="checkbox"
            checked={dataCurrent?.isMultiple ? "checked" : ""}
            onChange={isPresentation ? onChagePresentation : onChage}
          />
        </td>
      )}
      {isPresentation && (
        <td className="productDetailFieldsValueContainer__amount">
          <input
            name="amount"
            type="number"
            value={dataCurrent?.amount || 0}
            onChange={isPresentation ? onChagePresentation : onChage}
          />
        </td>
      )}
      <td className="productDetailFieldsValueContainer__button">
        <button
          onClick={
            isPresentation ? clearPresentationCurrent : clearGroupCurrent
          }
          value={"data" || ""}
          defaultValue=""
        >
          Eliminar
        </button>
      </td>
    </tr>
  );
};
