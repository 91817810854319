import { useNavigate } from "react-router-dom";
import { ButtonSearchComponent, InputSearchComponent } from "../../components";

export const StoreCoverageSearchContainer = ({ setParams, params }) => {
  const navigate = useNavigate();
  const filter = () => {
    const active = document.getElementById("storeActive").value;
    const search = document.getElementById("searchStore").value;
    let url = `/configuracion/tiendas/`;
    if (active !== "null") {
      url = url.concat(`active=${active}`);
    }
    if (search !== "null") {
      url = url.concat(`&search=${search}`);
    }
    setParams({
      ...params,
      active: active !== "null" ? active : "",
      search: search !== "null" ? search : "",
    });
    navigate(url);
  };
  const dataInput = { id: "searchStore", placeholder: "Buscar" };
  return (
    <div className="clientSearchContainer">
      {/* <div className="clientSearchContainer__filters">
        <div className="clientSearchContainer__filters__item">
          <FilterComponent
            data={clientActive}
            name={"Estado"}
            id={"storeActive"}
          />
        </div>
      </div> */}
      <div className="clientSearchContainer__search">
        <div className="clientSearchContainer__search__input">
          <InputSearchComponent data={dataInput} />
        </div>
        <div className="clientSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
