import React from "react";
import { KeyValueComponent } from "../../components";

export const PaymentRetrieveInformationContainer = ({ data }) => {
  const keyValueStyle = {
    fontSize: "14px",
    display: "flex",
    margin:"10px",
    justifyContent:"space-between"
  };
  return (
    <div className="paymentRetrieveInformationContainer">
      <div className="paymentRetrieveInformationContainer__title">
        <p>Información</p>
      </div>
      <div className="paymentRetrieveInformationContainer__fields">
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Orden"
          valueName={data?.order?.codigo}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Método de Pago"
          valueName={data?.paymentMethod?.name}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="OpenPay ID"
          valueName={data?.openpayId}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Order ID"
          valueName={data?.externalId}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Link"
          valueName={data?.link}
        />
      </div>
    </div>
  );
};
