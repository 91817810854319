import { MapComponent } from "../../components/MapComponent/MapComponent";

export const OrderDetailUserModalityContainer = ({ data }) => {
  const viewHour = () => {
    const hourStart = data?.schedulePickup?.startTime.slice(0, -3);
    const hourEnd = data?.schedulePickup?.endTime.slice(0, -3);
    return `${hourStart} - ${hourEnd}`;
  };

  return (
    <div className="orderDetailUserModalityContainer">
      <div className="orderDetailUserModalityContainer__title">
        <p>Modalidad</p>
      </div>
      <div className="orderDetailUserModalityContainer__container">
        <div className="orderDetailUserModalityContainer__container__description">
          <p className="orderDetailUserModalityContainer__container__description__left">
            Tipo de envio:
          </p>
          <p className="orderDetailUserModalityContainer__container__description__right">
            {data?.modality}
          </p>
        </div>
        <div className="orderDetailUserModalityContainer__container__description">
          <p className="orderDetailUserModalityContainer__container__description__left">
            Local:
          </p>
          <p className="orderDetailUserModalityContainer__container__description__right">
            {data?.shop?.name}
          </p>
        </div>
        {data?.schedulePickup?.startTime !== undefined && (
          <div className="orderDetailUserModalityContainer__container__description">
            <p className="orderDetailUserModalityContainer__container__description__left">
              Horario de Recojo:
            </p>
            <p className="orderDetailUserModalityContainer__container__description__right sizeHour">
              {viewHour()}
            </p>
          </div>
        )}

        {data?.modality === "Delivery" && (
          <>
            <div className="orderDetailUserModalityContainer__container__description">
              <p className="orderDetailUserModalityContainer__container__description__left">
                Direccion:
              </p>
              <p className="orderDetailUserModalityContainer__container__description__right">
                {data?.address}
              </p>
            </div>
            <div className="orderDetailUserModalityContainer__container__description">
              <p className="orderDetailUserModalityContainer__container__description__left">
                Referencia:
              </p>
              <p className="orderDetailUserModalityContainer__container__description__right">
                {data?.reference}
              </p>
            </div>
            <div className="orderDetailUserModalityContainer__container__description">
              <p className="orderDetailUserModalityContainer__container__description__left">
                Latitud:
              </p>
              <p className="orderDetailUserModalityContainer__container__description__right">
                {data?.latitude}
              </p>
            </div>
            <div className="orderDetailUserModalityContainer__container__description">
              <p className="orderDetailUserModalityContainer__container__description__left">
                Longitud:
              </p>
              <p className="orderDetailUserModalityContainer__container__description__right">
                {data?.longitude}
              </p>
            </div>
            <div className="orderDetailUserModalityContainer__map">
              <MapComponent
                zoom={17}
                latitude={data?.latitude}
                longitude={data?.longitude}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
