import { TitleComponent } from "../../../../components";
import { SettingBoxContainer } from "../../../../containers";

const data = [
  {
    id: 1,
    title: "Home",
    text: "Bienvenido a nuestra página de inicio.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/home-31-03-2023.webp",
    slug: "home",
  },
  {
    id: 2,
    title: "Nosotros",
    text: "Conoce más sobre nosotros y nuestra misión.",
    url: "https://cdn-icons-png.flaticon.com/512/4906/4906519.png",
    slug: "nosotros",
  },
  {
    id: 3,
    title: "Historia",
    text: "Descubre nuestra historia y cómo comenzamos.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/history-31-03-2023.png",
    slug: "historia",
  },
  {
    id: 4,
    title: "Panes",
    text: "Mira nuestra selección de panes recién horneados.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/bread-31-03-2023.png",
    slug: "panes",
  },
  {
    id: 5,
    title: "Aliados",
    text: "Descubre quiénes son nuestros aliados y colaboradores.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/allies-31-03-2023.webp",
    slug: "aliados",
  },
  {
    id: 6,
    title: "Trabajos",
    text: "Buscamos gente apasionada y comprometida para unirse a nuestro equipo.",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/jobs-31-03-2023.png",
    slug: "trabajos",
  },
];

export const SettingLandingBannersIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Banners"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
