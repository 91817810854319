import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPostAuthorization } from "../../services/requestPost";
import { cleanCreateNote } from "../../store/note";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const NoteCreateHeaderContainer = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const body = useSelector((state) => state.createNote);
  const onOpenConfirmd = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };
  const dispatch = useDispatch();
  const createNote = () => {
    const token = localStorage.getItem("token");
    requestPostAuthorization(`admin/notas/`, body, token);
    dispatch(cleanCreateNote());
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };
  return (
    <div className="noteCreateHeaderContainer ">
      <div className="noteCreateHeaderContainer__title">
        <TitleComponent title="Detalle de la nota" />
      </div>
      <div className="noteCreateHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirmd} />
      </div>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Crear Nota"
            msg="¿Está seguro que desea crear la nota?"
            style={canceledStateStyle}
            onClose={onOpenConfirmd}
            onConfirm={createNote}
          />
        </ModalLayout>
      )}
    </div>
  );
};
