import { requestGetAuthorization } from "../../../services/requestGet";
import { setRetrieveBannerHome, setRetrieveBannerLoading } from "./homeSlice";

function transformData(data) {
  const transformedData = {};
  for (const key in data) {
    if (Array.isArray(data[key]) && key === "photos") {
      const photosArray = [];
      data[key].forEach((photoObject) => {
        const url = photoObject.photo;
        const name = url.substring(url.lastIndexOf("/") + 1);
        const slug = photoObject.slug;
        photosArray.push({
          url,
          type: "",
          name,
          slug,
        });
      });
      transformedData[key] = photosArray;
    } else if (!Array.isArray(data[key])) {
      transformedData[key] = {
        url: data[key],
        type: "",
        name: data[key].substring(data[key].lastIndexOf("/") + 1),
      };
    } else {
      transformedData[key] = data[key];
    }
  }
  return transformedData;
}

export const getRetrieveBannerHome = () => {
  return async (dispatch) => {
    dispatch(setRetrieveBannerLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/banners/home/`, token).then((value) => {
      const transformedData = transformData(value);
      dispatch(setRetrieveBannerHome({ data: transformedData }));
      dispatch(setRetrieveBannerLoading({ showLoading: false }));
    });
  };
};
