import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerAlliesSlice = createSlice({
  name: "retrieveBannerAllies",
  initialState: {
    bannerAlliesData: {
      coverDesktop: "",
      coverMobile: "",
      companies: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerAllies: (state, action) => {
      state.bannerAlliesData.coverDesktop =
        action.payload?.data?.coverDesktop ||
        state?.bannerAlliesData?.coverDesktop;
      state.bannerAlliesData.coverMobile =
        action.payload?.data?.coverMobile ||
        state?.bannerAlliesData?.coverMobile;
      state.bannerAlliesData.companies =
        action.payload?.data?.companies || state?.bannerAlliesData?.companies;
    },
    setRetrieveBannerAlliesLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerAllies, setRetrieveBannerAlliesLoading } =
  retrieveBannerAlliesSlice.actions;
