const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const getFormatDate = (date) => {
  const newDate = new Date(date);
  let minutes = newDate.getMinutes();
  let hours = newDate.getHours();
  let day = newDate.getDate();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  return `${day} de ${
    MONTHS[newDate.getMonth()]
  } de ${newDate.getFullYear()} a las ${hours}:${minutes}`;
};
