import { CombosListFieldsKeyComponent } from "../../components";
import { CombosListFieldsValueContainer } from "../../containers";

export const CombosListTableContainer = () => {
  return (
    <table className="clientListTableContainer">
      <CombosListFieldsKeyComponent />
      <CombosListFieldsValueContainer />
    </table>
  );
};
