import { createSlice } from "@reduxjs/toolkit";
export const listOptionTagSlice = createSlice({
  name: "listOptionTag",
  initialState: {
    listOptionTagData: [],
  },
  reducers: {
    setListOptionTag: (state, action) => {
      state.listOptionTagData = action.payload.data;
    },
  },
});
export const { setListOptionTag } = listOptionTagSlice.actions;
