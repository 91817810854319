export const ProductComplementListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="productComplementListFieldsKeyComponent">
        <th className="productComplementListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="productComplementListFieldsKeyComponent__fullName">
          Nombre
        </th>
        <th className="productComplementListFieldsKeyComponent__products">
          Productos
        </th>
        <th className="productComplementListFieldsKeyComponent__phone">
          Orden
        </th>
      </tr>
    </thead>
  );
};
