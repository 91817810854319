import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ButtonPushComponent,
  LoadingComponent,
  TitleComponent,
} from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPostAuthorization } from "../../services/requestPost";
import { cleanCreateOrder } from "../../store/order/ordersSlice";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const OrderCreateTitleContainer = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [openModalLink, setOpenModalLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  let body = useSelector((state) => state.createOrder);
  const onOpenConfirm = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const createCollection = () => {
    const token = localStorage.getItem("token");
    const obj = {
      client: body.client,
      billingType: body.ticket,
      documentTypeBilling: body.typeDocument,
      documentNumberBilling: body.identidad,
      ruc: body.ruc,
      modality: body.typeStore,
      shop: body.store,
      schedulePickup: body.typeStore === "Delivery" ? null : body.hours,
      address: body.typeStore === "Delivery" ? body.address : "",
      reference: body.typeStore === "Delivery" ? body.reference : "",
      latitude: body.typeStore === "Delivery" ? body.latitude : null,
      longitude: body.typeStore === "Delivery" ? body.longitude : null,
      ordenesProductos: body.ordenesProductos,
      ordenesCombos: [],
    };
    setIsLoading(true);
    if (body.paymentMethod === 1) {
      requestPostAuthorization(`admin/ordenes/`, obj, token).then((value) => {
        const body = {
          paymentMethodId: 5,
        };
        requestPostAuthorization(
          `admin/ordenes/${value.id}/pagar_con_efectivo/`,
          body,
          token
        ).then(() => {
          setIsLoading(false);
          dispatch(cleanCreateOrder());
          navigate("/ordenes");
        });
      });
    }
    if (body.paymentMethod === 2) {
      requestPostAuthorization(`admin/ordenes/`, obj, token).then((value) => {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const body = {
          paymentMethodId: 4,
        };
        requestPostAuthorization(
          `admin/ordenes/${value.id}/pagar_con_link/`,
          body,
          token
        ).then((valueLink) => {
          setIsLoading(false);
          setLink(valueLink.link);
          dispatch(cleanCreateOrder());
          setIsOpenConfirm(false);
          setOpenModalLink(true);
        });
      });
    }
  };

  const handleNavigate = () => {
    setOpenModalLink(false);
    navigate("/ordenes");
  };
  return (
    <div className="collectionCreateHeaderContainer ">
      <div className="collectionCreateHeaderContainer__title">
        <TitleComponent title="Crear Orden" />
      </div>
      <div className="collectionCreateHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={onOpenConfirm} />
      </div>
      {isOpenConfirm && (
        <ModalLayout>
          <ModalContainer
            title="Crear Orden"
            msg="¿Está seguro que desea crear la Orden?"
            onClose={onOpenConfirm}
            onConfirm={createCollection}
          />
        </ModalLayout>
      )}

      {openModalLink && (
        <ModalLayout>
          <ModalContainer
            title="Order Creada"
            msg={link}
            buttonBack={true}
            handleNavigate={handleNavigate}
          />
        </ModalLayout>
      )}

      {isLoading && (
        <ModalLayout>
          <LoadingComponent />
        </ModalLayout>
      )}
    </div>
  );
};
