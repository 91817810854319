import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ProductDetailModifierContainer,
  ProductDetailTableContainer,
} from "../";
import {
  clearUpdateProductModifierGroup,
  setUpdateProductModifierGroup,
} from "../../store/product";

export const ProductDetailGroupModifiedContainer = ({ data, options }) => {
  const [modifiers, setModifiers] = useState([]);
  const dispatch = useDispatch();
  const [modifierGroup, setModifierGroup] = useState({
    name: "",
    isMandatory: false,
    order: 1,
    active: true,
    isMultiple: false,
  });
  useEffect(() => {
    if (!!data) {
      setModifierGroup(data);
      setModifiers(data?.modificadores ? data?.modificadores : []);
    }
  }, [data]);
  const handleOnchageModifierGroup = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active" || name === "isMandatory" || name === "isMultiple") {
      value = e.target.checked;
    }
    setModifierGroup({ ...modifierGroup, [name]: value });
    const updateProductModifierGroup = {
      ...modifierGroup,
      [name]: value,
    };
    updateProductModifierGroup.index = 0;
    dispatch(setUpdateProductModifierGroup(updateProductModifierGroup));
  };
  const addModifier = () => {
    setModifiers([...modifiers, null]);
  };
  const clearGroupCurrent = (e) => {
    setModifierGroup({
      name: "",
      isMandatory: false,
      order: 1,
      active: true,
      isMultiple: false,
    });
    setModifiers([]);
    dispatch(clearUpdateProductModifierGroup());
  };
  return (
    <div className="productDetailGroupModifiedContainer">
      <div className="productDetailGroupModifiedContainer__container">
        <div className="productDetailGroupModifiedContainer__container__title">
          Grupo Modificador
        </div>
        <div className="productDetailGroupModifiedContainer__container__table">
          <ProductDetailTableContainer
            isPresentation={false}
            data={modifierGroup}
            isOpen={false}
            handleOnchage={handleOnchageModifierGroup}
            clearGroupCurrent={clearGroupCurrent}
          />
        </div>
        {!!modifierGroup.name && (
          <div className="productDetailGroupModifiedContainer__container__left">
            <div className="productDetailGroupModifiedContainer__container__left__title">
              Modificadores
            </div>
            {modifiers.map((modifier, index) => (
              <ProductDetailModifierContainer
                key={index}
                options={options}
                data={modifier}
                index={index}
                modifiers={modifiers}
                setModifiers={setModifiers}
              />
            ))}
            <div
              className="productDetailGroupModifiedContainer__container__left__button"
              onClick={addModifier}
            >
              <div>Agregar Modificadores</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
