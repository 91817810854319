import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setCreateOrder } from "../../store/order/ordersSlice";

export const SearchLocationComponent = ({ currentAddress, onChangePlace, lat,log,reference }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        componentRestrictions: {
          country: "pe",
        },
      }
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      if (!place.geometry) return;
      onChangePlace(place);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentAddress) inputRef.current.value = currentAddress;
    if(currentAddress) dispatch(setCreateOrder({ "address": currentAddress }));
    if(currentAddress) dispatch(setCreateOrder({ "latitude": lat }));
    if(currentAddress)  dispatch(setCreateOrder({ "longitude": log })); 
    if(currentAddress)  dispatch(setCreateOrder({ "reference": reference })); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress]);

  return (
    <div className="selectOptionsContainer">
      <p>Buscar dirección</p>
      <div className="selectOptionsContainer__select">
        <input
          className="selectOptionsContainer__select__input"
          ref={inputRef}
          placeholder="Escribe aquí"
          type="text"
          name="inputSearchMap"
        />
      </div>
    </div>
  );
};
