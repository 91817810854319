import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { clearUpdateReception } from "../../store/reception";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const SettingEnvironmentHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { environmentData } = useSelector((state) => state.retrieveEnvironment);
  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const handleCreateCombo = async () => {
    const body = { ...environmentData };
    const token = localStorage.getItem("token");

    requestPatchAuthorization(
      `admin/variablesEntornos/${path.environmentId}/`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateReception());
      navigate("/configuracion/variables-de-entorno");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del Entorno" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Actualizar"} onClick={toggleModalUpdate} />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Variable de Entorno"
            msg="¿Está seguro que desea actualizar el Variable de Entorno?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
