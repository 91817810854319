import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateCollection } from "../../store/collection";
import { getListOptionsCombo } from "../../store/combo/thunks";
import { getListOptionsProducts } from "../../store/product";
import { ProductCollectionDetailInformationContainer } from "../ProductCollectionDetailInformationContainer/ProductCollectionDetailInformationContainer";
import { SectionSelectionContainer } from "../SectionSelectionContainer/SectionSelectionContainer";

export const CollectionCreateInformationContainer = () => {
  const data = useSelector((state) => state.createModifier);
  const { listOptionsCombo } = useSelector((state) => state.listOptionsCombo);
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const [collectionData, setCollectionData] = useState(data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptionsProducts());
    dispatch(getListOptionsCombo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData.productos]);

  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setCollectionData({ ...collectionData, [name]: value });
    dispatch(setCreateCollection({ [name]: value }));
  };

  return (
    <div className="productCollectionDetailMainContainer">
      <ProductCollectionDetailInformationContainer
        data={collectionData}
        handleOnchage={handleOnchage}
        collectionData={collectionData}
      />
      <SectionSelectionContainer
        title="Productos"
        subTitle="Producto"
        data={collectionData?.productos}
        handleOnchage={handleOnchage}
        listOptionData={listOptionsProducts}
        retrieve={false}
      />
      <SectionSelectionContainer
        title="Combos"
        subTitle="Combo"
        data={collectionData?.combos}
        handleOnchage={handleOnchage}
        listOptionData={listOptionsCombo}
        retrieve={false}
      />
    </div>
  );
};
