import { TitleInformationComponent } from "../../components";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const StoreDetailInformationContainer = ({
  storeData,
  handleOnchange,
}) => {
  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Nombre"}
        name="name"
        handleOnchange={handleOnchange}
        isInput={true}
        value={storeData.name}
      />
      <SelectOptionsContainer
        title={"Slug"}
        name="slug"
        handleOnchange={handleOnchange}
        isInput={true}
        value={storeData.slug}
      />
      <SelectOptionsContainer
        title={"Foto Principal"}
        name="image"
        handleOnchange={handleOnchange}
        isImage={true}
        value={storeData.image}
      />
      <SelectOptionsContainer
        title={"Portada Desktop"}
        name="cover"
        handleOnchange={handleOnchange}
        isImage={true}
        value={storeData.cover}
      />
      <SelectOptionsContainer
        title={"Portada Mobile"}
        name="coverMobile"
        handleOnchange={handleOnchange}
        isImage={true}
        value={storeData.coverMobile}
      />
      <SelectOptionsContainer
        title={"Descripción"}
        name="description"
        handleOnchange={handleOnchange}
        isTextArea={true}
        value={storeData.description}
      />
      <SelectOptionsContainer
        title={"Dirección "}
        name="adress"
        handleOnchange={handleOnchange}
        isInput={true}
        value={storeData.adress}
      />
      <SelectOptionsContainer
        title={"Maps"}
        name="maps"
        handleOnchange={handleOnchange}
        isImage={true}
        value={storeData.maps}
      />
      <SelectOptionsContainer
        title={"Nuestra Carta"}
        name="cart"
        handleOnchange={handleOnchange}
        isImage={true}
        value={storeData.cart}
      />
      <SelectOptionsContainer
        title={"¿Activo? "}
        handleOnchange={handleOnchange}
        name="active"
        isCheckbox={true}
        value={storeData.active}
      />
    </div>
  );
};
