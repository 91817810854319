export const OrderDetailSummaryContainer = ({ data }) => {
  return (
    <section className="orderDetailSummaryContainer">
      <div className="orderDetailSummaryContainer__title">
        <p>Resumen de la orden </p>
      </div>
      <div className="orderDetailSummaryContainer__container">
        <div className="orderDetailSummaryContainer__container__description">
          <p className="orderDetailSummaryContainer__container__description__text">
            Subtotal
          </p>
          <p className="orderDetailSummaryContainer__container__description__price">
            S/{data?.totalAmount && data?.totalAmount.toFixed(2)}
          </p>
        </div>
        <div className="orderDetailSummaryContainer__container__description">
          <p className="orderDetailSummaryContainer__container__description__text">
            Delivery
          </p>
          <p className="orderDetailSummaryContainer__container__description__price">
            S/{data?.deliveryCost && data?.deliveryCost.toFixed(2)}
          </p>
        </div>
        {data?.amountCoupon > 0 && (
          <div className="orderDetailSummaryContainer__container__description">
            <p className="orderDetailSummaryContainer__container__description__text">
              Descuento
            </p>
            <p className="orderDetailSummaryContainer__container__description__price" style={{color:"red"}}>
              S/{data?.amountCoupon && data?.amountCoupon.toFixed(2)}
            </p>
          </div>
        )}
        <div className="orderDetailSummaryContainer__container__description">
          <p className="orderDetailSummaryContainer__container__description__text">
            Total
          </p>
          <p className="orderDetailSummaryContainer__container__description__price">
            S/{data?.totalNetAmount && data?.totalNetAmount.toFixed(2)}
          </p>
        </div>
      </div>
    </section>
  );
};
