import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  PaginationContainer,
  StoreListContainer,
} from "../../../containers";
import { getListStore } from "../../../store/store/thunks";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../../components";

export const SettingStoreIndexPage = () => {
  const { listStore, showLoading } = useSelector((state) => state.listStore);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageStore = serachParams.get("page");
  const searchStore = serachParams.get("search");
  let storeParams = {
    page_size: 20,
    page: pageStore ?? 1,
    search: searchStore ?? "",
  };

  useEffect(() => {
    dispatch(getListStore(storeParams));
    // eslint-disable-next-line
  }, [pageStore, searchStore]);

  const dataHeader = {
    title: "Tiendas",
    nameButton: "Crear Tienda",
    url: "/configuracion/tiendas/crear",
  };

  return (
    <section className="orderIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <StoreListContainer />
          <PaginationContainer data={listStore} />
        </>
      )}
    </section>
  );
};
