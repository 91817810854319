import { createSlice } from "@reduxjs/toolkit";
export const listClaimSlice = createSlice({
  name: "listClaim",
  initialState: {
    claimData: [],
    showLoading: false,
  },
  reducers: {
    setListClaim: (state, action) => {
      state.claimData = action.payload.data;
    },
    setClaimLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListClaim, setClaimLoading } = listClaimSlice.actions;

export const retrieveClaimSlice = createSlice({
  name: "retrieveClaim",
  initialState: {
    claimData: {
      name: "",
      lastNameFather: "",
      lastNameMother: "",
      district: "",
      typeContracted: "",
      typeClaim: "",
      typeDocument: "",
      numberDocument: "",
      shop: "",
      adress: "",
      phono: "",
      email: "",
      orderNumber: "",
      amount: 0.0,
      descriptionContracted: "",
      descriptionClaim: "",
      consumerRequest: "",
      descriptionSeller: "",
      created: "",
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveClaim: (state, action) => {
      state.claimData.name =
        action.payload?.data?.name || state?.claimData?.name;
      state.claimData.lastNameFather =
        action.payload?.data?.lastNameFather ||
        state?.claimData?.lastNameFather;
      state.claimData.lastNameMother =
        action.payload?.data?.lastNameMother ||
        state?.claimData?.lastNameMother;
      state.claimData.district =
        action.payload?.data?.district || state?.claimData?.district;
      state.claimData.typeContracted =
        action.payload?.data?.typeContracted ||
        state?.claimData?.typeContracted;
      state.claimData.typeClaim =
        action.payload?.data?.typeClaim || state?.claimData?.typeClaim;
      state.claimData.typeDocument =
        action.payload?.data?.typeDocument || state?.claimData?.typeDocument;
      state.claimData.numberDocument =
        action.payload?.data?.numberDocument ||
        state?.claimData?.numberDocument;
      state.claimData.shop =
        action.payload?.data?.shop || state?.claimData?.shop;
      state.claimData.adress =
        action.payload?.data?.adress || state?.claimData?.adress;
      state.claimData.phono =
        action.payload?.data?.phono || state?.claimData?.phono;
      state.claimData.email =
        action.payload?.data?.email || state?.claimData?.email;
      state.claimData.orderNumber =
        action.payload?.data?.orderNumber || state?.claimData?.orderNumber;
      state.claimData.amount =
        action.payload?.data?.amount || state?.claimData?.amount;
      state.claimData.descriptionContracted =
        action.payload?.data?.descriptionContracted ||
        state?.claimData?.descriptionContracted;
      state.claimData.descriptionClaim =
        action.payload?.data?.descriptionClaim ||
        state?.claimData?.descriptionClaim;
      state.claimData.consumerRequest =
        action.payload?.data?.consumerRequest ||
        state?.claimData?.consumerRequest;
      state.claimData.descriptionSeller =
        action.payload?.data?.descriptionSeller ||
        state?.claimData?.descriptionSeller;
      state.claimData.created =
        action.payload?.data?.created || state?.claimData?.created;
    },
    setRetrieveClaimLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveClaim, setRetrieveClaimLoading } =
  retrieveClaimSlice.actions;
