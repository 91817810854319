import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";

export const ClientListFieldsValueComponent = ({ client }) => {
  const url = `/clientes/${client.id}/detalles`;
  const stateColor = orderStateColor(client?.active);
  return (
    <tr className="clientListFieldsValueComponent">
      <td className="clientListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="clientListFieldsValueComponent__code">
        <Link to={url}>{client.codigo}</Link>
      </td>
      <td className="clientListFieldsValueComponent__fullName">
        <Link to={url}>{client.user.fullName}</Link>
      </td>
      <td className="clientListFieldsValueComponent__email">
        <Link to={url}>{client.user.email}</Link>
      </td>
      <td className="clientListFieldsValueComponent__phone">
        <Link to={url}>{client.user.phone}</Link>
      </td>
      <td className={`clientListFieldsValueComponent__active ${stateColor}`}>
        <Link to={url}>{client.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
