import {
  CombosListTableContainer,
  CombosSearchContainer,
} from "../../containers";

export const CombosListContainer = () => {
  return (
    <div className="clientListContainer">
      <div className="clientListContainer__search">
        <CombosSearchContainer />
      </div>
      <div className="clientListContainer__table">
        <CombosListTableContainer />
      </div>
    </div>
  );
};
