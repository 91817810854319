import {
  ProductListTableContainer,
  ProductSearchContainer,
} from "../../containers";

export const ProductListContainer = () => {
  return (
    <div className="productListContainer">
      <div className="productListContainer__search">
        <ProductSearchContainer />
      </div>
      <div className="productListContainer__table">
        <ProductListTableContainer />
      </div>
    </div>
  );
};
