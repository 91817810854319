import { useNavigate } from "react-router-dom";
import { getAbbreviatedFormatDate } from "../../helpers";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
export const PaymentRetrieveStateContainer = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="paymentRetrieveStateContainer">
      <div
        className="paymentRetrieveStateContainer__icon"
        onClick={() => navigate("/pagos")}
      >
        <ArrowLeft />
      </div>
      <div className="paymentRetrieveStateContainer__container">
        <div className="paymentRetrieveStateContainer__container__title">
          <h5>Pago #{data?.code}</h5>
        </div>
        <div className="paymentRetrieveStateContainer__container__text">
          <p className="paymentRetrieveStateContainer__container__text__status">
            Estado: {data?.state}
          </p>
          <p className="paymentRetrieveStateContainer__container__text__date">
            Fecha: {data?.created && getAbbreviatedFormatDate(data?.created)}
          </p>
        </div>
      </div>
    </div>
  );
};
