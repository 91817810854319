import { LoginFormContainer, LoginImageContainer } from "../../containers";

export const LoginLayout = () => {
  return (
    <div className={"login-layout"}>
      <div className={"login-layout__container"}>
        <div className={"login-layout__container__welcome"}>
          <LoginImageContainer />
        </div>
        <div className={"login-layout__container__form"}>
          <div className={"login-layout__container__form__title"}>
            <h2>Iniciar sesión</h2>
          </div>
          <LoginFormContainer />
        </div>
      </div>
    </div>
  );
};
