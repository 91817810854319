import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { setRetrieveEnvironment } from "../../store/environment/environmentSlice";
import { getRetrieveEnvironment } from "../../store/environment/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const SettingEnvironmentInformationContainer = () => {
  const { environmentData, showLoading } = useSelector(
    (state) => state.retrieveEnvironment
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.environmentId) {
      dispatch(getRetrieveEnvironment(path.environmentId));
    }
    // eslint-disable-next-line
  }, []);

  const handleOnchage = (e) => {
    dispatch(
      setRetrieveEnvironment({ data: { [e.target.name]: e.target.value } })
    );
  };
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          <SelectOptionsContainer
            title={"Key"}
            name="key"
            handleOnchange={() => {}}
            isInput={true}
            value={environmentData?.key}
          />
          <SelectOptionsContainer
            title={"Valor"}
            handleOnchange={handleOnchage}
            name="value"
            isNumber={true}
            value={environmentData?.value}
          />
        </div>
      )}
    </>
  );
};
