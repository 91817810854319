import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setQuantityOrderProductDecrement,
  setQuantityOrderProductIncrement,
} from "../../store/order/ordersSlice";

export const SelectCountComponent = ({ indexProduct, selectValue }) => {
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();

  return (
    <div className="selectCountComponent">
      <div className="selectCountComponent__text">{selectValue}</div>
      <div className="selectCountComponent__value">
        <div
          className="selectCountComponent__value__rest"
          onClick={() => {
            if (!(count - 1)) return;
            setCount(count - 1);
            dispatch(
              setQuantityOrderProductDecrement({
                indexProduct,
                quantity: count,
              })
            );
          }}
        >
          -
        </div>
        {count}
        <div
          className="selectCountComponent__value__sum"
          onClick={() => {
            setCount(count + 1);
            dispatch(
              setQuantityOrderProductIncrement({
                indexProduct,
                quantity: count,
              })
            );
          }}
        >
          +
        </div>
      </div>
    </div>
  );
};
