import { ProductDetailFieldsKeyComponent } from "../../components";
import { ProductDetailFieldsValueContainer } from "../../containers";

export const ProductDetailTableContainer = ({
  data,
  isOpen = true,
  isPresentationGroup,
  isPresentation,
  handleOnchage,
  clearGroupCurrent,
}) => {
  return (
    <table className="productDetailTableContainer">
      <ProductDetailFieldsKeyComponent
        isPresentationGroup={isPresentationGroup}
        isPresentation={isPresentation}
      />
      <tbody>
        <ProductDetailFieldsValueContainer
          isPresentationGroup={isPresentationGroup}
          isPresentation={isPresentation}
          data={data}
          handleOnchage={handleOnchage}
          clearGroupCurrent={clearGroupCurrent}
        />
      </tbody>
    </table>
  );
};
