import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CollectionListContainer,
  ListHeaderContainer,
  PaginationContainer,
} from "../../../containers";
import { getListColletions } from "../../../store/collection";
import { LoadingComponent } from "../../../components";
import { useLocation } from "react-router-dom";

export const ProductCollectionIndexPage = () => {
  const { collectionData, showLoading } = useSelector(
    (state) => state.listCollections
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageColllection = serachParams.get("page");
  const activeColllection = serachParams.get("active");
  const searchColllection = serachParams.get("search");

  let collectionsParams = {
    page_size: 20,
    page: pageColllection ?? 1,
    active: activeColllection ?? "",
    search: searchColllection ?? "",
  };

  useEffect(() => {
    dispatch(getListColletions(collectionsParams));
    // eslint-disable-next-line
  }, [pageColllection, activeColllection, searchColllection]);

  const dataHeader = {
    title: "Colecciones",
    nameButton: "Crear Colección",
    url: "/productos/colecciones/crear",
  };
  return (
    <section>
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <CollectionListContainer />
          <PaginationContainer data={collectionData} />
        </>
      )}
    </section>
  );
};
