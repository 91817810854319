import { createSlice } from "@reduxjs/toolkit";
export const listModifierSlice = createSlice({
  name: "listModifiers",
  initialState: {
    modifierData: [],
    modifierParams: {
      page_size: 20,
      page: 1,
      active: "",
      search: "",
    },
    showLoading: false,
  },
  reducers: {
    setListModifier: (state, action) => {
      state.modifierData = action.payload.data;
      state.modifierParams = action.payload.params;
    },
    setModifierLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListModifier, setModifierLoading } =
  listModifierSlice.actions;

export const retrieveModifierSlice = createSlice({
  name: "retrieveModifier",
  initialState: {
    modifierData: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveModifier: (state, action) => {
      state.modifierData = action.payload.data;
    },
    updateRetrieveModifier: (state) => {
      state.modifierData = state;
    },
    setRetrieveModifierLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveModifier, setRetrieveModifierLoading } =
  retrieveModifierSlice.actions;

export const updateModifierSlice = createSlice({
  name: "updateModifier",
  initialState: {
    active: true,
    amount: 0.0,
    name: "",
    order: 1,
  },
  reducers: {
    setUpdateModifier: (state, action) => {
      state.name = action.payload.name || state.name;
      state.amount = action.payload.amount || state.amount;
      state.active = action.payload.active;
      state.order = action.payload.order || state.order;
    },
  },
});
export const { setUpdateModifier } = updateModifierSlice.actions;

export const createModifierSlice = createSlice({
  name: "createModifier",
  initialState: {
    active: true,
    amount: 0.0,
    name: "",
    order: 1,
  },
  reducers: {
    setCreateModifier: (state, action) => {
      state.name = action.payload.name || state.name;
      state.amount = action.payload.amount || state.amount;
      state.active = action.payload.active;
      state.order = action.payload.order || state.order;
    },
    cleanCreateModifier: (state) => {
      state.name = "";
      state.amount = 0.0;
      state.active = true;
      state.order = 1;
    },
  },
});
export const { setCreateModifier, cleanCreateModifier } =
  createModifierSlice.actions;

export const listOptionsModifierSlice = createSlice({
  name: "listOptionsModifier",
  initialState: {
    listOptionsModifier: [],
  },
  reducers: {
    setListOptionsModifier: (state, action) => {
      state.listOptionsModifier = action.payload.data;
    },
  },
});
export const { setListOptionsModifier } = listOptionsModifierSlice.actions;
