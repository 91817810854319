import { useState } from "react";
import { ModalMapContainer } from "../../containers";
import { ModalLayout } from "../../layouts";

export const ClientRetrieveAddressFieldsValueComponent = ({ address }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  return (
    <tr className="clientRetrieveAddressFieldsValueComponent">
      <td className="clientRetrieveAddressFieldsValueComponent__address">
        {address.address}
      </td>
      <td className="clientRetrieveAddressFieldsValueComponent__reference">
        {address.reference}
      </td>
      <td className="clientRetrieveAddressFieldsValueComponent__latitude">
        {address.latitude}
      </td>
      <td className="clientRetrieveAddressFieldsValueComponent__longitude">
        {address.longitude}
      </td>
      <td className="clientRetrieveAddressFieldsValueComponent__first">
        {address.first ? "Si" : "No"}
      </td>
      <td className="clientRetrieveAddressFieldsValueComponent__map">
        <button onClick={onClose}>Ver mapa</button>
      </td>
      <td>
        {isOpen && (
          <ModalLayout>
            <ModalMapContainer
              latitude={address.latitude}
              longitude={address.longitude}
              address={address.address}
              onClose={onClose}
            />
          </ModalLayout>
        )}
      </td>
    </tr>
  );
};
