export const InputSearchNavComponent = ({
  id,
  className = "inputSearchComponent",
  placeholder = "Escribe el producto",
  type = "text",
  handleSearch,
  handleEnter,
  textSearch,
  name,
}) => {
  return (
    <div className={className}>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={handleSearch}
        onKeyDown={handleEnter}
        value={textSearch}
        name={name}
      />
    </div>
  );
};

