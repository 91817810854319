import { createSlice } from "@reduxjs/toolkit";
export const listQuestionsSlice = createSlice({
  name: "listQuestions",
  initialState: {
    questionsData: [],
    showLoading: false,
  },
  reducers: {
    setListQuestions: (state, action) => {
      state.questionsData = action.payload.data;
    },
    setQuestionsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListQuestions, setQuestionsLoading } =
  listQuestionsSlice.actions;

export const retrieveQuestionsSlice = createSlice({
  name: "retrieveQuestions",
  initialState: {
    questionsData: {
      name: "",
      order: 0,
      active: "",
      questions: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveQuestions: (state, action) => {
      state.questionsData.name =
        action.payload?.data?.name ?? state?.questionsData?.name;
      state.questionsData.order =
        action.payload?.data?.order ?? state?.questionsData?.order;
      state.questionsData.active =
        action.payload?.data?.active ?? state?.questionsData?.active;
      state.questionsData.questions =
        action.payload?.data?.questions || state?.questionsData?.questions;
    },
    setRetrieveQuestionsItem: (state, action) => {
      const { index } = action.payload;
      const key = Object.keys(action.payload)[0];
      state.questionsData.questions[index][key] = action.payload[key];
    },
    setRetrieveAnswers: (state, action) => {
      const { index } = action.payload;
      const { data } = action.payload;
      const { indexAnswer } = action.payload;

      state.questionsData.questions[index].answers[indexAnswer].description =
        data;
    },
    setRetrieveAnswersOrder: (state, action) => {
      const { index } = action.payload;
      const { data } = action.payload;
      const { indexAnswer } = action.payload;

      state.questionsData.questions[index].answers[indexAnswer].order = data;
    },
    setRetrieveAnswersActive: (state, action) => {
      const { index } = action.payload;
      const { data } = action.payload;
      const { indexAnswer } = action.payload;

      state.questionsData.questions[index].answers[indexAnswer].active = data;
    },
    setAddQuestion: (state) => {
      const obj = {
        id: null,
        name: "",
        order: state.questionsData.questions.length + 1,
        active: false,
        answers: [
          {
            id: null,
            description: "",
            order: 1,
            active: false,
          },
        ],
      };
      state.questionsData.questions.push(obj);
    },
    setAddAnswers: (state, action) => {
      const { index } = action.payload;
      const obj = {
        id: null,
        description: "",
        order: state.questionsData.questions[index].answers.length + 1,
        active: false,
      };
      state.questionsData.questions[index].answers.push(obj);
    },
    setRetrieveQuestionsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveQuestions,
  setRetrieveQuestionsItem,
  setRetrieveAnswers,
  setAddQuestion,
  setRetrieveAnswersOrder,
  setRetrieveAnswersActive,
  setAddAnswers,
  setRetrieveQuestionsLoading,
} = retrieveQuestionsSlice.actions;
