import { TableListFieldsKeyComponent } from "../../components";
import {} from "../CombosListFieldsValueContainer/CombosListFieldsValueContainer";
import { TableListFieldsValueContainer } from "../TableListFieldsValueContainer/TableListFieldsValueContainer";

export const TableListContainer = ({ data, url, titles }) => {
  return (
    <table className="clientListTableContainer">
      <TableListFieldsKeyComponent titles={titles} />
      <TableListFieldsValueContainer data={data} url={url} />
    </table>
  );
};
