import MultipleSelectChip from "../SelectChipContainer/SelectChipContainer";
// import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const ComboDetailTableBodyContainer = ({
  name,
  id,
  number,
  order,
  products,
  listDataProducts,
  onChange = () => {},
  onChangeInput,
  index,
}) => {
  return (
    <tr className="comboDetailGroupsContainer__table__body__row">
      <td className="comboDetailGroupsContainer__table__body__row__name">
        <input
          value={name}
          onChange={(e) => onChangeInput(e, index)}
          name="name"
        />
      </td>
      <td className="comboDetailGroupsContainer__table__body__row__products">
        <MultipleSelectChip
          listOptions={listDataProducts}
          data={products}
          name={name}
          handleChange={(e) => onChange(e, index)}
          placeholderTitle="Elije uno o varios canales"
          newStyle={true}
        />
      </td>
      <td className="comboDetailGroupsContainer__table__body__row__quantity">
        <input
          value={number}
          onChange={(e) => onChangeInput(e, index)}
          type="number"
          name="number"
        />
      </td>
      <td className="comboDetailGroupsContainer__table__body__row__order">
        <input
          value={order}
          onChange={(e) => onChangeInput(e, index)}
          type="number"
          name="order"
        />
      </td>
      <td className="comboDetailGroupsContainer__table__body__row__id">{id}</td>
      <td className="comboDetailGroupsContainer__table__body__row__delete">
        <input type="checkbox" />
      </td>
    </tr>
  );
};
