import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";

export const CombosListFieldsValueComponent = ({ combo }) => {
  const url = `/combos/${combo.id}/detalles`;
  const stateColor = orderStateColor(combo?.active);
  return (
    <tr className="combosListFieldsValueComponent ">
      <td className="combosListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="combosListFieldsValueComponent__code">
        <Link to={url}>{combo.codigo}</Link>
      </td>
      <td className="combosListFieldsValueComponent__fullName">
        <Link to={url}>{combo.name}</Link>
      </td>
      <td className="combosListFieldsValueComponent__email">
        <Link to={url}>{combo.slug}</Link>
      </td>
      <td className="productListFieldsValueComponent__image">
        <Link to={url}>
          <img src={combo?.imageMini} alt={combo?.name} />
        </Link>
      </td>
      <td className="combosListFieldsValueComponent__phone">
        <Link to={url}>{combo.price}</Link>
      </td>
      <td className={`combosListFieldsValueComponent__active ${stateColor}`}>
        <Link to={url}>{combo.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
