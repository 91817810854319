import React from "react";
import { OrderListFieldsKeyComponent } from "../../components";
import { OrderListFieldsValueContainer } from "../../containers";

export const OrderListTableContainer = () => {
  return (
    <table className="orderListTableContainer">
      <OrderListFieldsKeyComponent />
      <OrderListFieldsValueContainer />
    </table>
  );
};
