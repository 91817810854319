import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setContactLoading,
  setListContact,
  setRetrieveContact,
  setRetrieveContactLoading,
} from "./contactSlice";

const obj = {
  page_size: 20,
  page: 1,
  search: "",
};

export const getListContact = (params = obj) => {
  return async (dispatch) => {
    dispatch(setContactLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/contactos/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "configuracion/landing/contacto";
        value.text = "Contact";
        value.page = params.page;
        dispatch(setListContact({ data: value, params: params }));
        dispatch(setContactLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveContact = (contactId) => {
  return async (dispatch) => {
    dispatch(setRetrieveContactLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/contactos/${contactId}/`, token).then(
      (value) => {
        dispatch(setRetrieveContact({ data: value }));
        dispatch(setRetrieveContactLoading({ showLoading: false }));
      }
    );
  };
};
