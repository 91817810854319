import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setEnvironmentLoading,
  setListEnvironment,
  setRetrieveEnvironment,
  setRetrieveEnvironmentLoading,
} from "./environmentSlice";

const obj = {
  page_size: 20,
  page: 1,
  search: "",
};

export const getListEnvironment = (params = obj) => {
  return async (dispatch) => {
    dispatch(setEnvironmentLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization(
      "admin/variablesEntornos/",
      params,
      token
    ).then((value) => {
      value.pageSize = params.page_size;
      value.redirectUrl = "ordenes";
      value.text = "Ordenes";
      value.page = params.page;
      dispatch(setListEnvironment({ data: value }));
      dispatch(setEnvironmentLoading({ showLoading: false }));
    });
  };
};

export const getRetrieveEnvironment = (environmentId) => {
  return async (dispatch) => {
    dispatch(setRetrieveEnvironmentLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/variablesEntornos/${environmentId}/`,
      token
    ).then((value) => {
      dispatch(setRetrieveEnvironment({ data: value }));
      dispatch(setRetrieveEnvironmentLoading({ showLoading: false }));
    });
  };
};
