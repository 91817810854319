export const CouponListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="clientListFieldsKeyComponent">
        <th className="clientListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="clientListFieldsKeyComponent__fullName">Nombre</th>
        <th className="clientListFieldsKeyComponent__code">Código</th>
        <th className="clientListFieldsKeyComponent__email">Tipo de Cupon</th>
        <th className="clientListFieldsKeyComponent__code">Descuento</th>
        <th className="clientListFieldsKeyComponent__phone">Tipo Descuento</th>
      </tr>
    </thead>
  );
};
