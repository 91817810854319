import React from "react";

export const ClientRetrieveAddressFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="clientRetrieveAddressFieldsKeyComponent">
        <th className="clientRetrieveAddressFieldsKeyComponent__address">
          Dirección
        </th>
        <th className="clientRetrieveAddressFieldsKeyComponent__reference">
          Referencia
        </th>
        <th className="clientRetrieveAddressFieldsKeyComponent__latitude">
          Latitud
        </th>
        <th className="clientRetrieveAddressFieldsKeyComponent__longitude">
          Longitud
        </th>
        <th className="clientRetrieveAddressFieldsKeyComponent__first">
          ¿Es el principal?
        </th>
        <th className="clientRetrieveAddressFieldsKeyComponent__map">
          Mapa
        </th>
      </tr>
    </thead>
  );
};
