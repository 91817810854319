import { useNavigate } from "react-router-dom";

export const OrderDetailUserCustomerContainer = ({ data }) => {
  const navigate = useNavigate();
  const id = data?.client?.id;
  const url = `/clientes/${id}/detalles`;
  const navigateToUserDetail = () => {
    navigate(url);
  };

  return (
    <div className="orderDetailUserCustomerContainer">
      <div className="orderDetailUserCustomerContainer__title">
        <p>Cliente</p>
      </div>
      <div
        className="orderDetailUserCustomerContainer__container"
        onClick={navigateToUserDetail}
      >
        <div className="orderDetailUserCustomerContainer__container__image">
          <img src="https://i.ibb.co/gm0F52m/5.png" alt="img" />
        </div>
        <div className="orderDetailUserCustomerContainer__container__description">
          <p className="orderDetailUserCustomerContainer__container__description__text">
            {data?.client?.user?.names &&
              data?.client?.user?.surnames &&
              `${data?.client?.user?.names} ${data?.client?.user?.surnames}`}
          </p>
          <p className="orderDetailUserCustomerContainer__container__description__email">
            {data?.client?.user?.email}
          </p>
        </div>
      </div>
    </div>
  );
};
