const calculatePageInformation = (page, pageSize, count) => {
  let totalCount = page * pageSize;
  if (totalCount > count) {
    totalCount = count;
  }
  return `${pageSize * (page - 1) + 1} - ${totalCount}`;
};
export const ShowingComponent = ({ count, data }) => {
  const pageInformation = calculatePageInformation(
    data?.page,
    data?.pageSize,
    count
  );
  return (
    <div className="showingComponent">
      <p>
        {pageInformation !== "NaN - NaN"
          ? `${data?.text} ${pageInformation} de ${count}`
          : ""}
      </p>
    </div>
  );
};
