import React from "react";

export const ComboDetailTableHeadContainer = () => {
  return (
    <thead className="comboDetailGroupsContainer__table__header">
      <tr className="comboDetailGroupsContainer__table__header__row">
        <th className="comboDetailGroupsContainer__table__header__row__name">
          Nombre
        </th>
        <th className="comboDetailGroupsContainer__table__header__row__products">
          Productos
        </th>
        <th className="comboDetailGroupsContainer__table__header__row__quantity">
          Cantidad
        </th>
        <th className="comboDetailGroupsContainer__table__header__row__order">
          Order
        </th>
        <th className="comboDetailGroupsContainer__table__header__row__id">
          ID
        </th>
        <th className="comboDetailGroupsContainer__table__header__row__delete">
          ¿Eliminar?
        </th>
      </tr>
    </thead>
  );
};
