import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { getRetrieveReservation } from "../../store/reservation/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { getFormatDate } from "../../helpers";

export const SettingLandingReservationInformationContainer = () => {
  const { reservationData, showLoading } = useSelector(
    (state) => state.retrieveReservation
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.reservationId) {
      dispatch(getRetrieveReservation(path.reservationId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          <SelectOptionsContainer
            title={"Nombre"}
            name="name"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.name}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Documento"}
            name="document"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.document}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Correo"}
            name="email"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.email}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Telefono"}
            name="phone"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.phone}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Estado"}
            name="state"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.state}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Tienda"}
            name="shop"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.shop}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Fecha de creación"}
            name="shop"
            handleOnchange={() => {}}
            isInput={true}
            value={getFormatDate(reservationData?.created)}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Cita"}
            name="date"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.date}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Hora"}
            name="time"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.time}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"N° de Personas"}
            name="numberPerson"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.numberPerson}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Razón de la Reservación"}
            name="reservationReason"
            handleOnchange={() => {}}
            isInput={true}
            value={reservationData?.reservationReason}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Descripción"}
            name="description"
            handleOnchange={() => {}}
            isTextArea={true}
            value={reservationData?.description}
            readOnly={true}
          />
        </div>
      )}
    </>
  );
};
