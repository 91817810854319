import { apiPath } from "../helpers";

export const requestUpdate = async (url, body) => {
  try {
    const response = await apiPath.put(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestUpdateAuthorization = async (url, body, token) => {
  try {
    const response = await apiPath.put(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestUpdateFileAuthorization = async (url, body, token) => {
  try {
    const response = await apiPath.put(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};