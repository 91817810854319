import { apiPath } from "../helpers";
const tokenUser = localStorage.getItem("token");
export const requestPatch = async (url, body) => {
  try {
    const response = await apiPath.patch(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestPatchAuthorization = async (
  url,
  body,
  token = tokenUser
) => {
  try {
    const response = await apiPath.patch(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestPatchFileAuthorization = async (
  url,
  body,
  token = tokenUser
) => {
  try {
    const response = await apiPath.patch(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};
