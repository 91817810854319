export const NoteListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="noteListFieldsKeyComponent">
        <th className="noteListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="noteListFieldsKeyComponent__name">Nombre</th>
        <th className="noteListFieldsKeyComponent__amount">
          Cantidad
        </th>
        <th className="noteListFieldsKeyComponent__state">Estado</th>
      </tr>
    </thead>
  );
};
