import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientRetrieveOrderFieldsValueComponent } from "../../components";
import { getRetrieveClientOrders } from "../../store/client";

export const ClientRetrieveOrderFieldsValueContainer = ({ clientId }) => {
  const { lisOrders } = useSelector((state) => state.retrieveClient);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRetrieveClientOrders(clientId));
    // eslint-disable-next-line
  }, []);
  return (
    <tbody>
      {lisOrders?.length > 0 &&
        lisOrders?.map((order) => {
          return (
            <ClientRetrieveOrderFieldsValueComponent
              key={order.id}
              order={order}
            />
          );
        })}
    </tbody>
  );
};
