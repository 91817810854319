import { apiPath } from "../helpers";

export default class CoverageService {
  static async createCoverage(body, token) {
    const response = await apiPath.post("admin/coberturas/", body, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  }

  static async getListCoverages(shopId, token) {
    const response = await apiPath.get(`admin/tiendas/${shopId}/coberturas/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data.results;
    }
    return [];
  }

  static async getListCoordinates(coverageId, token) {
    const response = await apiPath.get(`admin/coberturas/${coverageId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const listCoordinates = response.data.coordenadas;
      const newListCoordinates = listCoordinates.map((coordinate) => {
        return {
          lat: parseFloat(coordinate.latitude),
          lng: parseFloat(coordinate.longitude),
        };
      });
      return newListCoordinates;
    }
    return [];
  }
  static async deleteCoverage(coverageId, token) {
    const response = await apiPath.delete(`admin/coberturas/${coverageId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return;
    }
    return [];
  }
  static async updateCoverage(coverageId, token,body) {
    const response = await apiPath.patch(`admin/coberturas/${coverageId}/`,body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return;
    }
    return [];
  }
}
