import { getListOptions } from "../../helpers";
import { requestGetAuthorization } from "../../services/requestGet";
import { setListOptionChannel } from "./channelSlice";

export const getListOptionChannel = () => {
  return async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/canales/lista_opciones/`, token).then(
      (value) => {
        const inputParamsData = [value, "channels", "name", "id"];
        const listOptionChannel = getListOptions(inputParamsData);
        dispatch(setListOptionChannel({ data: listOptionChannel }));
      }
    );
  };
};
