import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListOptionsCollection } from "../../store/collection";
import { setUpdateProduct } from "../../store/product";
import MultipleSelectChip from "../SelectChipContainer/SelectChipContainer";
import { getListOptions } from "../../helpers";

export const ProductDetailInformationSecondContainer = ({
  data,
  optionsTags,
  optionsComplement,
}) => {
  const { listOptionsCollection } = useSelector(
    (state) => state.listOptionsCollection
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptionsCollection());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnchageMultiple = (e, name) => {
    dispatch(setUpdateProduct({ [name]: e.map((item) => item.value) }));
  };

  const processListTags = getListOptions([data.tags, "tags", "name"]);
  const processListCollection = getListOptions([
    data.colections,
    "collections",
    "name",
  ]);

  const processListComplements = getListOptions([
    data.complements,
    "complements",
    "name",
  ]);

  return (
    <div className="productDetailMainContainer__information-second">
      <div className="productDetailMainContainer__information-second__title">
        Información secundaria
      </div>
      <div className="productDetailMainContainer__information-second__container">
        <div className="productDetailMainContainer__information-second__container__item">
          <div className="productDetailMainContainer__information-second__container__item">
            <div className="productDetailMainContainer__information-second__container__item__title">
              Colecciones
            </div>
            <div className="productDetailMainContainer__information-second__container__item__description">
              <MultipleSelectChip
                listOptions={listOptionsCollection}
                data={processListCollection}
                handleChange={handleOnchageMultiple}
                name="colections"
                placeholderTitle="Elije uno o varias colecciones"
              />
            </div>
          </div>

          <div className="productDetailMainContainer__information-second__container__item">
            <div className="productDetailMainContainer__information-second__container__item__title">
              Tags
            </div>
            <div className="productDetailMainContainer__information-second__container__item__description">
              <MultipleSelectChip
                listOptions={optionsTags}
                data={processListTags}
                handleChange={handleOnchageMultiple}
                name="tags"
                placeholderTitle="Elije uno o varios tags"
              />
            </div>
          </div>

          <div className="productDetailMainContainer__information-second__container__item__title">
            Complementar pedido
          </div>
          <div className="productDetailMainContainer__information-second__container__item__description">
            <MultipleSelectChip
              listOptions={optionsComplement}
              data={processListComplements}
              handleChange={handleOnchageMultiple}
              name="complements"
              placeholderTitle="Elije uno o varios complementos"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
