import { Link, useParams } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
import { AiFillDelete } from "react-icons/ai";

export const StoreCoverageListFieldsValueComponent = ({
  store,
  handleClick,
}) => {
  const params = useParams();
  const url = `/configuracion/tiendas/${params.storeId}/coberturas/${store.id}/detalles`;
  const stateColor = orderStateColor(store?.active);

  return (
    <tr className="storeListFieldsValueComponent">
      <td className="storeListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="storeListFieldsValueComponent__name">
        <Link to={url}>{store.name}</Link>
      </td>
      <td className={`storeListFieldsValueComponent__active ${stateColor}`}>
        <Link to={url}>{store.active ? "Activo" : "Inactivo"}</Link>
      </td>
      <td className="storeListFieldsValueComponent__icon">
        <AiFillDelete
          size={"20"}
          onClick={() => handleClick(store.id, store.name)}
        />
      </td>
    </tr>
  );
};
