import { useState } from "react";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import dias from "../../data/dataWeek.json";
import meses from "../../data/dataMonth.json";
import DatePicker from "react-multi-date-picker";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { useEffect } from "react";
import {
  FilterComponent,
  LoadingComponent,
  TitleComponent,
} from "../../components";
import { useDate } from "../../hooks/useDate";
import {
  getListOrdersByDate,
  getListOrdersClients,
  getListOrdersDelivery,
  getListOrdersLocalMir,
  getListOrdersLocalPue,
  getListOrdersStates,
  getListOrdersStore,
  getListTopClients,
  getListTopPayment,
  getListTopProducts,
} from "../../store/statistics/thunks";
import modalities from "../../data/modality.json";
import stores from "../../data/store.json";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataStatistics,
  getDataStatisticsOther,
} from "../../helpers/getDataStatistics";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import orderStatus from "../../data/orderStatus.json";
import { changeDateFormat, changeDateFormatPlaceholder } from "../../helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { initialDateOrderSales } from "../../store/statistics/statisticsSlice";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  BarElement,
  Tooltip,
  Legend
);

export const HomeContainer = () => {
  const [dataModality, setDataModality] = useState(null);
  const [dataOrdersClients, setDataOrdersClients] = useState(null);
  const [dataLocal, setDataLocal] = useState(null);
  const [dataOrdersStates, setDataOrdersStates] = useState(null);
  const [dataOrdersTopClients, setDataOrdersTopClients] = useState(null);
  const [dataOrdersTopProducts, setDataOrdersTopProducts] = useState(null);
  const [dataOrdersTopPayment, setDataOrdersTopPayment] = useState(null);
  const [dataOrders, setDataOrders] = useState(null);

  const [orderStatusChange, setOrderStatusChange] = useState(null);
  const [orderModalityChange, setOrderModalityChange] = useState(null);
  const [orderStoreChange, setOrderStoreChange] = useState(null);

  const [dateModality, setDateModality] = useDate();
  const [dateLocal, setDateLocal] = useDate();
  const [dateOrders, setDateOrders] = useDate();
  const [dateOrdersStates, setDateOrdersStates] = useDate();
  const [dateOrdersClients, setDateOrdersClients] = useDate();
  const [dateTopClients, setDateTopClients] = useDate();
  const [dateOrdersProducts, setDateOrdersProducts] = useDate();
  const [dateOrdersPayment, setDateOrdersPayment] = useDate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialDateOrderSales());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentDate = changeDateFormat(new Date());
  let currentDatePlaceholder = changeDateFormatPlaceholder(new Date());
  let pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 15);
  let pastDatePlaceholder = changeDateFormatPlaceholder(pastDate);
  pastDate = changeDateFormat(pastDate);

  let widthResponsive = useWindowWidth();
  const {
    dataStatisticsDelivery,
    dataStatisticsStore,
    showLoading: showStaticLoading,
  } = useSelector((state) => state.listOrdersModality);
  const { dataStatisticsLocalMir, dataStatisticsLocalPue } = useSelector(
    (state) => state.listOrdersLocal
  );
  const { dataStatisticsClients, showLoading: showOrderClientsLoading } =
    useSelector((state) => state.listOrdersClients);
  const {
    dataStatisticsOrdersStates,
    showLoading: showStatisticsOrdersStatesLoading,
  } = useSelector((state) => state.listOrdersStates);
  const { dataStatisticsOrders, showLoading: showOrdersByDateLoading } =
    useSelector((state) => state.listOrdersByDate);
  const { dataTopClients, showLoading } = useSelector(
    (state) => state.listOrdersTopClients
  );
  const { dataTopProducts, showLoading: loadingTopProduct } = useSelector(
    (state) => state.listOrdersTopProducts
  );
  const { dataTopPayment, showLoading: loadingTopPayment } = useSelector(
    (state) => state.listOrdersTopPayment
  );

  useEffect(() => {
    const dataFormtStatisticsDelivery = getDataStatistics(
      dataStatisticsDelivery
    );
    const dataFormtStatisticsStore = getDataStatistics(dataStatisticsStore);
    setDataModality(
      {
        labels: dataFormtStatisticsDelivery?.map((item) => item.label),
        datasets: [
          {
            label: "Delivery",
            data: dataFormtStatisticsDelivery?.map((item) => item.count),
            backgroundColor: ["rgba(113, 102, 255, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            // pointRadius: 6,
          },
          {
            label: "En Tienda",
            data: dataFormtStatisticsStore?.map((item) => item.count),
            backgroundColor: ["rgba(255, 99, 132, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            // pointRadius: 6,
          },
        ],
      },
      []
    );
  }, [dataStatisticsDelivery, dataStatisticsStore]);

  useEffect(() => {
    const dataFormtStatisticsLocalMir = getDataStatistics(
      dataStatisticsLocalMir
    );
    const dataFormtStatisticsLocalPue = getDataStatistics(
      dataStatisticsLocalPue
    );
    setDataLocal(
      {
        labels: dataFormtStatisticsLocalMir?.map((item) => item.label),
        datasets: [
          {
            label: "Miraflores",
            data: dataFormtStatisticsLocalMir?.map((item) => item.count),
            backgroundColor: ["rgba(113, 102, 255, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            // pointRadius: 6,
          },
          {
            label: "Pueblo Libre",
            data: dataFormtStatisticsLocalPue?.map((item) => item.count),
            backgroundColor: ["rgba(255, 99, 132, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            // pointRadius: 6,
          },
        ],
      },
      []
    );
  }, [dataStatisticsLocalMir, dataStatisticsLocalPue]);

  useEffect(() => {
    const dataFormtStatisticsOrders = getDataStatistics(
      dataStatisticsOrders,
      31,
      12
    );
    const dataFormtStatisticsOrdersAmount = getDataStatisticsOther(
      dataStatisticsOrders,
      31,
      12
    );
    setDataOrders(
      {
        datasets: [
          {
            label: "Cantidad Total",
            data: dataFormtStatisticsOrders?.map((item) => item.count),
            backgroundColor: ["rgba(113, 102, 255, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            yAxisID: "y",
          },
          {
            label: "Monto Total",
            data: dataFormtStatisticsOrdersAmount?.map((item) => item.count),
            backgroundColor: ["rgba(255, 99, 132, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            yAxisID: "y1",
          },
        ],
        labels: dataFormtStatisticsOrders?.map((item) => item.label),
      },
      []
    );
  }, [dataStatisticsOrders]);

  useEffect(() => {
    const dataFormtStatisticsClients = getDataStatistics(dataStatisticsClients);
    setDataOrdersClients(
      {
        labels: dataFormtStatisticsClients?.map((item) => item.label),
        datasets: [
          {
            label: "Clientes vs Tiempo",
            data: dataFormtStatisticsClients?.map((item) => item.count),
            backgroundColor: ["rgba(113, 102, 255, 1)"],
            hoverBackgroundColor: ["#CF1317"],
            barThickness: 10,
            // pointRadius: 6,
          },
        ],
      },
      []
    );
  }, [dataStatisticsClients]);

  useEffect(() => {
    setDataOrdersStates(
      {
        labels: dataStatisticsOrdersStates?.map((item) => item.state),
        datasets: [
          {
            label: "Ordernes según Estados vs Tiempo",
            data: dataStatisticsOrdersStates?.map((item) => item.count),
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(201, 203, 207, 1)",
            ],
          },
        ],
      },
      []
    );
  }, [dataStatisticsOrdersStates]);

  const optionsDoughnut = {
    cutout: 0,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr?.map((data) => {
            sum += data;
            return null;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          if (value > 0) {
            return percentage;
          }
        },
        color: "#FFFFFF",
      },
    },
  };

  useEffect(() => {
    setDataOrdersTopPayment(
      {
        labels: dataTopPayment?.map((item) => item.name),
        datasets: [
          {
            label: "Top Metodos de Pago",
            data: dataTopPayment?.map((item) => item.count),
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
            ],
          },
        ],
      },
      []
    );
  }, [dataTopPayment]);

  useEffect(() => {
    setDataOrdersTopClients(
      {
        labels: dataTopClients?.map((item) => item.name),
        datasets: [
          {
            label: "Top de Clientes",
            data: dataTopClients?.map((item) => item.count),
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(201, 203, 207, 1)",
            ],
          },
        ],
      },
      []
    );
  }, [dataTopClients]);

  useEffect(() => {
    setDataOrdersTopProducts(
      {
        labels: dataTopProducts?.map((item) => item.name),
        datasets: [
          {
            label: "Top de Productos",
            data: dataTopProducts?.map((item) => item.count),
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(201, 203, 207, 1)",
            ],
          },
        ],
      },
      []
    );
  }, [dataTopProducts]);

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    y: {
      ticks: {
        font: {
          size: widthResponsive.width < 500 ? 5.9 : 10,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
  };
  const optionsAxisY = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          suggestedMin: 0,
        },
      },
    },
  };

  const options2 = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          suggestedMin: 0,
        },
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
        },
        type: "linear",
        display: true,
        position: "right",

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
  };

  useEffect(() => {
    const body = {
      startDate: dateModality[0] ?? pastDate,
      endDate: dateModality[1] ?? currentDate,
    };

    dispatch(getListOrdersDelivery(body));
    dispatch(getListOrdersStore(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateModality]);

  useEffect(() => {
    const body = {
      startDate: dateLocal[0] ?? pastDate,
      endDate: dateLocal[1] ?? currentDate,
    };

    dispatch(getListOrdersLocalMir(body));
    dispatch(getListOrdersLocalPue(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateLocal]);

  useEffect(() => {
    const validStoreValues = {
      Miraflores: 1,
      "Pueblo Libre": 2,
    };
    const defaultStoreValue = null;

    const body = {
      startDate: dateOrders[0] ?? pastDate,
      endDate: dateOrders[1] ?? currentDate,
      state: orderStatusChange,
      modality: orderModalityChange,
      shop: validStoreValues[orderStoreChange] ?? defaultStoreValue,
    };
    const cleanedBody = Object.fromEntries(
      Object.entries(body).filter(
        ([_, value]) => value !== null && value !== "null"
      )
    );
    dispatch(getListOrdersByDate(cleanedBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrders, orderStatusChange, orderModalityChange, orderStoreChange]);

  useEffect(() => {
    const body = {
      startDate: dateOrdersClients[0] ?? pastDate,
      endDate: dateOrdersClients[1] ?? currentDate,
    };
    dispatch(getListOrdersClients(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrdersClients]);

  useEffect(() => {
    const body = {
      startDate: dateOrdersStates[0] ?? pastDate,
      endDate: dateOrdersStates[1] ?? currentDate,
    };
    dispatch(getListOrdersStates(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrdersStates]);

  useEffect(() => {
    const body = {
      startDate: dateTopClients[0] ?? pastDate,
      endDate: dateTopClients[1] ?? currentDate,
    };
    dispatch(getListTopClients(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTopClients]);

  useEffect(() => {
    const body = {
      startDate: dateOrdersProducts[0] ?? pastDate,
      endDate: dateOrdersProducts[1] ?? currentDate,
    };
    dispatch(getListTopProducts(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrdersProducts]);

  useEffect(() => {
    const body = {
      startDate: dateOrdersPayment[0] ?? pastDate,
      endDate: dateOrdersPayment[1] ?? currentDate,
    };
    dispatch(getListTopPayment(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrdersPayment]);

  const handleChange = (setState) => (e) => {
    setState(e.target.value);
  };

  const handleChangeOrderStatus = handleChange(setOrderStatusChange);
  const handleChangeOrderModality = handleChange(setOrderModalityChange);
  const handleChangeOrderStore = handleChange(setOrderStoreChange);

  return (
    <div className="homeContainer">
      <TitleComponent title={"Dashboard"} />

      <div className="homeContainer__buttons">
        <a
          href="https://www.panatelier.com.pe/"
          target="_blank"
          rel="noreferrer"
        >
          <p>Ir a Pan Atelier</p>
        </a>
        <a
          href="https://www.cafeteria.panatelier.com.pe/"
          target="_blank"
          rel="noreferrer"
        >
          <p>Ir a Cafeteria Atelier</p>
        </a>
        <a
          href="https://www.bodega.panatelier.com.pe/"
          target="_blank"
          rel="noreferrer"
        >
          <p>Ir a Bodega Atelier</p>
        </a>
      </div>

      <div className="homeContainer__dashboard">
        <div className="homeContainer__dashboard__container">
          <div className="homeContainer__dashboard__container__figure">
            <div className="homeContainer__dashboard__container__figure__bar">
              {!showLoading ? (
                <div className="homeContainer__dashboard__container__figure__loading">
                  <LoadingComponent />
                </div>
              ) : (
                <>
                  <h3>TOP 10 CLIENTES</h3>
                  <div>
                    <DatePicker
                      months={meses}
                      weekDays={dias}
                      onChange={setDateTopClients}
                      range
                      rangeHover
                      format="DD/MM/YYYY"
                      placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                    />
                  </div>
                  {!!dataOrdersTopClients && (
                    <Bar options={options} data={dataOrdersTopClients} />
                  )}
                </>
              )}
            </div>
            <div className="homeContainer__dashboard__container__figure__bar">
              {!loadingTopProduct ? (
                <div className="homeContainer__dashboard__container__figure__loading">
                  <LoadingComponent />
                </div>
              ) : (
                <>
                  <h3>TOP 10 PRODUCTOS</h3>
                  <DatePicker
                    months={meses}
                    weekDays={dias}
                    onChange={setDateOrdersProducts}
                    range
                    rangeHover
                    format="DD/MM/YYYY"
                    placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                  />
                  {!!dataOrdersTopProducts && (
                    <Bar options={options} data={dataOrdersTopProducts} />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="homeContainer__dashboard__container__figure">
            <div className="homeContainer__dashboard__container__figure__bar">
              {!showStaticLoading ? (
                <div className="homeContainer__dashboard__container__figure__loading">
                  <LoadingComponent />
                </div>
              ) : (
                <>
                  <h3>TOTAL ORDENES POR MODALIDAD DE ENTREGA</h3>
                  <div>
                    <DatePicker
                      months={meses}
                      weekDays={dias}
                      onChange={setDateModality}
                      range
                      rangeHover
                      format="DD/MM/YYYY"
                      placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                    />
                  </div>
                  {!!dataModality && (
                    <Bar data={dataModality} options={optionsAxisY} />
                  )}
                </>
              )}
            </div>
            <div className="homeContainer__dashboard__container__figure__bar">
              <h3>TOTAL ORDENES POR LOCAL</h3>
              <DatePicker
                months={meses}
                weekDays={dias}
                onChange={setDateLocal}
                range
                rangeHover
                format="DD/MM/YYYY"
                placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
              />
              {!!dataLocal && <Bar data={dataLocal} options={optionsAxisY} />}
            </div>
          </div>
          <div className="homeContainer__dashboard__container__line">
            {!showOrdersByDateLoading ? (
              <div className="homeContainer__dashboard__container__line__loading">
                <LoadingComponent />
              </div>
            ) : (
              <>
                <h3>VENTAS Y ORDENES TOTALES</h3>
                <div className="homeContainer__dashboard__container__line__container">
                  <DatePicker
                    months={meses}
                    weekDays={dias}
                    onChange={setDateOrders}
                    range
                    rangeHover
                    format="DD/MM/YYYY"
                    placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                  />
                  <div className="homeContainer__dashboard__container__line__container__filters">
                    <FilterComponent
                      data={orderStatus}
                      name={"Estado"}
                      id={"clientActive"}
                      onchange={handleChangeOrderStatus}
                    />
                    <FilterComponent
                      data={modalities}
                      name={"Modalidad"}
                      id={"orderModality"}
                      onchange={handleChangeOrderModality}
                    />
                    <FilterComponent
                      data={stores}
                      name={"Tienda"}
                      id={"orderShop"}
                      onchange={handleChangeOrderStore}
                    />
                  </div>
                </div>
                <div>
                  {!!dataOrders && (
                    <Line type="line" options={options2} data={dataOrders} />
                  )}
                </div>
              </>
            )}
          </div>

          <div className="homeContainer__dashboard__container__figure">
            <div className="homeContainer__dashboard__container__figure__bar">
              {!showOrderClientsLoading ? (
                <div className="homeContainer__dashboard__container__line__loading">
                  <LoadingComponent />
                </div>
              ) : (
                <>
                  <h3>TOTAL CLIENTES</h3>
                  <DatePicker
                    months={meses}
                    weekDays={dias}
                    onChange={setDateOrdersClients}
                    range
                    rangeHover
                    format="DD/MM/YYYY"
                    placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                  />
                  {!!dataOrdersClients && (
                    <Line data={dataOrdersClients} options={optionsAxisY} />
                  )}
                </>
              )}
            </div>
            <div className="homeContainer__dashboard__container__figure__doughnut">
              {!showStatisticsOrdersStatesLoading ? (
                <div className="homeContainer__dashboard__container__line__loading">
                  <LoadingComponent />
                </div>
              ) : (
                <>
                  <h3>ORDENES POR ESTADO</h3>
                  <DatePicker
                    months={meses}
                    weekDays={dias}
                    onChange={setDateOrdersStates}
                    range
                    rangeHover
                    format="DD/MM/YYYY"
                    placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                  />
                  {!!dataOrdersStates && (
                    <Doughnut
                      data={dataOrdersStates}
                      options={optionsDoughnut}
                      plugins={[ChartDataLabels]}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="homeContainer__dashboard__container__figure__doughnut">
            {!loadingTopPayment ? (
              <div className="homeContainer__dashboard__container__line__loading">
                <LoadingComponent />
              </div>
            ) : (
              <>
                <h3>TOP MÉTODO DE PAGOS</h3>
                <DatePicker
                  months={meses}
                  weekDays={dias}
                  onChange={setDateOrdersPayment}
                  range
                  rangeHover
                  format="DD/MM/YYYY"
                  placeholder={`${pastDatePlaceholder} - ${currentDatePlaceholder}`}
                />
                {!!dataOrdersTopPayment && (
                  <Doughnut
                    data={dataOrdersTopPayment}
                    options={optionsDoughnut}
                    plugins={[ChartDataLabels]}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
