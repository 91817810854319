import { ImageComponent } from "../../components";

export const PaymentRetrieveVoucherContainer = ({ image }) => {
  const defaultImage =
    "https://panatelier-api.s3.us-east-2.amazonaws.com/emails/comprobante.png";
  return (
    <div className="paymentRetrieveVoucherContainer">
      <div className="paymentRetrieveVoucherContainer__title">
        <p>Comprobante</p>
      </div>
      <div className="paymentRetrieveVoucherContainer__image">
        <ImageComponent src={image ? image : defaultImage} />
      </div>
    </div>
  );
};
