import { useSelector } from "react-redux";
import { ClientListFieldsValueComponent } from "../../components";

export const ClientListFieldsValueContainer = () => {
  const { clientData } = useSelector((state) => state.listClients);
  return (
    <tbody>
      {clientData?.results?.length > 0 &&
        clientData?.results?.map((client) => {
          return (
            <ClientListFieldsValueComponent key={client.id} client={client} />
          );
        })}
    </tbody>
  );
};
