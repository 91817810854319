import { apiPath } from "../helpers";
const userToken = localStorage.getItem("token");
export const requestPost = async (url, body) => {
  const response = await apiPath.post(url, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};
// "admin/auth/login/"
export const requestPostAuthorization = async (
  url,
  body,
  token = userToken
) => {
  try {
    const response = await apiPath.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const requestPostFileAuthorization = async (
  url,
  body,
  token = userToken
) => {
  try {
    const response = await apiPath.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};
