import "./sass/index.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";

import { LoginContextProvider } from "./store/loginContext";
import { AuthLayout, MainLayout } from "./layouts";
import {
  ProductIndexPage,
  ProductCreatePage,
  ProductDetailPage,
  ProductCollectionIndexPage,
  NotFound,
  LoginPageValidate,
  HomePage,
  OrderIndexPage,
  OrderDetailPage,
  OrderCreatePage,
  ClienteIndexPage,
  ProductNoteIndex,
  ProductModifierIndex,
  ClientCreatePage,
  ProductCollectionCreatePage,
  ProductNoteCreatePage,
  ProductModifierCreatePage,
  PaymentIndexPage,
  ClientDetailPage,
  PaymentDetailPage,
  ProductModifierDetailPage,
  ProductCollectionDetailPage,
  ProductNoteDetailPage,
  CombosIndexPage,
  CombosDetailPage,
  CombosCreatePage,
  SettingStoreIndexPage,
  SettingStoreDetailPage,
  SettingStoreCreatePage,
  SettingCoffeeShopIndexPage,
  SettingGroceryStoreIndexPage,
  SettingIdentificationIndexPage,
  SettingLandingIndexPage,
  SettingPaymentMethodsIndexPage,
  SettingReceptionEmailsIndexPage,
  SettingIndexPage,
  ProductComplementIndexPage,
  CouponIndexPage,
  ProductComplementDetailPage,
  ProductComplementCreatePage,
  SettingStoreCoverageIndexPage,
  SettingStoreCoverageDetailPage,
  SettingStoreCoverageCreatePage,
  SettingStoreCoverageMapIndexPage,
  DashboardIndexPage,
  SettingEnvironmentIndexPage,
  SettingReceptionEmailIndexPage,
  SettingEnvironmentDetailPage,
  SettingReceptionEmailDetailPage,
  SettingLandingClaimIndexPage,
  SettingLandingClaimDetailPage,
  SettingLandingContactIndexPage,
  SettingLandingContactDetailPage,
  SettingLandingReservationIndexPage,
  SettingLandingReservationDetailPage,
  SettingLandingBannersIndexPage,
  SettingLandingBannersHomeDetailPage,
  SettingLandingBannersUsDetailPage,
  SettingLandingBannersHistoryDetailPage,
  SettingLandingBannersJobsDetailPage,
  SettingLandingCollaboratorsIndexPage,
  SettingLandingCollaboratorsDetailPage,
  SettingLandingQuestionsDetailPage,
  SettingLandingQuestionsIndexPage,
  SettingCoffeeShopBannersIndexPage,
  SettingCoffeeShopSuggestiveDetailPage,
  SettingCoffeeShopBannersMainDetailPage,
  SettingCoffeeShopBannersNewDetailPage,
  SettingCoffeeShopBannersOrderDetailPage,
  ReportsIndexPage,
} from "./pages";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "./helpers";
import { Provider } from "react-redux";
import { store } from "./store";
import { CouponDetailPage } from "./pages/CouponPage/CouponDetailPage";
import { CouponCreatePage } from "./pages/CouponPage/CouponCreatePage";
import { SettingLandingBannersBreadsDetailPage } from "./pages/SettingPage/SettingLandingPage/SettingLandingBannersPage/SettingLandingBannersBreadsPage/SettingLandingBannersBreadsDetailPage";
import { SettingLandingBannersAlliesDetailPage } from "./pages/SettingPage/SettingLandingPage/SettingLandingBannersPage/SettingLandingBannersAlliesPage/SettingLandingBannersAlliesDetailPage";
import {
  ReportsOrderClientContainer,
  ReportsOrderProductContainer,
  ReportsOrderTimeContainer,
} from "./containers";

export default function App() {
  onMessageListener()
    .then((payload) => {
      toast(payload.notification.title);
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        icon={"🚀"}
      />
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<HomePage />} />
              <Route
                path="/dashboard/:dashboardName"
                element={<DashboardIndexPage />}
              />
              <Route path="/productos/*">
                <Route path="" element={<ProductIndexPage />} />
                <Route path="crear" element={<ProductCreatePage />} />
                <Route
                  path=":productId/detalles"
                  element={<ProductDetailPage />}
                />
                <Route path="colecciones/*">
                  <Route path="" element={<ProductCollectionIndexPage />} />
                  <Route
                    path="crear"
                    element={<ProductCollectionCreatePage />}
                  />
                  <Route
                    path=":collectionId/detalles"
                    element={<ProductCollectionDetailPage />}
                  />
                </Route>
                <Route path="notas" element={<ProductNoteIndex />} />
                <Route path="notas/crear" element={<ProductNoteCreatePage />} />
                <Route
                  path="notas/:noteId/detalles"
                  element={<ProductNoteDetailPage />}
                />
                <Route
                  path="modificadores"
                  element={<ProductModifierIndex />}
                />
                <Route
                  path="modificadores/crear"
                  element={<ProductModifierCreatePage />}
                />
                <Route
                  path="modificadores/:modifierId/detalles"
                  element={<ProductModifierDetailPage />}
                />
                <Route
                  path="complementos"
                  element={<ProductComplementIndexPage />}
                />
                <Route
                  path="complementos/:complementId/detalles"
                  element={<ProductComplementDetailPage />}
                />
                <Route
                  path="complementos/crear"
                  element={<ProductComplementCreatePage />}
                />
              </Route>
              <Route path="/combos" element={<CombosIndexPage />} />
              <Route path="/combos/crear" element={<CombosCreatePage />} />
              <Route
                path="/combos/:combosId/detalles"
                element={<CombosDetailPage />}
              />
              <Route path="/ordenes" element={<OrderIndexPage />} />
              <Route
                path="/ordenes/:orderId/detalles"
                element={<OrderDetailPage />}
              />
              <Route path="/ordenes/crear" element={<OrderCreatePage />} />
              <Route path="/clientes" element={<ClienteIndexPage />} />
              <Route path="/clientes/crear" element={<ClientCreatePage />} />
              <Route
                path="/clientes/:clientId/detalles"
                element={<ClientDetailPage />}
              />
              <Route path="/pagos" element={<PaymentIndexPage />} />
              <Route
                path="/pagos/:paymentId/detalles"
                element={<PaymentDetailPage />}
              />

              <Route path="/informes" element={<ReportsIndexPage />} />
              <Route
                path="/informes/venta-ordenes-totales"
                element={<ReportsOrderTimeContainer />}
              />
              <Route
                path="/informes/ventas-por-cliente"
                element={<ReportsOrderClientContainer />}
              />
              <Route
                path="/informes/ventas-por-productos"
                element={<ReportsOrderProductContainer />}
              />

              <Route path="/configuracion" element={<SettingIndexPage />} />
              <Route
                path="/configuracion/variables-de-entorno"
                element={<SettingEnvironmentIndexPage />}
              />
              <Route
                path="/configuracion/variables-de-entorno/:environmentId/detalles"
                element={<SettingEnvironmentDetailPage />}
              />
              <Route
                path="/configuracion/correos-de-recepcion"
                element={<SettingReceptionEmailIndexPage />}
              />
              <Route
                path="/configuracion/correos-de-recepcion/:receptionId/detalles"
                element={<SettingReceptionEmailDetailPage />}
              />
              <Route
                path="/configuracion/tiendas"
                element={<SettingStoreIndexPage />}
              />
              <Route
                path="/configuracion/tiendas/:storeId/detalles"
                element={<SettingStoreDetailPage />}
              />
              <Route
                path="/configuracion/tiendas/:storeId/coberturas"
                element={<SettingStoreCoverageIndexPage />}
              />
              <Route
                path="/configuracion/tiendas/:storeId/coberturas/:covertureId/detalles"
                element={<SettingStoreCoverageDetailPage />}
              />
              <Route
                path="/configuracion/tiendas/:storeId/coberturas/mapa"
                element={<SettingStoreCoverageMapIndexPage />}
              />
              <Route
                path="/configuracion/tiendas/:storeId/coberturas/crear"
                element={<SettingStoreCoverageCreatePage />}
              />
              <Route
                path="/configuracion/tiendas/crear"
                element={<SettingStoreCreatePage />}
              />
              <Route
                path="/configuracion/cafeteria"
                element={<SettingCoffeeShopIndexPage />}
              />

              <Route
                path="/configuracion/bodega"
                element={<SettingGroceryStoreIndexPage />}
              />
              <Route
                path="/configuracion/identificacion"
                element={<SettingIdentificationIndexPage />}
              />
              <Route
                path="/configuracion/landing"
                element={<SettingLandingIndexPage />}
              />
              <Route
                path="/configuracion/landing/reclamacion"
                element={<SettingLandingClaimIndexPage />}
              />
              <Route
                path="/configuracion/landing/reclamacion/:claimId/detalles"
                element={<SettingLandingClaimDetailPage />}
              />
              <Route
                path="/configuracion/landing/contacto"
                element={<SettingLandingContactIndexPage />}
              />
              <Route
                path="/configuracion/landing/contacto/:contactId/detalles"
                element={<SettingLandingContactDetailPage />}
              />
              <Route
                path="/configuracion/landing/reservaciones"
                element={<SettingLandingReservationIndexPage />}
              />
              <Route
                path="/configuracion/landing/reservaciones/:reservationId/detalles"
                element={<SettingLandingReservationDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners"
                element={<SettingLandingBannersIndexPage />}
              />
              <Route
                path="/configuracion/landing/banners/home"
                element={<SettingLandingBannersHomeDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners/nosotros"
                element={<SettingLandingBannersUsDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners/historia"
                element={<SettingLandingBannersHistoryDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners/panes"
                element={<SettingLandingBannersBreadsDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners/aliados"
                element={<SettingLandingBannersAlliesDetailPage />}
              />
              <Route
                path="/configuracion/landing/banners/trabajos"
                element={<SettingLandingBannersJobsDetailPage />}
              />
              <Route
                path="/configuracion/landing/colaboradores"
                element={<SettingLandingCollaboratorsIndexPage />}
              />
              <Route
                path="/configuracion/landing/colaboradores/:collaboratorId/detalles"
                element={<SettingLandingCollaboratorsDetailPage />}
              />
              <Route
                path="/configuracion/landing/colaboradores/crear"
                element={<SettingLandingCollaboratorsDetailPage />}
              />
              <Route
                path="/configuracion/landing/preguntas-frecuentes"
                element={<SettingLandingQuestionsIndexPage />}
              />
              <Route
                path="/configuracion/landing/preguntas-frecuentes/:questionId/detalles"
                element={<SettingLandingQuestionsDetailPage />}
              />
              <Route
                path="/configuracion/landing/preguntas-frecuentes/crear"
                element={<SettingLandingQuestionsDetailPage />}
              />
              <Route
                path="/configuracion/cafeteria/banners"
                element={<SettingCoffeeShopBannersIndexPage />}
              />
              <Route
                path="/configuracion/cafeteria/banners/principal"
                element={<SettingCoffeeShopBannersMainDetailPage />}
              />
              <Route
                path="/configuracion/cafeteria/banners/lo-mas-nuevo"
                element={<SettingCoffeeShopBannersNewDetailPage />}
              />
              <Route
                path="/configuracion/cafeteria/banners/lo-mas-pedido"
                element={<SettingCoffeeShopBannersOrderDetailPage />}
              />
              <Route
                path="/configuracion/cafeteria/venta-sugestiva"
                element={<SettingCoffeeShopSuggestiveDetailPage />}
              />
              <Route
                path="/configuracion/metodo-de-pago"
                element={<SettingPaymentMethodsIndexPage />}
              />
              <Route
                path="/configuracion/recepcion-emails"
                element={<SettingReceptionEmailsIndexPage />}
              />
              <Route
                path="/pagos/:paymentId/detalles"
                element={<PaymentDetailPage />}
              />
              <Route path="/cupones" element={<CouponIndexPage />} />
              <Route
                path="/cupones/:couponId/detalles"
                element={<CouponDetailPage />}
              />
              <Route path="/cupones/crear" element={<CouponCreatePage />} />
            </Route>
          </Route>
          <Route path="/login" element={<LoginPageValidate />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export function AppMain() {
  return (
    <Provider store={store}>
      <LoginContextProvider>
        <App />
      </LoginContextProvider>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
const environment = process.env.REACT_APP_ENVIRONMENT;
root.render(
  environment === "LOCAL" ? (
    <React.StrictMode>
      <AppMain />
    </React.StrictMode>
  ) : (
    <AppMain />
  )
);
