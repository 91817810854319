import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getListOptionsItem } from "../../helpers";

const animatedComponents = makeAnimated();
export default function MultipleSelectChip({
  listOptions,
  data = [],
  name,
  handleChange,
  placeholderTitle,
  newStyle = false,
}) {
  const [listOptionInitial, setListOptionInitial] = useState([]);
  const path = useParams();
  useEffect(() => {
    if (!!data && !!path.productId) {
      const inputParamsData = [data, name, "name", "id"];
      setListOptionInitial(getListOptionsItem(inputParamsData));
    }
    // if (!!data) {
    //   setListOptionInitial(data);
    // }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!!data && !!path.combosId) {
      setListOptionInitial(data);
    }
  }, [data, path.combosId]);

  useEffect(() => {
    if (!!data && !!path.couponId) {
      setListOptionInitial(data);
    }
  }, [data, path.couponId]);
  useEffect(() => {
    if (!!data && !!path.complementId) {
      setListOptionInitial(data);
    }
  }, [data, path.complementId]);

  useEffect(() => {
    setListOptionInitial(data);
  }, [data]);

  const xd = (e) => {
    if (e.length > 6) {
      return;
    }
    handleChange(e, name);
    setListOptionInitial(e);
  };
  return (
    <div className={!newStyle ? "multipleSelectChip" : "multipleSelectChipNew"}>
      {!!data && (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          value={listOptionInitial}
          isMulti
          options={listOptions}
          onChange={xd}
          isClearable={false}
          placeholder={placeholderTitle}
        />
      )}
    </div>
  );
}
