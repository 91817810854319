export const OrderDetailUserDataContainer = ({ data }) => {
  return (
    <div className="orderDetailUserDataContainer">
      <div className="orderDetailUserDataContainer__title">
        <p>Datos del cliente</p>
      </div>
      <div className="orderDetailUserDataContainer__container">
        <div className="orderDetailUserDataContainer__container__description">
          <p>
            Nombre: <span>{data?.client?.user?.names}</span>
          </p>
          <p>
            Apellido: <span>{data?.client?.user?.surnames}</span>
          </p>
          <p>
            Correo: <span>{data?.client?.user?.email}</span>
          </p>
          <p>
            Celular: <span>{data?.client?.user?.phone}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
