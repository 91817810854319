import photo from "../../assets/images/Pagina_en_construccion.jpg";

export const UnderConstructionComponent = () => {
  return (
    <div className="under-construction">
      <h1>En construcción</h1>
      <p>Lo sentimos, esta página está actualmente en construcción.</p>
      <img src={photo} width="400px" alt="En construcción" />
    </div>
  );
};
