import { createSlice } from "@reduxjs/toolkit";
export const listOrdersModalitySlice = createSlice({
  name: "listOrdersModality",
  initialState: {
    dataStatisticsDelivery: [],
    dataStatisticsStore: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsDelivery: (state, action) => {
      state.dataStatisticsDelivery = action.payload.dataStatisticsDelivery;
    },
    setListStatisticsStore: (state, action) => {
      state.dataStatisticsStore = action.payload.dataStatisticsStore;
    },
    setStatisticsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});

export const {
  setListStatisticsDelivery,
  setListStatisticsStore,
  setStatisticsLoading,
} = listOrdersModalitySlice.actions;

export const listOrdersLocalSlice = createSlice({
  name: "listOrdersLocal",
  initialState: {
    dataStatisticsLocalMir: [],
    dataStatisticsLocalPue: [],
  },
  reducers: {
    setListStatisticsLocalMir: (state, action) => {
      state.dataStatisticsLocalMir = action.payload.dataStatisticsLocalMir;
    },
    setListStatisticsLocalPue: (state, action) => {
      state.dataStatisticsLocalPue = action.payload.dataStatisticsLocalPue;
    },
  },
});
export const { setListStatisticsLocalMir, setListStatisticsLocalPue } =
  listOrdersLocalSlice.actions;

export const listOrdersClientsSlice = createSlice({
  name: "listOrdersClients",
  initialState: {
    dataStatisticsClients: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsClients: (state, action) => {
      state.dataStatisticsClients = action.payload.dataStatisticsClients;
    },
    setStatisticsClientsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStatisticsClients, setStatisticsClientsLoading } =
  listOrdersClientsSlice.actions;

export const listOrdersStatesSlice = createSlice({
  name: "listOrdersStates",
  initialState: {
    dataStatisticsOrdersStates: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsOrdersStates: (state, action) => {
      state.dataStatisticsOrdersStates =
        action.payload.dataStatisticsOrdersStates;
    },
    setStatisticsOrdersStatesLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setListStatisticsOrdersStates,
  setStatisticsOrdersStatesLoading,
} = listOrdersStatesSlice.actions;

export const listOrdersByDateSlice = createSlice({
  name: "listOrdersByDate",
  initialState: {
    dataStatisticsOrders: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsOrders: (state, action) => {
      state.dataStatisticsOrders = action.payload.dataStatisticsOrders;
    },
    setStatisticsOrdersLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStatisticsOrders, setStatisticsOrdersLoading } =
  listOrdersByDateSlice.actions;

export const listOrdersTopClientsSlice = createSlice({
  name: "listOrdersTopClients",
  initialState: {
    dataTopClients: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsTopClients: (state, action) => {
      state.dataTopClients = action.payload.dataTopClients;
    },
    setStatisticsTopClientsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStatisticsTopClients, setStatisticsTopClientsLoading } =
  listOrdersTopClientsSlice.actions;

export const listOrdersTopProductsSlice = createSlice({
  name: "listOrdersTopProducts",
  initialState: {
    dataTopProducts: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsTopProducts: (state, action) => {
      state.dataTopProducts = action.payload.dataTopProducts;
    },
    setStatisticsTopProductsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStatisticsTopProducts, setStatisticsTopProductsLoading } =
  listOrdersTopProductsSlice.actions;

export const listOrdersTopPaymentsSlice = createSlice({
  name: "listOrdersTopPayment",
  initialState: {
    dataTopPayment: [],
    showLoading: false,
  },
  reducers: {
    setListStatisticsTopPayment: (state, action) => {
      state.dataTopPayment = action.payload.dataTopPayment;
    },
    setStatisticsTopPaymentLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStatisticsTopPayment, setStatisticsTopPaymentLoading } =
  listOrdersTopPaymentsSlice.actions;

export const listOrderSalesDetailSlice = createSlice({
  name: "listOrderSalesDetail",
  initialState: {
    dataOrderSalesDetail: [],
    showLoading: false,
  },
  reducers: {
    setListOrderSalesDetail: (state, action) => {
      state.dataOrderSalesDetail = action.payload.dataOrderSalesDetail;
    },
    setOrderSalesLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListOrderSalesDetail, setOrderSalesLoading } =
  listOrderSalesDetailSlice.actions;

export const listOrderClientsDetailSlice = createSlice({
  name: "listOrderClientsDetail",
  initialState: {
    dataOrderClientsDetail: [],
    showLoading: false,
  },
  reducers: {
    setListOrderClientsDetail: (state, action) => {
      state.dataOrderClientsDetail = action.payload.dataOrderClientsDetail;
    },
    setOrderClientsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListOrderClientsDetail, setOrderClientsLoading } =
  listOrderClientsDetailSlice.actions;

export const listOrderProductsDetailSlice = createSlice({
  name: "listOrderProductsDetail",
  initialState: {
    dataOrderProductsDetail: [],
    showLoading: false,
  },
  reducers: {
    setListOrderProductsDetail: (state, action) => {
      state.dataOrderProductsDetail = action.payload.dataOrderProductsDetail;
    },
    setOrderProductsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListOrderProductsDetail, setOrderProductsLoading } =
  listOrderProductsDetailSlice.actions;

export const dateOrderSalesDetailSlice = createSlice({
  name: "dateOrderSalesDetail",
  initialState: {
    bodyDate: {
      startDate: "2023-01-01",
      endDate: "2023-12-31",
    },
    date: {
      month: "00",
      year: "2023",
    },
    isActive: {
      active: false,
    },
  },
  reducers: {
    setDateBodyOrderSalesDetail: (state, action) => {
      state.bodyDate.startDate = action.payload.startDate;
      state.bodyDate.endDate = action.payload.endDate;
    },
    setDateOrderSalesDetail: (state, action) => {
      state.date.month = action.payload.month;
      state.date.year = action.payload.year;
    },
    setDateOrderSalesActiveDetail: (state, action) => {
      state.isActive.active = action.payload;
    },
    initialDateOrderSales: (state) => {
      state.date = {
        month: "00",
        year: "2023",
      };
      state.bodyDate = {
        startDate: "2023-01-01",
        endDate: "2023-12-31",
      };
      state.isActive = {
        active: false,
      };
    },
  },
});
export const {
  setDateOrderSalesDetail,
  setDateBodyOrderSalesDetail,
  setDateOrderSalesActiveDetail,
  initialDateOrderSales,
} = dateOrderSalesDetailSlice.actions;
