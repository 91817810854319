import { createSlice } from "@reduxjs/toolkit";
export const listClientsSlice = createSlice({
  name: "listClient",
  initialState: {
    clientData: [],
    showLoading: false,
  },
  reducers: {
    setListClients: (state, action) => {
      state.clientData = action.payload.data;
    },
    setClientLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListClients, setClientLoading } = listClientsSlice.actions;

export const retrieveClientSlice = createSlice({
  name: "retrieveClient",
  initialState: {
    clientData: {},
    listAddress: [],
    lisOrders: [],
    showLoading: false,
  },
  reducers: {
    setRetrieveClient: (state, action) => {
      state.clientData = action.payload.data;
    },
    setRetrieveClientAddress: (state, action) => {
      state.listAddress = action.payload.data;
    },
    setRetrieveClientOrders: (state, action) => {
      state.lisOrders = action.payload.data;
    },
    setRetrieveClientLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveClient,
  setRetrieveClientAddress,
  setRetrieveClientOrders,
  setRetrieveClientLoading,
} = retrieveClientSlice.actions;

export const createClientSlice = createSlice({
  name: "createClient",
  initialState: {
    email: "",
    names: "",
    surnames: "",
    phone: "",
    type: "",
  },
  reducers: {
    setCreateClient: (state, action) => {
      state.email = action.payload.email || state.email;
      state.names = action.payload.names || state.names;
      state.surnames = action.payload.surnames || state.surnames;
      state.phone = action.payload.phone || state.phone;
      state.type =
        action.payload.type === "" ? "" : action.payload.type || state.type;
    },
    cleanCreateClient: (state, action) => {
      state.email = "";
      state.names = "";
      state.surnames = "";
      state.phone = "";
      state.type = "";
    },
  },
});
export const { setCreateClient, cleanCreateClient } = createClientSlice.actions;

export const listTypeClientSlice = createSlice({
  name: "listTypeClient",
  initialState: {
    typeClient: [],
  },
  reducers: {
    setlistTypeClient: (state, action) => {
      state.typeClient = action.payload.data;
    },
  },
});
export const { setlistTypeClient } = listTypeClientSlice.actions;

export const listOptionsClientsSlice = createSlice({
  name: "listOptionsClients",
  initialState: {
    listOptionsClients: [],
  },
  reducers: {
    setListOptionsClients: (state, action) => {
      state.listOptionsClients = action.payload.data;
    },
  },
});
export const { setListOptionsClients } = listOptionsClientsSlice.actions;
