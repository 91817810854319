import { NoteListTableContainer, NoteSearchContainer } from "../../containers";

export const NoteListContainer = () => {
  return (
    <div className="noteListContainer">
      <div className="noteListContainer__search">
        <NoteSearchContainer />
      </div>
      <div className="noteListContainer__table">
        <NoteListTableContainer />
      </div>
    </div>
  );
};
