export const ProductListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="productListFieldsKeyComponent">
        <th className="productListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="productListFieldsKeyComponent__code">Código</th>
        <th className="productListFieldsKeyComponent__name">Nombre</th>
        <th className="productListFieldsKeyComponent__description">
          Descripcion
        </th>
        <th className="productListFieldsKeyComponent__image">Imagen</th>
        <th className="productListFieldsKeyComponent__collection">Colección</th>
        <th className="productListFieldsKeyComponent__sub-category">
          Sub Categoria
        </th>
        <th className="productListFieldsKeyComponent__state">Estado</th>
      </tr>
    </thead>
  );
};
