import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ListHeaderContainer,
  PaginationContainer,
  TableContainer,
} from "../../../../containers";
import { filterObjectKeys } from "../../../../helpers";
import { getListCollaborators } from "../../../../store/collaborators/thunks";
import { getListQuestions } from "../../../../store/questions/thunks";
import { LoadingComponent } from "../../../../components";
const URL = "configuracion/landing/colaboradores";
const dataInput = { id: "searchQuestions", placeholder: "Buscar variable" };
const allowedKeys = ["id", "name", "order", "active"];
const dataHeader = {
  title: "Preguntas Frecuentes",
  nameButton: "Crear Pregunta",
  url: "/configuracion/landing/preguntas-frecuentes/crear",
};

export const SettingLandingQuestionsIndexPage = () => {
  const { questionsData, showLoading } = useSelector(
    (state) => state.listQuestions
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageQuestions = serachParams.get("page");
  const searchQuestions = serachParams.get("search");

  let questionsParams = {
    page_size: 20,
    page: pageQuestions ?? 1,
    search: searchQuestions ?? "",
  };

  useEffect(() => {
    dispatch(getListQuestions(questionsParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuestions, searchQuestions]);

  const filter = () => {
    const searchValue = document.getElementById("searchQuestions").value;
    if (searchValue === "") {
      dispatch(getListCollaborators());
      navigate("/" + URL);
      return;
    }
    const searchUrl = `/configuracion/landing/preguntas-frecuentes?search=${searchValue}`;
    navigate(searchUrl);
  };

  const handleKeyDown = (e) => {
    const searchValue = document.getElementById("searchQuestions").value;
    if (e.key === "Backspace" && searchValue.length === 1) {
      dispatch(getListCollaborators());
      navigate("/" + URL);
    }
    if (e.key !== "Enter") return;
    filter();
  };

  const getUrl = (id) => {
    return `/configuracion/landing/preguntas-frecuentes/${id}/detalles`;
  };

  const newData = questionsData?.results?.map((obj) =>
    filterObjectKeys(obj, allowedKeys)
  );

  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TableContainer
            filter={filter}
            handleKeyDown={handleKeyDown}
            dataInput={dataInput}
            data={newData}
            url={getUrl}
            titles={allowedKeys}
            isFilter={false}
          />
          <PaginationContainer data={questionsData} />
        </>
      )}
    </section>
  );
};
