import { createSlice } from "@reduxjs/toolkit";
export const listPaymentsSlice = createSlice({
  name: "listPayments",
  initialState: {
    paymentData: [],
    showLoading: false,
  },
  reducers: {
    setListPayments: (state, action) => {
      state.paymentData = action.payload.data;
      state.paymentParams = action.payload.params;
    },
    setPaymentLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListPayments, setPaymentLoading } = listPaymentsSlice.actions;

export const retrievePaymentSlice = createSlice({
  name: "retrievePayment",
  initialState: {
    paymentData: {},
    showLoading: false,
  },
  reducers: {
    setRetrievePayment: (state, action) => {
      state.paymentData = action.payload.data;
    },
    setRetrievePaymentLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrievePayment, setRetrievePaymentLoading } =
  retrievePaymentSlice.actions;
