import { TitleInformationComponent } from "../../components";
import {
  StoreDetailTableHeadContainer,
  StoreSheduleDetailTableBodyContainer,
} from "../";
import { useCustomHook } from "../../hooks/useDispatchTimeCurrent";

export const StoreDetailGroupsDeliveryMethodsContainer = ({
  data,
  handleOnchage,
  handleAdd,
}) => {
  const { handleClick } = useCustomHook();
  const handlepDeliveryMethods = (index, name, selectHour = "") => {
    handleClick(index, "DeliveryMethods", name, selectHour);
  };

  return (
    <div className="comboDetailGroupsContainer">
      <TitleInformationComponent title={"Métodos de Entregas de las Tiendas"} />
      <hr />
      <table className="comboDetailGroupsContainer__table">
        <StoreDetailTableHeadContainer
          modality={true}
          openingTime={true}
          closingTime={true}
          isActive={true}
          isDelete={true}
        />
        <tbody>
          {data?.map((item, index) => (
            <StoreSheduleDetailTableBodyContainer
              key={index}
              modality={item.modality}
              timeOpening={item.timeOpening}
              timeClosing={item.timeClosing}
              active={item.active}
              isActive
              isDelete
              onChange={handleOnchage}
              index={index}
              getTimeCurrent={handlepDeliveryMethods}
            />
          ))}
          <tr className="comboDetailGroupsContainer__table__button">
            <td>
              <button onClick={handleAdd}>Agregar Producto</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
