export const changeStoreData = (e, index) => {
  const key = e.target.name;
  let photo = {};
  if (key === "photo") {
    const file = e.target.files[0];
    photo = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
  }
  return {
    index,
    value: e.target.value,
    name: e.target.name,
    checked: e.target.checked,
    photo: photo ?? "",
  };
};

export const handleOnChangeStore = (e) => {
  const key = e.target.name;
  let value = e.target.value;
  if (
    key === "image" ||
    key === "cover" ||
    key === "coverMobile" ||
    key === "cart"
  ) {
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
  }

  if (key === "active") {
    value = e.target.checked;
  }
  return { key, value };
};
