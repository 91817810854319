import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  ModifierListContainer,
  PaginationContainer,
} from "../../../containers";
import { getListModifier } from "../../../store/modifier/thunks";
import { LoadingComponent } from "../../../components";
import { useLocation } from "react-router-dom";

export const ProductModifierIndex = () => {
  const { modifierData, showLoading } = useSelector(
    (state) => state.listModifiers
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageModifier = serachParams.get("page");
  const activeModifier = serachParams.get("active");
  const searchModifier = serachParams.get("search");
  let modifierParams = {
    page_size: 20,
    page: pageModifier ?? 1,
    active: activeModifier ?? "",
    search: searchModifier ?? "",
  };

  useEffect(() => {
    dispatch(getListModifier(modifierParams));
    // eslint-disable-next-line
  }, [pageModifier, activeModifier, searchModifier]);
  const dataHeader = {
    title: "Modificadores",
    nameButton: "Crear Modificador",
    url: "/productos/modificadores/crear",
  };
  return (
    <section className="productIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ModifierListContainer />
          <PaginationContainer data={modifierData} />
        </>
      )}
    </section>
  );
};
