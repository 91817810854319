import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";

import {
  createClientSlice,
  listClientsSlice,
  listOptionsClientsSlice,
  listTypeClientSlice,
  retrieveClientSlice,
} from "./client";
import {
  createNoteSlice,
  listNotesSlice,
  listOptionsNoteSlice,
  retrieveNoteSlice,
  updateNoteSlice,
} from "./note";
import { listPaymentsSlice, retrievePaymentSlice } from "./payment";
import {
  listProductsSlice,
  listOptionsProductsSlice,
  retrieveProductSlice,
  updateProductSlice,
} from "./product";
import {
  listModifierSlice,
  createModifierSlice,
  retrieveModifierSlice,
  updateModifierSlice,
  listOptionsModifierSlice,
} from "./modifier";
import {
  collectionSlice,
  createCollectionSlice,
  listCollectionsSlice,
  listOptionsCollectionSlice,
  retrieveCollectionSlice,
} from "./collection";
import { listOptionChannelSlice } from "./channel";
import {
  listComplementSlice,
  listOptionComplementSlice,
  retrieveComplementSlice,
} from "./complement";
import { listOptionTagSlice } from "./tag";
import {
  createOrderSlice,
  listOrdersSlice,
  retrieveOrderItemsSlice,
  retrieveOrderSlice,
} from "./order/ordersSlice";
import {
  listCombosSlice,
  listOptionsComboSlice,
  retrieveComboSlice,
} from "./combo/comboSlice";
import {
  listOptionsStorePickupSlice,
  listOptionsStoreSlice,
  listStoreSlice,
  retrieveStoreSlice,
} from "./store/storeSlice";
import { listOptionsDocumentSlice } from "./document/documentSlice";
import {
  coverageSlice,
  listCoverageSlice,
  retrieveCoverageSlice,
} from "./coverage/coverageSlice";
import {
  dateOrderSalesDetailSlice,
  listOrderClientsDetailSlice,
  listOrderProductsDetailSlice,
  listOrderSalesDetailSlice,
  listOrdersByDateSlice,
  listOrdersClientsSlice,
  listOrdersLocalSlice,
  listOrdersModalitySlice,
  listOrdersStatesSlice,
  listOrdersTopClientsSlice,
  listOrdersTopPaymentsSlice,
  listOrdersTopProductsSlice,
} from "./statistics/statisticsSlice";
import { listCouponSlice, retrieveCouponSlice } from "./coupon/couponSlice";
import {
  listEnvironmentSlice,
  retrieveEnvironmentSlice,
} from "./environment/environmentSlice";
import { listReceptionSlice, retrieveReceptionSlice } from "./reception";
import { listClaimSlice, retrieveClaimSlice } from "./claim/claimSlice";
import { listContactSlice, retrieveContactSlice } from "./contact/contactSlice";
import { listReservationSlice, retrieveReservationSlice } from "./reservation";
import { retrieveBannerHomeSlice } from "./banner/home/homeSlice";
import { retrieveBannerUsSlice } from "./banner/us/usSlice";
import { retrieveBannerHistorySlice } from "./banner/history/historySlice";
import { retrieveBannerBreadSlice } from "./banner/bread";
import { retrieveBannerAlliesSlice } from "./banner/allies";
import { retrieveBannerJobsSlice } from "./banner/jobs/jobsSlice";
import {
  listCollaboratorsSlice,
  retrieveCollaboratorsSlice,
} from "./collaborators/collaboratorsSlice";
import {
  listQuestionsSlice,
  retrieveQuestionsSlice,
} from "./questions/questionsSlice";
import { retrieveBannerMainSlice } from "./coffeeShop/banner/main/mainSlice";
import { retrieveBannerNewSlice } from "./coffeeShop/banner/new/newSlice";
import { retrieveBannerOrderSlice } from "./coffeeShop/banner/order/orderSlice";
import { retrieveSuggestiveSlice } from "./coffeeShop/suggestive";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    collection: collectionSlice.reducer,
    coverage: coverageSlice.reducer,
    createClient: createClientSlice.reducer,
    createCollection: createCollectionSlice.reducer,
    createModifier: createModifierSlice.reducer,
    createNote: createNoteSlice.reducer,
    createOrder: createOrderSlice.reducer,
    listClients: listClientsSlice.reducer,
    listCollections: listCollectionsSlice.reducer,
    listComplement: listComplementSlice.reducer,
    listCombos: listCombosSlice.reducer,
    listModifiers: listModifierSlice.reducer,
    listNotes: listNotesSlice.reducer,
    listOptionChannel: listOptionChannelSlice.reducer,
    listOptionComplement: listOptionComplementSlice.reducer,
    listOptionsClients: listOptionsClientsSlice.reducer,
    listOptionsCollection: listOptionsCollectionSlice.reducer,
    listOptionsCombo: listOptionsComboSlice.reducer,
    listOptionsDocument: listOptionsDocumentSlice.reducer,
    listOptionsModifier: listOptionsModifierSlice.reducer,
    listOptionsNote: listOptionsNoteSlice.reducer,
    listOptionsProducts: listOptionsProductsSlice.reducer,
    listOptionsStore: listOptionsStoreSlice.reducer,
    listOptionsStorePickup: listOptionsStorePickupSlice.reducer,
    listOptionTag: listOptionTagSlice.reducer,
    listOrders: listOrdersSlice.reducer,
    listOrdersByDate: listOrdersByDateSlice.reducer,
    listOrdersClients: listOrdersClientsSlice.reducer,
    listOrdersLocal: listOrdersLocalSlice.reducer,
    listOrdersModality: listOrdersModalitySlice.reducer,
    listOrdersStates: listOrdersStatesSlice.reducer,
    listOrdersTopClients: listOrdersTopClientsSlice.reducer,
    listOrdersTopPayment: listOrdersTopPaymentsSlice.reducer,
    listOrdersTopProducts: listOrdersTopProductsSlice.reducer,
    listOrderSalesDetail: listOrderSalesDetailSlice.reducer,
    listOrderClientsDetail: listOrderClientsDetailSlice.reducer,
    listOrderProductsDetail: listOrderProductsDetailSlice.reducer,
    listEnvironment: listEnvironmentSlice.reducer,
    listClaim: listClaimSlice.reducer,
    listContact: listContactSlice.reducer,
    listReception: listReceptionSlice.reducer,
    listReservation: listReservationSlice.reducer,
    listPayments: listPaymentsSlice.reducer,
    listProducts: listProductsSlice.reducer,
    listStore: listStoreSlice.reducer,
    listCoverage: listCoverageSlice.reducer,
    listCoupon: listCouponSlice.reducer,
    listCollaborators: listCollaboratorsSlice.reducer,
    listQuestions: listQuestionsSlice.reducer,
    listTypeClient: listTypeClientSlice.reducer,
    retrieveClient: retrieveClientSlice.reducer,
    retrieveCollection: retrieveCollectionSlice.reducer,
    retrieveCombo: retrieveComboSlice.reducer,
    retrieveModifier: retrieveModifierSlice.reducer,
    retrieveNote: retrieveNoteSlice.reducer,
    retrieveOrder: retrieveOrderSlice.reducer,
    retrieveOrderItems: retrieveOrderItemsSlice.reducer,
    retrievePayment: retrievePaymentSlice.reducer,
    retrieveProduct: retrieveProductSlice.reducer,
    retrieveStore: retrieveStoreSlice.reducer,
    retrieveCoupon: retrieveCouponSlice.reducer,
    retrieveComplement: retrieveComplementSlice.reducer,
    retrieveCoverage: retrieveCoverageSlice.reducer,
    retrieveEnvironment: retrieveEnvironmentSlice.reducer,
    retrieveClaim: retrieveClaimSlice.reducer,
    retrieveReception: retrieveReceptionSlice.reducer,
    retrieveContact: retrieveContactSlice.reducer,
    retrieveReservation: retrieveReservationSlice.reducer,
    retrieveBannerHome: retrieveBannerHomeSlice.reducer,
    retrieveBannerUs: retrieveBannerUsSlice.reducer,
    retrieveBannerHistory: retrieveBannerHistorySlice.reducer,
    retrieveBannerBread: retrieveBannerBreadSlice.reducer,
    retrieveBannerAllies: retrieveBannerAlliesSlice.reducer,
    retrieveBannerJobs: retrieveBannerJobsSlice.reducer,
    retrieveCollaborators: retrieveCollaboratorsSlice.reducer,
    retrieveQuestions: retrieveQuestionsSlice.reducer,
    retrieveBannerMain: retrieveBannerMainSlice.reducer,
    retrieveBannerNew: retrieveBannerNewSlice.reducer,
    retrieveBannerOrder: retrieveBannerOrderSlice.reducer,
    retrieveSuggestive: retrieveSuggestiveSlice.reducer,
    updateModifier: updateModifierSlice.reducer,
    updateNote: updateNoteSlice.reducer,
    updateProduct: updateProductSlice.reducer,
    dateOrderSalesDetail: dateOrderSalesDetailSlice.reducer,
  },
});
