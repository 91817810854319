import {
  SettingLandingBannersAlliesHeaderContainer,
  SettingLandingBannersAlliesMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersAlliesDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersAlliesHeaderContainer />
      <SettingLandingBannersAlliesMainContainer />
    </section>
  );
};
