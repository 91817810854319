import {
  CouponSearchContainer,
  CouponListTableContainer,
} from "../../containers";

export const CouponListContainer = () => {
  return (
    <div className="clientListContainer">
      <div className="clientListContainer__search">
        <CouponSearchContainer />
      </div>
      <div className="clientListContainer__table">
        <CouponListTableContainer />
      </div>
    </div>
  );
};
