import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setClientLoading,
  setListClients,
  setListOptionsClients,
  setlistTypeClient,
  setRetrieveClient,
  setRetrieveClientAddress,
  setRetrieveClientLoading,
  setRetrieveClientOrders,
} from "./clientSlice";

export const getListClients = (params) => {
  return async (dispatch) => {
    dispatch(setClientLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/clientes/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "clientes";
        value.text = "Clientes";
        value.page = params.page;
        dispatch(setClientLoading({ showLoading: false }));
        dispatch(setListClients({ data: value }));
      }
    );
  };
};

export const getRetrieveClient = (clientId) => {
  return async (dispatch) => {
    dispatch(setRetrieveClientLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/clientes/${clientId}/`, token).then(
      (value) => {
        dispatch(setRetrieveClient({ data: value }));
        dispatch(setRetrieveClientLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveClientAddress = (clientId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/clientes/${clientId}/direcciones/`,
      token
    ).then((value) => {
      dispatch(setRetrieveClientAddress({ data: value }));
    });
  };
};

export const getRetrieveClientOrders = (clientId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/clientes/${clientId}/ordenes/`, token).then(
      (value) => {
        dispatch(setRetrieveClientOrders({ data: value }));
      }
    );
  };
};

export const getTypeClientOrders = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/clientes/tipos/`, token).then((value) => {
      dispatch(setlistTypeClient({ data: value }));
    });
  };
};

export const getListOptionsClients = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/clientes/lista_opciones/", token).then(
      (value) => {
        dispatch(setListOptionsClients({ data: value }));
      }
    );
  };
};
