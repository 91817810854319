import { FaSearch } from "react-icons/fa";

export const InputSearchComponent = ({
  data,
  onKeyDown,
}) => {
  const { id, placeholder } = data;
  return (
    <div className="inputSearchComponent">
      <FaSearch />
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
