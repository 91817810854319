import React from "react";
import { ShowingComponent } from "../../components";
import { PageContainer } from "../../containers";

export const PaginationContainer = ({ data }) => {
  const count = data?.count;
  const pages = Math.ceil(count / data.pageSize);
  const dataPagination = {
    pages: pages,
    next: data?.next,
    previus: data?.previus,
    redirectUrl: data?.redirectUrl,
  };
  return (
    <div className="paginationContainer">
      <ShowingComponent count={count} data={data} />
      <PageContainer dataPagination={dataPagination} />
    </div>
  );
};
