export const ProductDetailInformationItemContainer = ({
  title,
  name,
  value,
  type = "text",
  disabled = "",
  handleOnchage,
  checked=true
}) => {
  return (
    <div className="productDetailInformationItemContainer">
      <div className="productDetailInformationItemContainer__title">{title}</div>
      <div className="productDetailInformationItemContainer__description">
        <input
          disabled={disabled}
          type={type}
          value={value}
          checked={checked}
          onChange={handleOnchage}
          name={name}
        />
      </div>
    </div>
  );
};
