import { useDispatch, useSelector } from "react-redux";
import { SelectOptionsContainer } from "..";
import { useEffect } from "react";
import { getRetrieveBannerMain } from "../../store/coffeeShop/banner/main/thunks";
import { setRetrieveBannerMain } from "../../store/coffeeShop/banner/main/mainSlice";
import { LoadingComponent } from "../../components";
//getRetrieveBannerMain
export const SettingCoffeeShopBannersMainContainer = () => {
  const { photo, showLoading } = useSelector(
    (state) => state.retrieveBannerMain
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRetrieveBannerMain());
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    dispatch(setRetrieveBannerMain({ data: { [key]: value } }));
  };

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <SelectOptionsContainer
            title={"Principal"}
            handleOnchange={handleOnChange}
            name={"photo"}
            isImageEdit={true}
            value={photo?.photo?.url}
          />
        </div>
      )}
    </>
  );
};
