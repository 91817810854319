import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ProductDetailHeaderContainer,
  ProductDetailMainContainer,
} from "../../containers";
import { clearUpdateProduct } from "../../store/product";

export const ProductDetailPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateProduct());
    // eslint-disable-next-line
  }, []);
  return (
    <section className="productDetailPage">
      <ProductDetailHeaderContainer />
      <ProductDetailMainContainer />
    </section>
  );
};
