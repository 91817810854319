export const StoreDetailTableHeadContainer = ({
  name = false,
  day = false,
  openingTime = false,
  closingTime = false,
  photo = false,
  order = false,
  isActive = false,
  isDelete = false,
  modality = false,
}) => {
  return (
    <thead className="storeDetailTableHeadContainer">
      <tr className="storeDetailTableHeadContainer__row">
        {name && (
          <th className="storeDetailTableHeadContainer__row__name">Nombre</th>
        )}
        {modality && (
          <th className="storeDetailTableHeadContainer__row__modality">
            Tipo de Entrega{" "}
          </th>
        )}
        {day && (
          <th className="storeDetailTableHeadContainer__row__day">Dia</th>
        )}
        {openingTime && (
          <th className="storeDetailTableHeadContainer__row__openingTime">
            Hora de Apertura
          </th>
        )}
        {closingTime && (
          <th className="storeDetailTableHeadContainer__row__closingTime">
            Hora de Cierre
          </th>
        )}
        {photo && (
          <th className="storeDetailTableHeadContainer__row__photo">Foto</th>
        )}
        {order && (
          <th className="storeDetailTableHeadContainer__row__order">Order</th>
        )}
        {isActive && (
          <th className="storeDetailTableHeadContainer__row__active">
            ¿Activo?
          </th>
        )}
        {isDelete && (
          <th className="storeDetailTableHeadContainer__row__delete">
            ¿Eliminar?
          </th>
        )}
      </tr>
    </thead>
  );
};
