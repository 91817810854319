export const ModifierListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="modifierListFieldsKeyComponent">
        <th className="modifierListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="modifierListFieldsKeyComponent__name">Nombre</th>
        <th className="modifierListFieldsKeyComponent__amount">
          Cantidad
        </th>
        <th className="modifierListFieldsKeyComponent__state">Estado</th>
      </tr>
    </thead>
  );
};
