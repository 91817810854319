import clientActive from "../../data/stateActive.json";
import {
  ButtonSearchComponent,
  FilterComponent,
  InputSearchComponent,
} from "../../components";

export const TableSearchContainer = ({
  filter,
  handleKeyDown,
  dataInput,
  isFilter = true,
}) => {
  return (
    <div className="clientSearchContainer">
      {isFilter && (
        <div className="clientSearchContainer__filters">
          <div className="clientSearchContainer__filters__item">
            <FilterComponent
              data={clientActive}
              name={"Estado"}
              id={"clientActive"}
            />
          </div>
        </div>
      )}
      <div className="clientSearchContainer__search">
        <div className="clientSearchContainer__search__input">
          <InputSearchComponent
            data={dataInput}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="clientSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
