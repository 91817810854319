import {
  OrderDetailHeaderContainer,
  OrderDetailItemContainer,
  OrderDetailPaymentContainer,
  OrderDetailSummaryContainer,
} from "../";

export const OrderDetailContainer = ({ data, dataItems }) => {
  return (
    <section className="orderDetailContainer">
      <OrderDetailHeaderContainer data={data} />
      <OrderDetailItemContainer dataItems={dataItems} />
      <OrderDetailSummaryContainer data={data} />
      <OrderDetailPaymentContainer data={data} />
    </section>
  );
};
