import { createSlice } from "@reduxjs/toolkit";

export const coverageSlice = createSlice({
  name: "coverage",
  initialState: {
    coverage: [],
  },
  reducers: {
    setCoverage: (state, action) => {
      state.coverage = action.payload.data;
    },
  },
});
export const { setCoverage } = coverageSlice.actions;

export const listCoverageSlice = createSlice({
  name: "listCoverage",
  initialState: {
    coverageData: [],
    coverageParams: {
      page_size: 20,
      page: 1,
      active: "",
      search: "",
    },
  },
  reducers: {
    setListCoverage: (state, action) => {
      state.coverageData = action.payload.data;
      state.coverageParams = action.payload.params;
    },
    setListCoverageParams: (state, action) => {
      state.coverageParams.page_size = 20;
      state.coverageParams.page =
        action.payload.params.page || state.coverageParams.page;
      state.coverageParams.active =
        action.payload.params.active || state.coverageParams.active;
      state.coverageParams.search =
        action.payload.params.search || state.coverageParams.search;
    },
    filterResultsById: (state, action) => {
      state.coverageData.results = action.payload.data;
    },
  },
});
export const { setListCoverage, setListCoverageParams, filterResultsById } =
  listCoverageSlice.actions;

export const retrieveCoverageSlice = createSlice({
  name: "retrieveCoverage",
  initialState: {
    coverageData: {
      name: "",
      shop: "1",
      active: false,
      coordenadas: [],
    },
  },
  reducers: {
    setRetrieveCoverage: (state, action) => {
      state.coverageData.name =
        action?.payload?.data?.name || state.coverageData.name;
      state.coverageData.shop =
        action?.payload?.data?.shop || state.coverageData.shop;
      state.coverageData.active =
        action.payload.data?.active ?? state.coverageData.active;
      state.coverageData.coordenadas =
        action.payload.data?.coordenadas || state.coverageData.coordenadas;
    },
    setUpdateCoverageCoordinates: (state, action) => {
      const { index, name, value } = action.payload;
      const coordenadas = [...state.coverageData.coordenadas];
      coordenadas[index] = {
        ...coordenadas[index],
        [name]: value,
      };
      return {
        ...state,
        coverageData: {
          ...state.coverageData,
          coordenadas,
        },
      };
    },
    setAddCoverageCoordinates: (state) => {
      const obj = {
        latitude: "",
        longitude: "",
      };
      state.coverageData?.coordenadas?.push(obj);
    },
    clearUpdateCoverage: (state) => {
      state.coverageData = {
        name: "",
        shop: "1",
        active: false,
        coordenadas: [],
      };
    },
  },
});
export const {
  setRetrieveCoverage,
  setUpdateCoverageCoordinates,
  clearUpdateCoverage,
  setAddCoverageCoordinates,
} = retrieveCoverageSlice.actions;
