export const StoreCoverageListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="storeListFieldsKeyComponent">
        <th className="storeListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="storeListFieldsKeyComponent__name">Nombre</th>
        <th className="storeListFieldsKeyComponent__active">¿Activo?</th>
        <th className="storeListFieldsKeyComponent__delete">¿Eliminar?</th>
      </tr>
    </thead>
  );
};
