import { requestGetAuthorization } from "../../services/requestGet";
import { setListCoverage, setRetrieveCoverage } from "./coverageSlice";

export const getListCoverage = (coverageId) => {
  return async (dispatch) => {
    const tokenUser = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/tiendas/${coverageId}/coberturas/`,
      tokenUser
    ).then((value) => {
      dispatch(
        setListCoverage({
          data: value,
          params: {
            page_size: 20,
            page: 1,
            active: "",
            search: "",
          },
        })
      );
    });
  };
};

export const getRetrieveCoverage = (coverageId) => {
  return async (dispatch) => {
    const tokenUser = localStorage.getItem("token");

    requestGetAuthorization(`admin/coberturas/${coverageId}/`, tokenUser).then(
      (value) => {
        dispatch(setRetrieveCoverage({ data: value }));
      }
    );
  };
};
