import {
  SettingCoffeeShopBannersOrderHeaderContainer,
  SettingCoffeeShopBannersOrderMainContainer,
} from "../../../../../containers";
export const SettingCoffeeShopBannersOrderDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingCoffeeShopBannersOrderHeaderContainer />
      <SettingCoffeeShopBannersOrderMainContainer />
    </section>
  );
};
