import { requestGetAuthorization } from "../../services/requestGet";
import { setListOptionsDocument } from "./documentSlice";

export const getListOptionsDocument = () => {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      requestGetAuthorization("admin/tipos_documentos/lista_opciones/", token).then(
        (value) => {
          dispatch(setListOptionsDocument({ data: value }));
        }
      );
    };
  };