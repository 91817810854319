import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerUsSlice = createSlice({
  name: "retrieveBannerUs",
  initialState: {
    bannerUsData: {
      coverDesktop: "",
      coverMobile: "",
      photos: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerUs: (state, action) => {
      state.bannerUsData.coverDesktop =
        action.payload?.data?.coverDesktop || state?.bannerUsData?.coverDesktop;
      state.bannerUsData.coverMobile =
        action.payload?.data?.coverMobile || state?.bannerUsData?.coverMobile;
      state.bannerUsData.photos =
        action.payload?.data?.photos || state?.bannerUsData?.photos;
    },
    setRetrieveBannerUsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerUs, setRetrieveBannerUsLoading } =
  retrieveBannerUsSlice.actions;
