import {
  SettingLandingBannersHistoryHeaderContainer,
  SettingLandingBannersHistoryMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersHistoryDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersHistoryHeaderContainer />
      <SettingLandingBannersHistoryMainContainer />
    </section>
  );
};
