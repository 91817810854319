import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ListHeaderContainer,
  // PaginationContainer,
  StoreCoverageListContainer,
} from "../../../../containers";
import { setListCoverageParams } from "../../../../store/coverage/coverageSlice";
import { getListCoverage } from "../../../../store/coverage/thunks";
// import { getListStore } from "../../../../store/store/thunks";

export const SettingStoreCoverageIndexPage = () => {
  const { coverageParams } = useSelector((state) => state.listCoverage);
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(coverageParams);
  const paramsId = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getListCoverage(paramsId.storeId));
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    navigate(`/configuracion/tiendas/${paramsId.storeId}/coberturas/mapa`);
  };

  const dataHeader = {
    title: "Cobertura",
    nameButton: "Crear Cobertura",
    url: `/configuracion/tiendas/${paramsId.storeId}/coberturas/crear`,
    buttonMore: true,
    handleClick: handleClick,
  };
  const getData = (objParams) => {
    dispatch(setListCoverageParams({ params: objParams }));
  };
  return (
    <section className="orderIndexPage">
      <ListHeaderContainer data={dataHeader} />
      <StoreCoverageListContainer setParams={getData} params={params} />
      {/* <PaginationContainer
        data={coverageData}
        setParams={getData}
        params={params}
      /> */}
    </section>
  );
};
