import { CouponListFieldsKeyComponent } from "../../components";
import { CouponListFieldsValueContainer } from "../../containers";

export const CouponListTableContainer = () => {
  return (
    <table className="clientListTableContainer">
      <CouponListFieldsKeyComponent />
      <CouponListFieldsValueContainer />
    </table>
  );
};
