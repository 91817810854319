import { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaBreadSlice, FaUserFriends, FaCircle } from "react-icons/fa";
import { RiCoupon3Fill } from "react-icons/ri";
import { MdLocalGroceryStore, MdPayment } from "react-icons/md";
import { BiCircle } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Sidebar, SubMenu, Menu, MenuItem } from "react-pro-sidebar";
import { ReactComponent as SidebarLogo } from "../../assets/logo-pan-atelier.svg";
import { ReactComponent as ComboLogo } from "../../assets/combos.svg";
import { ReactComponent as SettingsLogo } from "../../assets/settings-icon.svg";

export const SidebarContainer = () => {
  const [isActive, setActive] = useState({
    home: false,
    order: false,
    product: false,
    "view-product": false,
    "collection-product": false,
    "modifier-product": false,
    "note-product": false,
    "complement-product": false,
    "settings-store": false,
    "settings-coffee-shop": false,
    "settings-grocery-store": false,
    "settings-landing": false,
    "settings-payment-methods": false,
    "settings-reception-emails": false,
    "settings-identification": false,
    "settings-settings": false,
    combos: false,
    payment: false,
    clients: false,
    settings: false,
    coupon: false,
    report: false,
  });
  const toggleClass = (valor) => {
    setActive(valor);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }} className="sidebar">
      <Sidebar
        defaultCollapsed
        collapsedWidth="80px"
        transitionDuration={800}
        id="sidebarReact"
        className="sidebar__container"
      >
        <Menu className="sidebar__container__menu">
          <MenuItem className="sidebar__logo" icon={<SidebarLogo />}>
            <p>atelier</p>
          </MenuItem>
          <MenuItem
            routerLink={<NavLink to="/" />}
            className={(isActive.home && "activeLink") + " sidebar__menu__item"}
            icon={<FaHome />}
            onClick={() => toggleClass({ home: true })}
          >
            Home
          </MenuItem>
          <MenuItem
            routerLink={<NavLink to="/ordenes" />}
            className={
              (isActive.order && "activeLink") + " sidebar__menu__item"
            }
            icon={<MdLocalGroceryStore />}
            onClick={() => {
              toggleClass({ order: true });
            }}
          >
            Ordenes
          </MenuItem>
          <SubMenu
            className={
              (isActive.product && "activeLink") + " sidebar__menu__item"
            }
            icon={<FaBreadSlice />}
            label="Productos"
            onClick={() => toggleClass({ product: true })}
          >
            <MenuItem
              icon={
                isActive["view-product"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["view-product"] && "activeLink"}
              routerLink={<NavLink to="/productos" />}
              onClick={() => {
                toggleClass({ "view-product": true });
              }}
            >
              Ver Productos
            </MenuItem>
            <MenuItem
              icon={
                isActive["collection-product"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["collection-product"] && "activeLink"}
              routerLink={<NavLink to="/productos/colecciones" />}
              onClick={() => toggleClass({ "collection-product": true })}
            >
              Colecciones
            </MenuItem>
            <MenuItem
              icon={
                isActive["modifier-product"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["modifier-product"] && "activeLink"}
              routerLink={<NavLink to="/productos/modificadores" />}
              onClick={() => toggleClass({ "modifier-product": true })}
            >
              Modificadores
            </MenuItem>
            <MenuItem
              icon={
                isActive["note-product"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["note-product"] && "activeLink"}
              routerLink={<NavLink to="/productos/notas" />}
              onClick={() => toggleClass({ "note-product": true })}
            >
              Notas
            </MenuItem>
            <MenuItem
              icon={
                isActive["complement-product"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["complement-product"] && "activeLink"}
              routerLink={<NavLink to="/productos/complementos" />}
              onClick={() => toggleClass({ "complement-product": true })}
            >
              Complementos
            </MenuItem>
          </SubMenu>
          <MenuItem
            routerLink={<NavLink to="/combos" />}
            className={
              (isActive.combos && "activeLink") + " sidebar__menu__item"
            }
            icon={<ComboLogo size="35" />}
            onClick={() => {
              toggleClass({ combos: true });
            }}
          >
            Combos
          </MenuItem>
          <MenuItem
            routerLink={<NavLink to="/pagos" />}
            className={
              (isActive.payment && "activeLink") + " sidebar__menu__item"
            }
            icon={<MdPayment />}
            onClick={() => {
              toggleClass({ payment: true });
            }}
          >
            Pago
          </MenuItem>
          <MenuItem
            routerLink={<NavLink to="/clientes" />}
            className={
              (isActive.clients && "activeLink") + " sidebar__menu__item"
            }
            icon={<FaUserFriends />}
            onClick={() => {
              toggleClass({ clients: true });
            }}
          >
            Clientes
          </MenuItem>
          <MenuItem
            routerLink={<NavLink to="/cupones" />}
            className={
              (isActive.coupon && "activeLink") + " sidebar__menu__item"
            }
            icon={<RiCoupon3Fill />}
            onClick={() => {
              toggleClass({ coupon: true });
            }}
          >
            Cupon
          </MenuItem>

          <MenuItem
            routerLink={<NavLink to="/informes" />}
            className={
              (isActive.report && "activeLink") + " sidebar__menu__item"
            }
            icon={<HiOutlineDocumentReport />}
            onClick={() => {
              toggleClass({ report: true });
            }}
          >
            Informes
          </MenuItem>

          <SubMenu
            className={
              (isActive.settings && "activeLink") + " sidebar__menu__item"
            }
            icon={<SettingsLogo />}
            label="Configuración"
            onClick={() => toggleClass({ settings: true })}
          >
            <MenuItem
              icon={
                isActive["settings-settings"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-settings"] && "activeLink"}
              routerLink={<NavLink to="/configuracion" />}
              onClick={() => {
                toggleClass({ "settings-settings": true });
              }}
            >
              Ver Configuración
            </MenuItem>
            <MenuItem
              icon={
                isActive["settings-store"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-store"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/tiendas" />}
              onClick={() => {
                toggleClass({ "settings-store": true });
              }}
            >
              Tiendas
            </MenuItem>
            <MenuItem
              icon={
                isActive["settings-coffee-shop"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-coffee-shop"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/cafeteria" />}
              onClick={() => {
                toggleClass({ "settings-coffee-shop": true });
              }}
            >
              Cafeteria
            </MenuItem>
            {/* <MenuItem
              icon={
                isActive["settings-grocery-store"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-grocery-store"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/bodega" />}
              onClick={() => {
                
                toggleClass({ "settings-grocery-store": true });
              }}
            >
              Bodega
            </MenuItem> */}
            {/* <MenuItem
              icon={
                isActive["settings-identification"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-identification"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/identificacion" />}
              onClick={() => {
                
                toggleClass({ "settings-identification": true });
              }}
            >
              Identificación
            </MenuItem> */}
            <MenuItem
              icon={
                isActive["settings-landing"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-landing"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/landing" />}
              onClick={() => {
                toggleClass({ "settings-landing": true });
              }}
            >
              Landing
            </MenuItem>
            {/* <MenuItem
              icon={
                isActive["settings-payment-methods"] ? (
                  <FaCircle size={8} />
                ) : (
                  <BiCircle size={8} />
                )
              }
              className={isActive["settings-payment-methods"] && "activeLink"}
              routerLink={<NavLink to="/configuracion/metodo-de-pago" />}
              onClick={() => {
                
                toggleClass({ "settings-payment-methods": true });
              }}
            >
              Método de Pago
            </MenuItem> */}
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
};
