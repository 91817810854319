import {
  StoreCoverageListTableContainer,
  StoreCoverageSearchContainer,
} from "../../containers";

export const StoreCoverageListContainer = ({ setParams, params }) => {
  return (
    <div className="orderListContainer">
      <div className="orderListContainer__search">
        <StoreCoverageSearchContainer setParams={setParams} params={params} />
      </div>
      <div className="orderListContainer__table">
        <StoreCoverageListTableContainer />
      </div>
    </div>
  );
};
