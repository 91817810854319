import React from "react";
import {
  PaymentRetrieveInformationContainer,
  PaymentRetrieveStateContainer,
} from "../../containers";

export const PaymentRetrieveDetailContainer = ({ data }) => {
  return (
    <div className="paymentRetrieveDetailContainer">
      <PaymentRetrieveStateContainer data={data} />
      <PaymentRetrieveInformationContainer data={data} />
    </div>
  );
};
