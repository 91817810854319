import { useDispatch, useSelector } from "react-redux";
import {
  CouponDetailUserTypeContainer,
  CouponDetailInformationContainer,
  CouponDetailSelectProductContainer,
  CouponDetailSelectCollectionContainer,
} from "../";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getRetrieveCoupon } from "../../store/coupon";
import { getListOptionsClients } from "../../store/client";
import { getListOptionsProducts } from "../../store/product";
import { getListOptionsCollection } from "../../store/collection";
import { LoadingComponent } from "../../components";

export const CouponDetailMainContainer = () => {
  const { couponData, showLoading } = useSelector(
    (state) => state.retrieveCoupon
  );
  const { listOptionsClients } = useSelector(
    (state) => state.listOptionsClients
  );
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );
  const { listOptionsCollection } = useSelector(
    (state) => state.listOptionsCollection
  );
  const path = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (path.couponId) {
      dispatch(getRetrieveCoupon(path.couponId));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getListOptionsClients());
    dispatch(getListOptionsCollection());
    dispatch(getListOptionsProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!showLoading && path.couponId ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <CouponDetailInformationContainer couponData={couponData} />
          <CouponDetailUserTypeContainer
            couponData={couponData}
            listOptionsClients={listOptionsClients}
          />
          <CouponDetailSelectProductContainer
            listOptionsProducts={listOptionsProducts}
            couponData={couponData}
          />
          <CouponDetailSelectCollectionContainer
            couponData={couponData}
            listOptionsCollection={listOptionsCollection}
          />
        </div>
      )}
    </>
  );
};
