import { createSlice } from "@reduxjs/toolkit";

export const listCombosSlice = createSlice({
  name: "listCombos",
  initialState: {
    combosData: [],
    showLoading: false,
  },
  reducers: {
    setListCombos: (state, action) => {
      state.combosData = action.payload.data.value;
    },
    setCombosLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListCombos, setCombosLoading } = listCombosSlice.actions;

export const listOptionsComboSlice = createSlice({
  name: "listOptionsCombo",
  initialState: {
    listOptionsCombo: [],
  },
  reducers: {
    setListOptionsCombo: (state, action) => {
      state.listOptionsCombo = action.payload.data;
    },
  },
});
export const { setListOptionsCombo } = listOptionsComboSlice.actions;

export const retrieveComboSlice = createSlice({
  name: "retrieveCombo",
  initialState: {
    comboData: {
      codigo: "",
      name: "",
      slug: "",
      description: "",
      image: "",
      order: 1,
      price: 0,
      igv: false,
      active: false,
      tags: [],
      complements: [],
      channels: [],
      colections: [],
      gruposProductos: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveCombo: (state, action) => {
      state.comboData.codigo =
        action.payload?.data?.codigo || state?.comboData?.codigo;
      state.comboData.name =
        action?.payload?.data?.name || state.comboData.name;
      state.comboData.slug = action?.payload.data?.slug || state.comboData.slug;
      state.comboData.description =
        action.payload.data?.description || state.comboData.description;
      state.comboData.image =
        action.payload.data?.image || state.comboData.image;
      state.comboData.order =
        action.payload.data?.order || state.comboData.order;
      state.comboData.price =
        action.payload.data?.price || state.comboData.price;
      state.comboData.igv = action.payload.data?.igv ?? state.comboData.igv;
      state.comboData.active =
        action.payload.data?.active ?? state.comboData.active;
      state.comboData.tags = action.payload.data?.tags || state.comboData.tags;
      state.comboData.complements =
        action.payload.data?.complements || state.comboData.complements;
      state.comboData.channels =
        action.payload.data?.channels || state.comboData.channels;
      state.comboData.colections =
        action.payload.data?.colections || state.comboData.colections;
      state.comboData.gruposProductos =
        action.payload.data?.gruposProductos || state.comboData.gruposProductos;
    },
    setUpdateComboChannels: (state, action) => {
      state.comboData.channels = action.payload.channels || state.channels;
    },
    setUpdateComboColections: (state, action) => {
      state.comboData.colections =
        action.payload.colections || state.colections;
    },
    setUpdateComboTags: (state, action) => {
      state.comboData.tags = action.payload.tags || state.tags;
    },
    setUpdateComboComplements: (state, action) => {
      state.comboData.complements =
        action.payload.complements || state.complements;
    },
    setUpdateComboGroupsProductsItems: (state, action) => {
      state.comboData.gruposProductos[action.payload.index].products =
        action.payload.gruposProductos || state.gruposProductos;
    },
    setUpdateComboGroupsAddProductsItems: (state, action) => {
      const obj = {
        id: action.payload.index + 1,
        name: "",
        number: 1,
        order: 1,
        products: [],
      };
      state.comboData.gruposProductos.push(obj);
    },
    setUpdateComboGroupsInputsProductsItems: (state, action) => {
      if (action.payload.name === "name") {
        state.comboData.gruposProductos[action.payload.index].name =
          action.payload.value;
      }
      if (action.payload.name === "number") {
        state.comboData.gruposProductos[action.payload.index].number =
          action.payload.value;
      }
      if (action.payload.name === "order") {
        state.comboData.gruposProductos[action.payload.index].order =
          action.payload.value;
      }
    },

    clearUpdateCombo: (state) => {
      state.comboData = {
        codigo: "",
        name: "",
        slug: "",
        description: "",
        image: "",
        order: 1,
        price: 0,
        igv: false,
        active: false,
        tags: [],
        complements: [],
        channels: [],
        colections: [],
        gruposProductos: [],
      };
    },
    setListOptionsCombo: (state, action) => {
      state.listOptionsCombo = action.payload.data;
    },
    setRetrieveCombosLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveCombo,
  setUpdateComboChannels,
  setUpdateComboColections,
  setUpdateComboTags,
  setUpdateComboComplements,
  setUpdateComboGroupsProductsItems,
  setUpdateComboGroupsAddProductsItems,
  setUpdateComboGroupsInputsProductsItems,
  clearUpdateCombo,
  setRetrieveCombosLoading,
} = retrieveComboSlice.actions;
