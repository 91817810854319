import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ProductCollectionDetailInformationContainer,
  SectionSelectionContainer,
} from "../";
import { getRetrieveCollection, setCollection } from "../../store/collection";
import { getListOptionsCombo } from "../../store/combo/thunks";
import { getListOptionsProducts } from "../../store/product";
import { LoadingComponent } from "../../components";

export const ProductCollectionDetailMainContainer = () => {
  const [collectionRetrieve, setCollectionRetrieve] = useState({});
  const { collectionData, showLoading } = useSelector(
    (state) => state.retrieveCollection
  );
  const { listOptionsCombo } = useSelector((state) => state.listOptionsCombo);
  const { listOptionsProducts } = useSelector(
    (state) => state.listOptionsProducts
  );

  const dispatch = useDispatch();

  const params = useParams();
  const id = params.collectionId;

  useEffect(() => {
    if (!!collectionData) {
      setCollectionRetrieve(collectionData);
    }
  }, [collectionData]);

  useEffect(() => {
    dispatch(getRetrieveCollection(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getListOptionsProducts());
    dispatch(getListOptionsCombo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData.productos]);

  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active") {
      value = e.target.checked;
    }
    setCollectionRetrieve({ ...collectionRetrieve, [name]: value });
    dispatch(setCollection({ [name]: value }));
  };

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productCollectionDetailMainContainer">
          <ProductCollectionDetailInformationContainer
            data={collectionRetrieve}
            handleOnchage={handleOnchage}
            collectionData={collectionData}
          />
          <SectionSelectionContainer
            title="Productos"
            subTitle="Producto"
            data={collectionData?.productos}
            handleOnchage={handleOnchage}
            listOptionData={listOptionsProducts}
          />
          <SectionSelectionContainer
            title="Combos"
            subTitle="Combo"
            data={collectionData?.combos}
            handleOnchage={handleOnchage}
            listOptionData={listOptionsCombo}
          />
        </div>
      )}
    </>
  );
};
