import {
  SettingLandingContactMainContainer,
  SettingLandingContactHeaderContainer,
} from "../../../../containers";

export const SettingLandingContactDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingContactHeaderContainer />
      <SettingLandingContactMainContainer />
    </section>
  );
};
