import { TitleComponent } from "../../../components";
import { SettingBoxContainer } from "../../../containers";

const data = [
  {
    id: 1,
    title: "Contactos",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/contacto_29_03_2023.jpg",
    slug: "contacto",
  },
  {
    id: 2,
    title: "Libro de reclamaciones",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/book_29_03_2023.png",
    slug: "reclamacion",
  },
  {
    id: 3,
    title: "Reservas",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/reservation_29_03_2023.png",
    slug: "reservaciones",
  },
  {
    id: 4,
    title: "Banners",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsCSkGMp-ZzJmr0LPGI_I29ljrIG-lAM1OIQ&usqp=CAU",
    slug: "banners",
  },
  {
    id: 5,
    title: "Colaboradores",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2020/01/02/13/28/winter-4735812_960_720.jpg",
    slug: "colaboradores",
  },
  {
    id: 6,
    title: "Preguntas frecuentes",
    text: "Determinar los atributos utilizados pra crear tipos de productos",
    url: "https://cdn.pixabay.com/photo/2020/01/02/13/28/winter-4735812_960_720.jpg",
    slug: "preguntas-frecuentes",
  },
];

export const SettingLandingIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Landing"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
