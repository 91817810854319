import {
  SettingCoffeeShopBannersMainContainer,
  SettingCoffeeShopBannersMainHeaderContainer,
} from "../../../../../containers";
export const SettingCoffeeShopBannersMainDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingCoffeeShopBannersMainHeaderContainer />
      <SettingCoffeeShopBannersMainContainer />
    </section>
  );
};
