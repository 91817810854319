import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { getRetrieveClaim } from "../../store/claim/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { getFormatDate } from "../../helpers";

export const SettingLandingClaimInformationContainer = () => {
  const { claimData, showLoading } = useSelector(
    (state) => state.retrieveClaim
  );
  const path = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path.claimId) {
      dispatch(getRetrieveClaim(path.claimId));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          <SelectOptionsContainer
            title={"Nombre"}
            name="name"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.name}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Apellido Paterno"}
            name="lastNameFather"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.lastNameFather}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Apellido Materno"}
            name="lastNameMother"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.lastNameMother}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Distrito"}
            name="district"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.district}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Fecha de Envio"}
            name="district"
            handleOnchange={() => {}}
            isInput={true}
            value={getFormatDate(claimData?.created)}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Tipo de contrato"}
            name="typeContracted"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.typeContracted}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Tipo de Reclamo"}
            name="typeClaim"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.typeClaim}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Tipo de Documento"}
            name="typeDocument"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.typeDocument}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Numero de Documento"}
            name="numberDocument"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.numberDocument}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Tienda"}
            name="shop"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.shop}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Dirreción"}
            name="adress"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.adress}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Telefono"}
            name="phono"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.phono}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Correo"}
            name="email"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.email}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Numero de Orden"}
            name="orderNumber"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.orderNumber}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Cantidad"}
            name="amount"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.amount || "0"}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Descripción del Contrato"}
            name="descriptionContracted"
            handleOnchange={() => {}}
            isTextArea={true}
            value={claimData?.descriptionContracted}
            readOnly={true}
          />
          <SelectOptionsContainer
            title={"Descripción del Reclamo"}
            name="descriptionClaim"
            handleOnchange={() => {}}
            isTextArea={true}
            value={claimData?.descriptionClaim}
            readOnly={true}
          />
          {/* <SelectOptionsContainer
            title={"Respuesta del Consumido"}
            name="consumerRequest"
            handleOnchange={() => {}}
            isTextArea={true}
            value={claimData?.consumerRequest}
            readOnly={true}
          /> */}
          <SelectOptionsContainer
            title={"Descripción"}
            name="descriptionSeller"
            handleOnchange={() => {}}
            isInput={true}
            value={claimData?.descriptionSeller}
            readOnly={true}
          />
        </div>
      )}
    </>
  );
};
