import { createSlice } from "@reduxjs/toolkit";
export const retrieveSuggestiveSlice = createSlice({
  name: "retrieveSuggestive",
  initialState: {
    suggestiveData: {
      name: "",
      products: [],
    },
  },
  reducers: {
    setRetrieveSuggestive: (state, action) => {
      state.suggestiveData.name = action.payload.data.name;
      state.suggestiveData.products = action.payload.data.products;
    },
    setRetrieveSuggestiveDescription: (state, action) => {
      state.suggestiveData.name = action.payload.name;
    },
    setRetrieveSuggestiveProducts: (state, action) => {
      state.suggestiveData.products =  action.payload.products;
    },
  },
});
export const {
  setRetrieveSuggestive,
  setRetrieveSuggestiveDescription,
  setRetrieveSuggestiveProducts,
} = retrieveSuggestiveSlice.actions;
