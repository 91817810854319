import { Link } from "react-router-dom";

export const ProductComplementListFieldsValueComponent = ({ complement }) => {
  const url = `/productos/complementos/${complement.id}/detalles`;
  const complementNames = complement?.products?.map((item) => item.name);
  return (
    <tr className="productComplementListFieldsValueComponent">
      <td className="productComplementListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="productComplementListFieldsValueComponent__fullName">
        <Link to={url}>{complement.name}</Link>
      </td>
      <td
        className="productComplementListFieldsValueComponent__products"
        style={{ textAlign: "left" }}
      >
        <Link to={url}>
          {complementNames?.map((name, index) => (
            <span key={index} style={{ display: "inline-block" }}>
              {name}
              {index < complementNames.length - 1 && ",  "}
            </span>
          ))}
        </Link>
      </td>
      <td className="productComplementListFieldsValueComponent__phone">
        <Link to={url}>{complement.order}</Link>
      </td>
    </tr>
  );
};
