import {
  SettingLandingBannersBreadsHeaderContainer,
  SettingLandingBannersBreadsMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersBreadsDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersBreadsHeaderContainer />
      <SettingLandingBannersBreadsMainContainer />
    </section>
  );
};
