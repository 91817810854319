export const CollectionListFieldsKeyComponent = () => {
  return (
    <thead>
      <tr className="collectionListFieldsKeyComponent">
        <th className="collectionListFieldsKeyComponent__checkbox">
          <input type="checkbox" />
        </th>
        <th className="collectionListFieldsKeyComponent__name">Nombre</th>
        <th className="collectionListFieldsKeyComponent__description">
          Descripcion
        </th>
        <th className="collectionListFieldsKeyComponent__time">
          Hora de apertura
        </th>
        <th className="collectionListFieldsKeyComponent__time">
          Hora de cierre
        </th>
        <th className="collectionListFieldsKeyComponent__state">Estado</th>
      </tr>
    </thead>
  );
};
