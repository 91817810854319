import {
  CollectionCreateHeaderContainer,
  CollectionCreateInformationContainer,
} from "../../../containers";

export const ProductCollectionCreatePage = () => {
  return (
    <section>
      <CollectionCreateHeaderContainer />
      <CollectionCreateInformationContainer />
    </section>
  );
};
