import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ListHeaderContainer,
  PaginationContainer,
  TableContainer,
} from "../../../../containers";
import {
  filterObjectKeys,
  getUrlWithID,
  handleEnterKey,
} from "../../../../helpers";
import { getListReception } from "../../../../store/reception";
import { LoadingComponent } from "../../../../components";

const URL = "configuracion/correos-de-recepcion";
const dataInput = { id: "searchReception", placeholder: "Buscar Correo" };
const allowedKeys = ["id", "email", "tipoFormulario"];
const dataHeader = {
  title: "Correos de Recepción ",
  nameButton: "Crear Correo",
  url: "",
};

export const SettingReceptionEmailIndexPage = () => {
  const { receptionData, showLoading } = useSelector(
    ({ listReception }) => listReception
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageReception = serachParams.get("page");
  const searchReception = serachParams.get("search");
  let receptionParams = {
    page_size: 20,
    page: pageReception ?? 1,
    search: searchReception ?? "",
  };

  useEffect(() => {
    dispatch(getListReception(receptionParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageReception, searchReception]);

  const filter = () => {
    const searchValue = document.getElementById("searchReception").value;
    if (searchValue === "") {
      dispatch(getListReception());
      navigate("/" + URL);
      return;
    }
    const searchUrl = `/configuracion/correos-de-recepcion?search=${searchValue}`;

    navigate(searchUrl);
  };
  const newData = receptionData?.results?.map((obj) =>
    filterObjectKeys(obj, allowedKeys)
  );
  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} addButton={false} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TableContainer
            filter={filter}
            handleKeyDown={(e) => handleEnterKey(e, filter)}
            dataInput={dataInput}
            data={newData}
            url={(id) => getUrlWithID(id, URL)}
            titles={allowedKeys}
            isFilter={false}
          />
          <PaginationContainer data={receptionData} />
        </>
      )}
    </section>
  );
};
