import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCreateOrder } from "../store/order/ordersSlice";

export const useField = (initialField = {}) => {
  const [valueState, setValueState] = useState(initialField);

  const dispatch = useDispatch();

  const onChange = (e) => {
    if (!e.label) {
      dispatch(setCreateOrder({ [e.target.name]: e.target.value }));
      setValueState({ ...valueState, [e.target.name]: e.target.value });
    } else {
      dispatch(setCreateOrder({ [e.name]: e.value }));
      setValueState({ ...valueState, [e.name]: e.value });
    }
  };
  return { ...valueState, valueState, onChange };
};
