import React from "react";
import { useSelector } from "react-redux";
import { OrderListFieldsValueComponent } from "../../components";

export const OrderListFieldsValueContainer = () => {
  const { orderData } = useSelector((state) => state.listOrders);
  const listOrders = orderData?.results;
  return (
    <tbody>
      {listOrders?.length > 0 &&
        listOrders?.map((order) => {
          return <OrderListFieldsValueComponent key={order.id} order={order} />;
        })}
    </tbody>
  );
};
