import { ClientRetrieveOrderFieldsKeyComponent } from "../../components";
import { ClientRetrieveOrderFieldsValueContainer } from "../../containers";

export const ClientRetrieveOrderTableContainer = ({ clientId }) => {
  return (
    <table className="clientRetrieveOrderTableContainer">
      <ClientRetrieveOrderFieldsKeyComponent />
      <ClientRetrieveOrderFieldsValueContainer  clientId={clientId}/>
    </table>
  );
};
