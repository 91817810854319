import {
  SettingLandingReservationHeaderContainer,
  SettingLandingReservationMainContainer,
} from "../../../../containers";

export const SettingLandingReservationDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingReservationHeaderContainer />
      <SettingLandingReservationMainContainer />
    </section>
  );
};
