import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setCollaboratorsLoading,
  setListCollaborators,
  setRetrieveCollaborators,
  setRetrieveCollaboratorsLoading,
} from "./collaboratorsSlice";

const obj = {
  page_size: 20,
  page: 1,
  search: "",
};

export const getListCollaborators = (params = obj) => {
  return async (dispatch) => {
    dispatch(setCollaboratorsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/colaboradores/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "configuracion/landing/colaboradores";
        value.text = "Collaborators";
        value.page = params.page;
        dispatch(setListCollaborators({ data: value, params: params }));
        dispatch(setCollaboratorsLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveCollaborators = (collaboratorsId) => {
  return async (dispatch) => {
    dispatch(setRetrieveCollaboratorsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/colaboradores/${collaboratorsId}/`,
      token
    ).then((value) => {
      dispatch(setRetrieveCollaborators({ data: value }));
      dispatch(setRetrieveCollaboratorsLoading({ showLoading: false }));
    });
  };
};
