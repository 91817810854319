import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CouponListContainer,
  ListHeaderContainer,
  PaginationContainer,
} from "../../containers";
import { getListCoupon } from "../../store/coupon";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

export const CouponIndexPage = () => {
  const { couponData, showLoading } = useSelector((state) => state.listCoupon);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageCoupon = serachParams.get("page");
  const searchCoupon = serachParams.get("search");
  let couponParams = {
    page_size: 20,
    page: pageCoupon ?? 1,
    search: searchCoupon ?? "",
  };
  useEffect(() => {
    dispatch(getListCoupon(couponParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCoupon, searchCoupon]);

  const dataHeader = {
    title: "Cupones",
    nameButton: "Crear Cupon",
    url: "/cupones/crear",
  };

  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <CouponListContainer />
          <PaginationContainer data={couponData} />
        </>
      )}
    </section>
  );
};
