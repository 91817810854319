import { createSlice } from "@reduxjs/toolkit";

export const retrieveBannerJobsSlice = createSlice({
  name: "retrieveBannerJobs",
  initialState: {
    bannerJobsData: {
      coverDesktop: "",
      coverMobile: "",
      photos: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerJobs: (state, action) => {
      state.bannerJobsData.coverDesktop =
        action.payload?.data?.coverDesktop ||
        state?.bannerJobsData?.coverDesktop;
      state.bannerJobsData.coverMobile =
        action.payload?.data?.coverMobile || state?.bannerJobsData?.coverMobile;
      state.bannerJobsData.photos =
        action.payload?.data?.photos || state?.bannerJobsData?.photos;
    },
    setRetrieveBannerJobsLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerJobs, setRetrieveBannerJobsLoading } =
  retrieveBannerJobsSlice.actions;
