import { FaTrash } from "react-icons/fa";
import { TitleInformationComponent } from "../../components";

export const SectionTableContainer = ({
  data,
  handleOnchangeDispath,
  handleOnchangeAddFuntion,
  title,
}) => {
  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={title} />
      <table width={"90%"} style={{ textAlign: "center" }}>
        <thead width={"90%"}>
          <tr>
            <th>Descripción</th>
            <th>Orden</th>
            <th>¿Activo?</th>
            <th>¿Eliminar?</th>
          </tr>
        </thead>
        <tbody width={"90%"} className="sectionTableContainer">
          {data?.map((item, index) => (
            <tr key={index}>
              <td>
                <textarea
                  name="description"
                  value={item.description}
                  onChange={(e) => handleOnchangeDispath(e, index)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="order"
                  value={item.order}
                  onChange={(e) => handleOnchangeDispath(e, index)}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={item.active}
                  name="active"
                  onChange={(e) => handleOnchangeDispath(e, index)}
                />
              </td>
              <td onClick={() => {}}>
                <FaTrash />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="productDetailGroupModifiedContainer__container__left__button"
        onClick={handleOnchangeAddFuntion}
      >
        <div>Agregar {title}</div>
      </div>
    </div>
  );
};
