import { useSelector } from "react-redux";
import { CombosListFieldsValueComponent } from "../../components";

export const CombosListFieldsValueContainer = () => {
  const { combosData } = useSelector((state) => state.listCombos);
  return (
    <tbody>
      {combosData?.results?.length > 0 &&
        combosData?.results?.map((combo) => {
          return (
            <CombosListFieldsValueComponent key={combo.id} combo={combo} />
          );
        })}
    </tbody>
  );
};
