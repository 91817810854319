import { useState, useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { ModalNotificationContainer } from "../ModalNotificationContainer/ModalNotificationContainer";
import { ReactComponent as IconMenu } from "../../assets/topnav-menu.svg";
import { ReactComponent as IconSearch } from "../../assets/topnav-search.svg";
import { ReactComponent as IconNotification } from "../../assets/topnav-notification.svg";
import { ModalPerfilContainer } from "../ModalPerfilContainer/ModalPerfilContainer";
import { InputSearchNavComponent } from "../../components";
import { useNavigate } from "react-router-dom";
import dataPage from "../../data/dataPage.json";
import { requestGetAuthorization } from "../../services/requestGet";

export const TopNavContainer = () => {
  const [modalNotificationHidden, setModalNotificationHidden] = useState(false);
  const [modalPerfilHidden, setModalPerfilHidden] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [dataNotification, setDataNotification] = useState([]);

  const navigate = useNavigate();

  const { collapseSidebar } = useProSidebar();

  const handleCollapse = () => {
    collapseSidebar();
  };

  const handleToggleNotification = () => {
    setModalNotificationHidden(!modalNotificationHidden);
  };

  const handleToggleModalPerfil = () => {
    setModalPerfilHidden(!modalPerfilHidden);
  };

  const handleEnter = (e) => {
    if (e.key !== "Enter") return;

    const simplicado = textSearch.toLocaleLowerCase().trim();

    const result = (nameLink) => {
      const result = new RegExp(simplicado).test(nameLink);
      return result;
    };

    dataPage?.map((item) => result(item.name) && navigate(item.path));
    setTextSearch("");
  };

  function handleSearch(e) {
    setTextSearch(e.target.value);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/notificaciones/", token).then((value) => {
      setDataNotification(value);
    });
  }, []);

  

  return (
    <div className="top-nav">
      <div className="top-nav__open-menu">
        <div className="top-nav__open-menu__svg" onClick={handleCollapse}>
          <IconMenu />
        </div>
        <div className="top-nav__open-menu__search">
          <label
            className="top-nav__open-menu__search__svg"
            htmlFor="searchPageInput"
          >
            <IconSearch />
          </label>
          <div className="top-nav__open-menu__search__input">
            <InputSearchNavComponent
              placeholder="Search anything..."
              handleSearch={handleSearch}
              handleEnter={handleEnter}
              textSearch={textSearch}
              name="searchPageInput"
              id="searchPageInput"
            />
          </div>
        </div>
      </div>

      <div className="top-nav__container-right">
        <div
          className="top-nav__container-right__notification"
          onClick={handleToggleNotification}
        >
          <IconNotification />
          <ModalNotificationContainer
            modalNotificationHidden={modalNotificationHidden}
            setModalNotificationHidden={setModalNotificationHidden}
            dataNotification={dataNotification}
          />
        </div>
        <div className="top-nav__container-right__separate">
          <span></span>
        </div>
        <div className="top-nav__container-right__icon">
          <img
            src="https://i.ibb.co/gm0F52m/5.png"
            alt="profile"
            width="40px"
            onClick={handleToggleModalPerfil}
          />
          <ModalPerfilContainer
            modalPerfilHidden={modalPerfilHidden}
            setModalPerfilHidden={setModalPerfilHidden}
          />
        </div>
      </div>
    </div>
  );
};
