import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import { setRetrieveCoupon } from "../../store/coupon/couponSlice";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
const typeCoupons = [
  { id: 1, name: "couponType", value: 1, label: "Para el subtotal" },
  { id: 2, name: "couponType", value: 2, label: "Para delivery" },
  { id: 3, name: "couponType", value: 3, label: "Para ciertos productos" },
  {
    id: 4,
    name: "couponType",
    value: 4,
    label: "Para ciertas colecciones",
  },
];
const typeDescount = [
  { id: 1, name: "discontType", value: 1, label: "Porcentaje" },
  { id: 2, name: "discontType", value: 2, label: "Monto" },
];

export const CouponDetailInformationContainer = ({ couponData }) => {
  const dispatch = useDispatch();

  const handleOnchage = (e) => {
    dispatch(setRetrieveCoupon({ data: { [e.target.name]: e.target.value } }));
  };

  const handleSelectOnchange = (e) => {
    dispatch(setRetrieveCoupon({ data: { [e.name]: e.label } }));
  };

  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Nombre"}
        name="name"
        handleOnchange={handleOnchage}
        isInput={true}
        value={couponData?.name}
      />
      <SelectOptionsContainer
        title={"Codigo"}
        handleOnchange={handleOnchage}
        name="code"
        isInput={true}
        value={couponData?.code}
      />
      <SelectOptionsContainer
        title={"Descripción"}
        handleOnchange={handleOnchage}
        name="description"
        isTextArea={true}
        value={couponData?.description}
      />
      <SelectOptionsContainer
        title={"Tipo de cupon"}
        handleOnchange={handleSelectOnchange}
        data={typeCoupons}
        placeholder={"Seleccionar tipo"}
        isSelect={true}
        valueDefault={couponData?.couponType}
      />
      <SelectOptionsContainer
        title={"Tipo de descuento"}
        handleOnchange={handleSelectOnchange}
        data={typeDescount}
        placeholder={"Seleccionar tipo"}
        isSelect={true}
        valueDefault={couponData?.discontType}
      />
      <SelectOptionsContainer
        title={"Descuento"}
        handleOnchange={handleOnchage}
        name="discont"
        placeholder={"0"}
        isNumber={true}
        value={couponData.discont || 0}
      />
      <SelectOptionsContainer
        title={"Stock de cupones"}
        handleOnchange={handleOnchage}
        name="numberCouponsLimit"
        isNumber={true}
        value={couponData.numberCouponsLimit}
      />
      <SelectOptionsContainer
        title={"Fecha de Inicio"}
        handleOnchange={handleOnchage}
        name="startDate"
        isInputDate={true}
        value={couponData?.startDate}
      />
      <SelectOptionsContainer
        title={""}
        handleOnchange={handleOnchage}
        name="startDateTime"
        isInputHour={true}
        value={couponData?.startDateTime}
      />
      <SelectOptionsContainer
        title={"Fecha de expiración"}
        handleOnchange={handleOnchage}
        name="expirationDate"
        isInputDate={true}
        value={couponData?.expirationDate}
      />
      <SelectOptionsContainer
        title={""}
        handleOnchange={handleOnchage}
        name="expirationDateTime"
        isInputHour={true}
        value={couponData?.expirationDateTime}
      />
    </div>
  );
};
