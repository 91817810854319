import { useEffect, useState } from "react";
import $ from "jquery";
import { useParams } from "react-router-dom";
import CoverageService from "../../../../../services/coverageService";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
export const SettingStoreCoverageMapIndexPage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dataCoverage, setDataCoverage] = useState({ id: null, name: "" });
  const [polygonUpdate, setPolygonUpdate] = useState([]);
  const [isUpdateCoverage, setIsUpdateCoverage] = useState(false);
  const [polygonCreate, setPolygonCreate] = useState([]);
  const [isCreateCoverage, setIsCreateCoverage] = useState(false);
  const token = localStorage.getItem("token");
  const params = useParams();
  useEffect(() => {
    mainMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const mainMap = async (isUpdateCoverage = false, coverageId = null) => {
    const mapProp = {
      center: new window.google.maps.LatLng(-12.0890202, -77.0333281),
      zoom: 12,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      draggable: true,
    };
    const map = new window.google.maps.Map(
      document.getElementById("map"),
      mapProp
    );
    const coverages = await CoverageService.getListCoverages(
      params.storeId,
      token
    );
    if (coverages.length > 0) {
      coverages.map(async (coverage) => {
        const coordinates = await CoverageService.getListCoordinates(
          coverage.id,
          token
        );
        const polygon = new window.google.maps.Polygon({
          paths: coordinates,
          strokeColor: `#000000`,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: `#000000`,
          fillOpacity: 0.35,
          map: map,
        });
        if (isUpdateCoverage && coverage.id === coverageId) {
          polygon.setEditable(true);
          window.google.maps.event.addListener(
            polygon.getPath(),
            "set_at",
            function (index) {
              let paths = polygon.getPaths().getArray();
              let array = [];
              for (let i = 0; i < paths.length; i++) {
                let path = paths[i];
                for (let j = 0; j < path.getLength(); j++) {
                  let latlng = path.getAt(j).toUrlValue().split(",");
                  let coordinate = {
                    latitude: latlng[0],
                    longitude: latlng[1],
                  };
                  array.push(coordinate);
                }
              }
              setPolygonUpdate(array);
            }
          );
          window.google.maps.event.addListener(
            polygon.getPath(),
            "insert_at",
            function (index) {
              let paths = polygon.getPaths().getArray();
              let array = [];
              for (let i = 0; i < paths.length; i++) {
                let path = paths[i];
                for (let j = 0; j < path.getLength(); j++) {
                  let latlng = path.getAt(j).toUrlValue().split(",");
                  let coordinate = {
                    latitude: latlng[0],
                    longitude: latlng[1],
                  };
                  array.push(coordinate);
                }
              }
              setPolygonUpdate(array);
            }
          );
        }

        window.google.maps.event.addListener(
          polygon,
          "click",
          function (event) {
            if (isUpdateCoverage) {
              setDataCoverage(dataCoverage);
              handleOpen();
            } else {
              setDataCoverage({ id: coverage.id, name: coverage.name });
              handleOpen();
            }
          }
        );
      });
    }

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
    });

    drawingManager.setMap(map);

    const drawingCoverage = async (event) => {
      let polygon = [];
      setDataCoverage({ id: null, name: "" });
      if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        event.overlay.setEditable(true);
        $.each(event.overlay.getPath().getArray(), function (key, latlng) {
          let lat = latlng.lat();
          let lon = latlng.lng();
          let coordinate = {
            latitude: lat.toString(),
            longitude: lon.toString(),
          };
          polygon.push(coordinate);
        });
      }
      drawingManager.setOptions({
        drawingControlOptions: { drawingModes: [] },
        drawingMode: null,
      });
      setIsCreateCoverage(true);
      setPolygonCreate(polygon);
      window.google.maps.event.addListener(
        event.overlay.getPath(),
        "insert_at",
        function (index) {
          let paths = event.overlay.getPaths().getArray();
          let array = [];
          for (let i = 0; i < paths.length; i++) {
            let path = paths[i];
            for (let j = 0; j < path.getLength(); j++) {
              let latlng = path.getAt(j).toUrlValue().split(",");
              let coordinate = {
                latitude: latlng[0],
                longitude: latlng[1],
              };
              array.push(coordinate);
            }
          }
          setPolygonCreate(array);
        }
      );
      window.google.maps.event.addListener(
        event.overlay,
        "click",
        function (event) {
          handleOpen();
        }
      );
      // await CoverageService.createCoverage(body, token);
    };

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      drawingCoverage
    );
  };

  const editCoverage = () => {
    setIsUpdateCoverage(true);
    mainMap(true, dataCoverage.id);
    handleClose();
  };
  const updateCoverage = async () => {
    const body = {
      coordenadas: polygonUpdate,
      name: dataCoverage.name,
    };
    await CoverageService.updateCoverage(dataCoverage.id, token, body);
    setIsUpdateCoverage(false);
    handleClose();
    setDataCoverage({ id: null, name: "" });
    mainMap();
  };
  const createCoverage = async () => {
    const body = {
      coordenadas: polygonCreate,
      shop: params.storeId,
      name: dataCoverage.name,
    };
    await CoverageService.createCoverage(body, token);
    setIsCreateCoverage(false);
    handleClose();
    setDataCoverage({ id: null, name: "" });
    mainMap();
  };
  const deleteCoverage = async () => {
    await CoverageService.deleteCoverage(dataCoverage.id, token);
    handleClose();
    mainMap();
  };
  const cancelCoverage = async () => {
    mainMap();
    setIsUpdateCoverage(false);
    setIsCreateCoverage(false);
    setDataCoverage({ id: null, name: "" });
    handleClose();
  };
  const onChange = (e) => {
    setDataCoverage({ id: dataCoverage.id, name: e.target.value });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "16px",
    p: 4,
  };

  return (
    <>
      <div className="map" id="map"></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <input
            className="map__title"
            type="text"
            value={dataCoverage.name}
            placeholder="Ingrese el nombre"
            onChange={onChange}
          />
          <div className="map__buttons">
            {!isUpdateCoverage && !isCreateCoverage && (
              <>
                <button onClick={editCoverage}>Editar</button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={deleteCoverage}
                >
                  Eliminar
                </button>
              </>
            )}
            {isUpdateCoverage && (
              <>
                <button onClick={updateCoverage}>Guardar</button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={cancelCoverage}
                >
                  Cancelar
                </button>
              </>
            )}
            {isCreateCoverage && (
              <>
                <button onClick={createCoverage}>Crear</button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={cancelCoverage}
                >
                  Cancelar
                </button>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};
