const orderStateColor = (state) => {
  const stateValue =
    state === true ? "Activo" : state === false ? "Inactivo" : state;
  const colors = {
    Recibida: "recibida",
    Confirmada: "confirmada",
    "Lista para retiro": "lista-para-retiro",
    "En camino": "en-camino",
    Entregada: "entregada",
    Cancelada: "cancelada",
    Activo: "activo",
    Inactivo: "inactivo",
    "En Revisión": "en-revision",
    Pagado: "activo",
    Rechazado: "inactivo",
    Pendiente: "pendiente",
  };

  return colors[stateValue] || "";
};

export default orderStateColor;
