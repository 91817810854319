import { TitleComponent } from "../../components";
import { ClientRetrieveAddressTableContainer } from "../ClientRetrieveAddressTableContainer/ClientRetrieveAddressTableContainer";

export const ClientRetrieveAddressContainer = ({ clientId }) => {
  const titleStyle = { fontSize: "18px" };
  return (
    <div className="clientRetrieveAddressContainer">
      <div className="clientRetrieveAddressContainer__title">
        <TitleComponent title={"DIRECCIONES"} style={titleStyle} />
      </div>
      <div className="clientRetrieveAddressContainer__line">
        <hr className="clientRetrieveAddressContainer__line__one" />
        <hr className="clientRetrieveAddressContainer__line__two" />
      </div>
      <div className="clientRetrieveAddressContainer__table">
        <ClientRetrieveAddressTableContainer clientId={clientId}/>
      </div>
    </div>
  );
};
