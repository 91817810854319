import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { useNavigate } from "react-router-dom";
import {
  processPhotoFormData,
  processPhotosArray,
} from "../../helpers/convertToFormData";

const photoFields = [
  { fieldName: "coverDesktop", dataField: "coverDesktop" },
  { fieldName: "coverMobile", dataField: "coverMobile" },
];

export const SettingLandingBannersHistoryHeaderContainer = () => {
  const { bannerHistoryData } = useSelector(
    (state) => state.retrieveBannerHistory
  );
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const navigate = useNavigate();
  const handleUpdate = async () => {
    let body = { ...bannerHistoryData };
    const formData = new FormData();

    await processPhotoFormData(body, formData, photoFields);
    await processPhotosArray(body?.photos, formData, "photos", "photo", "slug");

    await requestPatchFileAuthorization(
      "admin/banners/historias/",
      formData
    ).then(() => {
      navigate("/configuracion/landing/banners");
    });
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Historia" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Actualizar Historia"}
          onClick={toggleModalUpdate}
        />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Historia"
            msg="¿Está seguro que desea actualizar Historia?"
            onClose={toggleModalUpdate}
            onConfirm={handleUpdate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
