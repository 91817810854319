import { createSlice } from "@reduxjs/toolkit";
export const retrieveBannerNewSlice = createSlice({
  name: "retrieveBannerNew",
  initialState: {
    photo: {},
    slugItem: null,
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerNew: (state, action) => {
      state.photo = action.payload.data;
      state.slugItem = action.payload.slugItem;
    },
    setRetrieveBannerNewSlug: (state, action) => {
      state.slugItem = action.payload.slugItem;
    },
    setRetrieveBannerNewLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveBannerNewLoading,
  setRetrieveBannerNew,
  setRetrieveBannerNewSlug,
} = retrieveBannerNewSlice.actions;
