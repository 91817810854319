import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import { setRetrieveBannerHome } from "../../store/banner/home/homeSlice";
import {
  setRetrieveCombo,
  setUpdateComboChannels,
  setUpdateComboColections,
  setUpdateComboComplements,
  setUpdateComboTags,
} from "../../store/combo/comboSlice";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const ComboDetailInformationContainer = ({
  comboData,
  listOptionChannelData,
  listOptionsCollection,
  listOptionTagData,
  listOptionComplementData,
  params,
}) => {
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "image") {
      const file = e.target.files[0];
      value = {
        url: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      };
    }
    dispatch(setRetrieveBannerHome({ data: { [key]: value } }));
  };

  const handleOnChangeCheck = (e) => {
    dispatch(setRetrieveCombo({ data: { [e.target.name]: e.target.checked } }));
  };

  const handleOnchageMultipleChannels = (e) => {
    dispatch(setUpdateComboChannels({ channels: e }));
  };
  const handleOnchageMultipleColections = (e) => {
    dispatch(setUpdateComboColections({ colections: e }));
  };
  const handleOnchageMultipleTags = (e) => {
    dispatch(setUpdateComboTags({ tags: e }));
  };
  const handleOnchageMultipleComplement = (e) => {
    dispatch(setUpdateComboComplements({ complements: e }));
  };
  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Nombre"}
        name="name"
        handleOnchange={(e) => {
          handleOnChange(e);
        }}
        isInput={true}
        value={comboData.name}
      />
      <SelectOptionsContainer
        title={"Slug"}
        handleOnchange={(e) => {
          handleOnChange(e);
        }}
        name="slug"
        isInput={true}
        value={comboData.slug}
      />
      <SelectOptionsContainer
        title={"Descripción"}
        handleOnchange={(e) => {
          handleOnChange(e);
        }}
        name="description"
        isTextArea={true}
        value={comboData.description}
      />
      <SelectOptionsContainer
        title={"Imagen"}
        handleOnchange={(e) => {
          handleOnChange(e);
        }}
        name="image"
        isImage={true}
        value={comboData.image}
      />
      <SelectOptionsContainer
        title={"Canal"}
        handleOnchange={handleOnchageMultipleChannels}
        name="channels"
        isInputMulti={true}
        value={comboData.channels}
        dataList={listOptionChannelData}
      />
      <SelectOptionsContainer
        title={"Colección"}
        handleOnchange={handleOnchageMultipleColections}
        name="colections"
        isInputMulti={true}
        value={comboData.colections}
        dataList={listOptionsCollection}
      />
      <SelectOptionsContainer
        title={"Tags"}
        handleOnchange={handleOnchageMultipleTags}
        name="tags"
        isInputMulti={true}
        value={comboData.tags}
        dataList={listOptionTagData}
      />
      <SelectOptionsContainer
        title={"Complementar Pedido"}
        handleOnchange={handleOnchageMultipleComplement}
        name="complements"
        isInputMulti={true}
        value={comboData.complements}
        dataList={listOptionComplementData}
      />
      <SelectOptionsContainer
        title={"Igv"}
        handleOnchange={handleOnChangeCheck}
        name="igv"
        isCheckbox={true}
        value={comboData.igv}
      />
      <SelectOptionsContainer
        title={"Precio"}
        handleOnchange={handleOnChange}
        name="price"
        isNumber={true}
        value={comboData.price}
      />
      <SelectOptionsContainer
        title={"Order"}
        handleOnchange={handleOnChange}
        name="order"
        isNumber={true}
        value={comboData.order}
      />
      <SelectOptionsContainer
        title={"Activo"}
        handleOnchange={handleOnChangeCheck}
        name="active"
        isCheckbox={true}
        value={comboData.active}
      />
      {params.combosId && (
        <SelectOptionsContainer
          title={"Codigo"}
          handleOnchange={handleOnChange}
          name="codigo"
          isInput={true}
          value={comboData?.codigo}
        />
      )}
    </div>
  );
};
