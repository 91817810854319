import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  clearUpdateProductNote,
  setUpdateProductNote,
} from "../../store/product/productSlice";

export const ProductDetailNoteContainer = ({
  options,
  index,
  data,
  notes,
  setNotes,
}) => {
  const dispatch = useDispatch();

  const noteInitial = !!data
    ? {
        value: data?.id,
        label: data?.name,
        name: "modifier",
      }
    : false;
  const handleOnchageNote = (e) => {
    if (!!e) {
      const newNotes = [...notes];
      newNotes[index] = {
        id: e.value,
        name: e.label,
      };
      setNotes(newNotes);
      dispatch(
        setUpdateProductNote({
          groupIndex: 0,
          index: index,
          noteId: e.value,
        })
      );
    } else {
      clearNoteCurrent();
    }
  };
  const clearNoteCurrent = (e) => {
    const newNotes = [...notes];
    newNotes.splice(index, 1);
    setNotes(newNotes);
    dispatch(
      clearUpdateProductNote({
        groupIndex: 0,
        index: index,
      })
    );
  };
  return (
    <div className="productDetailNoteContainer">
      <div className="productDetailNoteContainer__select">
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={noteInitial}
          isSearchable={true}
          isClearable={true}
          name="note"
          options={options}
          placeholder={"Elije una nota"}
          onChange={handleOnchageNote}
        />
      </div>
      <div className="productDetailNoteContainer__button">
        <button onClick={clearNoteCurrent}>Eliminar</button>
      </div>
    </div>
  );
};
