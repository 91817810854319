import { StoreListFieldsKeyComponent } from "../../components";
import { StoreListFieldsValueContainer } from "../";

export const StoreListTableContainer = () => {
  return (
    <table className="clientListTableContainer">
      <StoreListFieldsKeyComponent />
      <StoreListFieldsValueContainer />
    </table>
  );
};
