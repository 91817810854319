import {
  StoreListTableContainer,
  StoreSearchContainer,
} from "../../containers";

export const StoreListContainer = ({ setParams, params }) => {
  return (
    <div className="orderListContainer">
      <div className="orderListContainer__search">
        <StoreSearchContainer setParams={setParams} params={params} />
      </div>
      <div className="orderListContainer__table">
        <StoreListTableContainer />
      </div>
    </div>
  );
};
