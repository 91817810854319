import { useDispatch, useSelector } from "react-redux";
import { SelectOptionsContainer } from "..";
import { useEffect } from "react";
import {
  getRetrieveBannerNew,
  setRetrieveBannerNew,
  setRetrieveBannerNewSlug,
} from "../../store/coffeeShop/banner/new";
import { LoadingComponent } from "../../components";
//getRetrieveBannerMain
export const SettingCoffeeShopBannersNewMainContainer = () => {
  const { photo, slugItem, showLoading } = useSelector(
    (state) => state.retrieveBannerNew
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRetrieveBannerNew());
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    dispatch(setRetrieveBannerNew({ data: { [key]: value } }));
  };

  const handleOnchage = (e) => {
    dispatch(setRetrieveBannerNewSlug({ slugItem: e.target.value }));
  };

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailMainContainer">
          <SelectOptionsContainer
            title={"Lo Más Nuevo"}
            handleOnchange={handleOnChange}
            name={"photo"}
            isImageEdit={true}
            value={photo?.photo?.url}
          />
          <SelectOptionsContainer
            title={"Slug"}
            name="slugItem"
            handleOnchange={handleOnchage}
            isInput={true}
            value={slugItem}
          />
        </div>
      )}
    </>
  );
};
