import { getFormatDate } from "../../helpers";
import { Link } from "react-router-dom";

export const ClientRetrieveOrderFieldsValueComponent = ({ order }) => {
  const date = new Date(order.created);
  const url = `/ordenes/${order.id}/detalles`;
  return (
    <tr className="clientRetrieveOrderFieldsValueComponent">
      <td className="clientRetrieveOrderFieldsValueComponent__code">
        <Link to={url}>{order.codigo}</Link>
      </td>
      <td className="clientRetrieveOrderFieldsValueComponent__modality">
        <Link to={url}>{order.modality}</Link>
      </td>
      <td className="clientRetrieveOrderFieldsValueComponent__shop">
        <Link to={url}>{order.shop?.name}</Link>
      </td>
      <td className="clientRetrieveOrderFieldsValueComponent__total">
        <Link to={url}>{order.totalNetAmount.toFixed(2)}</Link>
      </td>
      <td className="clientRetrieveOrderFieldsValueComponent__state">
        <Link to={url}>{order.state}</Link>
      </td>
      <td className="clientRetrieveOrderFieldsValueComponent__date">
        <Link to={url}>{getFormatDate(date)}</Link>
      </td>
    </tr>
  );
};
