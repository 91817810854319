import { ButtonPushComponent, KeyValueComponent } from "../../components";
import { getAbbreviatedFormatDate } from "../../helpers";

export const OrderDetailPaymentContainer = ({ data }) => {
  const payment = data?.pagos?.[0]
  const keyValueStyle = {
    fontSize: "14px",
    display: "flex",
    margin: "10px 0px",
    justifyContent: "space-between",
  };
  return (
    <div className="orderDetailPaymentContainer">
      <div className="orderDetailSummaryContainer__title">
        <p>Pago</p>
      </div>
      <div className="orderDetailPaymentContainer__fields">
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Código"
          valueName={`#${payment?.code}`}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Estado"
          valueName={payment?.state}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Método de Pago"
          valueName={payment?.paymentMethod?.name}
        />
        <KeyValueComponent
          style={keyValueStyle}
          keyName="Fecha"
          valueName={
            payment?.created && getAbbreviatedFormatDate(payment?.created)
          }
        />
        <div className="orderDetailPaymentContainer__button">
          <p>Ver más detalles</p>
          <ButtonPushComponent
            name={"Ver pago"}
            url={`/pagos/${payment?.id}/detalles`}
            margin={"0"}
          />
        </div>
      </div>
    </div>
  );
};
