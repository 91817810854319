import {
  CollectionListTableContainer,
  CollectionSearchContainer,
} from "../../containers";

export const CollectionListContainer = () => {
  return (
    <div className="collectionListContainer">
      <div className="collectionListContainer__search">
        <CollectionSearchContainer />
      </div>
      <div className="collectionListContainer__table">
        <CollectionListTableContainer />
      </div>
    </div>
  );
};
