import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
// import { requestDelete } from "../../services/requestDelete";
import {
  requestPatchAuthorization,
  // requestPatchFileAuthorization,
} from "../../services/requestPatch";
import { clearUpdateCombo } from "../../store/combo/comboSlice";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const CouponDetailHeaderContainer = () => {
  // const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { couponData } = useSelector((state) => state.retrieveCoupon);

  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const openOrCloseModal = () => {
  //   setIsOpenDelete(!isOpenDelete);
  // };
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const convertMap = (array) => {
    return array.map((item) => item.id);
  };

  const conbinedString = (date, time) => {
    const combinedString = date + "T" + time + "-05:00";
    return combinedString;
  };

  const handleCreateCombo = async () => {
    const body = { ...couponData };
    body.expirationDate = conbinedString(
      couponData?.expirationDate,
      couponData?.expirationDateTime
    );
    body.startDate = conbinedString(
      couponData?.startDate,
      couponData?.startDateTime
    );
    body.users && (body.users = convertMap(body.users));
    body.colections && (body.colections = convertMap(body.colections));
    body.products && (body.products = convertMap(body.products));
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/cupones/${path.couponId}/`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateCombo());
      navigate("/cupones");
    });
  };
  // const deleteCombo = () => {
  //   const token = localStorage.getItem("token");
  //   requestDelete(`admin/combos/${path.combosId}/`, token).then(() =>
  //     navigate("/combos")
  //   );
  // };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del cupon" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        {/* <ButtonPushComponent
          name={"Eliminar"}
          color={"red"}
          onClick={openOrCloseModal}
        /> */}
        <ButtonPushComponent name={"Guardar"} onClick={toggleModalUpdate} />
      </div>

      {/* {isOpenDelete && (
        <ModalLayout>
          <ModalContainer
            title="Eliminar Combo"
            msg="¿Está seguro que desea eliminar el combo?"
            style={canceledStateStyle}
            onClose={openOrCloseModal}
            onConfirm={deleteCombo}
          />
        </ModalLayout>
      )} */}

      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Combo"
            msg="¿Está seguro que desea actualizar el combo?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
