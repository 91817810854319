import { useState } from "react";

export const useDate = (initialField = []) => {
  const [valueState, setValueState] = useState(initialField);

  const onChange = (e) => {
    if (e.length >= 2) {
      let valueMonthFirst = e[0].monthIndex + 1;
      let valueMonthSecond = e[1].monthIndex + 1;

      let valueDayFirst = e[0].day;
      let valuedaySecond = e[1].day;

      if (e[0].monthIndex < 10) {
        valueMonthFirst = `0${valueMonthFirst}`;
      }
      if (e[1].monthIndex < 10) {
        valueMonthSecond = `0${valueMonthSecond}`;
      }
      if (e[0].day < 10) {
        valueDayFirst = `0${valueDayFirst}`;
      }
      if (e[1].day < 10) {
        valuedaySecond = `0${valuedaySecond}`;
      }

      const startDate = `${e[0].year}-${valueMonthFirst}-${valueDayFirst}`;
      const endDate = `${e[1].year}-${valueMonthSecond}-${valuedaySecond}`;
      setValueState([startDate, endDate]);
    }
  };
  return [valueState, onChange];
};
