import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setListOrders,
  setOrderLoading,
  setRetrieveOrder,
  setRetrieveOrderItems,
  setRetriveOrderItemsLoading,
  setRetriveOrderLoading,
} from "./ordersSlice";

export const getListOrders = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch(setOrderLoading({ showLoading: true }));
    requestGetParamsAuthorization("admin/ordenes/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "ordenes";
        value.text = "Ordenes";
        value.page = params.page;
        dispatch(setListOrders({ data: value, params: params }));
        dispatch(setOrderLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveOrder = (orderId) => {
  return async (dispatch) => {
    dispatch(setRetriveOrderLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/ordenes/${orderId}`, token).then((value) => {
      dispatch(setRetrieveOrder({ data: value }));
      dispatch(setRetriveOrderLoading({ showLoading: false }));
    });
  };
};

export const getRetrieveOrderItems = (orderId) => {
  return async (dispatch) => {
    dispatch(setRetriveOrderItemsLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/ordenes/${orderId}/items`, token).then(
      (value) => {
        dispatch(setRetrieveOrderItems({ data: value }));
        dispatch(setRetriveOrderItemsLoading({ showLoading: false }));
      }
    );
  };
};
