import { TitleInformationComponent } from "../../components";
import {
  StoreDetailTableBodyContainer,
  StoreDetailTableHeadContainer,
} from "../";

export const StoreDetailGroupsPhotosContainer = ({
  data,
  handleOnchage,
  handleAdd,
}) => {
  return (
    <div className="comboDetailGroupsContainer">
      <TitleInformationComponent title={"Fotos de las Tiendas"} />
      <hr />
      <table className="comboDetailGroupsContainer__table">
        <StoreDetailTableHeadContainer
          name={true}
          photo={true}
          order={true}
          isActive={true}
        />
        <tbody>
          {data?.map((item, index) => (
            <StoreDetailTableBodyContainer
              key={index}
              {...item}
              onChange={handleOnchage}
              index={index}
            />
          ))}
          <tr className="comboDetailGroupsContainer__table__button">
            <td>
              <button onClick={handleAdd}>Agregar Fotos</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
