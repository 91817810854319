import { createSlice } from "@reduxjs/toolkit";
export const listOptionsStoreSlice = createSlice({
  name: "listOptionsStore",
  initialState: {
    listOptionsStore: [],
  },
  reducers: {
    setListOptionsStore: (state, action) => {
      state.listOptionsStore = action.payload.data;
    },
  },
});
export const { setListOptionsStore } = listOptionsStoreSlice.actions;

export const listOptionsStorePickupSlice = createSlice({
  name: "listOptionsStorePickup",
  initialState: {
    listOptionsStorePickup: [],
  },
  reducers: {
    setListOptionsStorePickup: (state, action) => {
      state.listOptionsStorePickup = action.payload.data;
    },
  },
});
export const { setListOptionsStorePickup } =
  listOptionsStorePickupSlice.actions;

export const listStoreSlice = createSlice({
  name: "listStore",
  initialState: {
    listStore: [],
    showLoading: false,
  },
  reducers: {
    setListStore: (state, action) => {
      state.listStore = action.payload.data;
      state.storeParams = action.payload.params;
    },
    setStoreLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListStore, setStoreLoading } = listStoreSlice.actions;

export const retrieveStoreSlice = createSlice({
  name: "retrieveStore",
  initialState: {
    storeData: {
      id: "",
      name: "",
      slug: "",
      description: "",
      image: "",
      cover: "",
      coverMobile: "",
      adress: "",
      maps: "",
      cart: "",
      active: false,
      photos: [],
      shedules: [],
      horariosRecojos: [],
      metodosEntregasTiendas: [],
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveStore: (state, action) => {
      state.storeData.id = action.payload.data.id || state.storeData.id;
      state.storeData.name = action.payload.data.name || state.storeData.name;
      state.storeData.slug = action?.payload.data?.slug || state.storeData.slug;
      state.storeData.description =
        action.payload.data?.description || state.storeData.description;
      state.storeData.image =
        action.payload.data?.image || state.storeData.image;
      state.storeData.cover =
        action.payload.data?.cover || state.storeData.cover;
      state.storeData.coverMobile =
        action.payload.data?.coverMobile || state.storeData.coverMobile;
      state.storeData.adress =
        action.payload.data?.adress || state.storeData.adress;
      state.storeData.maps = action.payload.data?.maps || state.storeData.maps;
      state.storeData.cart = action.payload.data?.cart || state.storeData.cart;
      state.storeData.active =
        action.payload.data?.active ?? state.storeData.active;
      state.storeData.photos =
        action.payload.data?.photos || state.storeData.photos;
      state.storeData.shedules =
        action.payload.data?.shedules || state.storeData.shedules;
      state.storeData.horariosRecojos =
        action.payload.data?.horariosRecojos || state.storeData.horariosRecojos;
      state.storeData.metodosEntregasTiendas =
        action.payload.data?.metodosEntregasTiendas ||
        state.storeData.metodosEntregasTiendas;
    },
    setRetrieveStoreItems: (state, action) => {
      if (action.payload.name === "name") {
        state.storeData.photos[action.payload.index].name =
          action.payload.value;
      }
      if (action.payload.name === "active") {
        state.storeData.photos[action.payload.index].active =
          action.payload.checked;
      }
      if (action.payload.name === "order") {
        state.storeData.photos[action.payload.index].order =
          action.payload.value;
      }
      if (action.payload.name === "photo") {
        state.storeData.photos[action.payload.index].photo =
          action.payload.photo;
      }
    },

    setRetrieveStorePickUpItems: (state, action) => {
      if (action.payload.name === "day") {
        state.storeData.shedules[action.payload.index].day =
          action.payload.value;
      }
      if (action.payload.name === "startTime") {
        state.storeData.shedules[action.payload.index].startTime =
          action.payload.value;
      }
      if (action.payload.name === "endTime") {
        state.storeData.shedules[action.payload.index].endTime =
          action.payload.value;
      }
      if (action.payload.name === "order") {
        state.storeData.shedules[action.payload.index].order =
          action.payload.value;
      }
      if (action.payload.name === "active") {
        state.storeData.shedules[action.payload.index].active =
          action.payload.checked;
      }
    },
    setRetrieveStoreMethodsItems: (state, action) => {
      if (action.payload.name === "modality") {
        state.storeData.metodosEntregasTiendas[action.payload.index].modality =
          action.payload.value;
      }
      if (action.payload.name === "timeOpening") {
        state.storeData.metodosEntregasTiendas[
          action.payload.index
        ].timeOpening = action.payload.value;
      }
      if (action.payload.name === "timeClosing") {
        state.storeData.metodosEntregasTiendas[
          action.payload.index
        ].timeClosing = action.payload.value;
      }
      if (action.payload.name === "price") {
        state.storeData.metodosEntregasTiendas[action.payload.index].price =
          action.payload.value;
      }
      if (action.payload.name === "active") {
        state.storeData.metodosEntregasTiendas[action.payload.index].active =
          action.payload.checked;
      }
    },
    setRetrieveStoreShedulesItems: (state, action) => {
      if (action.payload.name === "modality") {
        state.storeData.horariosRecojos[action.payload.index].modality =
          action.payload.value;
      }
      if (action.payload.name === "timeOpening") {
        state.storeData.horariosRecojos[action.payload.index].timeOpening =
          action.payload.value;
      }
      if (action.payload.name === "timeClosing") {
        state.storeData.horariosRecojos[action.payload.index].timeClosing =
          action.payload.value;
      }
      if (action.payload.name === "price") {
        state.storeData.horariosRecojos[action.payload.index].price =
          action.payload.value;
      }
      if (action.payload.name === "active") {
        state.storeData.horariosRecojos[action.payload.index].active =
          action.payload.checked;
      }
    },
    setRetrieveStoreShedulesPickUpItems: (state, action) => {
      if (action.payload.name === "startTime") {
        state.storeData.horariosRecojos[action.payload.index].startTime =
          action.payload.value;
      }
      if (action.payload.name === "endTime") {
        state.storeData.horariosRecojos[action.payload.index].endTime =
          action.payload.value;
      }
      if (action.payload.name === "active") {
        state.storeData.horariosRecojos[action.payload.index].active =
          action.payload.checked;
      }
    },
    setUpdateStoreAddPhoto: (state) => {
      const index = state.storeData.photos.length;
      const obj = {
        id: null,
        name: "",
        photo: "",
        order: index + 1,
        active: false,
      };
      state.storeData.photos.push(obj);
    },
    setUpdateStoreAddMethodsDeliveries: (state) => {
      const obj = {
        id: null,
        modality: "En Tienda",
        timeOpening: "08:00:00",
        timeClosing: "22:00:00",
        price: 0,
        active: false,
      };
      state.storeData.metodosEntregasTiendas.push(obj);
    },
    setUpdateStoreAddShedules: (state) => {
      const index = state.storeData.shedules.length;
      const obj = {
        id: null,
        day: "lunes",
        startTime: "08:00:00",
        endTime: "22:00:00",
        order: index + 1,
        active: false,
      };
      state.storeData.shedules.push(obj);
    },
    setUpdateStoreAddShedulesPickUp: (state) => {
      const obj = {
        id: null,
        startTime: "08:00:00",
        endTime: "22:00:00",
        active: false,
      };
      state.storeData.horariosRecojos.push(obj);
    },
    clearUpdateStore: (state) => {
      const newPhoto = [
        {
          id: null,
          name: "giftcardMobile",
          photo: "",
          order: 1,
          active: true,
        },
        {
          id: null,
          name: "reserva",
          photo: "",
          order: 2,
          active: true,
        },
        {
          id: null,
          name: "Collage 1",
          photo: "",
          order: 3,
          active: true,
        },
        {
          id: null,
          name: "Collage 3",
          photo: "",
          order: 4,
          active: true,
        },
        {
          id: null,
          name: "Collage 2",
          photo: "",
          order: 5,
          active: true,
        },
        {
          id: null,
          name: "Gif Card desktop",
          photo: "",
          order: 6,
          active: true,
        },
      ];

      const newShedule = [
        {
          id: null,
          day: "lunes",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 1,
          active: true,
        },
        {
          id: null,
          day: "Martes",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 2,
          active: true,
        },
        {
          id: null,
          day: "Miercoles",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 3,
          active: true,
        },
        {
          id: null,
          day: "Jueves",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 4,
          active: true,
        },
        {
          id: null,
          day: "Viernes",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 5,
          active: true,
        },
        {
          id: null,
          day: "Sabado",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 6,
          active: true,
        },
        {
          id: null,
          day: "Domingo",
          startTime: "08:00:00",
          endTime: "22:00:00",
          order: 7,
          active: true,
        },
      ];

      const newHours = [
        {
          id: null,
          startTime: "08:00:00",
          endTime: "22:00:00",
          active: false,
        },
      ];

      const newRecojo = [
        {
          id: null,
          modality: "En Tienda",
          timeOpening: "08:00:00",
          timeClosing: "22:00:00",
          price: 0,
          active: true,
        },
        {
          id: null,
          modality: "Delivery",
          timeOpening: "08:00:00",
          timeClosing: "22:00:00",
          price: 0,
          active: true,
        },
      ];

      state.storeData.id = "";
      state.storeData.name = "";
      state.storeData.slug = "";
      state.storeData.description = "";
      state.storeData.image = "";
      state.storeData.cover = "";
      state.storeData.coverMobile = "";
      state.storeData.adress = "";
      state.storeData.maps = "";
      state.storeData.cart = "";
      state.storeData.active = false;
      state.storeData.photos = newPhoto;
      state.storeData.shedules = newShedule;
      state.storeData.horariosRecojos = newHours;
      state.storeData.metodosEntregasTiendas = newRecojo;
    },
    setRetrieveStoreLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveStore,
  setRetrieveStoreItems,
  setRetrieveStorePickUpItems,
  setRetrieveStoreMethodsItems,
  setRetrieveStoreShedulesItems,
  setRetrieveStoreShedulesPickUpItems,
  setUpdateStoreAddPhoto,
  setUpdateStoreAddShedules,
  setUpdateStoreAddShedulesPickUp,
  setUpdateStoreAddMethodsDeliveries,
  clearUpdateStore,
  setRetrieveStoreLoading,
} = retrieveStoreSlice.actions;
