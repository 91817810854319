import { useState } from "react";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { useParams } from "react-router-dom";
import { changeStatusOfClass } from "../../helpers";
import { ModalLayout } from "../../layouts";

const changeStateSyle = {
  fontSize: "15px",
  fontWeight: "bold",
};
const canceledStateStyle = {
  fontSize: "15px",
  fontWeight: "bold",
};
const modalityDelivery = [
  "Generada",
  "Recibida",
  "Confirmada",
  "En camino",
  "Entregada",
];
const modalityStore = [
  "Generada",
  "Recibida",
  "Confirmada",
  "Lista para retiro",
  "Entregada",
];
export const OrderDetailTitleContainer = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCanceled, setIsOpenCanceled] = useState(false);

  const path = useParams();
  const onClose = () => {
    setIsOpen(!isOpen);
  };

  const onCloseCanceled = () => {
    setIsOpenCanceled(!isOpenCanceled);
  };

  const urlOrderChangeState = `admin/ordenes/${path.orderId}/cambiar_estado/`;
  const urlOrderCanceled = `admin/ordenes/${path.orderId}/cancelar/`;

  const handleModality = (modality) => {
    if (data?.state === "Entregada") return "Entregada";
    if (modality === "Delivery") {
      const index = modalityDelivery.indexOf(data?.state);
      return modalityDelivery[index + 1];
    } else {
      const index = modalityStore.indexOf(data?.state);
      return modalityStore[index + 1];
    }
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle de la orden" />
      </div>
      {data?.state !== "Entregada" && data?.state !== "Cancelada" && (
        <div className="OrderDetailHeaderContainer__container-button">
          <ButtonPushComponent
            name="Cancelar Pedido"
            onClick={onCloseCanceled}
            color={"gray"}
          />
          <ButtonPushComponent
            name={`Cambiar a ${handleModality(data?.modality)}`}
            onClick={onClose}
          />
        </div>
      )}

      {isOpen && (
        <ModalLayout>
          <ModalContainer
            title="Cambiar estado"
            msg="¿Está seguro que desea cancelar el estado de la orden?"
            style={changeStateSyle}
            onClose={onClose}
            onConfirm={(e) => changeStatusOfClass(urlOrderChangeState)}
          />
        </ModalLayout>
      )}

      {isOpenCanceled && (
        <ModalLayout>
          <ModalContainer
            title="Cancelar estado"
            msg="¿Está seguro que desea cancelar el estado de la orden?"
            style={canceledStateStyle}
            onClose={onCloseCanceled}
            onConfirm={(e) => changeStatusOfClass(urlOrderCanceled)}
          />
        </ModalLayout>
      )}
    </section>
  );
};
