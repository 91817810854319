import { redirectToUrl, getUrl } from "../helpers";
import { requestGetAuthorization } from "../services/requestGet";

export const changeStatusOfClass = (url) => {
  const token = localStorage.getItem("token");
  requestGetAuthorization(url, token);
  const redirect = () => {
    redirectToUrl(getUrl());
  };
  setTimeout(redirect, 500);
};
