import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";
export const CollectionListFieldsValueComponent = ({ collection }) => {
  const url = `/productos/colecciones/${collection.id}/detalles`;
  const stateColor = orderStateColor(collection?.active);
  return (
    <tr className="collectionListFieldsValueComponent">
      <td className="collectionListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="collectionListFieldsValueComponent__name">
        <Link to={url}>{collection.name}</Link>
      </td>
      <td className="collectionListFieldsValueComponent__description">
        <Link to={url}>{collection.description}</Link>
      </td>
      <td className="collectionListFieldsValueComponent__time">
        <Link to={url}>{collection.startTime}</Link>
      </td>
      <td className="collectionListFieldsValueComponent__time">
        <Link to={url}>{collection.endTime}</Link>
      </td>
      <td className={`collectionListFieldsValueComponent__state ${stateColor}`}>
        <Link to={url}>{collection.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
