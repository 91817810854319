import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const SettingLandingReservationHeaderContainer = () => {
  const { reservationData } = useSelector((state) => state.retrieveReservation);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCanceled, setIsOpenCanceled] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalCanceled = () => {
    setIsOpenCanceled(!isOpenCanceled);
  };

  const path = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleUpdate = async (params) => {
    requestPatchAuthorization(`admin/reservaciones/${path.reservationId}/`, {
      state: params,
      token,
    }).then(() => {
      navigate("/configuracion/landing/reservaciones");
    });
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle de la Reserva" />
      </div>
      {reservationData?.state === "Pendiente" && (
        <div className="OrderDetailHeaderContainer__container-button">
          <ButtonPushComponent
            name="Cancelar Reserva"
            onClick={toggleModal}
            color={"gray"}
          />
          <ButtonPushComponent
            name={"Confirmar Reserva"}
            onClick={toggleModalCanceled}
          />
        </div>
      )}
      {isOpen && (
        <ModalLayout>
          <ModalContainer
            title="Cambiar estado"
            msg="¿Está seguro que desea cancelar  la Reserva?"
            onClose={toggleModal}
            onConfirm={() => handleUpdate("Cancelada")}
          />
        </ModalLayout>
      )}

      {isOpenCanceled && (
        <ModalLayout>
          <ModalContainer
            title="Cancelar estado"
            msg="¿Está seguro que desea confirmar la reserva?"
            onClose={toggleModalCanceled}
            onConfirm={() => handleUpdate("Confirmada")}
          />
        </ModalLayout>
      )}
    </section>
  );
};
