import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ListHeaderContainer,
  PaginationContainer,
  TableContainer,
} from "../../../../containers";
import { filterObjectKeys } from "../../../../helpers";
import { getListContact } from "../../../../store/contact/thunks";
import { LoadingComponent } from "../../../../components";

const URL = "configuracion/landing/contacto";
const dataInput = { id: "searchContact", placeholder: "Buscar Contacto" };
const allowedKeys = ["id", "name", "email", "created", "description"];
const dataHeader = {
  title: "Contactos",
  nameButton: "Crear Contacto",
  url: "",
};

export const SettingLandingContactIndexPage = () => {
  const { contactData, showLoading } = useSelector(
    (state) => state.listContact
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageContanct = serachParams.get("page");
  const searchContanct = serachParams.get("search");

  let contacParams = {
    page_size: 20,
    page: pageContanct ?? 1,
    search: searchContanct ?? "",
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getListContact(contacParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContanct, searchContanct]);

  const filter = () => {
    const searchValue = document.getElementById("searchContact").value;
    if (searchValue === "") {
      dispatch(getListContact());
      navigate("/" + URL);
      return;
    }
    const searchUrl = `/configuracion/landing/contacto?search=${searchValue}`;

    navigate(searchUrl);
  };

  const handleKeyDown = (e) => {
    const searchValue = document.getElementById("searchContact").value;
    if (e.key === "Backspace" && searchValue.length === 1) {
      dispatch(getListContact());
      navigate("/" + URL);
    }
    if (e.key !== "Enter") return;
    filter();
  };

  const getUrl = (id) => {
    return `/configuracion/landing/contacto/${id}/detalles`;
  };

  const newData = contactData?.results?.map((obj) =>
    filterObjectKeys(obj, allowedKeys)
  );
  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} addButton={false} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TableContainer
            filter={filter}
            handleKeyDown={handleKeyDown}
            dataInput={dataInput}
            data={newData}
            url={getUrl}
            titles={allowedKeys}
            isFilter={false}
          />
          <PaginationContainer data={contactData} />
        </>
      )}
    </section>
  );
};
