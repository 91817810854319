import { Link } from "react-router-dom";
import orderStateColor from "../../helpers/changeColor";

export const StoreListFieldsValueComponent = ({ store }) => {
  const url = `/configuracion/tiendas/${store.id}/detalles`;
  const stateColor = orderStateColor(store?.active);

  return (
    <tr className="storeListFieldsValueComponent">
      <td className="storeListFieldsValueComponent__checkbox">
        <input type="checkbox" />
      </td>
      <td className="storeListFieldsValueComponent__name">
        <Link to={url}>{store.name}</Link>
      </td>
      <td className="storeListFieldsValueComponent__slug">
        <Link to={url}>{store.slug}</Link>
      </td>
      <td className="storeListFieldsValueComponent__description">
        <Link to={url}>{store.description}</Link>
      </td>
      <td className="storeListFieldsValueComponent__address">
        <Link to={url}>{store.adress}</Link>
      </td>
      <td className="storeListFieldsValueComponent__image">
        <Link to={url}>
          <img src={store?.image} alt={store?.name} />
        </Link>
      </td>
      <td className={`storeListFieldsValueComponent__active ${stateColor}`}>
        <Link to={url}>{store.active ? "Activo" : "Inactivo"}</Link>
      </td>
    </tr>
  );
};
