import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyValueInputComponent } from '../../components';
import { setCreateModifier } from '../../store/modifier';

export const ModifierCreateInformationContainer = () => {
    const data = useSelector((state) => state.createModifier);
    const [modifierData, setModifierData] = useState(data);
    const dispatch = useDispatch();
  
    const handleOnchage = (e) => {
      const name = e.target.name;
      let value = e.target.value;
      if (name === "active") {
        value = e.target.checked;
      }
      if (name === "amount") {
        value = parseFloat(value);
      }
      setModifierData({ ...modifierData, [name]: value });
      dispatch(setCreateModifier({ [name]: value }));
    };
    return (
      <div className="noteCreateInformationContainer">
        <div className="noteCreateInformationContainer__title">Información</div>
        <div className="noteCreateInformationContainer__container">
          <KeyValueInputComponent
            text={"Nombre"}
            name="name"
            value={modifierData.name || ""}
            handleOnchage={handleOnchage}
          />
          <KeyValueInputComponent
            text={"Monto"}
            name="amount"
            value={modifierData.amount >= 0 ? modifierData.amount : ""}
            type="number"
            handleOnchage={handleOnchage}
            step={0.01}
          />
          <KeyValueInputComponent
            text={"Orden"}
            name="order"
            value={modifierData.order >= 0 ? modifierData.order : ""}
            type="number"
            handleOnchage={handleOnchage}
          />
          <KeyValueInputComponent
            text={"¿Activo?"}
            name="active"
            value={modifierData.active || ""}
            type="checkbox"
            checked={modifierData.active}
            handleOnchage={handleOnchage}
          />
        </div>
      </div>
    );
}
