import { TitleComponent } from "../../../components";
import { SettingBoxContainer } from "../../../containers";

const data = [
  {
    id: 1,
    title: "Variables de Entornos ",
    text: "Aquí se podrá visualizar nuestras variables con la que trabaja la página",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/setting_29_03_2023.png",
    slug: "variables-de-entorno",
  },
  {
    id: 2,
    title: "Correos de Recepción",
    text: "Aquí se podrá visualizar los correos que se almacenan en la página",
    url: "https://panatelier-api.s3.us-east-2.amazonaws.com/assets/images/email_29_03_2023.png",
    slug: "correos-de-recepcion",
  },
];

export const SettingIndexPage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <TitleComponent title={"Configuración"} />
      <SettingBoxContainer data={data} />
    </div>
  );
};
