import { createSlice } from "@reduxjs/toolkit";
export const listReceptionSlice = createSlice({
  name: "listReception",
  initialState: {
    receptionData: [],
    showLoading: false,
  },
  reducers: {
    setListReception: (state, action) => {
      state.receptionData = action.payload.data;
    },
    setReceptionLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});

export const { setListReception, setReceptionLoading } =
  listReceptionSlice.actions;

export const retrieveReceptionSlice = createSlice({
  name: "retrieveReception",
  initialState: {
    receptionData: {
      tipoFormulario: "",
      email: "",
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveReception: (state, action) => {
      state.receptionData.tipoFormulario =
        action.payload?.data?.tipoFormulario ||
        state?.receptionData?.tipoFormulario;
      state.receptionData.email =
        action?.payload?.data?.email || state.receptionData.email;
    },
    clearUpdateReception: (state) => {
      state.receptionData = {
        tipoFormulario: "",
        email: "",
      };
    },
    setRetrieveReceptionLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const {
  setRetrieveReception,
  clearUpdateReception,
  setRetrieveReceptionLoading,
} = retrieveReceptionSlice.actions;
