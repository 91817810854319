import {
  OrderDetailUserCustomerContainer,
  OrderDetailUserDataContainer,
  OrderDetailUserModalityContainer,
} from "../";

export const OrderDetailUserContainer = ({ data }) => {
  return (
    <section className="orderDetailUserContainer">
      <OrderDetailUserCustomerContainer data={data} />
      <OrderDetailUserDataContainer data={data} />
      <OrderDetailUserModalityContainer data={data} />
    </section>
  );
};
