import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CouponCreateHeaderContainer,
  CouponDetailMainContainer,
} from "../../containers";
import { clearUpdateCoupon } from "../../store/coupon/couponSlice";

export const CouponCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateCoupon());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="orderCreateMainContainer">
      <CouponCreateHeaderContainer />
      <CouponDetailMainContainer />
    </div>
  );
};
