import { useNavigate } from "react-router-dom";
export const ButtonPushComponent = ({ name, url, color, onClick, margin }) => {
  const navigate = useNavigate();
  const redirectToUrl = () => {
    navigate(url);
  };

  const handleClickButton = () => {
    if (url) {
      redirectToUrl();
      return;
    }
    onClick();
  };

  return (
    <div className="buttonPushComponent" style={{margin: margin}}>
      <button
        style={{ backgroundColor: `${color}`}}
        onClick={handleClickButton}
      >
        {name}
      </button>
    </div>
  );
};
