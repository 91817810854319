import { NoteListFieldsKeyComponent } from "../../components";
import { NoteListFieldsValueContainer } from "../../containers";

export const NoteListTableContainer = () => {
  return (
    <table className="noteListTableContainer">
      <NoteListFieldsKeyComponent />
      <NoteListFieldsValueContainer />
    </table>
  );
};
