import { getListOptions } from "../../../helpers";
import { requestGetAuthorization } from "../../../services/requestGet";
import { setRetrieveSuggestive } from "./suggestiveSlice";

export const getRetrieveSuggestive = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/ventas_sugestivas/`, token).then((value) => {
      const processProducts = getListOptions([
        value?.products,
        "products",
        "name",
      ]);
      let obj = {...value, products : processProducts}
      dispatch(
        setRetrieveSuggestive({
          data: obj,
        })
      );
    });
  };
};
