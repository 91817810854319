import { createSlice } from "@reduxjs/toolkit";
export const retrieveBannerMainSlice = createSlice({
  name: "retrieveBannerMain",
  initialState: {
    photo: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveBannerMain: (state, action) => {
      state.photo = action.payload.data;
    },
    setRetrieveBannerMainLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveBannerMain, setRetrieveBannerMainLoading } =
  retrieveBannerMainSlice.actions;
