import { apiPath } from "../helpers";
const tokenID = localStorage.getItem("token");
export const requestDelete = async (url, token = tokenID) => {
  try {
    const response = await apiPath.delete(url, {
      headers: {
        Authorization: `Token ${tokenID}`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};
