import {
  OrderListTableContainer,
  OrderSearchContainer,
} from "../../containers";

export const OrderListContainer = () => {
  return (
    <div className="orderListContainer">
      <div className="orderListContainer__search">
        <OrderSearchContainer />
      </div>
      <div className="orderListContainer__table">
        <OrderListTableContainer />
      </div>
    </div>
  );
};
