import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CombosCreateHeaderContainer,
  CombosDetailMainContainer,
} from "../../containers";
import { clearUpdateCombo } from "../../store/combo";

export const CombosCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateCombo());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="orderCreateMainContainer">
      <CombosCreateHeaderContainer />
      <CombosDetailMainContainer />
    </div>
  );
};
