import { ProductComplementListFieldsKeyComponent } from "../../components";
import { ProductComplementListFieldsValueContainer } from "../../containers";

export const ProductComplementListTableContainer = () => {
  return (
    <table className="clientListTableContainer">
      <ProductComplementListFieldsKeyComponent />
      <ProductComplementListFieldsValueContainer />
    </table>
  );
};
