import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

export default function SelectTimeMUIComponent({
  setIsOpen,
  onChange,
  index,
  name,
}) {
  // const [value, setValue] = useState(new Date("2023-02-21T09:00:00"));
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    if (e.$H < 10) {
      e.$H = `0${e.$H}`;
    }
    if (e.$m < 10) {
      e.$m = `0${e.$m}`;
    }
    onChange(index, name, `${e.$H}:${e.$m}:00`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="selectTimeMUIComponent">
        <StaticTimePicker
          ampm={false}
          value={value}
          onAccept={handleChange}
          onClose={setIsOpen}
          closeOnSelect={true}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              className="selectTimeMUIComponent__picker__text"
              {...params}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
