import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListHeaderContainer,
  PaginationContainer,
  ProductComplementListContainer,
} from "../../../containers";
import { getListComplement } from "../../../store/complement";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../../components";

export const ProductComplementIndexPage = () => {
  const { complementData, showLoading } = useSelector(
    (state) => state.listComplement
  );
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageComplement = serachParams.get("page");
  const searchComplement = serachParams.get("search");
  let complementParams = {
    page_size: 20,
    page: pageComplement ?? 1,
    search: searchComplement ?? "",
  };
  useEffect(() => {
    dispatch(getListComplement(complementParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageComplement, searchComplement]);

  const dataHeader = {
    title: "Complementos",
    nameButton: "Crear Complemento",
    url: "/productos/complementos/crear",
  };

  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} />
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ProductComplementListContainer />
          <PaginationContainer data={complementData} />
        </>
      )}
    </section>
  );
};
