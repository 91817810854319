import { useSelector } from "react-redux";
import { CouponListFieldsValueComponent } from "../../components";

export const CouponListFieldsValueContainer = () => {
  const { couponData } = useSelector((state) => state.listCoupon);

  return (
    <tbody>
      {couponData?.results?.length > 0 &&
        couponData?.results?.map((coupon) => {
          return (
            <CouponListFieldsValueComponent key={coupon.id} coupon={coupon} />
          );
        })}
    </tbody>
  );
};
