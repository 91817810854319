import {
  ProductComplementListTableContainer,
  ProductComplementSearchContainer,
} from "../../containers";

export const ProductComplementListContainer = () => {
  return (
    <div className="clientListContainer">
      <div className="clientListContainer__search">
        <ProductComplementSearchContainer />
      </div>
      <div className="clientListContainer__table">
        <ProductComplementListTableContainer />
      </div>
    </div>
  );
};
