import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";

import {
  setListProducts,
  setRetrieveProduct,
  setListOptionsProducts,
  setUpdateProduct,
  setUpdateProductPresentationGroup,
  setUpdateProductPresentation,
  setUpdateProductModifier,
  setUpdateProductModifierGroup,
  setUpdateProductNoteGroup,
  setUpdateProductNote,
  setProductLoading,
  setRetrieveProductLoading,
} from "./productSlice";

export const getListProducts = (params) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch(setProductLoading({ showLoading: true }));
    requestGetParamsAuthorization("admin/productos/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "productos";
        value.text = "Productos";
        value.page = params.page;
        dispatch(setProductLoading({ showLoading: false }));
        dispatch(setListProducts({ data: value }));
      }
    );
  };
};

export const getRetrieveProduct = (productId) => {
  return async (dispatch) => {
    dispatch(setRetrieveProductLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/productos/${productId}/`, token).then(
      (value) => {
        dispatch(setRetrieveProduct({ data: value }));
        dispatch(setUpdateProduct(value));
        dispatch(
          setUpdateProduct({ channels: value.channels.map((item) => item.id) })
        );
        dispatch(
          setUpdateProduct({
            colections: value.colections.map((item) => item.id),
          })
        );
        dispatch(setUpdateProduct({ tags: value.tags.map((item) => item.id) }));
        dispatch(
          setUpdateProduct({
            complements: value.complements.map((item) => item.id),
          })
        );
        value.gruposPresentaciones.map((presentationGroup, groupIndex) => {
          dispatch(
            setUpdateProductPresentationGroup({
              index: groupIndex,
              name: presentationGroup.name,
              isMandatory: presentationGroup.isMandatory,
              order: presentationGroup.order,
              active: presentationGroup.active,
            })
          );
          presentationGroup.presentaciones.map((presentation, index) => {
            dispatch(
              setUpdateProductPresentation({
                groupIndex: groupIndex,
                index: index,
                name: presentation.name,
                amount: presentation.amount,
                order: presentation.order,
                active: presentation.active,
              })
            );
            return null;
          });
          return null;
        });
        value.gruposModificadores.map((modifierGroup, groupIndex) => {
          dispatch(
            setUpdateProductModifierGroup({
              index: groupIndex,
              name: modifierGroup.name,
              isMandatory: modifierGroup.isMandatory,
              order: modifierGroup.order,
              active: modifierGroup.active,
              isMultiple: modifierGroup.isMultiple,
            })
          );
          modifierGroup.modificadores.map((modifier, index) => {
            dispatch(
              setUpdateProductModifier({
                groupIndex: groupIndex,
                index: index,
                modifierId: modifier.id,
              })
            );
            return null;
          });
          return null;
        });
        value.gruposNotas.map((noteGroup, groupIndex) => {
          dispatch(
            setUpdateProductNoteGroup({
              index: groupIndex,
              name: noteGroup.name,
              isMandatory: noteGroup.isMandatory,
              order: noteGroup.order,
              active: noteGroup.active,
              isMultiple: noteGroup.isMultiple,
            })
          );
          noteGroup.notas.map((note, index) => {
            dispatch(
              setUpdateProductNote({
                groupIndex: groupIndex,
                index: index,
                noteId: note.id,
              })
            );
            return null;
          });
          return null;
        });
        dispatch(setRetrieveProductLoading({ showLoading: false }));
      }
    );
  };
};

export const getListOptionsProducts = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      "admin/productos/lista_opciones/?active=true",
      token
    ).then((value) => {
      // const listOptions = value.filter(option => {
      //   let isExists = false;
      //   for (let i=0; i<listOptionCurrent?.length; i++){
      //     if(listOptionCurrent?.[i]?.id === option?.id){

      //       isExists = true;
      //       break;
      //     }
      //   }
      //   if(!isExists){
      //     return option
      //   }
      //   return null
      // });
      dispatch(setListOptionsProducts({ data: value }));
    });
  };
};
