import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  StoreCoverageDetailHeaderContainer,
  StoreCoverageDetailMainContainer,
} from "../../../../containers";
import { clearUpdateCoverage } from "../../../../store/coverage/coverageSlice";

export const SettingStoreCoverageCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUpdateCoverage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="orderDetailPage">
      <StoreCoverageDetailHeaderContainer />
      <StoreCoverageDetailMainContainer />
    </section>
  );
};
