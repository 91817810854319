import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "CourierNew"];
Quill.register(Font, true);
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
  
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
  
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
  
    [{ color: [] }, { background: [] }],
    [
      {
        font:  Font.whitelist 
      },
    ],
    [{ align: ["right", "center", "justify"] }],
  
    ["clean"],
  ],
};



export const ReactQuillComponent = ({
  data,
  fnc,
  index,
  indexOf,
  isArray = true,
}) => {

  return (
    <>
      <ReactQuill
        theme="snow"
        value={data}
        onChange={isArray ? (e) => fnc(e, index, indexOf) : fnc}
        modules={modules}
      />
    </>
  );
};
