import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import SelectTimeMUIComponent from "../../components/SelectTimeMUIComponent/SelectTimeMUIComponent";
import { ModalLayout } from "../../layouts";
import { setCollection, setCreateCollection } from "../../store/collection";
import { InformationFieldInputContainer } from "../InformationFieldInputContainer/InformationFieldInputContainer";

const InformationContainerFieldInputDate = ({
  label,
  onClick,
  hourNow,
  hours,
  name,
  value,
  handleOnchage,
  getHourModalTime,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getTimeCurrent = (index, name, value) => {
    hours(value);
    getHourModalTime(name, value);
  };

  return (
    <div className="productCollectionDetailInformationContainer__fields__item">
      <div className="productCollectionDetailInformationContainer__fields__item__text">
        {label}
      </div>
      <div className="productCollectionDetailInformationContainer__fields__item__input-date">
        <div className="productCollectionDetailInformationContainer__fields__item__input-date__input">
          <input value={value || ""} name={name} onChange={handleOnchage} />
        </div>
        <div className="productCollectionDetailInformationContainer__fields__item__input-date__container">
          <div
            className="productCollectionDetailInformationContainer__fields__item__input-date__container__text"
            onClick={onClick}
          >
            <p>
              Ahora <span>|</span>
            </p>
          </div>
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="productCollectionDetailInformationContainer__fields__item__input-date__container__icon"
          >
            ⌚
          </div>
        </div>
      </div>
      {isOpen && (
        <ModalLayout>
          <SelectTimeMUIComponent
            setIsOpen={() => setIsOpen(!isOpen)}
            onChange={getTimeCurrent}
            index="0"
            name={name}
          />
        </ModalLayout>
      )}
    </div>
  );
};

export const ProductCollectionDetailInformationContainer = ({
  data,
  handleOnchage,
}) => {
  const [hourOpen, setHourOpen] = useState("");
  const [hourClose, setHourClose] = useState("");
  const [orderCollection, setOrderCollection] = useState("");
  const [checkCollection, setCheckCollection] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setHourOpen(data?.startTime);
    setHourClose(data?.endTime);
    setOrderCollection(data?.order);
    setCheckCollection(data?.active);
  }, [data]);

  const getHour = () => {
    const today = new Date();
    const now = today.toLocaleTimeString();
    setHourOpen(now);
    dispatch(setCollection({ startTime: now }));
  };
  const getHourClose = () => {
    const today = new Date();
    const now = today.toLocaleTimeString();
    setHourClose(now);
    dispatch(setCollection({ endTime: now }));
  };
  const getOrder = (e) => {
    setOrderCollection(e.target.value);
    dispatch(setCollection({ order: parseInt(e.target.value) }));
    dispatch(setCreateCollection({ order: parseInt(e.target.value) }));
  };

  const getHourModalTime = (name, value) => {
    dispatch(setCollection({ [name]: value }));
  };

  return (
    <div className="productCollectionDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <div className="productCollectionDetailInformationContainer__fields">
        <InformationFieldInputContainer
          label="Nombre"
          value={data?.name}
          name="name"
          handleOnchage={handleOnchage}
        />
        <InformationFieldInputContainer
          label="Slug"
          value={data?.slug}
          handleOnchage={handleOnchage}
          name="slug"
        />
        <InformationFieldInputContainer
          label="Descripción"
          handleOnchage={handleOnchage}
          name="description"
          value={data?.description}
        />
        <InformationContainerFieldInputDate
          label="Hora de apertura"
          onClick={getHour}
          hourNow={hourOpen}
          hours={setHourOpen}
          value={hourOpen}
          handleOnchage={handleOnchage}
          name="startTime"
          getHourModalTime={getHourModalTime}
        />
        <InformationContainerFieldInputDate
          label="Hora de cierre"
          onClick={getHourClose}
          hourNow={hourClose}
          hours={setHourClose}
          value={hourClose}
          handleOnchage={handleOnchage}
          name="endTime"
          getHourModalTime={getHourModalTime}
        />
        <InformationFieldInputContainer
          label="Order"
          handleOnchage={getOrder}
          name="order"
          type="number"
          value={orderCollection}
        />
        <InformationFieldInputContainer
          label="¿Activo?"
          value={checkCollection}
          setCheckCollection={setCheckCollection}
          handleOnchage={handleOnchage}
          name="active"
          type="checkbox"
        />
      </div>
    </div>
  );
};
