import {
  ClientListTableContainer,
  ClientSearchContainer,
} from "../../containers";

export const ClientListContainer = () => {
  return (
    <div className="clientListContainer">
      <div className="clientListContainer__search">
        <ClientSearchContainer />
      </div>
      <div className="clientListContainer__table">
        <ClientListTableContainer />
      </div>
    </div>
  );
};
