import { useSelector } from "react-redux";
import { ProductComplementListFieldsValueComponent } from "../../components";

export const ProductComplementListFieldsValueContainer = () => {
  const { complementData } = useSelector((state) => state.listComplement);

  return (
    <tbody>
      {complementData?.results?.length > 0 &&
        complementData?.results?.map((complement) => {
          return (
            <ProductComplementListFieldsValueComponent
              key={complement.id}
              complement={complement}
            />
          );
        })}
    </tbody>
  );
};
