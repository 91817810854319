import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { KeyValueInputComponent } from "../../components";
import { setUpdateModifier } from "../../store/modifier/modifierSlice";

export const ModifierRetrieveInformationContainer = ({ data }) => {
  const [modifierData, setModifierData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!data) {
      setModifierData(data);
    }
  }, [data]);

  const handleOnchage = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "active") {
      value = e.target.checked;
    }
    if (name === "amount") {
      value = parseFloat(value);
    }
    setModifierData({ ...modifierData, [name]: value });
    dispatch(setUpdateModifier({ [name]: value }));
  };
  return (
    <div className="modifierRetrieveInformationContainer">
      <div className="modifierRetrieveInformationContainer__title">
        Información
      </div>
      <div className="modifierRetrieveInformationContainer__container">
        <KeyValueInputComponent
          text="Nombre"
          name="name"
          value={modifierData.name || ""}
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"Monto"}
          name="amount"
          value={modifierData.amount >= 0 ? modifierData.amount : ""}
          type="number"
          handleOnchage={handleOnchage}
          step={0.01}
        />
        <KeyValueInputComponent
          text={"Orden"}
          name="order"
          value={modifierData.order >= 0 ? modifierData.order : ""}
          type="number"
          handleOnchage={handleOnchage}
        />
        <KeyValueInputComponent
          text={"¿Activo?"}
          name="active"
          value={modifierData.active || ""}
          type="checkbox"
          checked={data?.active}
          handleOnchage={handleOnchage}
        />
      </div>
    </div>
  );
};
