import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClientListContainer,
  ListHeaderContainer,
  PaginationContainer,
} from "../../containers";
import { getListClients } from "../../store/client";
import { useLocation } from "react-router-dom";
import { LoadingComponent } from "../../components";

export const ClienteIndexPage = () => {
  const { clientData, showLoading } = useSelector((state) => state.listClients);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serachParams = new URLSearchParams(search);
  const pageClient = serachParams.get("page");
  const activeClient = serachParams.get("active");
  const searchClient = serachParams.get("search");
  let clientParams = {
    page_size: 20,
    page: pageClient ?? 1,
    active: activeClient ?? "",
    search: searchClient ?? "",
  };

  useEffect(() => {
    dispatch(getListClients(clientParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageClient, activeClient, searchClient]);

  const dataHeader = {
    title: "Clientes",
    nameButton: "Crear Cliente",
    url: "/clientes/crear",
  };

  return (
    <section className="clientIndexPage">
      <ListHeaderContainer data={dataHeader} />

      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ClientListContainer />
          <PaginationContainer data={clientData} />
        </>
      )}
    </section>
  );
};
