import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent, TitleInformationComponent } from "../../components";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";
import { getRetrieveBannerUs } from "../../store/banner/us/thunks";
import { setRetrieveBannerUs } from "../../store/banner/us/usSlice";

export const SettingLandingBannersUsInformationContainer = () => {
  const { bannerUsData, showLoading } = useSelector(
    (state) => state.retrieveBannerUs
  );
  const dispatch = useDispatch();

  const newData = [
    {
      title: "Portada Desktop",
      name: "coverDesktop",
      value: bannerUsData?.coverDesktop?.url,
    },
    {
      title: "Portada Mobile",
      name: "coverMobile",
      value: bannerUsData?.coverMobile?.url,
    },
  ];

  useEffect(() => {
    dispatch(getRetrieveBannerUs());
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    dispatch(setRetrieveBannerUs({ data: { [key]: value } }));
  };

  const handleOnChangeArray = (e, index, data, name) => {
    let value = e.target.value;
    const file = e.target.files[0];
    value = {
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    };
    const updatedData = {
      ...data[index],
      url: value.url,
      type: value.type,
      name: value.name,
    };
    const updateds = [
      ...data.slice(0, index),
      updatedData,
      ...data.slice(index + 1),
    ];
    dispatch(setRetrieveBannerUs({ data: { [name]: updateds } }));
  };

  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="productDetailInformationContainer">
          <TitleInformationComponent title={"Información General"} />
          {newData?.map(({ title, name, value }) => (
            <SelectOptionsContainer
              key={name}
              title={title}
              handleOnchange={handleOnChange}
              name={name}
              isImageEdit={true}
              value={value}
            />
          ))}
          {bannerUsData?.photos?.map((_, index) => (
            <SelectOptionsContainer
              key={`collage-${index}`}
              title={`Collage ${index + 1}`}
              handleOnchange={(e) =>
                handleOnChangeArray(e, index, bannerUsData?.photos, "photos")
              }
              name={`imagen_${index + 1}`}
              isImageEdit={true}
              value={bannerUsData?.photos?.[index]?.url}
            />
          ))}
        </div>
      )}
    </>
  );
};
