import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  PaymentRetrieveDetailContainer,
  PaymentRetrieveVoucherContainer,
} from "../../containers";
import { getRetrievePayment } from "../../store/payment";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent } from "../../components";

export const PaymentRetrieveContainer = () => {
  const { paymentData, showLoading } = useSelector(
    (state) => state.retrievePayment
  );
  const dispatch = useDispatch();
  const path = useParams();

  useEffect(() => {
    dispatch(getRetrievePayment(path.paymentId));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showLoading ? (
        <LoadingComponent />
      ) : (
        <div className="paymentRetrieveContainer">
          <PaymentRetrieveDetailContainer data={paymentData} />
          <PaymentRetrieveVoucherContainer image={paymentData?.voucher} />
        </div>
      )}
    </>
  );
};
