import { getListOptions, getListProcess } from "../../helpers";
import {
  requestGetAuthorization,
  requestGetParamsAuthorization,
} from "../../services/requestGet";
import {
  setComplementLoading,
  setListComplement,
  setListOptionComplement,
  setRetrieveComplement,
  setRetrieveComplementLoading,
} from "./complementSlice";

export const getListOptionComplement = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(`admin/complementos/lista_opciones/`, token).then(
      (value) => {
        const inputParamsData = [value, "complements", "name", "id"];
        const listOptionComplement = getListOptions(inputParamsData);
        dispatch(setListOptionComplement({ data: listOptionComplement }));
      }
    );
  };
};

export const getListComplement = (params) => {
  return async (dispatch) => {
    dispatch(setComplementLoading({ showLoading: true }));
    const token = localStorage.getItem("token");
    requestGetParamsAuthorization("admin/complementos/", params, token).then(
      (value) => {
        value.pageSize = params.page_size;
        value.redirectUrl = "productos/complementos";
        value.text = "Complementos";
        value.page = params.page;
        dispatch(setListComplement({ data: value }));
        dispatch(setComplementLoading({ showLoading: false }));
      }
    );
  };
};

export const getRetrieveComplement = (complementId) => {
  return async (dispatch) => {
    dispatch(setRetrieveComplementLoading({ showLoading: true }));
    requestGetAuthorization(`admin/complementos/${complementId}/`).then(
      (value) => {
        if (value.products) {
          value.products = getListProcess([value.products]);
        }
        dispatch(setRetrieveComplement({ data: value }));
        dispatch(setRetrieveComplementLoading({ showLoading: false }));
      }
    );
  };
};
