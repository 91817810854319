import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TitleInformationComponent } from "../../components";
import {
  setAddCoverageCoordinates,
  setRetrieveCoverage,
  setUpdateCoverageCoordinates,
} from "../../store/coverage/coverageSlice";
import { getRetrieveCoverage } from "../../store/coverage/thunks";
import { getListOptionsStore } from "../../store/store/thunks";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const StoreCoverageDetailInformationContainer = () => {
  const [isOpenHead, setIsOpenHead] = useState(false);
  const { coverageData } = useSelector((state) => state.retrieveCoverage);
  const path = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (path.covertureId) {
      dispatch(getRetrieveCoverage(path.covertureId));
    }
    dispatch(getListOptionsStore());
    // eslint-disable-next-line
  }, []);

  const handleOnchage = (e) => {
    dispatch(
      setRetrieveCoverage({ data: { [e.target.name]: e.target.value } })
    );
  };

  const handleOnChangeCheck = (e) => {
    dispatch(
      setRetrieveCoverage({ data: { [e.target.name]: e.target.checked } })
    );
  };

  const handleOnchageCoordinate = (e, index) => {
    dispatch(
      setUpdateCoverageCoordinates({
        name: e.target.name,
        value: e.target.value,
        index,
      })
    );
  };

  const handleOnchageAdd = () => {
    setIsOpenHead(true);
    dispatch(setAddCoverageCoordinates());
  };

  const coverageTable = (data) => {
    return (
      <tbody>
        {data?.coordenadas?.map((coverage, index) => (
          <tr
            key={coverage?.id || index}
            className="storeCoverageDetailInformationContainerTable"
          >
            <td className="storeCoverageDetailInformationContainer__lat">
              {coverage?.latitude}
            </td>
            <td className="storeCoverageDetailInformationContainer__long">
              {coverage?.longitude}
            </td>
            <td className="storeCoverageDetailInformationContainerTable__active">
              <input type="checkbox" onChange={() => {}} />
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Información General"} />
      <SelectOptionsContainer
        title={"Nombre"}
        name="name"
        handleOnchange={handleOnchage}
        isInput={true}
        value={coverageData?.name}
      />
      <SelectOptionsContainer
        title={"Activo"}
        name="active"
        handleOnchange={handleOnChangeCheck}
        isCheckbox={true}
        value={coverageData?.active}
      />
      <table>
        {(path.covertureId || isOpenHead) && (
          <thead>
            <tr className="storeCoverageDetailInformationContainer">
              <th className="storeCoverageDetailInformationContainer__lat">
                Latitud
              </th>
              <th className="storeCoverageDetailInformationContainer__long">
                Longitud
              </th>
              <th className="storeCoverageDetailInformationContainer__active">
                ¿Eliminar?
              </th>
            </tr>
          </thead>
        )}
        {!!path.covertureId ? (
          coverageTable(coverageData)
        ) : (
          <tbody>
            {coverageData?.coordenadas?.map((coordinate, index) => (
              <tr
                key={index}
                className="storeCoverageDetailInformationContainerTable"
              >
                <td className="storeCoverageDetailInformationContainer__lat">
                  <input
                    value={coordinate.latitude}
                    name="latitude"
                    onChange={(e) => handleOnchageCoordinate(e, index)}
                  />
                </td>
                <td className="storeCoverageDetailInformationContainer__long">
                  <input
                    value={coordinate.longitude}
                    name="longitude"
                    onChange={(e) => handleOnchageCoordinate(e, index)}
                  />
                </td>
                <td className="storeCoverageDetailInformationContainerTable__active">
                  <input type="checkbox" onChange={() => {}} />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!path.covertureId && (
        <div
          className="productDetailGroupModifiedContainer__container__left__button"
          onClick={handleOnchageAdd}
        >
          <div>Agregar Coordenadas</div>
        </div>
      )}
    </div>
  );
};
