import clientActive from "../../data/stateActive.json";
import { useNavigate } from "react-router-dom";
import {
  ButtonSearchComponent,
  FilterComponent,
  InputSearchComponent,
} from "../../components";

export const ClientSearchContainer = () => {
  const navigate = useNavigate();
  const filter = () => {
    const active = document.getElementById("clientActive").value;
    const search = document.getElementById("searchClient").value;
    let url = `/clientes?`;
    if (active !== "null") {
      url = url.concat(`active=${active}`);
    }
    if (search !== "null") {
      url = url.concat(`&search=${search}`);
    }
    navigate(url);
  };
  const dataInput = { id: "searchClient", placeholder: "Buscar cliente" };
  const handleEnter = (e) => {
    if (e.key !== "Enter") return;
    filter();
  };
  return (
    <div className="clientSearchContainer">
      <div className="clientSearchContainer__filters">
        <div className="clientSearchContainer__filters__item">
          <FilterComponent
            data={clientActive}
            name={"Estado"}
            id={"clientActive"}
          />
        </div>
      </div>
      <div className="clientSearchContainer__search">
        <div className="clientSearchContainer__search__input">
          <InputSearchComponent data={dataInput} onKeyDown={handleEnter} />
        </div>
        <div className="clientSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
