import { useEffect, useState } from "react";
import state from "../../data/stateActive.json";
import {
  ButtonSearchComponent,
  FilterComponent,
  InputSearchComponent,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { requestGetAuthorization } from "../../services/requestGet";

export const ProductSearchContainer = () => {
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    requestGetAuthorization("admin/colecciones/lista_opciones/", token).then(
      (value) => {
        setCollection(value);
      }
    );
  }, []);

  const filter = () => {
    const collectionProduct =
      document.getElementById("collectionProduct").value;
    const stateProduct = document.getElementById("stateProduct").value;
    const searchProduct = document.getElementById("searchProduct").value;

    let url = `/productos?`;
    if (stateProduct !== "null") {
      url = url.concat(`active=${stateProduct}`);
    }
    if (collectionProduct !== "null") {
      url = url.concat(`&collection=${collectionProduct}`);
    }
    if (searchProduct !== "null") {
      url = url.concat(`&search=${searchProduct}`);
    }
    navigate(url);
  };
  const dataInput = { id: "searchProduct", placeholder: "Buscar Producto" };

  const handleEnter = (e) => {
    if (e.key !== "Enter") return;
    filter();
  };
  return (
    <div className="productSearchContainer">
      <div className="productSearchContainer__filters">
        <div className="productSearchContainer__filters__item">
          <FilterComponent
            data={collection}
            name={"Colección"}
            id={"collectionProduct"}
          />
        </div>
        <div className="productSearchContainer__filters__item">
          <FilterComponent data={state} name={"Estado"} id={"stateProduct"} />
        </div>
      </div>
      <div className="productSearchContainer__search">
        <div className="productSearchContainer__search__input">
          <InputSearchComponent data={dataInput} onKeyDown={handleEnter} />
        </div>
        <div className="productSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
