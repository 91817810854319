import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { clearUpdateCoverage } from "../../store/coverage/coverageSlice";
import { useNavigate, useParams } from "react-router";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { requestPostAuthorization } from "../../services/requestPost";

export const StoreCoverageDetailHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenMap, setIsOpenMap] = useState(false);

  const { coverageData } = useSelector((state) => state.retrieveCoverage);

  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };

  const handleOpenMap = () => {
    setIsOpenMap(!isOpenMap);
  };

  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const handleCreateCombo = async () => {
    const body = { ...coverageData };
    const filteredCoordenadas = body?.coordenadas.filter(
      (coordenada) =>
        Boolean(coordenada.latitude) && Boolean(coordenada.longitude)
    );
    body.coordenadas = filteredCoordenadas;
    body.shop = path.storeId;
    requestPostAuthorization("admin/coberturas/", body).then(() => {
      dispatch(clearUpdateCoverage());
      navigate(`/configuracion/tiendas/`);
    });
  };
  const handleUpdateCombo = async () => {
    const body = { ...coverageData };
    const filteredCoordenadas = body?.coordenadas.filter(
      (coordenada) =>
        Boolean(coordenada.latitude) && Boolean(coordenada.longitude)
    );
    body.coordenadas = filteredCoordenadas;
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/coberturas/${path.covertureId}`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateCoverage());
      navigate(`/configuracion/tiendas/${path.storeId}/coberturas/`);
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent
          title={
            !path.covertureId ? "Crear Cobertura" : "Detalle de la Cobertura"
          }
        />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={!path.covertureId ? "Crear" : "Actualizar"}
          onClick={toggleModalUpdate}
        />
      </div>

      {isOpenMap && (
        <ModalLayout>
          <ModalContainer
            title="Aqui se vera el mapa"
            msg="klk"
            onClose={handleOpenMap}
            onConfirm={handleOpenMap}
          />
        </ModalLayout>
      )}

      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title={
              !path.covertureId ? "Crear Cobertura" : "Actualizar Cobertura"
            }
            msg={
              !path.covertureId
                ? "¿Está seguro que desea actualizar el Cobertura?"
                : "¿Está seguro que desea crear la Cobertura?"
            }
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={
              !path.covertureId ? handleCreateCombo : handleUpdateCombo
            }
          />
        </ModalLayout>
      )}
    </section>
  );
};
