import { createSlice } from "@reduxjs/toolkit";
export const listNotesSlice = createSlice({
  name: "listNotes",
  initialState: {
    noteData: {},
    noteParams: {
      page_size: 20,
      page: 1,
      active: "",
      search: "",
    },
    showLoading: false,
  },
  reducers: {
    setListNotes: (state, action) => {
      state.noteData = action.payload.data;
      state.noteParams = action.payload.params;
    },
    setNoteLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListNotes, setNoteLoading } = listNotesSlice.actions;

export const retrieveNoteSlice = createSlice({
  name: "retrieveNote",
  initialState: {
    noteData: {},
    showLoading: false,
  },
  reducers: {
    setRetrieveNote: (state, action) => {
      state.noteData = action.payload.data;
    },
    setRetrieveNoteLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveNote, setRetrieveNoteLoading } =
  retrieveNoteSlice.actions;

export const updateNoteSlice = createSlice({
  name: "updateNote",
  initialState: {
    active: true,
    amount: 0.0,
    name: "",
    order: 1,
  },
  reducers: {
    setUpdateNote: (state, action) => {
      state.name = action.payload.name || state.name;
      state.amount = action.payload.amount || state.amount;
      state.active = action.payload.active;
      state.order = action.payload.order || state.order;
    },
  },
});
export const { setUpdateNote } = updateNoteSlice.actions;

export const createNoteSlice = createSlice({
  name: "createNote",
  initialState: {
    active: true,
    amount: 0.0,
    name: "",
    order: 1,
  },
  reducers: {
    setCreateNote: (state, action) => {
      state.name = action.payload.name || state.name;
      state.amount = action.payload.amount || state.amount;
      state.active = action.payload.active;
      state.order = action.payload.order || state.order;
    },
    cleanCreateNote: (state) => {
      state.name = "";
      state.amount = 0.0;
      state.active = true;
      state.order = 1;
    },
  },
});
export const { setCreateNote, cleanCreateNote } = createNoteSlice.actions;

export const listOptionsNoteSlice = createSlice({
  name: "listOptionsNote",
  initialState: {
    listOptionsNote: [],
  },
  reducers: {
    setListOptionsNote: (state, action) => {
      state.listOptionsNote = action.payload.data;
    },
  },
});
export const { setListOptionsNote } = listOptionsNoteSlice.actions;
