import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { requestPatchFileAuthorization } from "../../services/requestPatch";
import { useNavigate } from "react-router-dom";
import {
  processPhotoFormData,
  processPhotosArray,
  processTextFormData,
} from "../../helpers/convertToFormData";

const photoFields = [
  { fieldName: "mainCover", dataField: "mainCover" },
  { fieldName: "coverMobile", dataField: "coverMobile" },
  { fieldName: "coffeeImage", dataField: "coffeeImage" },
  { fieldName: "store", dataField: "store" },
  { fieldName: "giftcard", dataField: "giftcard" },
  { fieldName: "giftcardMobile", dataField: "giftcardMobile" },
  { fieldName: "videoHistory", dataField: "videoHistory" },
];

export const SettingLandingBannersHomeHeaderContainer = () => {
  const { bannerHomeData } = useSelector((state) => state.retrieveBannerHome);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const navigate = useNavigate();
  const handleUpdate = async () => {
    let body = { ...bannerHomeData };
    const formData = new FormData();

    await processPhotoFormData(body, formData, photoFields);
    await processPhotosArray(body?.photos, formData, "photos", "photo", "slug");
    processTextFormData(
      body?.phrases,
      formData,
      "phrases",
      "description",
      "slug"
    );

    await requestPatchFileAuthorization("admin/banners/home/", formData).then(
      () => {
        navigate("/configuracion/landing/banners");
      }
    );
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del Home" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={"Actualizar Home"}
          onClick={toggleModalUpdate}
        />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Home"
            msg="¿Está seguro que desea actualizar el Home?"
            onClose={toggleModalUpdate}
            onConfirm={handleUpdate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
