import { Link } from "react-router-dom";

export const SettingBoxComponent = ({ url, title, text, slug }) => {
  return (
    <Link to={slug} className="settingBoxComponent">
      <div className="settingBoxComponent__icon">
        <img src={url} width="50px" height={"50px"} alt={"url"} />
      </div>
      <div className="settingBoxComponent__description">
        <h3 className="settingBoxComponent__description__title">{title}</h3>
        <p className="settingBoxComponent__description__text">{text} </p>
      </div>
    </Link>
  );
};
