import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { clearUpdateComplement } from "../../store/complement";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const ProductComplementDetailHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const complementData = useSelector((state) => state.retrieveComplement);

  const path = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const convertMap = (array) => {
    return array.map((item) => item.id);
  };

  const handleCreateCombo = async () => {
    const body = { ...complementData };
    body.products && (body.products = convertMap(body.products));
    body.order ? (body.order = Number(body.order)) : (body.order = 0);
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/complementos/${path.complementId}/`,
      body,
      token
    ).then(() => {
      dispatch(clearUpdateComplement());
      navigate("/productos/complementos");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Detalle del complemento" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={toggleModalUpdate} />
      </div>

      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Complemento"
            msg="¿Está seguro que desea actualizar el complemento?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
