export const getUrlWithID = (id, url, format) => {
  switch (format) {
    case "format1":
      return `/${url}/${id}/detalles`;
    case "format2":
      return `/${url}/detalles/${id}`;
    case "format3":
      return `/${url}/datos/${id}`;
    default:
      return `/${url}/${id}/detalles`;
  }
};

// export default getUrlWithID;
