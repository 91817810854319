export const getDataStatistics = (years = [], maxDays = 16, maxMonths = 16) => {
  let countDays = 0;
  let countMonths = 0;
  years.map((year) => {
    countMonths = countMonths + year.months.length;
    year.months.map((month) => {
      countDays = countDays + month.days.length;
      return month;
    });
    return year;
  });
  const data = [];
  let isEmptyDataDays = true;
  let isEmptyDataMonths = true;
  years.map((itemYear) => {
    if (countMonths <= maxMonths) {
      itemYear.months.map((itemMonth) => {
        if (countDays <= maxDays) {
          itemMonth.days.map((itemDay) => {
            data.push({
              label: itemDay.day,
              count: itemDay.count,
            });
            isEmptyDataDays = false;
            return itemDay;
          });
        }
        if (isEmptyDataDays) {
          data.push({
            label: itemMonth.month,
            count: itemMonth.count,
          });
          isEmptyDataMonths = false;
        }
        return itemMonth;
      });
    }
    if (isEmptyDataMonths && isEmptyDataDays) {
      data.push({
        label: itemYear.year,
        count: itemYear.count,
      });
    }
    return itemYear;
  });
  return data;
};

export const getDataStatisticsOther = (
  years = [],
  maxDays = 16,
  maxMonths = 16
) => {
  let countDays = 0;
  let countMonths = 0;
  years.map((year) => {
    countMonths = countMonths + year.months.length;
    year.months.map((month) => {
      countDays = countDays + month.days.length;
      return month;
    });
    return year;
  });
  const data = [];
  let isEmptyDataDays = true;
  let isEmptyDataMonths = true;
  years.map((itemYear) => {
    if (countMonths <= maxMonths) {
      itemYear.months.map((itemMonth) => {
        if (countDays <= maxDays) {
          itemMonth.days.map((itemDay) => {
            data.push({
              label: itemDay.day,
              count: itemDay.totalAmount,
            });
            isEmptyDataDays = false;
            return itemDay;
          });
        }
        if (isEmptyDataDays) {
          data.push({
            label: itemMonth.month,
            count: itemMonth.totalAmount,
          });
          isEmptyDataMonths = false;
        }
        return itemMonth;
      });
    }
    if (isEmptyDataMonths && isEmptyDataDays) {
      data.push({
        label: itemYear.year,
        count: itemYear.totalAmount,
      });
    }
    return itemYear;
  });
  return data;
};
