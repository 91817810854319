import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { requestGetAuthorization } from "../../services/requestGet";
import { setCreatedOrderProductGroup } from "../../store/order/ordersSlice";

export const OrderListGroupPresentationContainer = ({ id, indexProduct }) => {
  const [isOpenPresentation, setIsOpenPresentation] = useState(false);
  const [isOpenModifier, setIsOpenModifier] = useState(false);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [groupPresentation, setGroupPresentation] = useState([]);
  const [groupModifier, setGroupModifier] = useState([]);
  const [groupNote, setGroupNote] = useState([]);
  const [formData, setFormData] = useState({
    groupPresentation: null,
    groupModifier: null,
    groupNote: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    requestGetAuthorization(
      `admin/productos/${id}/grupos_presentaciones/`,
      token
    ).then((value) => {
      setGroupPresentation(value[0]);
    });

    requestGetAuthorization(
      `admin/productos/${id}/grupos_modificadores/`,
      token
    ).then((value) => {
      setGroupModifier(value[0]);
    });

    requestGetAuthorization(`admin/productos/${id}/grupos_notas/`, token).then(
      (value) => {
        setGroupNote(value[0]);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnChange = (e, index) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    groupPresentation?.id &&
      dispatch(
        setCreatedOrderProductGroup({
          presentation_group: groupPresentation?.id ?? null,
          indexProduct,
          index: 0,
          presentation:
            e.target.name === "groupPresentation"
              ? e.target.value
              : formData.groupPresentation,
        })
      );
    groupModifier?.id &&
      dispatch(
        setCreatedOrderProductGroup({
          modifier_group: groupModifier?.id ?? null,
          indexProduct,
          index: !groupPresentation?.id ? 0 : 1,
          modifier:
            e.target.name === "groupModifier"
              ? e.target.value
              : formData.groupModifier,
        })
      );

    groupNote?.id &&
      dispatch(
        setCreatedOrderProductGroup({
          note_group: groupNote?.id ?? null,
          indexProduct,
          index:
            !groupPresentation?.id && !groupModifier?.id
              ? 0
              : !groupModifier?.id
              ? 1
              : 2,
          note:
            e.target.name === "groupNote" ? e.target.value : formData.groupNote,
        })
      );
  };

  return (
    <div className="orderListGroupPresentationContainer">
      {groupPresentation?.presentaciones?.length > 0 && (
        <div className="orderListGroupPresentationContainer__groups">
          {groupPresentation?.name && (
            <div
              className="orderListGroupPresentationContainer__groups__container"
              onClick={() => setIsOpenPresentation(!isOpenPresentation)}
            >
              <div className="orderListGroupPresentationContainer__groups__container__title">
                {groupPresentation?.name}{" "}
              </div>
              <div className="orderListGroupPresentationContainer__groups__container__span">
                <span>{isOpenPresentation ? " - " : "+"}</span>
              </div>
            </div>
          )}
          {isOpenPresentation &&
            groupPresentation.presentaciones.map((item, index) => (
              <div
                key={item.id}
                className="orderListGroupPresentationContainer__groups__body"
              >
                <div className="orderListGroupPresentationContainer__groups__body__left">
                  <input
                    type="radio"
                    name="groupPresentation"
                    value={item.id}
                    onChange={(e) => handleOnChange(e, index)}
                  />
                  <label>{item.name}</label>
                </div>
                <div className="orderListGroupPresentationContainer__groups__body__right">
                  <label>s/ {item.amount.toFixed(2)}</label>
                </div>
              </div>
            ))}
        </div>
      )}

      {groupModifier?.modificadores?.length > 0 && (
        <div className="orderListGroupPresentationContainer__groups">
          {groupModifier?.name && (
            <div
              className="orderListGroupPresentationContainer__groups__container"
              onClick={() => setIsOpenModifier(!isOpenModifier)}
            >
              <div className="orderListGroupPresentationContainer__groups__container__title">
                {groupModifier?.name}{" "}
              </div>
              <div className="orderListGroupPresentationContainer__groups__container__span">
                {isOpenPresentation ? <div>-</div> : <div>+</div>}
              </div>
            </div>
          )}
          {isOpenModifier &&
            groupModifier.modificadores.map((item, index) => (
              <div
                key={item.id}
                className="orderListGroupPresentationContainer__groups__body"
              >
                <div className="orderListGroupPresentationContainer__groups__body__left">
                  <input
                    type="radio"
                    name="groupModifier"
                    value={item.id}
                    onChange={(e) => handleOnChange(e, index)}
                  />
                  <label>{item.name}</label>
                </div>
                <div className="orderListGroupPresentationContainer__groups__body__right">
                  <label>s/ {item.amount.toFixed(2)}</label>
                </div>
              </div>
            ))}
        </div>
      )}

      {groupNote?.notas?.length > 0 && (
        <div className="orderListGroupPresentationContainer__groups">
          {groupNote?.name && (
            <div
              className="orderListGroupPresentationContainer__groups__container"
              onClick={() => setIsOpenNote(!isOpenNote)}
            >
              <div className="orderListGroupPresentationContainer__groups__container__title">
                {groupNote?.name}{" "}
              </div>
              <div className="orderListGroupPresentationContainer__groups__container__span">
                <span>{isOpenPresentation ? " - " : "+"}</span>
              </div>
            </div>
          )}
          {isOpenNote &&
            groupNote.notas.map((item, index) => (
              <div
                key={item.id}
                className="orderListGroupPresentationContainer__groups__body"
              >
                <div className="orderListGroupPresentationContainer__groups__body__left">
                  <input
                    type="radio"
                    name="groupNote"
                    value={item.id}
                    onChange={(e) => handleOnChange(e, index)}
                  />
                  <label>{item.name}</label>
                </div>
                <div className="orderListGroupPresentationContainer__groups__body__right">
                  <label>s/ {item.amount.toFixed(2)}</label>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
