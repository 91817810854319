import {
  ButtonPushComponent,
  CodeBlockComponent,
  MessageComponent,
  TitleComponent,
} from "../../components";

export const ModalContainer = ({
  onClose,
  onConfirm,
  title = "Title",
  msg = "Message",
  style = {
    fontSize: "20px",
    fontWeight: "bold",
  },
  buttonBack = false,
  handleNavigate,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className="modalContainer">
      <TitleComponent title={title} style={style} />
      {!buttonBack && <MessageComponent msg={msg} />}

      {buttonBack && <CodeBlockComponent code={msg} />}

      <div className="modalContainer__button">
        {!buttonBack ? (
          <>
            <ButtonPushComponent
              name={"Cancelar"}
              color={"grey"}
              onClick={onClose}
            />
            <ButtonPushComponent name={"Confirmar"} onClick={handleConfirm} />
          </>
        ) : (
          <ButtonPushComponent name={"Regresar"} onClick={handleNavigate} />
        )}
      </div>
    </div>
  );
};
