import {
  SettingLandingBannersHomeHeaderContainer,
  SettingLandingBannersHomeMainContainer,
} from "../../../../../containers";

export const SettingLandingBannersHomeDetailPage = () => {
  return (
    <section className="productDetailPage">
      <SettingLandingBannersHomeHeaderContainer />
      <SettingLandingBannersHomeMainContainer />
    </section>
  );
};
