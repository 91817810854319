import orderStatus from "../../data/orderStatus.json";
import stores from "../../data/store.json";
import modalities from "../../data/modality.json";
import { useNavigate } from "react-router-dom";
import {
  ButtonSearchComponent,
  FilterComponent,
  InputSearchComponent,
} from "../../components";
export const OrderSearchContainer = () => {
  const navigate = useNavigate();
  const filter = () => {
    const state = document.getElementById("orderState").value;
    const shop = document.getElementById("orderShop").value;
    const modality = document.getElementById("orderModality").value;
    const search = document.getElementById("searchOrder").value;
    let url = `/ordenes?`;
    if (state !== "null") {
      url = url.concat(`state=${state}`);
    }
    if (shop !== "null") {
      url = url.concat(`&shop=${shop}`);
    }
    if (modality !== "null") {
      url = url.concat(`&modality=${modality}`);
    }
    if (search !== "null") {
      url = url.concat(`&search=${search}`);
    }
    navigate(url);
  };

  const handleEnter = (e) => {
    if (e.key !== "Enter") return;
    filter();
  };

  const dataInput = { id: "searchOrder", placeholder: "Buscar orden" };
  return (
    <div className="orderSearchContainer">
      <div className="orderSearchContainer__filters">
        <div className="orderSearchContainer__filters__item">
          <FilterComponent
            data={orderStatus}
            name={"Estado"}
            id={"orderState"}
          />
        </div>
        <div
          className="orderSearchContainer__filters__item"
          style={{ margin: "0px" }}
        >
          <FilterComponent data={stores} name={"Tienda"} id={"orderShop"} />
        </div>
        <div className="orderSearchContainer__filters__item">
          <FilterComponent
            data={modalities}
            name={"Modalidad"}
            id={"orderModality"}
          />
        </div>
      </div>
      <div className="orderSearchContainer__search">
        <div className="orderSearchContainer__search__input">
          <InputSearchComponent data={dataInput} onKeyDown={handleEnter} />
        </div>
        <div className="orderSearchContainer__search__button">
          <ButtonSearchComponent filter={filter} />
        </div>
      </div>
    </div>
  );
};
