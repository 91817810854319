import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { useNavigate } from "react-router-dom";

export const SettingCoffeeShopSuggestiveHeaderContainer = () => {
  const { suggestiveData } = useSelector((state) => state.retrieveSuggestive);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };

  const convertMap = (array) => {
    return array.map((item) => item.id);
  };
  const navigate = useNavigate();
  const handleUpdate = async () => {
    let body = { ...suggestiveData };
    body.products && (body.products = convertMap(body.products));
    const token = localStorage.getItem("token");
    requestPatchAuthorization("admin/ventas_sugestivas/", body, token).then(
      () => navigate("/configuracion/cafeteria")
    );
  };
  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Ventas Sugestivas" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Guardar"} onClick={toggleModalUpdate} />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Ventas"
            msg="¿Está seguro que desea actualizar Ventas?"
            onClose={toggleModalUpdate}
            onConfirm={handleUpdate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
