import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { useNavigate, useParams } from "react-router-dom";
import { requestPatchAuthorization } from "../../services/requestPatch";
import { requestPostAuthorization } from "../../services/requestPost";

export const SettingLandingCollaboratorsHeaderContainer = () => {
  const { collaboratorsData } = useSelector(
    (state) => state.retrieveCollaborators
  );
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const navigate = useNavigate();
  const path = useParams();
  const { collaboratorId } = path;
  const handleUpdate = async () => {
    let body = { ...collaboratorsData };
    const token = localStorage.getItem("token");
    requestPatchAuthorization(
      `admin/colaboradores/${collaboratorId}/`,
      body,
      token
    ).then(() => {
      navigate("/configuracion/landing/colaboradores");
    });
  };

  const handleCreate = async () => {
    let body = { ...collaboratorsData };
    requestPostAuthorization(`admin/colaboradores/`, body).then(() => {
      navigate("/configuracion/landing/colaboradores");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Colaboradores" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent
          name={`${
            collaboratorId ? "Actualizar Colaborador" : "Crear Colaborador"
          }`}
          onClick={toggleModalUpdate}
        />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Actualizar Colaborador"
            msg="¿Está seguro que desea actualizar Colaborador?"
            onClose={toggleModalUpdate}
            onConfirm={collaboratorId ? handleUpdate : handleCreate}
          />
        </ModalLayout>
      )}
    </section>
  );
};
