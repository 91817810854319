import { createSlice } from "@reduxjs/toolkit";
export const listReservationSlice = createSlice({
  name: "listReservation",
  initialState: {
    reservationData: [],
    showLoading: false,
  },
  reducers: {
    setListReservation: (state, action) => {
      state.reservationData = action.payload.data;
    },
    setReservationLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setListReservation, setReservationLoading } =
  listReservationSlice.actions;

export const retrieveReservationSlice = createSlice({
  name: "retrieveReservation",
  initialState: {
    reservationData: {
      name: "",
      email: "",
      document: "",
      phone: "",
      shop: "",
      date: "",
      time: "",
      numberPerson: 1,
      reservationReason: "",
      description: "",
      state: "",
      created: "",
    },
    showLoading: false,
  },
  reducers: {
    setRetrieveReservation: (state, action) => {
      state.reservationData.name =
        action.payload?.data?.name || state?.reservationData?.name;
      state.reservationData.email =
        action.payload?.data?.email || state?.reservationData?.email;
      state.reservationData.document =
        action.payload?.data?.document || state?.reservationData?.document;
      state.reservationData.phone =
        action.payload?.data?.phone || state?.reservationData?.phone;
      state.reservationData.shop =
        action.payload?.data?.shop || state?.reservationData?.shop;
      state.reservationData.date =
        action.payload?.data?.date || state?.reservationData?.date;
      state.reservationData.time =
        action.payload?.data?.time || state?.reservationData?.time;
      state.reservationData.numberPerson =
        action.payload?.data?.numberPerson ||
        state?.reservationData?.numberPerson;
      state.reservationData.reservationReason =
        action.payload?.data?.reservationReason ||
        state?.reservationData?.reservationReason;
      state.reservationData.created =
        action.payload?.data?.created || state?.reservationData?.created;
      state.reservationData.description =
        action.payload?.data?.description ||
        state?.reservationData?.description;
      state.reservationData.state =
        action.payload?.data?.state || state?.reservationData?.state;
    },
    setRetrieveReservationLoading: (state, action) => {
      state.showLoading = !action.payload.showLoading;
    },
  },
});
export const { setRetrieveReservation, setRetrieveReservationLoading } =
  retrieveReservationSlice.actions;
