import { TableListContainer, TableSearchContainer } from "..";

export const TableContainer = ({
  filter,
  handleKeyDown,
  dataInput,
  data,
  url,
  titles,
  isFilter,
}) => {
  return (
    <div className="clientListContainer">
      <div className="clientListContainer__search">
        <TableSearchContainer
          filter={filter}
          handleKeyDown={handleKeyDown}
          dataInput={dataInput}
          isFilter={isFilter}
        />
      </div>
      <div className="clientListContainer__table">
        <TableListContainer data={data} url={url} titles={titles} />
      </div>
    </div>
  );
};
